<template>
  <div>
    <div
      v-if="readOnly && isLoading"
      class="d-flex justify-center align-center"
    >
      <v-progress-circular indeterminate size="50" color="grey">
      </v-progress-circular>
    </div>
    <v-card
      v-if="document && (!readOnly || !isLoading)"
      :class="[
        'editsection',
        'no_print_border',
        'printFullWidth',
        readOnly ? 'readOnly' : '',
        show_suggestions ? 'hideMargins' : '',
        show_database ? 'hideMargins' : '',
        use_jd_flow ? 'flowDesignPanel' : '',
        !use_jd_flow && (show_database || show_suggestions)
          ? 'databaseViewPanel'
          : '',
      ]"
    >
      <v-card-title v-if="use_jd_flow">
        <v-row :class="flowStyling('header')" dense no-gutters>
          <v-col cols="12" class="pa-0 col">
            <div class="d-flex row-part">
              <h2
                class="col-part"
                style="
                  padding: 0px 10px;
                  padding-left: 73px;
                  padding-bottom: 15px;
                "
              >
                {{ active_section }}
              </h2>
              <div
                class="part-action"
                style="padding-right: 115px; display: inline-flex"
              >
                <v-btn
                  icon
                  color="green"
                  v-if="sectionHasSuggestions()"
                  @click="showSuggestions()"
                  title="Show Suggestions"
                >
                  <v-icon color="green">mdi-auto-fix</v-icon>
                </v-btn>
                <v-btn
                  icon
                  v-if="sectonHasDatabaseParts()"
                  @click="showDatabase()"
                  title="Show Database"
                >
                  <v-icon>mdi-database-cog</v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text
        :class="[
          useFlowStyling('') ? 'flow_design_panel' : '',
          'pa-1',
          'userPilotFullDocument',
        ]"
      >
        <v-row
          dense
          v-for="pt in activeParts()"
          :key="pt.type + pt.tmpl_part_id"
        >
          <v-col class="pa-0">
            <v-row dense class="py-0" v-if="!newUi && !readOnly">
              <v-col cols="10" :class="['gutter', pt.layoutTags[0]]"
                ><v-icon
                  style="margin-left: -19px"
                  small
                  :title="'View deleted ' + pt.type + ' rows'"
                  v-if="pt.tmpl_part_metadata.multi_row && pt.hasDeletedParts"
                  @click="showDeletedPartHistory(pt)"
                  >mdi-history</v-icon
                >
                <span style="float: right">{{ pt.type }}</span>
              </v-col>
            </v-row>
            <draggable
              :list="pt.parts"
              ghost-class="ghost"
              handle=".dragHandle"
              :disabled="
                readOnly ||
                !document.state.canEditDocParts ||
                editInProgress.active
              "
              :move="canMove"
              @change="
                (e) => {
                  return movePart(e, pt);
                }
              "
              :group="{ name: 'pt' }"
              @start="draggedParent = null"
              @end="dragging = false"
            >
              <div
                :id="'dp' + p.doc_part_id"
                dense
                v-for="(p, ip) in pt.parts"
                :key="document.system_number + 'ip' + ip"
                :class="[
                  is_section_active(pt),
                  flowStyling(''),
                  flowStyling('line'),
                ]"
              >
                <div
                  v-for="(sp, isp) in p.subParts"
                  :key="document.system_number + 'ip' + ip + '_' + isp"
                  :class="[
                    'd-flex',
                    'row-part',
                    sp.type === 'listitem' ? 'ListItemRow' : '',
                    getCssStyles(pt.layoutTags),
                  ]"
                >
                  <div v-if="!readOnly" class="part text-center col-icons">
                    <div
                      class="hidden-sm-and-down"
                      v-if="
                        isp === 0 && ip === 0 && document.state.canEditDocParts
                      "
                    >
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            small
                            v-if="
                              pt.tmpl_part_metadata.why_text && hintsVisible
                            "
                            v-bind="attrs"
                            v-on="on"
                            @click="showHint(pt, true)"
                            aria-label="View Insights"
                            >mdi-lightbulb-outline</v-icon
                          >
                        </template>
                        <span>View Insights</span>
                      </v-tooltip>
                    </div>
                    <div
                      class="hidden-sm-and-down"
                      v-if="
                        isp === 0 && ip === 0 && document.state.canEditDocParts
                      "
                    >
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-if="
                              pt.tmpl_part_metadata.how_to_text && hintsVisible
                            "
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click="showHint(pt, false)"
                            aria-label="Guidance"
                            >mdi-help-circle-outline</v-icon
                          >
                        </template>
                        <span>Guidance</span>
                      </v-tooltip>
                    </div>
                    <div
                      class="hidden-sm-and-down"
                      v-if="
                        isp === 0 &&
                        ip === 0 &&
                        document.state.canEditDocParts &&
                        newUi &&
                        pt.tmpl_part_metadata.multi_row &&
                        pt.hasDeletedParts
                      "
                    >
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            small
                            @click="showDeletedPartHistory(pt)"
                            v-bind="attrs"
                            v-on="on"
                            aria-label="View deleted"
                            >mdi-history</v-icon
                          >
                        </template>
                        <span>{{ "View deleted " + pt.type + " rows" }}</span>
                      </v-tooltip>
                    </div>
                    <div
                      class="workingPattern"
                      v-if="
                        isp === 0 &&
                        ip === 0 &&
                        document.state.canEditDocParts &&
                        !readOnly &&
                        !p.editing &&
                        (pt.type === 'Working Pattern' ||
                          flexPartId === pt.tmpl_part_id)
                      "
                    >
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="success"
                            fab
                            x-small
                            title="Run Flex Assessment"
                          >
                            <v-icon
                              @click="rFlex()"
                              v-bind="attrs"
                              v-on="on"
                              color="white"
                              style="cursor: pointer; font-size: 2.1em"
                              aria-label="Run Flex Assessment"
                              >mdi-electron-framework</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Run Flex Assessment</span>
                      </v-tooltip>
                    </div>
                    <div
                      v-if="
                        isp === 0 &&
                        ip === 0 &&
                        document.state.canEditDocParts &&
                        !newUi &&
                        !readOnly &&
                        p.hover &&
                        (pt.tmpl_part_metadata.tp_locked || p.locked)
                      "
                    >
                      <v-icon
                        title="Content Locked"
                        class="locked"
                        color="grey lighten-2"
                        x-small
                        >mdi-lock</v-icon
                      >
                    </div>
                    <div
                      v-if="newUi && canDrag(pt, sp) && !partActionDragDense"
                    >
                      <v-icon
                        :class="
                          editInProgress.active
                            ? 'dragHandleDisabled'
                            : 'dragHandle'
                        "
                        >mdi-drag</v-icon
                      >
                    </div>
                  </div>
                  <div class="part col-part">
                    <v-divider
                      v-if="pt.layoutTags.includes('LineBefore')"
                      class="section-divider"
                    />
                    <v-row dense>
                      <v-col
                        :class="[
                          document.state.canEditDocParts
                            ? sp.text ||
                              p.editing ||
                              (p.attributes && p.attributes.length)
                              ? 'editArea'
                              : 'editAreaBlank'
                            : 'editAreaReadOnly',
                          p.highlightSection ? 'highlightSection' : '',
                          p.editing
                            ? 'partBorderEdit'
                            : p.hover &&
                              !pt.tmpl_part_metadata.tp_locked &&
                              !p.locked
                            ? 'partBorderHover'
                            : pt.layoutTags.includes('Border')
                            ? 'partBorderPersist'
                            : 'partBorder',
                          pt.tmpl_part_metadata.tp_locked || p.locked
                            ? 'part-locked'
                            : '',
                          isPartOptional(p.tmpl_part_id) ? 'optional' : '',
                          pt.layoutTags.includes('TagList') ? 'TagList' : '',
                        ]"
                      >
                        <v-row dense>
                          <v-col cols="12" class="part">
                            <v-row dense>
                              <v-col
                                cols="1"
                                v-if="
                                  !pt.layoutTags.includes('InnerGutter') &&
                                  (pt.tmpl_part_metadata.multi_row || sp.type === 'listitem')
                                "
                                :class="[
                                  'part',
                                  newUi &&
                                  canDrag(pt, sp) &&
                                  partActionDragDense
                                    ? 'ListPartDrag'
                                    : 'ListPart',
                                ]"
                              >
                                <div
                                  v-if="
                                    newUi &&
                                    canDrag(pt, sp) &&
                                    partActionDragDense
                                  "
                                >
                                  <v-icon
                                    x-small
                                    :class="
                                      editInProgress.active
                                        ? 'dragHandleDisabled'
                                        : 'dragHandle'
                                    "
                                    @mouseenter="partHasHover(pt, p)"
                                    @mouseleave="partLostHover(pt, p)"
                                    >mdi-drag-variant</v-icon
                                  >
                                </div>
                              </v-col>
                              <v-col
                                cols="1"
                                v-else-if="
                                  pt.tmpl_part_metadata.layout
                                    .split('|')[2]
                                    .indexOf('AttrHeader') === 0
                                "
                                class="AttrHeaderIndent"
                              ></v-col>
                              <v-col
                                cols="2"
                                v-if="
                                  pt.layoutTags.includes('InnerGutter') &&
                                  p.visible !== false
                                "
                                :class="['gutter', pt.layoutTags[0]]"
                              >
                                <div
                                  v-if="
                                    newUi &&
                                    canDrag(pt, sp) &&
                                    partActionDragDense
                                  "
                                >
                                  <v-icon
                                    x-small
                                    :class="
                                      editInProgress.active
                                        ? 'dragHandleDisabled'
                                        : 'dragHandle'
                                    "
                                    @mouseenter="partHasHover(pt, p)"
                                    @mouseleave="partLostHover(pt, p)"
                                    >mdi-drag-variant</v-icon
                                  >
                                </div>
                                {{ pt.type }}
                              </v-col>
                              <v-col
                                @mouseenter="partHasHover(pt, p)"
                                @mouseleave="partLostHover(pt, p)"
                                :class="{
                                  part: true,
                                  'part-text': true,
                                  hasEssential:
                                    pt.tmpl_part_metadata.uses_essential_flag,
                                  highlightDragEnd:
                                    (show_database || show_suggestions) &&
                                    isDragInProgress &&
                                    document.state.canEditDocParts &&
                                    isNlpFunctionAvailable('drag', p) &&
                                    !pt.tmpl_part_metadata.tp_locked,
                                  highlightDragEndHover:
                                    (show_database || show_suggestions) &&
                                    isDragInProgress &&
                                    document.state.canEditDocParts &&
                                    pt.tmpl_part_id === dragTargetPart &&
                                    isNlpFunctionAvailable('drag', p) &&
                                    !pt.tmpl_part_metadata.tp_locked,
                                }"
                                @dragover.prevent
                                @dragenter="highlightDragTarget(pt, $event)"
                                @drop="dropItem(pt, p, $event)"
                              >
                                <DocumentPartTagList
                                  v-if="pt.layoutTags.includes('TagList')"
                                  :readOnly="
                                    readOnly || !document.state.canEditDocParts
                                  "
                                  :value="p.text"
                                  :restrict_to_lookup="
                                    pt.tmpl_part_metadata.restrict_to_lookup
                                  "
                                  :tmpl_part_id="pt.tmpl_part_id"
                                  :dp_id="p.dp_id"
                                  @changed="
                                    inLineChange(
                                      $event,
                                      pt,
                                      p,
                                      sp,
                                      undefined,
                                      true
                                    )
                                  "
                                >
                                </DocumentPartTagList>
                                <div
                                  v-else-if="
                                    !p.editing ||
                                    (p.attributes && p.attributes.length)
                                  "
                                  style="
                                    display: -ms-flex;
                                    display: -webkit-flex;
                                    display: flex;
                                  "
                                >
                                  <div
                                    v-if="
                                      (!p.editing ||
                                        (p.attributes &&
                                          p.attributes.length)) &&
                                      pt.tmpl_part_metadata
                                    "
                                    style="min-height: 25px; flex: 1"
                                    :class="[
                                      ...pt.layoutTags,
                                      sp.text ||
                                      (p.attributes && p.attributes.length)
                                        ? ''
                                        : 'emptyText',
                                      'part-data',
                                      pt.layoutTags.includes('HeaderPart')
                                        ? 'userPilot' +
                                          p.text.replace(/\s+/g, '')
                                        : '',
                                    ]"
                                  >
                                    <span
                                      v-if="
                                        !p.attributes || !p.attributes.length
                                      "
                                      style="width: 100%"
                                      @click="inLineEdit(pt, p, sp)"
                                      @focus="inLineEdit(pt, p, sp)"
                                      :tabindex="
                                        readOnly ||
                                        !document.state.canEditDocParts ||
                                        pt.tmpl_part_metadata.tp_locked ||
                                        p.locked
                                          ? -1
                                          : 0
                                      "
                                      v-html="
                                        applyReadOnlyHighlights(
                                          sp.text,
                                          pt.tmpl_part_metadata,
                                          p
                                        )
                                      "
                                    />
                                    <v-row v-else no-gutters>
                                      <v-col
                                        v-for="(a, ai) in p.attributes"
                                        :key="
                                          document.system_number +
                                          'ip' +
                                          ip +
                                          '_' +
                                          isp +
                                          'a' +
                                          ai
                                        "
                                        :cols="a.cols"
                                        @click="inLineEdit(pt, p, sp, false, a)"
                                        @focus="inLineEdit(pt, p, sp, false, a)"
                                        :tabindex="
                                          readOnly ||
                                          !document.state.canEditDocParts ||
                                          pt.tmpl_part_metadata.tp_locked ||
                                          p.locked ||
                                          a.locked
                                            ? -1
                                            : 0
                                        "
                                        style="padding-right: 20px"
                                        :class="[
                                          a.text ? '' : 'emptyText',
                                          a.text || !a.required
                                            ? ''
                                            : 'attrAreaBlank',
                                          a.text === document.doc_name
                                            ? 'thisJob'
                                            : '',
                                          a.text === 'Organisational Structure'
                                            ? 'hierHeading'
                                            : '',
                                          getCssStyle(a.css_class_name),
                                        ]"
                                      >
                                        <TagAttribute
                                          v-if="a.tag_type_id"
                                          :attribute="a"
                                          :editable="
                                            !readOnly && !a.locked && !p.locked
                                          "
                                          @changed="
                                            inLineChange(
                                              $event,
                                              pt,
                                              p,
                                              null,
                                              a,
                                              true
                                            )
                                          "
                                          @cancel="inLineTextCancel(p, sp, a)"
                                        ></TagAttribute>
                                        <span
                                          v-else-if="
                                            (!a.editing && !a.allow_adding) ||
                                            (!a.editing &&
                                              a.allow_adding &&
                                              a.css_class_name == null)
                                          "
                                          v-html="
                                            applyReadOnlyHighlights(
                                              null,
                                              pt.tmpl_part_metadata,
                                              p,
                                              a
                                            )
                                          "
                                        ></span>
                                        <span
                                          dense
                                          v-else-if="
                                            !a.editing &&
                                            a.allow_adding &&
                                            a.css_class_name === 'chips'
                                          "
                                        >
                                          <v-combobox
                                            class="inLineEdit"
                                            dense
                                            :value="a.text"
                                            append-icon=""
                                            style="padding-top: 0px"
                                            small-chips
                                            flat
                                            solo
                                          >
                                          </v-combobox>
                                        </span>
                                        <TextHighlight
                                          v-else
                                          hideBorder
                                          :value="a.text"
                                          :showActionIcons="true"
                                          :lookup="lookup"
                                          :restrict_to_lookup="
                                            a.restrict_to_lookup
                                          "
                                          :rank_attribute="a.rank_attribute"
                                          :restrict_to_date="a.restrict_to_date"
                                          :restrict_to_htid="{
                                            ht_id: a.restrict_to_htid,
                                            hr_selected:
                                              document.hierarchies.find(
                                                (dh) =>
                                                  dh.ht_id ===
                                                  a.restrict_to_htid
                                              ),
                                            canadd:
                                              document.state.ht_id_can_add_node.some(
                                                (h) => h === a.restrict_to_htid
                                              ),
                                          }"
                                          :multi_select="a.multi_select"
                                          :multi_select_separator="
                                            a.multi_select_separator
                                          "
                                          :attribute="a"
                                          :words="
                                            getWordChecksForPart(
                                              pt.tmpl_part_metadata
                                                .tmpl_part_id,
                                              a
                                            )
                                          "
                                          :restrictedChars="
                                            getRestrictedCharsForPart(pt)
                                          "
                                          :placeholder="a.placeholder"
                                          :clearable="true"
                                          @changed="
                                            inLineChange($event, pt, p, null, a)
                                          "
                                          @initialised="a.editing = true"
                                          @save="
                                            inLineTextSave(pt, p, sp, false, a)
                                          "
                                          @addNewLookup="
                                            addNewLookup(pt, p, sp, a)
                                          "
                                          @escape="inLineTextCancel(p, sp, a)"
                                          :allow_adding="a.allow_adding"
                                          :css_class_name="a.css_class_name"
                                          :spellCheckData="
                                            getSpellChecks(
                                              pt.tmpl_part_metadata
                                                .tmpl_part_id,
                                              p.doc_part_id
                                            )
                                          "
                                          :allow_suggestions="show_suggestions"
                                          :use_jd_flow="use_jd_flow"
                                          @debounce="
                                            (data) =>
                                              addDebounceLanguage(pt, p, data)
                                          "
                                          @doSpellCheckReplace="
                                            (oldVal) =>
                                              doSpellCheckTextReplace(
                                                pt,
                                                oldVal
                                              )
                                          "
                                          @debounceHover="
                                            (data) =>
                                              debounceHoverEvent(data, pt, p)
                                          "
                                          @debounceCancel="cancelDebounceHover"
                                          :spell_check_language_code="
                                            document.spell_check_language_code
                                          "
                                          @languageIssue="
                                            (data) =>
                                              showLanguageMenu(data, pt, p)
                                          "
                                          :source="{
                                            doc_part_id: p.doc_part_id,
                                            tmpl_part_id: pt.tmpl_part_id,
                                          }"
                                          :suggestionType="
                                            editInProgress.suggestionType
                                          "
                                          :summarise="editInProgress.summarise"
                                          :document_language="documentLanguage"
                                          :docId="document.doc_id"
                                        ></TextHighlight>
                                      </v-col>
                                    </v-row>
                                  </div>
                                  <div
                                    :class="[
                                      'partActionHolder',
                                      p.attributes && p.attributes.length
                                        ? 'hasAttributes'
                                        : '',
                                    ]"
                                  >
                                    <div
                                      class="part-action"
                                      v-if="
                                        pt.childPartTypes &&
                                        p.childParts &&
                                        pt.childPartTypes.length &&
                                        !p.expanded &&
                                        p.childParts.length
                                      "
                                      @click="expandPart(p, pt)"
                                    >
                                      <v-badge
                                        color="grey lighten-1"
                                        overlap
                                        :content="
                                          p.childParts && p.childParts.length
                                        "
                                        dense
                                      >
                                        <v-btn
                                          icon
                                          small
                                          style="float: right"
                                          title="Show sub-parts"
                                          @click="expandPart(p, pt)"
                                        >
                                          <v-icon small
                                            >mdi-chevron-down</v-icon
                                          ></v-btn
                                        >
                                      </v-badge>
                                    </div>
                                    <div
                                      class="part-action"
                                      v-if="
                                        pt.childPartTypes &&
                                        p.childParts &&
                                        pt.childPartTypes.length &&
                                        !p.expanded &&
                                        !p.childParts.length
                                      "
                                    >
                                      <v-icon
                                        title="Show sub-parts"
                                        small
                                        @click="expandPart(p, pt)"
                                        color="red"
                                        >mdi-chevron-down</v-icon
                                      >
                                    </div>
                                    <div
                                      class="part-action"
                                      v-if="
                                        pt.childPartTypes &&
                                        pt.childPartTypes.length &&
                                        p.expanded
                                      "
                                    >
                                      <v-icon small @click="shrinkPart(p)"
                                        >mdi-chevron-up</v-icon
                                      >
                                    </div>
                                    <div
                                      class="part-action"
                                      v-if="pt.spellChecking === true"
                                    >
                                      <v-progress-circular
                                        :width="2"
                                        :size="25"
                                        color="green"
                                        indeterminate
                                      ></v-progress-circular>
                                    </div>
                                    <v-tooltip
                                      top
                                      v-if="
                                        pt.tmpl_part_metadata.suggestion_part &&
                                        !readOnly &&
                                        document.state.canEditDocParts &&
                                        useOpenAISuggestions &&
                                        !(
                                          pt.tmpl_part_metadata.tp_locked ||
                                          p.locked
                                        )
                                      "
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-icon
                                          v-bind="attrs"
                                          v-on="on"
                                          :style="`padding-left: 2px; ${
                                            p.hover ? '' : 'visibility: hidden'
                                          }`"
                                          :class="
                                            'userPilotSuggestions' +
                                            pt.type.replace(/\s+/g, '')
                                          "
                                          color="green"
                                          @click="showSuggestionsNew(pt, p, sp)"
                                          aria-label="Generate AI Suggestions"
                                        >
                                          mdi-auto-fix
                                        </v-icon>
                                      </template>
                                      <div style="max-width: 500px">
                                        Generate AI Suggestions
                                      </div>
                                    </v-tooltip>
                                    <div
                                      v-if="
                                        isp === 0 &&
                                        partActionMenu &&
                                        !readOnly &&
                                        ((!pt.tmpl_part_metadata.tp_locked &&
                                          !p.locked) ||
                                          (document.state.canEditDocParts &&
                                            p.canAdd))
                                      "
                                      class="part-action"
                                    >
                                      <v-menu bottom left>
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                          ><v-btn
                                            icon
                                            small
                                            v-bind="attrs"
                                            v-on="on"
                                            :class="
                                              'userPilotPartMenuBtn' +
                                              pt.type.replace(/\s+/g, '')
                                            "
                                            title="Menu"
                                          >
                                            <v-icon small
                                              >mdi-dots-vertical</v-icon
                                            ></v-btn
                                          >
                                        </template>
                                        <v-list dense>
                                          <v-list-item
                                            v-if="
                                              document.state.canEditDocParts &&
                                              p.canAdd
                                            "
                                            @click="addPart(p, pt)"
                                            ><v-list-item-icon
                                              style="margin-right: 10px"
                                            >
                                              <v-icon>mdi-plus</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title
                                              >Add new</v-list-item-title
                                            >
                                          </v-list-item>
                                          <v-list-item
                                            v-if="
                                              partsHistory.length &&
                                              document.lifecycle_id &&
                                              document.state
                                                .docPartCommentAction
                                            "
                                            @click="
                                              showPartHistory(p, pt, true)
                                            "
                                            ><v-list-item-icon
                                              style="margin-right: 10px"
                                            >
                                              <v-icon
                                                >mdi-comment-text-outline</v-icon
                                              >
                                            </v-list-item-icon>
                                            <v-list-item-title>{{
                                              document.state
                                                .docPartCommentAction.name
                                            }}</v-list-item-title>
                                          </v-list-item>
                                          <v-list-item
                                            v-if="
                                              allowSummarise &&
                                              sp.text &&
                                              document.state.canEditDocParts &&
                                              !(
                                                pt.tmpl_part_metadata
                                                  .tp_locked || p.locked
                                              )
                                            "
                                            :class="
                                              'userPilotSummarise' +
                                              pt.type.replace(/\s+/g, '')
                                            "
                                            @click="summarise(pt, p, sp)"
                                            ><v-list-item-icon
                                              style="margin-right: 10px"
                                            >
                                              <v-icon>compress</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title
                                              >Summarise</v-list-item-title
                                            >
                                          </v-list-item>
                                          <v-list-item
                                            v-if="partsHistory.length"
                                            @click="showPartHistory(p, pt)"
                                            ><v-list-item-icon
                                              style="margin-right: 10px"
                                            >
                                              <v-icon>mdi-history</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title
                                              >History</v-list-item-title
                                            >
                                          </v-list-item>
                                          <v-list-item
                                            v-if="
                                              document.state.canEditDocParts &&
                                              pt.tmpl_part_metadata
                                                .allow_edit_dialog &&
                                              !pt.tmpl_part_metadata
                                                .tp_locked &&
                                              !p.locked
                                            "
                                            @click="editText(pt, p)"
                                            ><v-list-item-icon
                                              style="margin-right: 10px"
                                            >
                                              <v-icon
                                                >mdi-square-edit-outline</v-icon
                                              >
                                            </v-list-item-icon>
                                            <v-list-item-title
                                              >Edit Notes</v-list-item-title
                                            >
                                          </v-list-item>
                                          <v-list-item
                                            v-if="p.canLock"
                                            @click="toggleLockRow(p, pt, null)"
                                            ><v-list-item-icon
                                              style="margin-right: 10px"
                                            >
                                              <v-icon>{{
                                                p.locked ? "lock_open" : "lock"
                                              }}</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>{{
                                              p.locked ? "Un-Lock" : "Lock"
                                            }}</v-list-item-title>
                                          </v-list-item>
                                          <v-list-item
                                            v-if="
                                              document.state.canEditDocParts &&
                                              p.canDelete
                                            "
                                            @click="deleteRow(p, pt, null)"
                                            ><v-list-item-icon
                                              style="margin-right: 10px"
                                            >
                                              <v-icon>mdi-delete</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title
                                              >Delete</v-list-item-title
                                            >
                                          </v-list-item>
                                          <v-list-item
                                            v-if="
                                              document.state.canEditDocParts &&
                                              summariseEnabled &&
                                              isNlpFunctionAvailable(
                                                'summarise',
                                                p
                                              )
                                            "
                                            @click="nlpSummariseDialog(pt, p)"
                                            ><v-list-item-icon
                                              style="margin-right: 10px"
                                            >
                                              <v-icon>mdi-file-edit</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title
                                              >Summarise Text</v-list-item-title
                                            >
                                          </v-list-item>
                                        </v-list>
                                      </v-menu>
                                    </div>
                                    <div
                                      class="part-action"
                                      v-if="
                                        newUi &&
                                        !readOnly &&
                                        isp === 0 &&
                                        (pt.tmpl_part_metadata.tp_locked ||
                                          p.locked)
                                      "
                                    >
                                      <v-icon v-if="!p.hover"
                                        ><!--spacer--></v-icon
                                      >
                                      <v-tooltip right v-else>
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-icon
                                            v-bind="attrs"
                                            v-on="on"
                                            class="locked"
                                            color="grey lighten-2"
                                            small
                                            aria-label="Content Locked"
                                            >mdi-lock</v-icon
                                          >
                                        </template>
                                        <span>Content Locked</span>
                                      </v-tooltip>
                                    </div>
                                    <div
                                      class="part-action"
                                      v-if="
                                        !partActionMenu &&
                                        partsHistory.length &&
                                        isp === 0
                                      "
                                    >
                                      <v-icon
                                        color="grey darken-1"
                                        small
                                        v-show="p.hover"
                                        @click="showPartHistory(p, pt)"
                                        :title="'View ' + pt.type + ' History'"
                                        >mdi-history</v-icon
                                      >
                                    </div>
                                    <div
                                      class="part-action"
                                      v-if="
                                        !partActionMenu &&
                                        document.state.canEditDocParts &&
                                        pt.tmpl_part_metadata
                                          .allow_edit_dialog &&
                                        isp === 0
                                      "
                                    >
                                      <v-icon
                                        color="grey darken-1"
                                        small
                                        v-show="p.hover"
                                        @click="editText(pt, p)"
                                        :title="
                                          p.attributes &&
                                          p.attributes.length > 0
                                            ? 'Notes'
                                            : 'Edit ' + pt.type
                                        "
                                        >mdi-square-edit-outline</v-icon
                                      >
                                    </div>
                                    <div
                                      class="part-action"
                                      v-if="
                                        !partActionMenu &&
                                        document.state.canEditDocParts &&
                                        isp === 0 &&
                                        p.canAdd
                                      "
                                    >
                                      <v-icon
                                        color="grey darken-1"
                                        small
                                        v-show="p.hover"
                                        @click="addPart(p, pt)"
                                        :title="'Add ' + pt.type"
                                        >mdi-playlist-plus</v-icon
                                      >
                                    </div>
                                    <div
                                      class="part-action"
                                      v-if="
                                        !partActionMenu &&
                                        document.state.canEditDocParts &&
                                        isp === 0 &&
                                        p.canDelete
                                      "
                                    >
                                      <v-icon
                                        color="grey darken-1"
                                        small
                                        v-show="p.hover"
                                        @click="deleteRow(p, pt, null)"
                                        :title="'Delete ' + pt.type"
                                        >mdi-delete</v-icon
                                      >
                                    </div>
                                  </div>
                                </div>
                                <div
                                  v-else
                                  style="
                                    display: -ms-flex;
                                    display: -webkit-flex;
                                    display: flex;
                                  "
                                >
                                  <div
                                    v-if="!p.attribures || !p.attributes.length"
                                    style="flex: 1"
                                  >
                                    <TextHighlight
                                      hideBorder
                                      :value="sp.text"
                                      :showActionIcons="true"
                                      :lookup="lookup"
                                      :restrict_to_lookup="
                                        pt.tmpl_part_metadata.restrict_to_lookup
                                      "
                                      :restrict_to_htid="{
                                        ht_id:
                                          pt.tmpl_part_metadata
                                            .restrict_to_htid,
                                        hr_selected: document.hierarchies.find(
                                          (dh) =>
                                            dh.ht_id ===
                                            pt.tmpl_part_metadata
                                              .restrict_to_htid
                                        ),
                                        canadd:
                                          document.state.ht_id_can_add_node.some(
                                            (h) =>
                                              h ===
                                              pt.tmpl_part_metadata
                                                .restrict_to_htid
                                          ),
                                      }"
                                      :words="
                                        getWordChecksForPart(
                                          pt.tmpl_part_metadata.tmpl_part_id
                                        )
                                      "
                                      :restrictedChars="
                                        getRestrictedCharsForPart(pt)
                                      "
                                      :placeholder="
                                        pt.tmpl_part_metadata.placeholder
                                      "
                                      :clearable="true"
                                      @changed="inLineChange($event, pt, p, sp)"
                                      @initialised="p.editing = true"
                                      @save="
                                        inLineTextSave(
                                          pt,
                                          p,
                                          sp,
                                          editInProgress.isAddPart
                                        )
                                      "
                                      @escape="inLineTextCancel(p, sp)"
                                      :spellCheckData="
                                        getSpellChecks(
                                          pt.tmpl_part_metadata.tmpl_part_id,
                                          p.doc_part_id
                                        )
                                      "
                                      :allow_suggestions="show_suggestions"
                                      :use_jd_flow="use_jd_flow"
                                      @debounce="
                                        (data) =>
                                          addDebounceLanguage(pt, p, data)
                                      "
                                      @doSpellCheckReplace="
                                        (oldVal) =>
                                          doSpellCheckTextReplace(pt, oldVal)
                                      "
                                      @debounceHover="
                                        (data) =>
                                          debounceHoverEvent(data, pt, p)
                                      "
                                      @debounceCancel="cancelDebounceHover"
                                      :spell_check_language_code="
                                        document.spell_check_language_code
                                      "
                                      @languageIssue="
                                        (data) => showLanguageMenu(data, pt, p)
                                      "
                                      :source="{
                                        doc_part_id: p.doc_part_id,
                                        tmpl_part_id: pt.tmpl_part_id,
                                      }"
                                      :suggestionType="
                                        editInProgress.suggestionType
                                      "
                                      :summarise="editInProgress.summarise"
                                      :document_language="documentLanguage"
                                      :docId="document.doc_id"
                                    ></TextHighlight>
                                  </div>
                                  <div class="partActionHolder">
                                    <div
                                      v-if="
                                        isp === 0 &&
                                        partActionMenu &&
                                        !readOnly &&
                                        !pt.tmpl_part_metadata.tp_locked &&
                                        !p.locked
                                      "
                                      class="part-action"
                                    >
                                      <v-menu
                                        bottom
                                        left
                                        :open-on-hover="false"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                          ><v-btn
                                            icon
                                            small
                                            v-bind="attrs"
                                            v-on="on"
                                            title="Menu"
                                          >
                                            <v-icon small
                                              >mdi-dots-vertical</v-icon
                                            ></v-btn
                                          >
                                        </template>
                                        <v-list dense>
                                          <v-list-item
                                            v-if="
                                              document.state.canEditDocParts &&
                                              p.canAdd
                                            "
                                            @click="addPart(p, pt)"
                                            ><v-list-item-icon
                                              style="margin-right: 10px"
                                            >
                                              <v-icon>mdi-plus</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title
                                              >Add new</v-list-item-title
                                            >
                                          </v-list-item>
                                          <!-- <v-list-item
                                            v-if="partsHistory.length"
                                            @click="showPartHistory(p, pt)"
                                            ><v-list-item-icon
                                              style="margin-right: 10px"
                                            >
                                              <v-icon>mdi-history</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title
                                              >History</v-list-item-title
                                            >
                                          </v-list-item>
                                          <v-list-item
                                            v-if="document.state.canEditDocParts && pt.tmpl_part_metadata.allow_edit_dialog"
                                            @click="editText(pt, p)"
                                            ><v-list-item-icon
                                              style="margin-right: 10px"
                                            >
                                              <v-icon
                                                >mdi-square-edit-outline</v-icon
                                              >
                                            </v-list-item-icon>
                                            <v-list-item-title
                                              >Edit Notes</v-list-item-title
                                            >
                                          </v-list-item> -->
                                          <v-list-item
                                            v-if="
                                              document.state.canEditDocParts &&
                                              p.canDelete
                                            "
                                            @click="deleteRow(p, pt, null)"
                                            ><v-list-item-icon
                                              style="margin-right: 10px"
                                            >
                                              <v-icon>mdi-delete</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title
                                              >Delete</v-list-item-title
                                            >
                                          </v-list-item>
                                        </v-list>
                                      </v-menu>
                                    </div>
                                  </div>
                                </div>
                              </v-col>
                              <v-col
                                v-if="
                                  (!p.editing ||
                                    (p.attributes && p.attributes.length)) &&
                                  pt.tmpl_part_metadata.uses_essential_flag &&
                                  isp === 0
                                "
                                :class="[
                                  'part',
                                  tempEssentials ? '' : 'essential',
                                ]"
                                :cols="tempEssentials ? 2 : 1"
                              >
                                <v-checkbox
                                  v-if="
                                    !readOnly &&
                                    document.state.canEditDocParts &&
                                    !(
                                      pt.tmpl_part_metadata.tp_locked ||
                                      p.locked
                                    ) &&
                                    !tempEssentials
                                  "
                                  dense
                                  :disabled="!document.state.canEditDocParts"
                                  v-model="p.is_essential"
                                  label="Essential?"
                                  @mouseup.once="saveEssential(p, pt)"
                                  :class="{
                                    'essential-select': true,
                                    desirableBox: !p.is_essential && p.text,
                                  }"
                                ></v-checkbox>
                                <v-select
                                  v-else-if="
                                    !readOnly &&
                                    document.state.canEditDocParts &&
                                    !(
                                      pt.tmpl_part_metadata.tp_locked ||
                                      p.locked
                                    ) &&
                                    tempEssentials
                                  "
                                  flat
                                  solo
                                  dense
                                  item-text="text"
                                  item-value="value"
                                  label="Essential?"
                                  :items="essentialFlags"
                                  :disabled="!document.state.canEditDocParts"
                                  :class="[
                                    'essential-select',
                                    p.temp && p.is_essential === null
                                      ? 'unselected'
                                      : '',
                                  ]"
                                  v-model="p.is_essential"
                                  style="padding-left: 5px; height: 40px"
                                  @change="saveEssential(p, pt)"
                                ></v-select>
                                <span
                                  v-else-if="!p.temp || p.is_essential !== null"
                                  class="float-right essential-select"
                                  style="font-style: italic; font-size: 12px"
                                >
                                  {{
                                    p.is_essential
                                      ? "Essential"
                                      : "Non Essential"
                                  }}
                                </span>
                              </v-col>
                              <v-col
                                cols="1"
                                v-else-if="
                                  pt.tmpl_part_metadata.layout.split('|')[2] ===
                                  'AttrHeaderEssential'
                                "
                                class="essential part"
                              ></v-col>
                            </v-row>
                            <v-row
                              dense
                              v-if="p.expanded"
                              :class="{
                                allSubParts: true,
                                indent: !p.childPartTypes.some((cpt) =>
                                  cpt.layoutTags.includes('NoIndent')
                                ),
                              }"
                            >
                              <v-col class="part">
                                <v-row
                                  dense
                                  class="subPartRow"
                                  v-for="(cpt, cpti) in p.childPartTypes"
                                  :key="'p' + p.doc_part_id + '_' + cpti"
                                >
                                  <v-col
                                    cols="12"
                                    v-if="cpt.layoutTags.includes('LineBefore')"
                                  >
                                    <v-divider class="section-divider" />
                                  </v-col>
                                  <v-col
                                    :class="[
                                      'subPartGutter',
                                      cpt.layoutTags[0],
                                    ]"
                                    cols="2"
                                  >
                                    {{ cpt.type }}
                                  </v-col>
                                  <v-col class="part">
                                    <draggable
                                      :list="cpt.parts"
                                      :handle="newUi ? '.dragHandle' : ''"
                                      :disabled="
                                        readOnly ||
                                        !document.state.canEditDocParts ||
                                        editInProgress.active
                                      "
                                      ghost-class="ghost"
                                      :move="canMove"
                                      @change="
                                        (e) => {
                                          return movePart(e, cpt, p);
                                        }
                                      "
                                      :group="{ name: 'pt' }"
                                      @start="
                                        draggedParent = {
                                          partType: cpt,
                                          part: p,
                                        }
                                      "
                                      @end="dragging = false"
                                    >
                                      <div
                                        v-for="(cp, icp) in cpt.parts"
                                        :key="
                                          'cp' +
                                          cp.doc_part_id +
                                          'ip' +
                                          cpt.tmpl_part_id +
                                          'icp' +
                                          icp
                                        "
                                      >
                                        <v-row
                                          :id="'dp' + cp.doc_part_id"
                                          dense
                                          v-for="(csp, icsp) in cp.subParts"
                                          :key="
                                            'p' +
                                            p.doc_part_id +
                                            'ip' +
                                            cpt.tmpl_part_id +
                                            'icp' +
                                            icsp +
                                            '_' +
                                            icsp
                                          "
                                          :class="
                                            sp.type === 'listitem'
                                              ? 'ListItemRow'
                                              : ''
                                          "
                                        >
                                          <v-col cols="12" class="part">
                                            <v-row dense>
                                              <v-col
                                                :class="[
                                                  'part',
                                                  document.state.canEditDocParts
                                                    ? csp.text
                                                      ? 'editArea'
                                                      : 'editAreaBlank'
                                                    : 'editAreaReadOnly',
                                                  cp.highlightSection
                                                    ? 'highlightSection'
                                                    : '',
                                                  cp.editing
                                                    ? 'partBorderEdit'
                                                    : cp.hover &&
                                                      !pt.tmpl_part_metadata
                                                        .tp_locked &&
                                                      !cp.locked
                                                    ? 'partBorderHover'
                                                    : 'partBorder',
                                                  cpt.layoutTags.includes(
                                                    'TagList'
                                                  )
                                                    ? 'TagList'
                                                    : '',
                                                  ((!use_jd_flow &&
                                                    show_database) ||
                                                    show_suggestions) &&
                                                  isDragInProgress &&
                                                  document.state
                                                    .canEditDocParts &&
                                                  isNlpFunctionAvailable(
                                                    'drag',
                                                    cpt
                                                  )
                                                    ? 'highlightDragEnd'
                                                    : '',
                                                  ((!use_jd_flow &&
                                                    show_database) ||
                                                    show_suggestions) &&
                                                  isDragInProgress &&
                                                  document.state
                                                    .canEditDocParts &&
                                                  cpt.tmpl_part_id ===
                                                    dragTargetPart &&
                                                  isNlpFunctionAvailable(
                                                    'drag',
                                                    cpt
                                                  )
                                                    ? 'highlightDragEndHover'
                                                    : '',
                                                ]"
                                                @mouseenter="
                                                  partHasHover(pt, cp)
                                                "
                                                @mouseleave="
                                                  partLostHover(pt, cp)
                                                "
                                                @dragover.prevent
                                                @dragenter="
                                                  highlightDragTarget(
                                                    cpt,
                                                    $event
                                                  )
                                                "
                                                @drop="
                                                  dropItem(cpt, cp, $event)
                                                "
                                              >
                                                <v-row dense>
                                                  <v-col
                                                    cols="1"
                                                    v-if="
                                                      newUi &&
                                                      canDrag(cpt, csp) &&
                                                      !partActionDragDense
                                                    "
                                                    style="max-width: 40px"
                                                  >
                                                    <v-icon
                                                      :class="
                                                        editInProgress.active
                                                          ? 'dragHandleDisabled'
                                                          : 'dragHandle'
                                                      "
                                                      >mdi-drag</v-icon
                                                    >
                                                  </v-col>
                                                  <v-col
                                                    cols="1"
                                                    v-if="
                                                      !cpt.layoutTags.includes(
                                                        'TagList'
                                                      ) &&
                                                      (cpt.cardinality
                                                        .maxError > 1 ||
                                                        csp.type === 'listitem')
                                                    "
                                                    :class="[
                                                      'part',
                                                      newUi &&
                                                      canDrag(pt, sp) &&
                                                      partActionDragDense
                                                        ? 'ListPartDrag'
                                                        : 'ListPart',
                                                    ]"
                                                    :style="{
                                                      'list-style-image':
                                                        cpt.tmpl_part_metadata
                                                          .uses_essential_flag &&
                                                        !cp.is_essential &&
                                                        cp.text
                                                          ? 'url(' +
                                                            require('@/assets/static/red-x.png') +
                                                            ')'
                                                          : '',
                                                    }"
                                                  >
                                                    <div
                                                      v-if="
                                                        newUi &&
                                                        canDrag(pt, sp) &&
                                                        partActionDragDense
                                                      "
                                                    >
                                                      <v-icon
                                                        x-small
                                                        :color="
                                                          cpt.tmpl_part_metadata
                                                            .uses_essential_flag &&
                                                          !cp.is_essential &&
                                                          cp.text
                                                            ? 'red'
                                                            : ''
                                                        "
                                                        :class="
                                                          editInProgress.active
                                                            ? 'dragHandleDisabled'
                                                            : 'dragHandle'
                                                        "
                                                        >mdi-drag-variant</v-icon
                                                      >
                                                    </div></v-col
                                                  >
                                                  <v-col>
                                                    <div
                                                      v-if="!cp.editing"
                                                      style="
                                                        display: -ms-flex;
                                                        display: -webkit-flex;
                                                        display: flex;
                                                      "
                                                    >
                                                      <DocumentPartTagList
                                                        style="flex: 1"
                                                        v-if="
                                                          cpt.layoutTags.includes(
                                                            'TagList'
                                                          )
                                                        "
                                                        :readOnly="
                                                          readOnly ||
                                                          !document.state
                                                            .canEditDocParts
                                                        "
                                                        :value="csp.text"
                                                        :restrict_to_lookup="
                                                          cpt.tmpl_part_metadata
                                                            .restrict_to_lookup
                                                        "
                                                        :tmpl_part_id="
                                                          cpt.tmpl_part_id
                                                        "
                                                        :dp_id="cp.dp_id"
                                                        @changed="
                                                          inLineChange(
                                                            $event,
                                                            cpt,
                                                            cp,
                                                            csp,
                                                            undefined,
                                                            true
                                                          )
                                                        "
                                                      >
                                                      </DocumentPartTagList>
                                                      <v-row
                                                        dense
                                                        v-else-if="
                                                          cpt.tmpl_part_metadata &&
                                                          cpt.tmpl_part_metadata
                                                            .attributes.length
                                                        "
                                                      >
                                                        <v-col
                                                          v-for="(
                                                            cpta, cptai
                                                          ) in cp.attributes"
                                                          :key="
                                                            'p' +
                                                            p.doc_part_id +
                                                            'ip' +
                                                            cpt.tmpl_part_id +
                                                            'icp' +
                                                            icsp +
                                                            '_' +
                                                            icsp +
                                                            'cpta' +
                                                            cptai
                                                          "
                                                          :cols="cpta.cols"
                                                          :class="[
                                                            cpt.tmpl_part_metadata.layout.split(
                                                              '|'
                                                            )[1],
                                                            csp.text
                                                              ? ''
                                                              : 'emptyText',
                                                          ]"
                                                          >{{
                                                            cpta.text
                                                          }}</v-col
                                                        >
                                                      </v-row>
                                                      <div
                                                        v-else-if="
                                                          cpt.tmpl_part_metadata
                                                        "
                                                        @click="
                                                          inLineEdit(
                                                            cpt,
                                                            cp,
                                                            csp,
                                                            icp === 0 &&
                                                              !csp.text
                                                          )
                                                        "
                                                        @focus="
                                                          inLineEdit(
                                                            cpt,
                                                            cp,
                                                            csp,
                                                            icp === 0 &&
                                                              !csp.text
                                                          )
                                                        "
                                                        :tabindex="
                                                          readOnly ||
                                                          !document.state
                                                            .canEditDocParts ||
                                                          cpt.tmpl_part_metadata
                                                            .tp_locked ||
                                                          cp.locked
                                                            ? -1
                                                            : 0
                                                        "
                                                        style="
                                                          min-height: 25px;
                                                          flex: 1;
                                                        "
                                                        :class="[
                                                          cpt.tmpl_part_metadata.layout.split(
                                                            '|'
                                                          )[1],
                                                          csp.text
                                                            ? ''
                                                            : 'emptyText',
                                                        ]"
                                                        v-html="
                                                          applyReadOnlyHighlights(
                                                            csp.text,
                                                            cpt.tmpl_part_metadata,
                                                            cp
                                                          )
                                                        "
                                                      ></div>
                                                      <div
                                                        :class="[
                                                          'partActionHolder',
                                                          cp.attributes &&
                                                          cp.attributes.length
                                                            ? 'hasAttributes'
                                                            : '',
                                                        ]"
                                                        :style="
                                                          partActionMenu
                                                            ? { flex: 0 }
                                                            : {}
                                                        "
                                                      >
                                                        <div
                                                          v-if="
                                                            icsp === 0 &&
                                                            partActionMenu &&
                                                            cp.doc_part_id &&
                                                            !readOnly &&
                                                            ((!cpt
                                                              .tmpl_part_metadata
                                                              .tp_locked &&
                                                              !cp.locked) ||
                                                              (document.state
                                                                .canEditDocParts &&
                                                                cp.canAdd))
                                                          "
                                                          class="part-action part-action-sub"
                                                        >
                                                          <v-menu
                                                            bottom
                                                            left
                                                            :open-on-hover="
                                                              false
                                                            "
                                                          >
                                                            <template
                                                              v-slot:activator="{
                                                                on,
                                                                attrs,
                                                              }"
                                                              ><v-btn
                                                                icon
                                                                small
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                title="Menu"
                                                              >
                                                                <v-icon small
                                                                  >mdi-dots-vertical</v-icon
                                                                ></v-btn
                                                              >
                                                            </template>
                                                            <v-list dense>
                                                              <v-list-item
                                                                v-if="
                                                                  !cpt
                                                                    .tmpl_part_metadata
                                                                    .tp_locked &&
                                                                  document.state
                                                                    .canEditDocParts &&
                                                                  cp.canAdd
                                                                "
                                                                @click="
                                                                  addPart(
                                                                    cp,
                                                                    cpt,
                                                                    p
                                                                  )
                                                                "
                                                                ><v-list-item-icon
                                                                  style="
                                                                    margin-right: 10px;
                                                                  "
                                                                >
                                                                  <v-icon
                                                                    >mdi-plus</v-icon
                                                                  >
                                                                </v-list-item-icon>
                                                                <v-list-item-title
                                                                  >Add
                                                                  new</v-list-item-title
                                                                >
                                                              </v-list-item>
                                                              <v-list-item
                                                                v-if="
                                                                  partsHistory.length &&
                                                                  document.lifecycle_id &&
                                                                  document.state
                                                                    .docPartCommentAction
                                                                "
                                                                @click="
                                                                  showPartHistory(
                                                                    cp,
                                                                    cpt,
                                                                    true
                                                                  )
                                                                "
                                                                ><v-list-item-icon
                                                                  style="
                                                                    margin-right: 10px;
                                                                  "
                                                                >
                                                                  <v-icon
                                                                    >mdi-comment-text-outline</v-icon
                                                                  >
                                                                </v-list-item-icon>
                                                                <v-list-item-title
                                                                  >{{
                                                                    document
                                                                      .state
                                                                      .docPartCommentAction
                                                                      .name
                                                                  }}</v-list-item-title
                                                                >
                                                              </v-list-item>
                                                              <v-list-item
                                                                v-if="
                                                                  partsHistory.length
                                                                "
                                                                @click="
                                                                  showPartHistory(
                                                                    cp,
                                                                    cpt
                                                                  )
                                                                "
                                                                ><v-list-item-icon
                                                                  style="
                                                                    margin-right: 10px;
                                                                  "
                                                                >
                                                                  <v-icon
                                                                    >mdi-history</v-icon
                                                                  >
                                                                </v-list-item-icon>
                                                                <v-list-item-title
                                                                  >History</v-list-item-title
                                                                >
                                                              </v-list-item>
                                                              <v-list-item
                                                                v-if="
                                                                  document.state
                                                                    .canEditDocParts &&
                                                                  pt
                                                                    .tmpl_part_metadata
                                                                    .allow_edit_dialog &&
                                                                  !pt
                                                                    .tmpl_part_metadata
                                                                    .tp_locked &&
                                                                  !p.locked
                                                                "
                                                                @click="
                                                                  editText(
                                                                    cpt,
                                                                    cp
                                                                  )
                                                                "
                                                                ><v-list-item-icon
                                                                  style="
                                                                    margin-right: 10px;
                                                                  "
                                                                >
                                                                  <v-icon
                                                                    >mdi-square-edit-outline</v-icon
                                                                  >
                                                                </v-list-item-icon>
                                                                <v-list-item-title
                                                                  >Edit
                                                                  Notes</v-list-item-title
                                                                >
                                                              </v-list-item>
                                                              <v-list-item
                                                                v-if="
                                                                  document.state
                                                                    .canEditDocParts &&
                                                                  cp.canDelete
                                                                "
                                                                @click="
                                                                  deleteRow(
                                                                    cp,
                                                                    cpt,
                                                                    p
                                                                  )
                                                                "
                                                                ><v-list-item-icon
                                                                  style="
                                                                    margin-right: 10px;
                                                                  "
                                                                >
                                                                  <v-icon
                                                                    >mdi-delete</v-icon
                                                                  >
                                                                </v-list-item-icon>
                                                                <v-list-item-title
                                                                  >Delete</v-list-item-title
                                                                >
                                                              </v-list-item>
                                                            </v-list>
                                                          </v-menu>
                                                        </div>
                                                        <div
                                                          class="part-action"
                                                          v-if="
                                                            !partActionMenu &&
                                                            partsHistory.length &&
                                                            icsp === 0 &&
                                                            cp.doc_part_id
                                                          "
                                                        >
                                                          <v-icon
                                                            color="grey darken-1"
                                                            small
                                                            v-show="cp.hover"
                                                            @click="
                                                              showPartHistory(
                                                                cp,
                                                                cpt
                                                              )
                                                            "
                                                            :title="
                                                              'View ' +
                                                              cpt.type +
                                                              ' History'
                                                            "
                                                            >mdi-history</v-icon
                                                          >
                                                        </div>
                                                        <div
                                                          class="part-action"
                                                          v-if="
                                                            !partActionMenu &&
                                                            !cpt
                                                              .tmpl_part_metadata
                                                              .tp_locked &&
                                                            document.state
                                                              .canEditDocParts &&
                                                            cp.doc_part_id &&
                                                            cp.canAdd
                                                          "
                                                        >
                                                          <v-icon
                                                            color="grey darken-1"
                                                            small
                                                            v-show="cp.hover"
                                                            @click="
                                                              addPart(
                                                                cp,
                                                                cpt,
                                                                p
                                                              )
                                                            "
                                                            :title="
                                                              'Add ' + cpt.type
                                                            "
                                                            >mdi-playlist-plus</v-icon
                                                          >
                                                        </div>
                                                        <div
                                                          class="part-action"
                                                          v-if="
                                                            !partActionMenu &&
                                                            !cpt
                                                              .tmpl_part_metadata
                                                              .tp_locked &&
                                                            document.state
                                                              .canEditDocParts &&
                                                            cp.doc_part_id &&
                                                            cp.canDelete
                                                          "
                                                        >
                                                          <v-icon
                                                            color="grey darken-1"
                                                            small
                                                            v-show="cp.hover"
                                                            @click="
                                                              deleteRow(
                                                                cp,
                                                                cpt,
                                                                p
                                                              )
                                                            "
                                                            :title="
                                                              'Delete ' +
                                                              cpt.type
                                                            "
                                                            >mdi-delete</v-icon
                                                          >
                                                        </div>
                                                        <div
                                                          class="part-action"
                                                          v-if="
                                                            !partActionMenu &&
                                                            document.state
                                                              .canEditDocParts &&
                                                            cpt
                                                              .tmpl_part_metadata
                                                              .allow_edit_dialog &&
                                                            icsp === 0
                                                          "
                                                          style="flex: 0"
                                                        >
                                                          <v-icon
                                                            color="grey darken-1"
                                                            small
                                                            v-show="cp.hover"
                                                            @click="
                                                              editText(cpt, cp)
                                                            "
                                                            :title="
                                                              cp.attributes &&
                                                              cp.attributes
                                                                .length > 0
                                                                ? 'Notes'
                                                                : 'Edit ' +
                                                                  cpt.type
                                                            "
                                                            >mdi-square-edit-outline</v-icon
                                                          >
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div
                                                      v-else
                                                      style="
                                                        display: -ms-flex;
                                                        display: -webkit-flex;
                                                        display: flex;
                                                      "
                                                    >
                                                      <div style="flex: 1">
                                                        <TextHighlight
                                                          hideBorder
                                                          :value="csp.text"
                                                          :showActionIcons="
                                                            true
                                                          "
                                                          :lookup="lookup"
                                                          :restrict_to_lookup="
                                                            cpt
                                                              .tmpl_part_metadata
                                                              .restrict_to_lookup
                                                          "
                                                          :restrict_to_htid="{
                                                            ht_id:
                                                              cpt
                                                                .tmpl_part_metadata
                                                                .restrict_to_htid,
                                                            hr_selected:
                                                              document.hierarchies.find(
                                                                (dh) =>
                                                                  dh.ht_id ===
                                                                  cpt
                                                                    .tmpl_part_metadata
                                                                    .restrict_to_htid
                                                              ),
                                                            canadd:
                                                              document.state.ht_id_can_add_node.some(
                                                                (h) =>
                                                                  h ===
                                                                  cpt
                                                                    .tmpl_part_metadata
                                                                    .restrict_to_htid
                                                              ),
                                                          }"
                                                          :words="
                                                            getWordChecksForPart(
                                                              cpt
                                                                .tmpl_part_metadata
                                                                .tmpl_part_id
                                                            )
                                                          "
                                                          :restrictedChars="
                                                            getRestrictedCharsForPart(
                                                              cpt
                                                            )
                                                          "
                                                          :placeholder="
                                                            cpt
                                                              .tmpl_part_metadata
                                                              .placeholder
                                                          "
                                                          :clearable="true"
                                                          @changed="
                                                            inLineChange(
                                                              $event,
                                                              cpt,
                                                              cp,
                                                              csp
                                                            )
                                                          "
                                                          @initialised="
                                                            cp.editing = true
                                                          "
                                                          @save="
                                                            inLineTextSave(
                                                              cpt,
                                                              cp,
                                                              csp,
                                                              editInProgress.isAddPart
                                                            )
                                                          "
                                                          @escape="
                                                            inLineTextCancel(
                                                              cp,
                                                              csp
                                                            )
                                                          "
                                                          :spellCheckData="
                                                            getSpellChecks(
                                                              pt
                                                                .tmpl_part_metadata
                                                                .tmpl_part_id,
                                                              cp.doc_part_id
                                                            )
                                                          "
                                                          :allow_suggestions="
                                                            show_suggestions
                                                          "
                                                          :use_jd_flow="
                                                            use_jd_flow
                                                          "
                                                          @debounce="
                                                            (data) =>
                                                              addDebounceLanguage(
                                                                cpt,
                                                                cp,
                                                                data
                                                              )
                                                          "
                                                          @doSpellCheckReplace="
                                                            (oldVal) =>
                                                              doSpellCheckTextReplace(
                                                                pt,
                                                                oldVal
                                                              )
                                                          "
                                                          @debounceHover="
                                                            (data) =>
                                                              debounceHoverEvent(
                                                                data,
                                                                cpt,
                                                                cp
                                                              )
                                                          "
                                                          @debounceCancel="
                                                            cancelDebounceHover
                                                          "
                                                          :spell_check_language_code="
                                                            document.spell_check_language_code
                                                          "
                                                          @languageIssue="
                                                            (data) =>
                                                              showLanguageMenu(
                                                                data,
                                                                cpt,
                                                                cp
                                                              )
                                                          "
                                                          :source="{
                                                            doc_part_id:
                                                              cp.doc_part_id,
                                                            tmpl_part_id:
                                                              cpt.tmpl_part_id,
                                                          }"
                                                          :suggestionType="
                                                            editInProgress.suggestionType
                                                          "
                                                          :summarise="
                                                            editInProgress.summarise
                                                          "
                                                          :document_language="
                                                            documentLanguage
                                                          "
                                                          :docId="
                                                            document.doc_id
                                                          "
                                                        ></TextHighlight>
                                                      </div>
                                                      <div
                                                        v-if="
                                                          icsp === 0 &&
                                                          partActionMenu &&
                                                          cp.doc_part_id &&
                                                          !readOnly &&
                                                          !pt.tmpl_part_metadata
                                                            .tp_locked &&
                                                          !p.locked
                                                        "
                                                        class="part-action part-action-sub"
                                                      >
                                                        <v-menu
                                                          bottom
                                                          left
                                                          :open-on-hover="false"
                                                        >
                                                          <template
                                                            v-slot:activator="{
                                                              on,
                                                              attrs,
                                                            }"
                                                            ><v-btn
                                                              icon
                                                              small
                                                              v-bind="attrs"
                                                              v-on="on"
                                                              title="Menu"
                                                            >
                                                              <v-icon small
                                                                >mdi-dots-vertical</v-icon
                                                              ></v-btn
                                                            >
                                                          </template>
                                                          <v-list dense>
                                                            <v-list-item
                                                              v-if="
                                                                !cpt
                                                                  .tmpl_part_metadata
                                                                  .tp_locked &&
                                                                document.state
                                                                  .canEditDocParts &&
                                                                cp.canAdd
                                                              "
                                                              @click="
                                                                addPart(
                                                                  cp,
                                                                  cpt,
                                                                  p
                                                                )
                                                              "
                                                              ><v-list-item-icon
                                                                style="
                                                                  margin-right: 10px;
                                                                "
                                                              >
                                                                <v-icon
                                                                  >mdi-plus</v-icon
                                                                >
                                                              </v-list-item-icon>
                                                              <v-list-item-title
                                                                >Add
                                                                new</v-list-item-title
                                                              >
                                                            </v-list-item>
                                                            <!-- <v-list-item
                                                                v-if="
                                                                  partsHistory.length
                                                                "
                                                                @click="
                                                                  showPartHistory(
                                                                    cp,
                                                                    cpt
                                                                  )
                                                                "
                                                                ><v-list-item-icon
                                                                  style="
                                                                    margin-right: 10px;
                                                                  "
                                                                >
                                                                  <v-icon
                                                                    >mdi-history</v-icon
                                                                  >
                                                                </v-list-item-icon>
                                                                <v-list-item-title
                                                                  >History</v-list-item-title
                                                                >
                                                              </v-list-item>
                                                              <v-list-item
                                                                v-if="
                                                                  document.state.canEditDocParts && cpt.tmpl_part_metadata.allow_edit_dialog
                                                                "
                                                                @click="
                                                                  editText(
                                                                    cpt,
                                                                    cp
                                                                  )
                                                                "
                                                                ><v-list-item-icon
                                                                  style="
                                                                    margin-right: 10px;
                                                                  "
                                                                >
                                                                  <v-icon
                                                                    >mdi-square-edit-outline</v-icon
                                                                  >
                                                                </v-list-item-icon>
                                                                <v-list-item-title
                                                                  >Edit
                                                                  Notes</v-list-item-title
                                                                >
                                                              </v-list-item> -->
                                                            <v-list-item
                                                              v-if="
                                                                document.state
                                                                  .canEditDocParts &&
                                                                cp.canDelete
                                                              "
                                                              @click="
                                                                deleteRow(
                                                                  cp,
                                                                  cpt,
                                                                  p
                                                                )
                                                              "
                                                              ><v-list-item-icon
                                                                style="
                                                                  margin-right: 10px;
                                                                "
                                                              >
                                                                <v-icon
                                                                  >mdi-delete</v-icon
                                                                >
                                                              </v-list-item-icon>
                                                              <v-list-item-title
                                                                >Delete</v-list-item-title
                                                              >
                                                            </v-list-item>
                                                          </v-list>
                                                        </v-menu>
                                                      </div>
                                                    </div>
                                                  </v-col>
                                                  <v-col
                                                    :cols="
                                                      tempEssentials ? 2 : 1
                                                    "
                                                    v-if="
                                                      !cp.editing &&
                                                      cpt.tmpl_part_metadata
                                                        .uses_essential_flag &&
                                                      icsp === 0
                                                    "
                                                    :class="[
                                                      'part',
                                                      tempEssentials
                                                        ? ''
                                                        : 'essential',
                                                    ]"
                                                  >
                                                    <v-checkbox
                                                      v-if="
                                                        !readOnly &&
                                                        document.state
                                                          .canEditDocParts &&
                                                        !(
                                                          cpt.tmpl_part_metadata
                                                            .tp_locked ||
                                                          cp.locked
                                                        ) &&
                                                        !tempEssentials
                                                      "
                                                      dense
                                                      v-model="cp.is_essential"
                                                      label="Essential?"
                                                      :disabled="
                                                        !document.state
                                                          .canEditDocParts
                                                      "
                                                      @mouseup.once="
                                                        saveEssential(cp, cpt)
                                                      "
                                                      :class="{
                                                        'essential-select': true,
                                                        desirableBox:
                                                          !cp.is_essential &&
                                                          cp.text,
                                                      }"
                                                    ></v-checkbox>
                                                    <v-select
                                                      v-else-if="
                                                        !readOnly &&
                                                        document.state
                                                          .canEditDocParts &&
                                                        !(
                                                          cpt.tmpl_part_metadata
                                                            .tp_locked ||
                                                          cp.locked
                                                        ) &&
                                                        tempEssentials
                                                      "
                                                      flat
                                                      solo
                                                      dense
                                                      item-text="text"
                                                      item-value="value"
                                                      :items="essentialFlags"
                                                      label="Essential?"
                                                      v-model="cp.is_essential"
                                                      style="
                                                        padding-left: 5px;
                                                        height: 40px;
                                                      "
                                                      :disabled="
                                                        !document.state
                                                          .canEditDocParts
                                                      "
                                                      @change="
                                                        saveEssential(cp, cpt)
                                                      "
                                                      :background-color="
                                                        cp.temp &&
                                                        cp.is_essential === null
                                                          ? 'rgb(225 93 8 / 9%)'
                                                          : ''
                                                      "
                                                    ></v-select>
                                                    <span
                                                      v-else-if="
                                                        !p.temp ||
                                                        p.is_essential !== null
                                                      "
                                                      class="float-right"
                                                      style="
                                                        font-style: italic;
                                                        font-size: 12px;
                                                        color: rgba(
                                                          56,
                                                          106,
                                                          163,
                                                          0.801
                                                        );
                                                      "
                                                      >{{
                                                        cp.is_essential
                                                          ? "Essential"
                                                          : "Non Essential"
                                                      }}</span
                                                    >
                                                  </v-col>
                                                  <v-col
                                                    cols="1"
                                                    class="col-notes inner hidden-sm-and-down"
                                                  >
                                                    <v-tooltip
                                                      bottom
                                                      v-if="
                                                        document.state
                                                          .canEditDocParts &&
                                                        cp.notes &&
                                                        cpt.tmpl_part_metadata
                                                          .allow_edit_dialog &&
                                                        icsp === 0
                                                      "
                                                    >
                                                      <template
                                                        v-slot:activator="{
                                                          on,
                                                          attrs,
                                                        }"
                                                      >
                                                        <v-icon
                                                          class="notesIcon"
                                                          v-bind="attrs"
                                                          v-on="on"
                                                          @click="
                                                            editText(cpt, cp)
                                                          "
                                                          aria-label="Edit Notes"
                                                          >mdi-comment-text-outline</v-icon
                                                        >
                                                      </template>
                                                      <div
                                                        style="max-width: 500px"
                                                        v-html="
                                                          utils.sanitize(
                                                            cp.notes
                                                          )
                                                        "
                                                      ></div>
                                                    </v-tooltip>
                                                    <v-tooltip
                                                      bottom
                                                      v-if="
                                                        (cp.hasComments ||
                                                          getPartHistory(cp)
                                                            .length > 1) &&
                                                        document.state
                                                          .docPartCommentAction &&
                                                        icsp === 0
                                                      "
                                                    >
                                                      <template
                                                        v-slot:activator="{
                                                          on,
                                                          attrs,
                                                        }"
                                                      >
                                                        <v-icon
                                                          class="notesIcon"
                                                          v-bind="attrs"
                                                          v-on="on"
                                                          @click="
                                                            showPartHistory(
                                                              cp,
                                                              cpt,
                                                              true
                                                            )
                                                          "
                                                          aria-label="Show History"
                                                          >mdi-timeline-text</v-icon
                                                        >
                                                      </template>
                                                      <span
                                                        style="max-width: 500px"
                                                      >
                                                        <span
                                                          v-if="
                                                            cp.hasComments &&
                                                            getPartHistory(cp)
                                                              .length === 1
                                                          "
                                                          >{{
                                                            cpt.type
                                                          }}
                                                          comments</span
                                                        >
                                                        <span
                                                          v-else-if="
                                                            !cp.hasComments &&
                                                            getPartHistory(cp)
                                                              .length > 1
                                                          "
                                                          >{{
                                                            cpt.type
                                                          }}
                                                          activity</span
                                                        >
                                                        <span v-else
                                                          >{{
                                                            cpt.type
                                                          }}
                                                          comments and
                                                          activity</span
                                                        >
                                                      </span>
                                                    </v-tooltip>
                                                    <div
                                                      v-if="
                                                        collaboration &&
                                                        icsp === 0
                                                      "
                                                    >
                                                      <span
                                                        v-if="
                                                          getPartHistory(cp)
                                                            .length > 3
                                                        "
                                                      >
                                                        <v-speed-dial
                                                          v-model="collabExpand"
                                                          direction="right"
                                                          open-on-hover
                                                          transition="slide-x-transition"
                                                        >
                                                          <template
                                                            v-slot:activator
                                                            ><v-icon
                                                              v-model="
                                                                collabExpand
                                                              "
                                                              color="teal"
                                                              size="21"
                                                              @click="
                                                                reviewChanges(
                                                                  cpt,
                                                                  cp
                                                                )
                                                              "
                                                              >mdi-checkbox-multiple-blank-circle
                                                              ></v-icon
                                                            >
                                                          </template>
                                                          <span
                                                            @click="
                                                              reviewChanges(
                                                                cpt,
                                                                cp
                                                              )
                                                            "
                                                            v-for="(
                                                              cpd, cpdi
                                                            ) in getPartHistory(
                                                              cp
                                                            )"
                                                            :key="
                                                              'p' +
                                                              ip +
                                                              'cpd' +
                                                              cpdi
                                                            "
                                                          >
                                                            <v-tooltip bottom>
                                                              <template
                                                                v-slot:activator="{
                                                                  on,
                                                                  attrs,
                                                                }"
                                                              >
                                                                <v-avatar
                                                                  v-bind="attrs"
                                                                  v-on="on"
                                                                  color="teal"
                                                                  size="15"
                                                                  aria-label="Review Changes"
                                                                  ><span
                                                                    style="
                                                                      font-size: 8px;
                                                                    "
                                                                    class="white--text"
                                                                    >{{
                                                                      cpd.version_user_inits
                                                                    }}</span
                                                                  ></v-avatar
                                                                >
                                                              </template>
                                                              <div
                                                                style="
                                                                  max-width: 500px;
                                                                "
                                                              >
                                                                <span
                                                                  style="
                                                                    font-weight: bold;
                                                                  "
                                                                  >{{
                                                                    cpd.version_desc
                                                                  }}</span
                                                                >
                                                                <div
                                                                  v-html="
                                                                    utils.sanitize(
                                                                      cpd.previous
                                                                    )
                                                                  "
                                                                ></div>
                                                              </div>
                                                            </v-tooltip>
                                                          </span>
                                                        </v-speed-dial>
                                                      </span>
                                                      <span v-else>
                                                        <span
                                                          @click="
                                                            reviewChanges(
                                                              cpt,
                                                              cp
                                                            )
                                                          "
                                                          v-for="(
                                                            pd, pdi
                                                          ) in getPartHistory(
                                                            cp
                                                          )"
                                                          :key="
                                                            'p' +
                                                            ip +
                                                            'icp' +
                                                            icp +
                                                            'pd' +
                                                            pdi
                                                          "
                                                        >
                                                          <v-tooltip bottom>
                                                            <template
                                                              v-slot:activator="{
                                                                on,
                                                                attrs,
                                                              }"
                                                            >
                                                              <v-avatar
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                color="teal"
                                                                size="15"
                                                                aria-label="Review Changes"
                                                                ><span
                                                                  style="
                                                                    font-size: 8px;
                                                                  "
                                                                  class="white--text"
                                                                  >{{
                                                                    pd.version_user_inits
                                                                  }}</span
                                                                ></v-avatar
                                                              >
                                                            </template>
                                                            <div
                                                              style="
                                                                max-width: 500px;
                                                              "
                                                            >
                                                              <span
                                                                style="
                                                                  font-weight: bold;
                                                                "
                                                                >{{
                                                                  pd.version_desc
                                                                }}</span
                                                              >
                                                              <div
                                                                v-html="
                                                                  utils.sanitize(
                                                                    pd.previous
                                                                  )
                                                                "
                                                              ></div>
                                                            </div>
                                                          </v-tooltip>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <v-tooltip
                                                      top
                                                      v-if="
                                                        tempEssentials &&
                                                        cp.temp &&
                                                        cp.is_essential === null
                                                      "
                                                    >
                                                      <template
                                                        v-slot:activator="{
                                                          on,
                                                          attrs,
                                                        }"
                                                      >
                                                        <v-icon
                                                          v-bind="attrs"
                                                          v-on="on"
                                                          color="orange"
                                                          :aria-label="
                                                            readOnly ||
                                                            !document.state
                                                              .canEditDocParts
                                                              ? 'Essential/Non Essential not selected'
                                                              : 'You must select Essential/Non Essential'
                                                          "
                                                          >mdi-alert</v-icon
                                                        >
                                                      </template>
                                                      <div
                                                        style="max-width: 500px"
                                                      >
                                                        {{
                                                          readOnly ||
                                                          !document.state
                                                            .canEditDocParts
                                                            ? `Essential/Non Essential not selected`
                                                            : `You must select if this ${cp.type} is Essential/Non Essential`
                                                        }};
                                                      </div>
                                                    </v-tooltip>
                                                  </v-col>
                                                </v-row>
                                              </v-col>
                                            </v-row>
                                          </v-col>
                                        </v-row>
                                      </div>
                                    </draggable>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <span color="red">{{ p.rowMessage }}</span>
                      </v-col>
                    </v-row>
                  </div>
                  <div
                    class="col-notes hidden-sm-and-down"
                    style="display: -webkit-inline-box"
                    :style="{
                      'margin-right':
                        show_database || show_suggestions ? '15px' : '0px',
                    }"
                  >
                    <v-tooltip
                      bottom
                      v-if="
                        document.state.canEditDocParts &&
                        p.notes &&
                        pt.tmpl_part_metadata.allow_edit_dialog &&
                        isp === 0
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          :class="{
                            notesIcon: true,
                            notesEssential:
                              pt.tmpl_part_metadata.uses_essential_flag,
                          }"
                          v-bind="attrs"
                          v-on="on"
                          @click="editText(pt, p)"
                          aria-label="Edit Notes"
                          >mdi-comment-text-outline</v-icon
                        >
                      </template>
                      <div
                        style="max-width: 500px"
                        v-html="utils.sanitize(p.notes)"
                      ></div>
                    </v-tooltip>
                    <v-tooltip
                      bottom
                      v-if="
                        (p.hasComments || getPartHistory(p).length > 1) &&
                        isp === 0 &&
                        document.state.docPartCommentAction
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          :class="{
                            notesIcon: true,
                            notesEssential:
                              pt.tmpl_part_metadata.uses_essential_flag,
                          }"
                          v-bind="attrs"
                          v-on="on"
                          @click="showPartHistory(p, pt, true)"
                          aria-label="Show History"
                          >mdi-timeline-text</v-icon
                        >
                      </template>
                      <span style="max-width: 500px">
                        <span
                          v-if="p.hasComments && getPartHistory(p).length === 1"
                          >{{ pt.type }} comments</span
                        >
                        <span
                          v-else-if="
                            !p.hasComments && getPartHistory(p).length > 1
                          "
                          >{{ pt.type }} activity</span
                        >
                        <span v-else>{{ pt.type }} comments and activity</span>
                      </span>
                    </v-tooltip>
                    <div v-if="collaboration && isp === 0">
                      <span v-if="getPartHistory(p).length > 3">
                        <v-speed-dial
                          v-model="collabExpand"
                          direction="right"
                          open-on-hover
                          transition="slide-x-transition"
                        >
                          <template v-slot:activator
                            ><v-icon
                              v-model="collabExpand"
                              color="teal"
                              size="21"
                              @click="reviewChanges(pt, p)"
                              aria-label="Review Changes"
                              >mdi-checkbox-multiple-blank-circle ></v-icon
                            >
                          </template>
                          <span
                            @click="reviewChanges(pt, p)"
                            v-for="(pd, pdi) in getPartHistory(p)"
                            :key="'p' + ip + 'pd' + pdi"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-avatar
                                  v-bind="attrs"
                                  v-on="on"
                                  color="teal"
                                  size="15"
                                  aria-label="Review Changes"
                                  ><span
                                    style="font-size: 8px"
                                    class="white--text"
                                    >{{ pd.version_user_inits }}</span
                                  ></v-avatar
                                >
                              </template>
                              <div style="max-width: 500px">
                                <span style="font-weight: bold">{{
                                  pd.version_desc
                                }}</span>
                                <div v-html="utils.sanitize(pd.previous)"></div>
                              </div>
                            </v-tooltip>
                          </span>
                        </v-speed-dial>
                      </span>
                      <span v-else>
                        <span
                          @click="reviewChanges(pt, p)"
                          v-for="(pd, pdi) in getPartHistory(p)"
                          :key="'p' + ip + 'pd' + pdi"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-avatar
                                v-bind="attrs"
                                v-on="on"
                                color="teal"
                                size="15"
                                aria-label="Review Changes"
                                ><span
                                  style="font-size: 8px"
                                  class="white--text"
                                  >{{ pd.version_user_inits }}</span
                                ></v-avatar
                              >
                            </template>
                            <div style="max-width: 500px">
                              <span style="font-weight: bold">{{
                                pd.version_desc
                              }}</span>
                              <div v-html="utils.sanitize(pd.previous)"></div>
                            </div>
                          </v-tooltip>
                        </span>
                      </span>
                    </div>
                    <div style="display: inline-flex">
                      <v-tooltip
                        top
                        v-if="
                          pt.tmpl_part_metadata.uses_essential_flag &&
                          tempEssentials &&
                          p.temp &&
                          p.is_essential === null
                        "
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            color="orange"
                            :aria-label="
                              readOnly || !document.state.canEditDocParts
                                ? 'Essential/Non Essential not selected'
                                : 'You must select Essential/Non Essential'
                            "
                            >mdi-alert</v-icon
                          >
                        </template>
                        <div style="max-width: 500px">
                          {{
                            readOnly || !document.state.canEditDocParts
                              ? `Essential/Non Essential not selected`
                              : `You must select if this ${pt.type} is Essential/Non Essential`
                          }}
                        </div>
                      </v-tooltip>
                      <v-tooltip
                        top
                        v-if="
                          !useOpenAISuggestions &&
                          pt.tmpl_part_metadata.suggestion_part &&
                          isp === 0 &&
                          ip === 0
                        "
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            style="padding-left: 2px"
                            :class="
                              'userPilotSuggestions' +
                              pt.type.replace(/\s+/g, '')
                            "
                            color="green"
                            @click="showSuggestionsNew(pt, p, sp)"
                            aria-label="Generate AI Suggestions"
                          >
                            mdi-auto-fix
                          </v-icon>
                        </template>
                        <div style="max-width: 500px">
                          Generate AI Suggestions
                        </div>
                      </v-tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </draggable>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        v-if="use_jd_flow && active_section !== 'Full Job Description'"
      >
        <h2 class="col-part section_next_text" v-if="next_section !== ''">
          Next - {{ next_section }}
        </h2>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="markSectionDone('Fwd')"
          style="margin-right: 20px"
          >Done</v-btn
        >
      </v-card-actions>
      <v-card-actions v-if="use_jd_flow && (show_database || show_suggestions)">
        <v-btn
          color="primary"
          v-if="prev_section !== ''"
          @click="markSectionDone('Back')"
          style="margin-right: 20px"
          title="Previous Section"
          ><v-icon>mdi-chevron-double-left</v-icon>{{ prev_section }}</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          v-if="next_section !== ''"
          @click="markSectionDone('Fwd')"
          style="margin-right: 20px"
          title="Next Section"
          ><v-icon>mdi-chevron-double-right</v-icon>{{ next_section }}</v-btn
        >
      </v-card-actions>
    </v-card>

    <ResponseHandler :serviceResponse="response"></ResponseHandler>
    <TextEdit
      :open="textEdit.show"
      @saved="saveTextEdit"
      @closed="textEdit.show = false"
      @deleted="deleteTextEdit"
      :settings="textEdit"
    ></TextEdit>
    <RoleFlex
      :open="roleFlex.show"
      @closed="roleFlex.show = false"
      @workingPattern="newWorkingPattern"
      @flexupdated="saveFlex"
      label="text"
      :documentId="roleFlex.documentId"
      :docPart="roleFlex.docPart"
      :fixedResults="flexFixedResults"
    ></RoleFlex>
    <v-dialog v-model="hint.show" scrollable max-width="800px">
      <v-card>
        <v-card-title>
          <!-- <v-icon>{{ hint.icon }}</v-icon> -->
          <span class="subtitle-1">{{ hint.title }}</span>
          <v-spacer></v-spacer>
          <!-- {{ hint.subtitle }} -->
          <v-btn
            icon
            title="close"
            class="btn-background"
            @click="hint.show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="max-height: 800px">
          <div v-html="utils.sanitize(hint.text)"></div>
        </v-card-text>
      </v-card> </v-dialog
    ><v-dialog v-model="review.show" scrollable max-width="800px">
      <v-card v-if="review.show">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >{{ review.partType.type }}
                <v-btn
                  icon
                  class="float-right"
                  @click="review.show = false"
                  title="close"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn></v-list-item-title
              >
              <v-list-item-subtitle>Version History</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-card-text>
          <v-timeline align-top dense>
            <v-timeline-item
              small
              v-for="(rc, rci) in review.changes"
              :key="'rc' + rci"
            >
              <div>
                <div class="font-weight-normal">
                  <strong>{{ rc.version_desc }}</strong>
                  <strong v-if="rci === 0" class="float-right">
                    Latest Version</strong
                  >
                  <v-btn
                    v-if="rci > 0"
                    class="float-right"
                    small
                    @click="reviewRevert(rc, review.part, review.partType)"
                    >Revert To</v-btn
                  >
                </div>
                <div>{{ rc.previous }}</div>
                <!-- <div v-for="(a, ai) in rc.attributes" :key="'ai' + ai">
                  {{ a.tpa_id + " - " + a.text }}
                </div> -->
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--:attach="targetElement"
      style="position:sticky" 
      -->
    <v-menu
      v-if="!readOnly"
      v-model="nlpMenu.show"
      :close-on-content-click="false"
      scrollable
      :position-x="nlpMenu.x"
      :position-y="nlpMenu.y"
      offset-y
      offset-x
    >
      <v-card width="575px" max-height="320px" class="rm-context-menu">
        <v-card-title>
          Language Issues: &nbsp;&nbsp;<b>{{
            nlpMenu.activeSpellCheck.word
          }}</b>
          <v-spacer></v-spacer>
          Dictionary:&nbsp;&nbsp;<b>{{
            document.spell_check_language_code === "en" ? "UK" : "US"
          }}</b>
          <v-spacer></v-spacer>
          <v-icon @click="nlpMenu.show = false" small right>mdi-close</v-icon>
        </v-card-title>
        <v-card-text scrollable>
          <span class="grammarHint">{{ nlpMenu.activeSpellCheck.message }}</span
          ><br /><br />
          <!--<span  class="languageCorrectionText" v-if="nlpMenu.activeSpellCheck.partcount > 1">
            <v-checkbox dense small label="Update All Occurances?" v-model="nlpMenu.activeSpellCheck.applyAll"></v-checkbox>
          <br/>
          </span>-->
          <span
            v-if="
              nlpMenu.activeSpellCheck.candidates.length > 0 &&
              nlpMenu.activeSpellCheck.correction !== ''
            "
          >
            <b>Suggested:</b> &nbsp;&nbsp;
            <span>
              <v-hover v-slot="{ hover }" open-delay="200">
                <span
                  @click="
                    performSpellReplace(
                      nlpMenu.activeSpellCheck.word,
                      nlpMenu.activeSpellCheck.correction
                    )
                  "
                  style="cursor: pointer"
                  :class="{ 'on-hover': hover }"
                  >{{ nlpMenu.activeSpellCheck.correction }}</span
                >
              </v-hover> </span
            ><br />
            <span v-if="nlpMenu.activeSpellCheck.candidates.length > 1">
              <b>Alternatives:</b><br />
              <div class="altContainer">
                <span
                  v-for="(v, i) in nlpMenu.activeSpellCheck.candidates"
                  :key="i"
                >
                  <v-hover v-slot="{ hover }" open-delay="200">
                    <span
                      @click="
                        performSpellReplace(nlpMenu.activeSpellCheck.word, v)
                      "
                      style="cursor: pointer"
                      :class="{ 'on-hover': hover }"
                      >{{ v }} &nbsp;</span
                    >
                  </v-hover>
                </span>
              </div>
            </span>
          </span>
          <span class="grammarHint" v-else>
            No Suggestions -
            <v-btn
              small
              color="warning"
              dark
              @click="performSpellReplace(nlpMenu.activeSpellCheck.word, '')"
            >
              <v-icon left> mdi-close </v-icon>
              Delete
            </v-btn>
          </span>
        </v-card-text>
      </v-card>
    </v-menu>
    <v-dialog v-model="spellCheckDialog" scrollable max-width="800px">
      <v-card>
        <v-card-title>
          <span class="subtitle-1">SpellCheck Result</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text style="max-height: 800px">
          <div>{{ spellCheckResult }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="nlpSummarise.show" scrollable max-width="800px">
      <v-card>
        <v-card-title>
          <v-icon style="padding-right: 20px">mdi-file-edit</v-icon>
          Summarise Options
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="max-height: 800px">
          <v-row dense>
            <v-col dense cols="6" style="padding-top: 15px"
              >Max Sentences</v-col
            >
            <v-col dense cols="6">
              <v-text-field
                v-model="nlpSummarise.summarise_max_sent"
                hide-details
                single-line
                dense
                type="number"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="success" @click="doNlpSummarise()" text
            >Summarise Text</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="errorDialogue.show" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Validation Error
        </v-card-title>
        <v-card-text>
          {{ errorDialogue.message }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="errorDialogue.show = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <LanguageMenu
      :show="languageMenu.show"
      @close="closeLanguageMenu"
      :xCoord="languageMenu.x"
      :yCoord="languageMenu.y"
      :menu="languageMenu"
      @doSpellReplace="(data, source) => doSpellReplace(data, source)"
    ></LanguageMenu>
    <Loading :isVisible="!readOnly && isLoading" />
  </div>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import TextEdit from "@/components/cTextEditDialogue"; // @ is an alias to /src
import RoleFlex from "@/components/cRoleFlex"; // @ is an alias to /src
//import DocumentHeader from "@/components/cDocumentHeader"; // @ is an alias to /src
//import CheckStatus from "@/components/cDocumentCheckStatus";
import utils from "@/common/utils.js";
import LanguageMenu from "@/components/common/LanguageMenu";
//import documentExport from "@/common/documentExport.js";
//import jsPDF from "jspdf";
//import html2canvas from "html2canvas";
//import html2docx from "html-docx-js/dist/html-docx";
//import ScrollTo from "vue-scrollto";
//import FileUploader from "./cFileUpload.vue";
import TextHighlight from "./cTextHighlight.vue";
//import CountryFlag from "vue-country-flag";
import DocumentPartTagList from "./cDocumentPartTagList.vue";
import TagAttribute from "@/components/document/TagAttribute";
import { mapState } from "vuex";
import nlpUtils from "@/common/nlpUtils.js";
//import documentExport from "@/common/documentExport.js";

export default {
  name: "DocumentFullEditSection",
  components: {
    ResponseHandler,
    TextEdit,
    RoleFlex,
    //DocumentHeader,
    draggable,
    //CheckStatus,
    //FileUploader,
    TextHighlight,
    //CountryFlag,
    DocumentPartTagList,
    TagAttribute,
    LanguageMenu,
  },
  props: {
    documentId: null,
    selectdTranslateLang: null,
    data: null,
    isNew: Boolean,
    useInline: Boolean,
    hightLightIssue: null,
    readOnly: Boolean,
    openFlex: Boolean,
    use_jd_flow: Boolean,
    active_section: String,
    show_suggestions: Boolean,
    show_database: Boolean,
    docPartUpdateData: Array,
    next_section: String,
    prev_section: String,
    isDataDrag: Boolean,
    nlpActions: Array,
    jd_flow_sections: Array,
    tempEssentials: Boolean,
    revertTrigger: null,
    headerUpdated: null,
  },
  data: function () {
    return {
      utils: utils,
      response: null,
      document: null,
      docHierarchies: [],
      documentLanguage: { text: "English", value: "en", flagCode: "gb" },
      docClassifiers: [],
      isLoading: false,
      editInProgress: {
        active: false,
        pt: null,
        part: null,
        subpart: null,
        isAddPart: false,
        parent: null,
        attr: null,
        suggestionType: null,
      },
      textEdit: {
        text: "",
        notes: "",
        label: "text",
        show: false,
        showWordCount: true,
        showNotes: true,
        title: "",
        rules: null,
        lookup: [],
        wordChecks: [],
        allow_errors: false,
        //closed: false
      },
      wordChecks: [],
      roleFlex: {
        documentId: "",
        show: false,
        docPart: {},
      },
      dragging: false,
      draggedParent: null,
      pdfing: false,
      showHeaderDetail: false,
      showCheckStatus: false,
      checkSummary: null,
      checkSummaryPanels: [],
      checkSummaryView: 2,
      exportMenu: false,
      copymenu: false,
      reqDialogue: {
        show: false,
        value: null,
      },
      emailDialogue: {
        show: false,
        value: null,
      },
      usesInterviewTemplate: false,
      usesIntakeFlow: false,
      dpScrolled: [],
      confirmCopy: "",
      confirmCopyShow: false,
      fileUploadFormData: {},
      fileUploadDisabled: false,
      history: null,
      docHistory: null,
      ptDeleteHistory: null,
      partsHistory: [],
      docStatus: null,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      lookup: [],
      hint: { show: false, icon: "", title: "", subtitle: "", text: "" },
      hintsVisible: true,
      collaboration: false,
      collabExpand: false,
      review: { changes: [], show: false },
      hoveredPart: null,
      isFlexTemplate: false,
      flexPartId: null,
      flexFixedResults: false,
      partActionMenu:
        this.$loginState.user &&
        this.$loginState.user.settings.some(
          (s) =>
            s.setting === "document_part_action" &&
            s.value.indexOf("menu") === 0
        ),
      partActionDragDense:
        this.$loginState.user &&
        this.$loginState.user.settings.some(
          (s) =>
            s.setting === "document_part_drag" &&
            s.value.indexOf("condensed") === 0
        ),
      spellCheckEnabled: false,
      summariseEnabled: false,
      spellCheckDialog: false,
      spellCheckResult: [],
      grammarCheckResult: [],
      nlpSummarise: {
        show: false,
        icon: "",
        title: "",
        subtitle: "",
        text: "",
        summarise_max_sent: 1,
      },
      nlpExclusions: [],
      essentialFlags: [
        {
          value: true,
          text: "Essential",
        },
        {
          value: false,
          text: "Non Essential",
        },
      ],
      isDragInProgress: false,
      dragTargetPart: 0,
      nlpActionsToPerform: [],
      commentDialogue: {
        show: false,
        partType: null,
        part: null,
      },
      ddCount: 0,
      errorDialogue: {
        show: false,
        message: null,
      },
      debounceTimeout: null,
      targetElement: null,
      targetPart: null,
      nlpMenu: {
        show: false,
        loading: true,
        x: 0,
        y: 0,
        spellCheckData: [],
        activeSpellCheck: {
          word: "",
          correction: "",
          candidates: [],
          message: "",
          partcount: 0,
          applyAll: false,
        },
        //closed: false
      },
      languageMenu: {
        show: false,
        x: 0,
        y: 0,
        textStatus: null,
        element: null,
        match: null,
      },
      updateSpellCheckWord: {
        word: "",
        replace: "",
      },
      newSpellCheckData: [],
    };
  },
  created() {
    this.setup();
    if (this.data && this.data.document) {
      this.isFlexTemplate = this.data.document.derived_from_flex === 1;
      this.flexFixedResults = this.data.document.flex_fixed_results;
      this.documentLanguate = this.data.documentLanguage;
      const firstFlexDerivedPart = this.data.document.parttypes.find(
        (pt) => pt.tmpl_part_metadata.derived_from_flex
      );
      if (firstFlexDerivedPart)
        this.flexPartId = firstFlexDerivedPart.tmpl_part_id;
      else if (this.isFlexTemplate)
        this.flexPartId = this.data.document.parttypes[0].tmpl_part_id;
      if (this.openFlex || (this.isFlexTemplate && this.isNew)) {
        this.$nextTick(function () {
          this.rFlex();
        });
      }
    }
  },
  updated() {
    this.setup();
  },
  computed: {
    ...mapState({
      newUi: (state) => state.theme.newUi,
      statuses: (state) => state.hierarchies.statuses,
      config: (state) => state.settings.config,
    }),
    autoAddNewRow() {
      return !this.$loginState.user.settings.some(
        (s) =>
          s.setting === "document_suppress_autoaddnewrow" && s.value !== "false"
      );
    },
    allowSummarise() {
      return this.config.contentGenerationConfig?.enableSummarise || false;
    },
    useOpenAISuggestions() {
      return this.config.contentGenerationConfig?.enablePartSuggestions;
    },
    dbDragRules() {
      return this.config.settings?.find((s) => s.setting === "database_drag_splitRules")?.value;
    }
  },
  watch: {
    documentId(newval) {
      if (newval && !this.data) this.fetchData();
    },
    hightLightIssue(val) {
      if (val) this.highlightSection(val);
      else this.unhighlightSection();
    },
    docPartUpdateData(data) {
      this.processUpdates(data);
    },
    isDataDrag(val) {
      this.isDragInProgress = val;
    },
    nlpActions(data) {
      this.nlpActionsToPerform = data;
    },
    revertTrigger(data) {
      if (!data) return;
      if (!data.processed) this.reviewRevert(data.rc, data.part, data.partType);
      data.processed = true;
    },
    headerUpdated(newVal) {
      if (newVal) this.setup(true);
    },
    "data.document.state": function (newVal) {
      this.document.state = newVal;
    },
  },
  methods: {
    doSpellReplace(data, source) {
      let tmpl_part = this.document.parttypes.find(
        (x) => x.tmpl_part_id === source.tmpl_part_id
      );
      if (tmpl_part) {
        let part = tmpl_part.parts.find(
          (x) => x.doc_part_id === source.doc_part_id
        );
        if (part) {
          let spellCheck = source.issue.type === "spell_check";
          if (spellCheck) {
            let item = this.newSpellCheckData.find(
              (x) => x.tmpl_part_id === source.tmpl_part_id
            );
            let spellIssuePart = item.parts.find(
              (x) => x.doc_part_id === source.doc_part_id
            );
            let correction = spellIssuePart.issues.find(
              (x) => x.word === source.issue.word
            );
            correction.variations.splice(
              correction.variations.findIndex(
                (x) =>
                  x.idx === source.issue.idx && x.word === source.issue.word
              ),
              1
            );
          }

          let targetPart = part;
          let subPart = part.subParts[0];
          targetPart.text = data;
          subPart.text = data;

          this.languageMenu.show = false;
          this.inLineTextSave(tmpl_part, part, subPart, false);
        }
      }
    },
    showLanguageMenu(data, pt, p) {
      if (data.issueTypes) {
        data.issueTypes.forEach((x) => {
          x.source = {
            tmpl_part_id: p.tmpl_part_id,
            doc_part_id: p.doc_part_id,
            ...x.source,
          };
        });

        this.languageMenu = {
          show: true,
          x: data.ev.clientX,
          y: data.ev.clientY,
          doc_id: this.data.document.doc_id,
          issueTypes: data.issueTypes,
          textStatus: data.textStatus,
          spellCheckData: data.spellCheckData,
          languageCode: this.data.document.spell_check_language_code,
        };
      }
    },
    closeLanguageMenu() {
      this.languageMenu.show = false;
    },
    cancelDebounceHover() {
      this.nlpMenu.show = false;
    },
    hoverLanguageMenu(event, pt, p) {
      //if(!this.spellCheckEnabled) return;
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      if (event.toElement?.classList.contains("nlpData")) {
        this.debounceTimeout = setTimeout(() => {
          this.targetElement = event.toElement;
          this.targetPart = {
            partType: pt,
            part: p,
          };
          this.displayNlpMenu(event);
        }, 200); // delay
      }
    },
    debounceHoverEvent(event, pt, p) {
      if (event.word !== "") {
        this.targetElement = event.ev.toElement;
        this.targetPart = {
          partType: pt,
          part: p,
        };

        let rootItem = this.newSpellCheckData.find(
          (x) => x.tmpl_part_id === pt.tmpl_part_id
        );
        if (rootItem) {
          let partItem = rootItem.parts.find(
            (x) => x.doc_part_id === p.doc_part_id
          );
          if (partItem) {
            let issue = partItem.issues.find((x) => x.word === event.word);
            if (issue) {
              if (issue.variations.length > 1) {
                let cursor = event.ev.currentTarget.selectionStart;
                issue.variations.forEach((v) => {
                  if (
                    cursor <= v.offset + v.word.length &&
                    cursor >= v.offset
                  ) {
                    this.nlpMenu.activeSpellCheck = v;
                    this.nlpMenu.x = event.ev.clientX;
                    this.nlpMenu.y = event.ev.clientY;
                    this.nlpMenu.show = true;
                  }
                });
              } else {
                /*let spellIssue = {
                  type: "spellcheck",
                  spellCheckData: rootItem,
                  match: null,
                  word: event.word
                };

                this.languageMenu = {
                  show: true,
                  x: event.ev.clientX,
                  y: event.ev.clientY,
                  issueTypes: [spellIssue]
                }    */
                /*this.nlpMenu.activeSpellCheck = issue.variations[0];
                this.nlpMenu.x = event.ev.clientX;
                this.nlpMenu.y = event.ev.clientY;
                this.nlpMenu.show = true;*/
                /*let issue = allIssues.find(x => x.short_description === data.element.title);
                this.languageMenu = {
                  show: true,
                  x: data.coords.x,
                  y: data.coords.y,
                  textStatus: data.status,
                  element: issue,
                  doc_id: doc.doc_id,
                  item: item,
                  ...data
                }*/
              }
            }
          }
        }
      }
    },
    displayNlpMenu(event) {
      let rootItem = this.newSpellCheckData.find(
        (x) => x.tmpl_part_id === this.targetPart.partType.tmpl_part_id
      );
      if (rootItem) {
        let partItem = rootItem.parts.find(
          (x) => x.doc_part_id === this.targetPart.part.doc_part_id
        );
        if (partItem) {
          let issue = partItem.issues.find(
            (x) => x.word === event.srcElement.innerText
          );
          if (issue) {
            if (issue.variations.length > 1) {
              let attrVal = event.toElement.getAttribute(
                "data-language-reference"
              );
              if (attrVal !== null) {
                let attrs = attrVal.split("_");
                let indx = Number(attrs[attrs.length - 1]);
                this.nlpMenu.activeSpellCheck = issue.variations.find(
                  (x) => x.idx === indx
                );
                this.nlpMenu.x = event.clientX;
                this.nlpMenu.y = event.clientY;
                this.nlpMenu.show = true;
              }
            } else {
              this.nlpMenu.activeSpellCheck = issue.variations[0];
              this.nlpMenu.x = event.clientX;
              this.nlpMenu.y = event.clientY;
              this.nlpMenu.show = true;
            }
          }
        }
      }
    },
    performSpellReplace(word, replace) {
      let rootItem = this.newSpellCheckData.find(
        (x) => x.tmpl_part_id === this.targetPart.partType.tmpl_part_id
      );
      if (rootItem) {
        let partItem = rootItem.parts.find(
          (x) => x.doc_part_id === this.targetPart.part.doc_part_id
        );
        if (partItem) {
          let activeItem = this.nlpMenu.activeSpellCheck;
          let targetPart = this.targetPart.part;
          let subPart = this.targetPart.part.subParts[0];
          let newText =
            targetPart.text.substring(0, activeItem.offset) +
            replace +
            targetPart.text.substring(
              activeItem.offset + activeItem.word.length
            );
          targetPart.text = newText;
          subPart.text = newText;

          this.inLineTextSave(
            this.targetPart.partType,
            this.targetPart.part,
            subPart,
            false
          );
          this.nlpMenu.show = false;
          //todo remove spellcheck item from variants/purge if no more issues etc
          //reget the spellchecks for the part
          //temporary measure while waiting for new spellchecks;
          //calculate offset difference in old word/replacement and for all issues over offset of activeItem.offset, apply difference to offsets
        }
      }
    },
    doSpellCheckTextReplace(pt, old) {
      let item = this.spellCheckResult.find((x) => x.tp_id === pt.tmpl_part_id);
      let subItem = item.data.find((x) => x.word === old);
      let idx = item.data.indexOf(subItem);
      item.data.splice(idx, 1);
    },
    addDebounceLanguage(pt, p, data) {
      let obj = {
        tmpl_part_id: pt.tmpl_part_id,
        parts: [
          {
            doc_part_id: p.doc_part_id,
            issues: data,
          },
        ],
      };

      this.addSpellCheckDataNew([obj]);
    },
    addSpellCheckDataNew(data) {
      data.forEach((d) => {
        let existing = this.newSpellCheckData.find(
          (x) => x.tmpl_part_id === d.tmpl_part_id
        );
        if (!existing) {
          this.newSpellCheckData.push(d);
        } else {
          d.parts.forEach((p) => {
            let existingPart = existing.parts.find(
              (dp) => dp.doc_part_id === p.doc_part_id
            );
            if (existingPart) {
              existingPart.issues = p.issues;
            } else {
              existing.parts.push(p);
            }
          });
        }

        let item = this.document.parttypes.find(
          (x) => x.tmpl_part_id === d.tmpl_part_id
        );
        if (item) {
          item.spellChecking = false;
        }
      });
    },
    activeParts() {
      return this.document
        ? this.document.parttypes.filter((p) => p.visible === true)
        : [];
    },
    highlightDragTarget(p, event) {
      event.stopImmediatePropagation();
      this.dragTargetPart = p.tmpl_part_id;
    },
    async dropItem(pt, p, event) {
      try {
        if (
          event.dataTransfer &&
          this.isNlpFunctionAvailable("drag", pt) &&
          !pt.tmpl_part_metadata.tp_locked
        ) {
          let dataObj = JSON.parse(event.dataTransfer.getData("object"));
          if (!dataObj) return;
          let targetPart = {
            part: {
              tmpl_part_id: pt.tmpl_part_id,
              type: pt.type,
              doc_part: p.doc_part_id,
              parent_doc_part: p.parent_dp_id,
            },
            text: dataObj.text,
            html: dataObj.html,
            actions: [],
          };
          if (this.nlpActionsToPerform.length > 0) {
            targetPart.actions = this.nlpActionsToPerform;
          }

          await this.updateDocPartFromSource(targetPart);
        }
      } catch (ex) {
        // console.log(ex);
      }
    },
    async updateDocPartFromSource(part) {
      let counter = 0;
      if (part.actions.length > 0) {
        part.actions.forEach(async (action) => {
          switch (action.type) {
            case "summarise":
              await nlpUtils
                .doSummarise(part.text, part.actions[0].params.max_sent)
                .then((resp) => {
                  part.text = resp.data.Data.summary;
                  counter++;
                });
              break;
          }

          if (counter === part.actions.length) {
            this.updateDocPartValue(part);
            this.isDragInProgress = false;
            this.nlpActionsToPerform = [];
          }
        });
      } else {
        this.updateDocPartValue(part);
        this.isDragInProgress = false;
        this.nlpActionsToPerform = [];
      }

      this.$emit("dragFinish");
    },
    markSectionDone(direction) {
      this.$emit("sectionDone", direction);
    },
    useFlowStyling() {
      if (this.use_jd_flow === true) {
        if (this.active_section === "Full " + this.document.doc_type) {
          return false;
        }

        return true;
      }

      return false;
    },
    getCssStyle(style) {
      switch (style) {
        case "BoldFlow":
          return this.use_jd_flow ? "flow-font-bold" : "";
        case "HeaderPart":
          return this.use_jd_flow ? "flow-font-header" : style;
        default:
          return style;
      }
    },
    getCssStyles(tag) {
      if (tag !== undefined) {
        if (Array.isArray(tag)) {
          let style = "";
          tag.forEach((t) => {
            style += this.getCssStyle(t) + " ";
          });
          return style;
        } else {
          return this.getCssStyle(tag);
        }
      }
    },
    flowStyling(style) {
      if (this.use_jd_flow === true) {
        /*if(this.active_section === "Full Job Description"){
          return '';
        }*/
        switch (style) {
          case "":
            return "flow-font";
          case "bold":
            return "flow-font-bold";
          case "header":
            return "flow-font-header";
          case "line":
            return "flow-font-line-spacer";
        }
      }

      return "";
    },
    sectonHasDatabaseParts() {
      if (this.active_section === "Full " + this.document.doc_type) {
        return true;
      }
      let targetParts = this.document.parttypes.filter(
        (x) => x.tmpl_part_metadata.part_section === this.active_section
      );

      let dbAvailable = false;

      targetParts.forEach((p) => {
        if (dbAvailable !== true) {
          dbAvailable = this.isNlpFunctionAvailable("drag", p);
        }
      });

      return dbAvailable;
    },
    sectionHasSuggestions() {
      let targetParts = this.document.parttypes.filter(
        (x) => x.tmpl_part_metadata.part_section === this.active_section
      );
      let suggestions = targetParts.filter(
        (x) => x.tmpl_part_metadata.suggestion_part === true
      );
      if (suggestions !== undefined && suggestions.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    summarise(pt, p, sp) {
      let attr;
      if (pt.tmpl_part_metadata.attributes.length && p.attributes.length) {
        p.attributes.forEach((a) => {
          if (
            !a.restrict_to_lookup &&
            !a.restrict_to_date &&
            !a.locked &&
            !a.tag_type_id
          ) {
            attr = a;
          }
        });
      }
      this.inLineEdit(pt, p, sp, undefined, attr, { summarise: true });
    },
    showSuggestionsNew(pt, p, sp) {
      if (!this.useOpenAISuggestions) {
        const tp_id = pt.tmpl_part_metadata.tmpl_part_id;
        let targetParts = this.document.parttypes.filter(
          (x) => x.tmpl_part_metadata.tmpl_part_id === tp_id
        );
        this.$emit("showSuggestions", targetParts);
      } else {
        let attr;
        if (pt.tmpl_part_metadata.attributes.length && p.attributes.length) {
          p.attributes.forEach((a) => {
            if (
              !a.restrict_to_lookup &&
              !a.restrict_to_date &&
              !a.locked &&
              !a.tag_type_id
            ) {
              attr = a;
            }
          });
        }
        this.inLineEdit(pt, p, sp, undefined, attr, {
          suggestionType: pt.type,
        });
      }
    },
    showSuggestions() {
      let targetParts = this.document.parttypes.filter(
        (x) =>
          x.tmpl_part_metadata.part_section === this.active_section &&
          x.tmpl_part_metadata.suggestion_part === true
      );
      this.$emit("showSuggestions", targetParts);
    },
    showDatabase() {
      this.$emit("showDatabase");
    },
    processUpdates(data) {
      if (Array.isArray(data)) {
        data.forEach((p) => {
          this.updateDocPartValue(p);
        });
      } else {
        this.updateDocPartValue(data);
      }
      //this.updateDocPartValue(data);
    },
    isNlpFunctionAvailable(func, p) {
      let isAvail = nlpUtils.isNlpFunctionAvailable(
        this.nlpExclusions,
        func,
        p.tmpl_part_id
      );
      //alert(isAvail);
      return isAvail;
    },
    updateDocPartItem(part, targetPart, callback) {
      //, currentPart, parent){
      this.saveRow(
        part,
        part.text,
        part.notes,
        null,
        part.is_essential,
        part.attributes,
        part.quality,
        targetPart,
        callback
      );
    },
    processDataAttributeUpdate(text, targetPart) {
      let attrpart = targetPart.parts[0].attributes[1];
      attrpart.text = text;
      attrpart.isDirty = true;

      let part = targetPart.parts[0];
      let sepIdx = part.text.indexOf("- ");
      if (sepIdx === -1) {
        let tmp = targetPart.tmpl_part_metadata.attributes[0];
        if (tmp.include_in_part_val && tmp.separator) {
          let prefix = tmp.title + tmp.separator;
          part.text = prefix + text;
        }
      } else {
        part.text = part.text.substring(0, sepIdx + 2) + text;
      }

      this.saveRow(
        part,
        part.text,
        part.notes,
        null,
        part.is_essential,
        part.attributes,
        part.quality,
        targetPart,
        null
      );
    },
    async processMultipleDataUpdates(
      text,
      targetPart,
      isChildPart,
      parentPart,
      data,
      isAttr
    ) {
      return new Promise((resolve) => {
        this.processDataUpdate(
          text,
          targetPart,
          isChildPart,
          parentPart,
          data,
          isAttr,
          function () {
            resolve(true);
          }
        );
      });
    },
    processDataUpdate(
      text,
      targetPart,
      isChildPart,
      parentPart,
      data,
      isAttr,
      callback
    ) {
      let part = null;
      let parentTmplPart = null;

      if (isChildPart) {
        parentTmplPart = parentPart.parts.find(
          (x) => x.doc_part_id === data.part.parent_doc_part
        );
        let childPart = parentTmplPart.childPartTypes.find(
          (cp) => cp.tmpl_part_id === data.part.tmpl_part_id
        );
        let current = childPart.parts.length;
        part =
          current === 1 ? childPart.parts[0] : childPart.parts[current - 1];

        let parentItem = this.document.parttypes.find(
          (p) => p.tmpl_part_id === parentTmplPart.tmpl_part_id
        );
        let previousItem = parentItem.parts.find(
          (sp) => sp.doc_part_id === part.parent_dp_id
        );

        if (part.text !== "") {
          part = this.instantiateNewRow(childPart, previousItem);
          childPart.parts.push(part);
        }
      } else {
        let current = targetPart.parts.length;
        part =
          current == 1 ? targetPart.parts[0] : targetPart.parts[current - 1];

        if (part.text !== "") {
          part = this.instantiateNewRow(targetPart);
        }
      }

      if (isAttr) {
        let attr = part.attributes[part.attributes.length - 1];
        attr.isDirty = true;
        attr.text = text;
      }

      part.text = text;
      part.subParts[0].text = text;
      this.updateDocPartItem(part, targetPart, callback);
    },
    async updateDocPartValue(data) {
      let targetPart = undefined;
      let isChildPart = false;
      let parentPart = null;

      this.document.parttypes.forEach((p) => {
        if (targetPart !== undefined) {
          return;
        }
        if (p.tmpl_part_id === data.part.tmpl_part_id) {
          targetPart = p;
        }
        if (p.childPartTypes.length) {
          p.childPartTypes.forEach((cp) => {
            if (cp.tmpl_part_id === data.part.tmpl_part_id) {
              targetPart = cp;
              isChildPart = true;
              parentPart = p;
              if (targetPart.cardinality === undefined) {
                targetPart.cardinality = utils.getPartTypeMinMax(
                  utils.getWordChecksForPart(
                    targetPart.tmpl_part_id,
                    null,
                    this.wordChecks
                  ),
                  null,
                  cp
                );
              }
            }
          });
        }
      });

      if (targetPart !== undefined) {
        let maxParts = targetPart.cardinality.maxError;

        if (maxParts > 1) {
          let results = [];

          if (data.html !== undefined) {
            results = utils.getSplitParts(data.html, this.dbDragRules);
          }

          let isAttr = targetPart.tmpl_part_metadata.attributes.length > 0;

          if (results.length > 1) {
            for (var i = 0; i < results.length; i++) {
              await this.processMultipleDataUpdates(
                results[i],
                targetPart,
                isChildPart,
                parentPart,
                data,
                isAttr
              );
            }

            /*results.forEach((piece) => {
              setTimeout(
                () =>
                  this.processDataUpdate(
                    piece,
                    targetPart,
                    isChildPart,
                    parentPart,
                    data
                  ),
                100
              );
            });*/
          } else {
            this.processDataUpdate(
              utils.cleanItems([data.text], this.dbDragRules)[0],
              targetPart,
              isChildPart,
              parentPart,
              data,
              isAttr
            );
          }
        } else {
          if (targetPart.tmpl_part_metadata.attributes.length > 0) {
            this.processDataAttributeUpdate(data.text, targetPart);
          } else {
            let part = targetPart.parts[0];
            part.text = data.text;
            part.subParts[0].text = data.text;
            this.saveRow(
              part,
              part.text,
              part.notes,
              null,
              part.is_essential,
              part.attributes,
              part.quality,
              targetPart,
              null
            );
          }
        }

        this.$emit("updateDone");
      }
    },
    is_section_active(name) {
      if (this.use_jd_flow === true) {
        if (this.active_section === "Full Job Description") {
          return "";
        }

        if (name.tmpl_part_metadata.part_section === this.active_section) {
          return "";
        }

        return "section_inactive";
      }

      return "";
    },
    async doNlpSummarise() {
      await nlpUtils
        .doSummarise(
          this.nlpSummarise.text,
          this.nlpSummarise.summarise_max_sent
        )
        .then((resp) => {
          let obj = {
            part: {
              tmpl_part_id: this.nlpSummarise.target,
            },
            text: resp.data.Data.summary,
          };
          this.updateDocPartValue(obj);
          this.nlpSummarise.show = false;
        });
    },
    nlpSummariseDialog(pt, p) {
      this.nlpSummarise.text = p.text;
      this.nlpSummarise.show = true;
      this.nlpSummarise.target = p.tmpl_part_id;
    },
    setup(forceUpdate = false) {
      if (this.data) {
        let docUpdated =
          forceUpdate ||
          !this.document ||
          this.document.doc_id != this.data.document.doc_id;

        if (docUpdated) {
          this.document = this.data.document;
          this.docHierarchies = this.data.docHierarchies;
          this.documentLanguage = this.data.documentLanguage;
          this.docClassifiers = this.data.document.classifiers;
          this.wordChecks = this.data.wordChecks;
          this.partsHistory = this.data.partsHistory;
          this.document.parttypes.forEach((pt) => {
            pt.parts
              .filter((p) => p.expanded)
              .forEach((p) => this.expandPart(p));
            pt.parts.forEach((p) => utils.childPartTypesToChildParts(p));
          });
          this.setCheckSummary();
          this.nlpExclusions = this.data.document.nlpFunctionExclusions;
          this.spellCheckData = this.data.spellCheckData;
          this.setNlpOptions();
          //this.runSpellCheck();
        }

        if (
          !this.docStatus ||
          this.docStatus.status !== this.data.docStatus.status
        ) {
          this.setDocStatus(this.data.docStatus);
        }
      } else if (
        (!this.document ||
          this.document.system_number != utils.removeTags(this.documentId)) &&
        !this.isLoading
      ) {
        this.fetchData();
      }
    },
    setNlpOptions() {
      let clientNlpOpts = nlpUtils.getNlpActions(
        this.$loginState.user.settings
      );

      let languageTools = nlpUtils.getLanguageTools(this.$loginState.user);

      /*let userSpellCheckEnabled = nlpUtils.getSpellCheckSettings(this.$loginState.user);
      let clientSpellCheckEnabled = false;
      let spellCheckEnabled = null;

      if (clientNlpOpts.length > 0) {
        spellCheckEnabled = clientNlpOpts.find((x) => x.text === "SpellCheck");
        clientSpellCheckEnabled = spellCheckEnabled.enabled;
        let clientSummariseEnabled = clientNlpOpts.find((x) => x.text === "Summarize");
        this.summariseEnabled = clientSummariseEnabled.enabled;
      }*/

      this.spellCheckEnabled = languageTools.spellCheck.enabled;
      if (clientNlpOpts.length > 0) {
        let clientSummariseEnabled = clientNlpOpts.find(
          (x) => x.text === "Summarize"
        );
        this.summariseEnabled = clientSummariseEnabled.enabled;
      }

      if (
        this.spellCheckEnabled &&
        languageTools.spellCheck.options.checkOnLoad &&
        (this.document.language_code === "" ||
          this.document.language_code === "en") &&
        !this.readOnly &&
        this.document.state.canEditDocParts
      ) {
        this.doDocSpellCheck();
      }
    },
    doDocSpellCheck() {
      this.document.parttypes.forEach(async (p) => {
        let spellCheckAllowed = !this.nlpExclusions.some(
          (x) =>
            x.tp_id === p.tmpl_part_id && x.opts.indexOf("spell_check") > -1
        );
        if (spellCheckAllowed && p.tmpl_part_metadata.tp_locked === 0) {
          p.parts.forEach((pt) => {
            if (pt.text !== "") {
              p.spellChecking = true;
            }
          });
        }
      });

      axios
        .post("document/doDocSpellCheck/", {
          doc_ref: this.document.system_number,
          lang: this.document.spell_check_language_code,
        })
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.addSpellCheckDataNew(resp.data.Data);
          } else {
            this.$emit(
              "triggerNotification",
              "Error running spellcheck - " + resp.data.Message,
              "error"
            );
          }
        })
        .catch((err) => {
          this.$emit(
            "triggerNotification",
            "Error running spellcheck - " + err,
            "error"
          );
        });
    },
    fetchData(targetLang) {
      this.isLoading = true;
      this.history = null;
      this.ptDeleteHistory = null;
      utils
        .fetchDocumentData(
          utils.removeTags(this.documentId),
          this.statuses,
          targetLang,
          this.selectdTranslateLang
        )
        .then((resp) => {
          this.document = resp.document;
          this.setDocStatus(
            this.statuses.find((x) => x.status === this.document.doc_status)
          );
          this.docHierarchies = resp.docHierarchies;
          this.documentLanguage = resp.documentLanguage;
          this.partsHistory = resp.partsHistory;
          this.wordChecks = resp.wordChecks;
          this.nlpExclusions = resp.nlpFunctionExclusions;
          this.setCheckSummary();
          this.document.parttypes.forEach((pt) => {
            pt.parts
              .filter((p) => p.expanded)
              .forEach((p) => this.expandPart(p));
            pt.parts.forEach((p) => utils.childPartTypesToChildParts(p));
          });

          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    setDocStatus(docStatus) {
      this.docStatus = docStatus;
      this.textEdit.allow_errors = this.document.state.allow_errors;
      this.collaboration =
        this.document.state.show_collaboration &&
        this.document.state.canEditDocParts;
    },
    revertChange(item) {
      let docpart = this.history.part;
      let obj = {
        text: null,
        is_essential: null,
        notes: null,
        quality: null,
        attributes: item.oldAttributes || [],
      };
      if (obj.attributes.length) {
        this.revertAttributes(docpart.attributes, obj.attributes);
      }
      item.changes.forEach((c) => {
        obj[c.column] = c.oldValue;
      });
      let that = this;
      this.saveRow(
        docpart,
        obj.text === null ? docpart.text : obj.text,
        obj.notes === null ? docpart.notes : obj.notes,
        null,
        obj.is_essential === null ? docpart.is_essential : obj.is_essential,
        docpart.attributes,
        obj.quality === null ? docpart.quality : obj.quality,
        this.history.partType,
        function () {
          that.showPartHistory(docpart, that.history.partType);
        }
      );
    },
    saveEssential(row, partType) {
      this.saveRow(
        row,
        row.text,
        row.notes,
        null,
        this.tempEssentials ? row.is_essential : !row.is_essential,
        row.attributes,
        row.quality,
        partType
      );
    },
    saveRow(
      row,
      newval,
      newnotes,
      newsubparts,
      is_essential,
      attributes,
      quality,
      partType,
      callback,
      locked
    ) {
      if (
        this.tempEssentials &&
        row.temp &&
        row.is_essential === null &&
        partType.tmpl_part_metadata.uses_essential_flag
      ) {
        //this.inLineReset(row, partType);
        this.$emit(
          "triggerNotification",
          "You must select if this " +
            partType.type +
            " is Essential/Non Essential",
          "warning"
        );
        //return;
      }

      if (this.readOnly || !this.document.state.canEditDocParts) return;

      this.isLoading = true;
      if (newsubparts && newsubparts.length) {
        newval = utils.subpartsToPart(newsubparts);
      }
      const isNew = !row.doc_part_id;
      let data = {
        text: newval,
        notes: newnotes,
        is_essential: is_essential,
        attributes: attributes
          ? attributes
              .filter((a) => a.isDirty)
              .map((a) => {
                return {
                  tpa_id: a.tpa_id,
                  dpa_id: a.dpa_id,
                  text: a.text,
                  tag_type_id: a.tag_type_id,
                  currentTags: a.currentTags,
                  deletedTags: a.deletedTags,
                };
              })
          : [],
        quality: quality,
        locked: locked,
        doc_part_id: row.doc_part_id,
        doc_part_type: partType.type,
        tmpl_part_id: row.tmpl_part_id,
      };
      if (isNew) {
        data.sequence = row.sequence;
        data.doc_id = this.document.doc_id;
        data.system_number = this.document.system_number;
        data.parent_dp_id = row.parent_dp_id;
      }

      axios
        .post("document/savedocpart/", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            row.isDirty = false;
            row.hover = false;
            row.editing = false;
            row.temp = row.is_essential === null;
            row.text = newval;
            row.notes = newnotes;
            row.is_essential = is_essential;
            row.quality = quality;
            if (locked !== undefined) row.locked = locked;
            utils.setDocPartStatus(
              row,
              partType,
              this.wordChecks,
              this.docStatus,
              this.document
            );
            if (isNew) {
              row.doc_part_id = parseInt(resp.data.Data.newRow.doc_part_id);
              row.dp_name = resp.data.Data.newRow.dp_name;
              if (row.attributes)
                row.attributes.forEach((a) => {
                  a.dpa_id = resp.data.Data.newRowAttributes.find(
                    (na) => na.tpa_id === a.tpa_id
                  )?.dpa_id;
                });

              let parent = null;
              if (row.parent_dp_id) {
                this.document.parttypes.forEach((pt) => {
                  if (!parent) {
                    parent = pt.parts.find(
                      (p) => p.doc_part_id === row.parent_dp_id
                    );
                  }
                });
              }

              if (partType.parts) {
                row.childParts = [];
                row.expanded = false;
                if (
                  !partType.parts.some((x) => x.doc_part_id === row.doc_part_id)
                ) {
                  if (row.newIndex >= 0) {
                    partType.parts.splice(row.newIndex, 0, row);
                  } else {
                    partType.parts.push(row);
                  }
                }

                if (parent) {
                  parent.childParts = partType.parts;
                }

                partType.parts.forEach((p) => {
                  utils.setDocPartStatus(
                    p,
                    partType,
                    this.wordChecks,
                    this.docStatus,
                    this.document
                  );
                });
              } else if (parent) {
                row.canDelete = true;
                parent.childParts.push(row);
              }
            }
            if (row.attributes) {
              if (resp.data.Data.tagPart) {
                row.text = resp.data.Data.tagPart.varVal;
              }
              row.attributes.forEach((a) => {
                a.editing = false;
                a.isDirty = false;
                if (a.tag_type_id && resp.data.Data.tagPart?.attributes) {
                  let tagAttr = resp.data.Data.tagPart.attributes.find(
                    (x) => x.tpa_id === a.tpa_id
                  );
                  a.currentTags
                    .filter((x) => x.tag_value_id <= 0)
                    .forEach((x) => {
                      let newTag = tagAttr.tags.find(
                        (t) =>
                          t.value === x.value && t.tag_type_id === x.tag_type_id
                      );
                      if (newTag) {
                        this.$store.dispatch("hierarchies/addTag", newTag);
                      }
                    });
                  a.currentTags = [];
                  a.deletedTags = [];
                  a.text = tagAttr?.text || "";
                  a.tags = tagAttr?.tags || [];
                }
              });
            }
            let setHistory = false;
            let phIndex = this.partsHistory.findIndex(
              (ph) => ph.key === row.doc_part_id
            );
            if (phIndex >= 0) {
              if (this.history && this.history.key === row.doc_part_id) {
                this.history = null;
                setHistory = true;
              }
              this.partsHistory.splice(phIndex, 1);
            }
            this.partsHistory.push(resp.data.Data.audit);
            if (setHistory) {
              this.history = this.partsHistory[this.partsHistory.length - 1];
              this.history.part = row;
              this.history.partType = partType;
            }
            this.setCheckSummary();
            if (typeof callback === "function") callback();
          } else {
            row.rowMessage = resp.data.Message;
          }
          this.response = resp.data;
          this.checkLoading();
          this.$emit("docUpdated", this.document);
          this.$emit("wordChecksUpdated", this.wordChecks);
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            row.rowMessage = err;
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.checkLoading();
        });
    },
    toggleLockRow(row, partType) {
      //, partType, parent) {
      this.saveRow(
        row,
        row.text,
        row.notes,
        null,
        row.is_essential,
        row.attributes,
        row.quality,
        partType,
        null,
        !row.locked
      );
    },
    deleteRow(row, partType, parent) {
      if (!partType) {
        this.document.parttypes.forEach((x) => {
          if (!partType && x.tmpl_part_id === row.tmpl_part_id) partType = x;
          if (!partType && x.childPartTypes) {
            partType = x.childPartTypes.find(
              (c) => c.tmpl_part_id === row.tmpl_part_id
            );
          }
        });
        if (!partType) alert("partType.type missing");
      }
      let data = {
        doc_part_id: row.doc_part_id,
        doc_part_type: partType.type,
        tmpl_part_id: row.tmpl_part_id,
      };

      if (!data.doc_part_id) {
        //doc part not yet saved so just remove locally.
        this.handleDelete(row, partType, parent);
      } else {
        this.isLoading = true;
        axios
          .post("document/removedocpart/", data)
          .then((resp) => {
            if (resp.data.Status === "OK") {
              this.handleDelete(row, partType, parent, resp);
            } else {
              row.rowMessage = resp.data.Message;
            }
            this.updateRankAttributes(partType, resp.data.Data.affectedRows);
            this.response = resp.data;
            this.checkLoading();
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              console.log(err);
              this.response = err.response
                ? err.response.data
                : { message: "Unexpected Error" };
            }
            this.checkLoading();
          });
      }
    },
    checkLoading() {
      this.ddCount--;
      if (this.ddCount <= 0) {
        this.ddCount = 0;
        this.isLoading = false;
      }
    },
    handleDelete(row, partType, parent, resp) {
      row.isDirty = false;
      let pts = (parent ? parent.childPartTypes : this.document.parttypes).find(
        (pt) => pt.type === partType.type
      );
      if (pts && pts.parts) {
        let pIndex = pts.parts.findIndex(
          (p) => p.doc_part_id === row.doc_part_id
        );
        if (pIndex >= 0) {
          // won't be present if moved
          pts.parts.splice(pIndex, 1);
        }
      }
      if (parent) {
        // clean up child list
        let cIndex = parent.childParts.findIndex(
          (p) => p.doc_part_id === row.doc_part_id
        );
        if (cIndex >= 0) parent.childParts.splice(cIndex, 1);
        if (!parent.childParts.length) {
          parent.expanded = false;
        }
      }
      partType.parts.forEach((p) => {
        utils.setDocPartStatus(
          p,
          partType,
          this.wordChecks,
          this.docStatus,
          this.document
        );
      });

      let phIndex = this.partsHistory.findIndex(
        (ph) => ph.key === row.doc_part_id
      );
      if (phIndex >= 0) {
        if (this.history && this.history.key === row.doc_part_id) {
          this.history = null;
        }
        this.partsHistory.splice(phIndex, 1);
      }
      if (resp && resp.data && resp.data.Data && resp.data.Data.audit) {
        this.partsHistory.push(resp.data.Data.audit);
        if (this.ptDeleteHistory) {
          this.ptDeleteHistory.push(resp.data.Data.audit);
        }
      }
      partType.hasDeletedParts = true;
      this.setCheckSummary();
    },
    revertDelete(item) {
      let partType = this.ptDeleteHistoryPT;
      let row = this.instantiateNewRow(partType);
      let data = {
        doc_part_id: item.key,
        sequence: row.sequence,
        doc_part_type: partType.type,
      };
      this.isLoading = true;

      axios
        .post("document/undoRemoveDocpart/", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            row.doc_part_id = parseInt(resp.data.Data.newRow.doc_part_id);
            row.dp_name = resp.data.Data.newRow.dp_name;
            row.text = resp.data.Data.newRow.text;
            row.sequence = resp.data.Data.newRow.sequence;
            row.notes = resp.data.Data.newRow.notes;
            row.tp_id = resp.data.Data.newRow.tp_id;
            row.is_essential = resp.data.Data.newRow.is_essential;
            row.quality = resp.data.Data.newRow.quality;
            row.dp_active = resp.data.Data.newRow.dp_active;

            if (resp.data.Data.newRowAttributes) {
              row.attributes = row.attributes.map((ra) => {
                let match = resp.data.Data.newRowAttributes.find(
                  (x) => x.tpa_id === ra.tpa_id
                );
                return {
                  ...ra,
                  dpa_id: match?.dpa_id,
                  text: match?.value,
                };
              });
            }

            utils.setDocPartStatus(
              row,
              partType,
              this.wordChecks,
              this.docStatus,
              this.document
            );
            if (partType.parts) {
              row.childParts = [];
              row.expanded = false;
              partType.parts.push(row);
              partType.parts.forEach((p) => {
                utils.setDocPartStatus(
                  p,
                  partType,
                  this.wordChecks,
                  this.docStatus,
                  this.document
                );
              });
            } else {
              this.textEdit.parent.childParts.push(row);
            }

            let phIndex = this.partsHistory.findIndex(
              (ph) => ph.key === row.doc_part_id
            );
            if (phIndex >= 0) {
              this.partsHistory.splice(phIndex, 1);
            }
            this.partsHistory.push(resp.data.Data.audit);

            let dhIndex = this.ptDeleteHistory.findIndex(
              (ph) => ph.key === row.doc_part_id
            );
            if (dhIndex >= 0) {
              this.ptDeleteHistory.splice(dhIndex, 1);
            }
            partType.hasDeletedParts = this.partsHistory.some(
              (pa) => pa.isDeleted && pa.name === partType.type
            );
            this.updateRankAttributes(partType, resp.data.Data.affectedRows);

            this.showDeletedPartHistory(partType);
            this.setCheckSummary();
          } else {
            row.rowMessage = resp.data.Message;
          }
          this.response = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    revertHierarchy(hc) {
      this.isLoading = true;
      let data = {
        doc_id: this.document.doc_id,
        system_number: this.document.system_number,
        ht_id: hc.ht_id,
        hr_id: hc.oldValue,
      };

      axios
        .post("document/saveDocumentHierarchy/", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.documentHeaderSaved(resp.data);
          } else {
            resp.data.Message;
          }
          this.response = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    rFlex() {
      this.roleFlex.show = true;
      this.roleFlex.documentId = this.document.doc_id.toString();
      if (this.isFlexTemplate || this.flexPartId) this.roleFlex.docPart = null;
      else
        this.roleFlex.docPart = this.data.document.parttypes.find(
          (t) => t.type === "Working Pattern"
        );
    },
    addPart(currentPart, partType, parent) {
      let part = this.instantiateNewRow(partType, parent);
      let curIndex = partType.parts.findIndex(
        (p) => p.doc_part_id === currentPart.doc_part_id
      );
      let isLast = partType.parts.length === curIndex + 1;
      partType.parts.splice(curIndex + 1, 0, part);
      let callback = this.changeOrder;

      part.is_essential =
        this.tempEssentials === true &&
        partType.tmpl_part_metadata.uses_essential_flag === 2
          ? null
          : part.is_essential;

      //if (part.temp !== true) {
      this.saveRow(
        part,
        part.text,
        part.notes,
        null,
        part.is_essential,
        part.attributes,
        part.quality,
        partType,
        function () {
          callback(
            null,
            partType,
            isLast ? curIndex + 1 : null,
            isLast ? parent : null
          );
        }
      );
      //}
    },
    instantiateNewRow(partType, parent) {
      let currentParts = (parent ? parent.childParts : partType.parts).filter(
        (x) => x.tmpl_part_id === partType.tmpl_part_id
      );
      let sequence = currentParts.length
        ? currentParts.reduce(
            (pval, cval) => (pval > cval.sequence ? pval : cval.sequence),
            0
          ) + 1
        : 1;
      let ret = {
        Status: "",
        StatusList: [],
        WordCount: "",
        doc_part_id: 0,
        id: "",
        notes: "",
        isDirty: false,
        rowMessage: "",
        sequence: sequence,
        status: "",
        text: "",
        is_essential:
          partType.tmpl_part_metadata.uses_essential_flag === 2
            ? this.tempEssentials
              ? null
              : 1
            : 0,
        attributes: [],
        quality: 0,
        type: partType.type,
        tmpl_part_id: partType.tmpl_part_id,
        parent_dp_id: parent ? parent.doc_part_id : null,
        highlightSection: false,
        editing: false,
        hover: false,
        expanded: false,
      };
      if (partType.tmpl_part_metadata.attributes.length) {
        ret.attributes = partType.tmpl_part_metadata.attributes.map((a) => {
          return {
            dpa_id: 0,
            tpa_id: a.tpa_id,
            text: "",
            editing: false,
            isDirty: true,
            cols: a.cols,
            restrict_to_lookup: a.restrict_to_lookup,
            rank_attribute: a.rank_attribute,
            placeholder: a.placeholder,
            restrict_to_date: a.restrict_to_date,
            tag_type_id: a.tag_type_id,
            locked: a.locked,
            css_class_name: a.css_class_name,
            allow_adding: a.allow_adding,
            lookup_type: a.lookup_type,
            lookup_key: a.lookup_key,
            skip_checks: a.skip_checks,
            restrict_to_htid: a.restrict_to_htid,
            restrict_to_ht_level: a.restrict_to_ht_level,
            multi_select: a.multi_select,
            multi_select_separator: a.multi_select_separator,
          };
        });
      }

      if (partType.childPartTypes && partType.childPartTypes.length) {
        ret.childParts = [];
        utils.setupChildPartTypes(
          ret,
          partType,
          this.partsHistory,
          this.docHierarchies,
          this.wordChecks
        );
      }
      utils.setDocPartStatus(
        ret,
        partType,
        this.wordChecks,
        this.docStatus,
        this.document
      );

      if (parent !== undefined && parent.temp === true) {
        ret.temp = true;
      }

      return ret;
    },
    inLineEdit(pt, part, subpart, isAddPart, attr, opts) {
      // { {
      if (this.readOnly || (attr && (attr.editing || attr.locked))) return;

      if (this.editInProgress.active) {
        if (this.editInProgress.part.isDirty) {
          this.inLineTextSave(
            this.editInProgress.pt,
            this.editInProgress.part,
            this.editInProgress.subpart,
            false,
            this.editInProgress.attr
          );
        } else {
          this.inLineReset(
            this.editInProgress.part,
            this.editInProgress.subpart,
            this.editInProgress.attr
          );
        }
      }

      if (pt.tmpl_part_metadata.derived_from_flex) {
        this.rFlex();
        return;
      }

      if (
        !this.useInline ||
        pt.tmpl_part_metadata.tp_locked ||
        part.locked ||
        !this.document.state.canEditDocParts ||
        (part.subParts && part.subParts.length > 1)
      ) {
        if (
          pt.tmpl_part_metadata.allow_edit_dialog &&
          !pt.tmpl_part_metadata.tp_locked &&
          !part.locked
        )
          this.editText(pt, part);
        return;
      }
      let parent = null;
      if (part.parent_dp_id) {
        this.document.parttypes.forEach((pt) => {
          if (!parent) {
            parent = pt.parts.find((p) => p.doc_part_id === part.parent_dp_id);
          }
        });
      }
      const suggestionType = opts?.suggestionType;
      const summarise = opts?.summarise || false;
      this.editInProgress.active = true;
      this.editInProgress.pt = pt;
      this.editInProgress.part = part;
      this.editInProgress.subpart = subpart;
      this.editInProgress.attr = attr;
      this.editInProgress.isAddPart = isAddPart || false;
      this.editInProgress.parent = parent;
      this.editInProgress.suggestionType = suggestionType;
      this.editInProgress.summarise = summarise;
      part.origText = part.text;
      //   let ameta = null;
      if (attr) {
        attr.origText = attr.text;
        // ameta = pt.tmpl_part_metadata.attributes.find((a) => a.tpa_id === attr.tpa_id);
      }

      if (part.attributes)
        part.attributes.forEach((a) => (a.origText = a.text));

      //let setToEdit = () => { part.editing = true; part.attributes.forEach(a => a.editing = true); };
      let setToEdit = () => {
        if (attr) attr.editing = true;
        part.editing = true;
      };
      if (subpart) {
        subpart.editing = true;
        part.origSubParts = JSON.parse(JSON.stringify(part.subParts));
      }
      if (
        !suggestionType &&
        !summarise &&
        attr &&
        !attr.restrict_to_date &&
        !attr.tag_type_id &&
        (!attr.text || attr.restrict_to_lookup)
      ) {
        this.prepEditPartLookup(
          pt.tmpl_part_id,
          true,
          setToEdit,
          attr.tpa_id,
          part.doc_part_id
        );
      } else if (
        !suggestionType &&
        !summarise &&
        (!part.text || pt.tmpl_part_metadata.restrict_to_lookup)
      ) {
        this.prepEditPartLookup(pt.tmpl_part_id, true, setToEdit);
      } else {
        setToEdit();
      }
    },
    inLineChange(newval, partType, part, subpart, attr, autoSave) {
      const setNewTextValue = (newval, metadata) => {
        const textval =
          metadata.restrict_to_htid && newval.hr
            ? newval.hr[`level${metadata.restrict_to_ht_level}`]
            : newval.text;
        if (metadata.restrict_to_htid && newval.hr) {
          this.$emit("hierarchyUpdated", {
            ht_id: metadata.restrict_to_htid,
            hr_id: newval.hr.value,
          });
        }
        return textval;
      };

      if (subpart) {
        subpart.text = setNewTextValue(newval, partType.tmpl_part_metadata);
        part.text = utils.subpartsToPart(part.subParts);
      } else if (attr?.tag_type_id) {
        attr.currentTags = newval.currentTags;
        attr.deletedTags = newval.deletedTags;
        attr.isDirty =
          newval.deletedTags.length > 0 ||
          newval.currentTags.some((x) => x.change);
      } else if (attr) {
        attr.text = setNewTextValue(newval, attr);
        attr.isDirty = attr.text !== attr.origText;
        part.text = utils.attributesToText(
          partType.tmpl_part_metadata.attributes,
          part.attributes
        );
        part.subParts = utils.partToSubParts(part.text);
      } else {
        part.text = setNewTextValue(newval, partType.tmpl_part_metadata);
      }
      part.isDirty = part.text !== part.origText;
      if (part.isDirty) {
        part.hasIssues =
          newval.errors?.length > 0 || newval.warnings?.length > 0;
      }
      if (autoSave) this.inLineTextSave(partType, part, subpart, false, attr);
    },
    addNewLookup(pt, part, subpart, attr) {
      if (attr.origText !== attr.text) {
        let existingItem = this.lookup.find((x) => x.varVal === attr.text);

        if (!existingItem) {
          let tpa = pt.tmpl_part_metadata.attributes.find(
            (x) => x.tpa_id === attr.tpa_id
          );
          if (tpa) {
            let targetLookup = {
              key: "",
              type: 0,
              value: attr.text,
              isKey: 1,
            };

            if (tpa.lookup_key) {
              targetLookup.key = tpa.lookup_key;
              targetLookup.type = tpa.lookup_type;
            } else {
              let linkedTpa = part.attributes.find(
                (x) => x.tpa_id === tpa.lookup_dependency_tpa_source
              );
              let sourceTpa = pt.tmpl_part_metadata.attributes.find(
                (x) => x.tpa_id === tpa.lookup_dependency_tpa_source
              );
              targetLookup.key = linkedTpa.text;
              targetLookup.type = sourceTpa.lookup_type;
              targetLookup.isKey = 0;
            }

            axios
              .post("/document/addLookupValue", targetLookup)
              .then((resp) => {
                this.response = resp.data;
                this.isLoading = false;
              })
              .catch((err) => {
                if (err.response && err.response.status === 401) {
                  this.$emit("sessionExpired", err);
                } else {
                  console.log(err);
                  this.response = err.response
                    ? err.response.data
                    : { message: "Unexpected Error" };
                }
                this.isLoading = false;
              });
          }
        }
      }
    },
    inLineTextSave(pt, part, subpart, addAnother, attr) {
      let parent = this.editInProgress.parent;
      let partQuality = Number(part.quality);
      if (part.hasIssues && partQuality === 0) part.quality = -1;
      if (!part.hasIssues && partQuality === -1) part.quality = 0;
      if (attr) {
        let ameta = pt.tmpl_part_metadata.attributes.find(
          (m) => m.tpa_id === attr.tpa_id
        );
        if (ameta && ameta.lookup_v2_tpa_destination) {
          let lkup = this.lookup.find(
            (l) => l.varVal === attr.text && l.value2
          );
          if (lkup) {
            let affected = part.attributes.find(
              (a) => a.tpa_id === ameta.lookup_v2_tpa_destination
            );
            this.inLineChange({ text: lkup.value2 }, pt, part, null, affected);
          }
        }
        if (ameta && ameta.lookup_v3_tpa_destination) {
          let lkup = this.lookup.find(
            (l) => l.varVal === attr.text && l.value3
          );
          if (lkup) {
            let affected = part.attributes.find(
              (a) => a.tpa_id === ameta.lookup_v3_tpa_destination
            );
            this.inLineChange(
              { text: lkup.value3 },
              pt,
              part,
              subpart,
              affected
            );
          }
        }
      }
      const vm = this;
      this.saveRow(
        part,
        part.text,
        part.notes,
        part.subParts,
        part.is_essential,
        part.attributes,
        part.quality,
        pt,
        function () {
          vm.inLineReset(part, subpart, attr);
        }
      );
      if (addAnother && part.text && this.autoAddNewRow)
        this.addPart(part, pt, parent);
    },
    inLineTextCancel(part, subpart, attr) {
      part.text = part.origText;
      part.subParts = part.origSubParts;
      if (attr) attr.text = attr.origText;
      //run on timeout else menu click events (deleteRow, addRow) get eaten...
      setTimeout(() => this.inLineReset(part, subpart, attr), 200);
    },
    inLineReset(part, subpart, attr) {
      part.editing = false;
      part.isDirty = false;
      if (subpart) {
        subpart.editing = false;
      }
      if (attr) {
        attr.isDirty = false;
        attr.editing = false;
      }
      this.editInProgress.active = false;
      this.editInProgress.suggestionType = null;
      this.lookup.splice(0);
    },
    expandPart(part) {
      part.expanded = true;
      part.childPartTypes.forEach((cpt) => {
        if (cpt.parts.length === 0) {
          cpt.parts.push(this.instantiateNewRow(cpt, part));
        }
      });
    },
    shrinkPart(part) {
      part.expanded = false;
    },
    editText(partType, row, singleSection, parent) {
      if (this.readOnly || !this.document.state.canEditDocParts) return;
      this.textEdit.text = utils.removeTags(row.text).trim();
      this.textEdit.notes = row.notes;
      this.textEdit.is_essential = row.is_essential;
      this.textEdit.quality = row.quality;
      this.textEdit.subParts = singleSection ? null : row.subParts;
      this.textEdit.row = row;
      this.textEdit.title = partType.type;
      this.textEdit.lookup = [];
      this.textEdit.partType = partType;
      this.textEdit.parent = parent;
      this.textEdit.wordChecks = this.getWordChecksForPart(
        partType.tmpl_part_metadata.tmpl_part_id
      );
      this.textEdit.rules = JSON.parse(
        JSON.stringify(partType.tmpl_part_metadata)
      );
      if (!this.document.state.canEditDocParts || row.locked)
        this.textEdit.rules.tp_locked = 1;
      this.textEdit.canDelete =
        singleSection &&
        (parent || partType.cardinality.minError >= 0) &&
        (!partType.parts ||
          partType.cardinality.minError < partType.parts.length); // not applying rule on childparts yet
      this.prepEditPartLookup(row.tmpl_part_id);
    },
    prepEditPartLookup(tmpl_part_id, isLocal, readyCallback, attr_id, dp_id) {
      if (this.readOnly) return;
      if (!this.cachedLookups) this.cachedLookups = [];
      let existing = this.cachedLookups.find(
        (l) =>
          l.tmpl_part_id === tmpl_part_id &&
          l.tmpl_part_id === tmpl_part_id &&
          (attr_id ? l.attr_id === attr_id : true)
      );
      let setLookup = (data) => {
        if (data.some((d) => d.title !== null)) {
          data = data.sort((a, b) =>
            a.title > b.title ? 1 : a.title < b.title ? -1 : 0
          );
        }
        if (isLocal) {
          this.lookup = data;
        } else {
          this.textEdit.lookup = data;
          this.textEdit.show = true;
        }
      };
      if (existing) {
        setLookup(existing.data);
        if (typeof readyCallback === "function") readyCallback();
      } else {
        this.isLoading = true;
        axios
          .get(
            `document/getTemplatePartValues/${
              this.document.language_code
            }/${tmpl_part_id}_${attr_id ? attr_id : "0"}_${
              dp_id ? dp_id : "0"
            }_0_${this.document.doc_id}`
          )
          .then((resp) => {
            setLookup(resp.data.Data);
            if (resp.data.Data.length > 0 && resp.data.Data[0].nocache === 0) {
              this.cachedLookups.push({
                tmpl_part_id: tmpl_part_id,
                attr_id: attr_id,
                data: resp.data.Data,
              });
            }
            if (typeof readyCallback === "function") readyCallback();
            this.isLoading = false;
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              console.log(err);
              this.response = err.response
                ? err.response.data
                : { message: "Unexpected Error" };
            }
            this.isLoading = false;
          });
      }
    },
    saveTextEdit(newval) {
      this.saveRow(
        this.textEdit.row,
        newval.text,
        newval.notes,
        newval.subParts,
        newval.is_essential,
        [],
        newval.quality,
        this.textEdit.partType
      );
      this.textEdit.show = false;
    },
    deleteTextEdit() {
      this.deleteRow(
        this.textEdit.row,
        this.textEdit.partType,
        this.textEdit.parent
      );
      this.textEdit.show = false;
    },
    getSpellChecks(tp_id, dp_id) {
      if (this.newSpellCheckData.length > 0) {
        let spellChecks = this.newSpellCheckData.find(
          (x) => x.tmpl_part_id === tp_id
        );

        let data = spellChecks?.parts.some((x) => x.doc_part_id === dp_id)
          ? {
              ...spellChecks,
              doc_part_id: dp_id,
            }
          : null;

        return data;
      }

      return null;
    },
    // getGrammarChecks(tp_id) {
    //   if (this.grammarCheckResult.length > 0) {
    //     let grammarChecks = this.grammarCheckResult.find(
    //       (x) => x.type === "spell_check" && x.tp_id === tp_id
    //     );
    //     if (grammarChecks !== undefined && grammarChecks.data.length > 0) {
    //       return [...new Set(grammarChecks.data)];
    //     }
    //   }

    //   return null;
    // },
    applyReadOnlyHighlights(text, tmpl_part_metadata, part, attr) {
      let txt = attr ? attr.text : text;
      if (this.document.state.canEditDocParts) {
        if (!txt) {
          if (attr) {
            let ameta = tmpl_part_metadata.attributes.find(
              (a) => a.tpa_id === attr.tpa_id
            );
            txt = ameta ? ameta.placeholder : "";
          } else {
            txt =
              (tmpl_part_metadata.display_prefix
                ? tmpl_part_metadata.display_prefix + " "
                : "") + tmpl_part_metadata.placeholder;
          }
        } else {
          let wordChecks = this.getWordChecksForPart(
            tmpl_part_metadata.tmpl_part_id
          );
          if (attr && attr.skip_checks.length > 0) {
            wordChecks = wordChecks.filter(
              (x) => !attr.skip_checks.includes(x.check_type_label)
            );
          }

          let status = utils.applyHighlights(
            txt,
            wordChecks,
            true,
            false,
            this.document
          );

          if (this.newSpellCheckData.length > 0) {
            let languageOpts = nlpUtils.getLanguageTools(this.$loginState.user);
            status.highlightedText = utils.applyLanguageHighlights(
              status.highlightedText,
              this.newSpellCheckData,
              tmpl_part_metadata.tmpl_part_id,
              part.doc_part_id,
              languageOpts.spellCheck.options.dictionary
            );
          }

          txt =
            (tmpl_part_metadata.display_prefix
              ? tmpl_part_metadata.display_prefix + " "
              : "") + status.highlightedText;
        }
      }
      //replace newlines with breaks.
      return txt
        .trim()
        .replace(/^(?:\r\n|\r|\n)|(?:\r\n|\r|\n)$/g, "")
        .replace(/(?:\r\n|\r|\n)/g, "<br />");
    },
    getRestrictedCharsForPart(pt) {
      return pt.tmpl_part_metadata.exclusions
        ? pt.tmpl_part_metadata.exclusions.restrict_chars
        : [];
    },
    getWordChecksForPart(tpid, attr) {
      let hchys = this.docHierarchies;
      let results = this.wordChecks.filter((w) =>
        w.tmpl_part_types.some(
          (tp) =>
            tp.tp_id === tpid &&
            (!tp.exclusions.length ||
              !hchys.some((h) =>
                tp.exclusions.some((e) => e.hr_id === h.hr_id)
              ))
        )
      );
      if (attr && attr.skip_checks.length > 0) {
        results = results.filter(
          (x) => !attr.skip_checks.includes(x.check_type_label)
        );
      }
      return results;
    },
    isPartOptional(tp_id) {
      return !this.getWordChecksForPart(tp_id).some(
        (wc) => wc.check_type === "row_count" && wc.include && wc.is_error
      );
    },
    setCheckSummary() {
      this.checkSummary = utils.documentCheckSummary(
        this.document,
        this.wordChecks,
        this.checkSummaryView
      );
      this.$emit("checkSummaryUpdated", this.checkSummary);
      if (this.checkSummary.categories && this.checkSummary.categories.length) {
        this.allCheckSummaryPanels();
      }
    },
    allCheckSummaryPanels() {
      this.checkSummaryPanels = this.checkSummary.categories.map((c, i) => i);
    },
    closeCheckSummaryPanels() {
      this.checkSummaryPanels = [];
    },
    copyCheck() {
      let text = "";
      if (this.checkSummary) {
        if (this.checkSummary.errors.length) {
          text = `${this.checkSummary.errors.length} error${
            this.checkSummary.errors.length > 1 ? "s" : ""
          }`;
        }
        if (this.checkSummary.warnings.length) {
          if (text) text += " and ";
          text += `${this.checkSummary.warnings.length} warning${
            this.checkSummary.warnings.length > 1 ? "s" : ""
          }`;
        }
        if (text) {
          this.confirmCopy = `The document has ${text}. Are you sure you want to copy it?`;
          this.confirmCopyShow = true;
          return false;
        }
      }
      return true;
    },
    canDrag: function (pt, sp) {
      return (
        (pt.tmpl_part_metadata.multi_row || sp.type === "listitem") &&
        (pt.tmpl_part_metadata.moveable || pt.tmpl_part_metadata.canDelete) &&
        !this.readOnly &&
        this.document.state.canEditDocParts
      );
    },
    canMove: function (evt) {
      if (
        this.readOnly ||
        this.editInProgress.active ||
        !evt.relatedContext.element ||
        !evt.draggedContext.element.doc_part_id
      )
        return false;
      if (
        (evt.draggedContext.element.attributes?.length ||
          evt.relatedContext.element.attributes?.length) &&
        evt.draggedContext.element.tmpl_part_id !==
          evt.relatedContext.element.tmpl_part_id
      )
        return false;
      return (
        (evt.draggedContext.element.moveable === true &&
          evt.draggedContext.element.tmpl_part_id ===
            evt.relatedContext.element.tmpl_part_id) ||
        (evt.draggedContext.element.canDelete === true &&
          evt.relatedContext.element.canAdd === true)
      );
    },
    movePart: function (source, destParttype, parent) {
      this.ddCount = 0;
      if (this.readOnly) return;
      let destIndex = null;
      let destBlockType = "";
      let destBlockParent = "";

      if (
        source.removed &&
        source.removed.element.childParts &&
        source.removed.element.childParts.length
      ) {
        destBlockType = source.removed.element.childPartTypes[0].type;
        destBlockParent = destParttype.type;
        let parentset = this.document.parttypes.find(
          (x) => x.tmpl_part_id === source.removed.element.tmpl_part_id
        );
        if (parentset)
          parentset.parts.splice(
            source.removed.oldIndex,
            0,
            source.removed.element
          );
        // check removed not still in childset - will be if dropped from above but will have fired "added" first if below
        this.document.parttypes.forEach((pt) => {
          pt.parts.forEach((p) => {
            p.childPartTypes.forEach((cpt) => {
              let dIndex = cpt.parts.findIndex(
                (x) => x.doc_part_id === source.removed.element.doc_part_id
              );
              if (dIndex >= 0) {
                cpt.parts.splice(dIndex, 1);
              }
            });
          });
        });
      } else if (
        parent &&
        source.added &&
        source.added.element.childParts &&
        source.added.element.childParts.length
      ) {
        destBlockType = destParttype.type;
        let ptype = null;
        this.document.parttypes.forEach((x) => {
          if (
            !ptype &&
            x.parts.some((p) => p.doc_part_id === parent.doc_part_id)
          )
            ptype = x.type;
          if (
            !ptype &&
            x.parts.some((xp) =>
              xp.childPartTypes.some((xc) =>
                xc.parts.some((xcp) => xcp.doc_part_id === parent.doc_part_id)
              )
            )
          )
            ptype = x.type;
        });
        let childset = parent.childPartTypes.find(
          (x) => x.tmpl_part_id === destParttype.tmpl_part_id
        );
        if (childset) {
          let csIndex = childset.parts.findIndex(
            (x) => x.doc_part_id === source.added.element.doc_part_id
          );
          if (csIndex >= 0) childset.parts.splice(csIndex, 1);
        }
      }
      if (destBlockType) {
        const childType = utils.pluralise(destBlockType);
        this.errorDialogue.message = `You cannot move this part to within a ${destBlockParent} as it contains ${childType}. Please remove it's ${childType} first.`;
        this.errorDialogue.show = true;
        return false;
      }

      if (source.removed) {
        // higher level dropped at lower level from "above" - need to find where dropped...
        let movedId = source.removed.element.doc_part_id;
        // find where dropped
        let dest = null;
        let destType = null;
        let destParent = null;
        this.document.parttypes.forEach((pt) => {
          if (!dest) {
            dest = pt.parts.find((p) => p.doc_part_id === movedId);
            if (dest) destType = pt;
          }
          if (!dest) {
            pt.parts.forEach((p) => {
              p.childPartTypes.forEach((cpt) => {
                if (!dest) {
                  let dIndex = cpt.parts.findIndex(
                    (x) => x.doc_part_id === movedId
                  );
                  if (dIndex >= 0) {
                    dest = cpt.parts[dIndex];
                    destType = cpt;
                    destParent = p;
                    destIndex = dIndex;
                  }
                }
              });
            });
          }
        });
        if (dest) {
          destParttype = destType;
          parent = destParent;
        } else {
          destParttype = null;
        }
        source.added = source.removed;
      }
      if (!destParttype) return; // remove fired after added in some cases

      if (source.added) {
        let newPart = JSON.parse(JSON.stringify(source.added.element));
        newPart.tmpl_part_id = destParttype.tmpl_part_id;
        newPart.type = destParttype.type;
        newPart.doc_part_id = 0;
        newPart.status = "";
        newPart.newIndex = source.added.newIndex || destIndex;
        newPart.is_essential =
          destParttype.tmpl_part_metadata.uses_essential_flag === 2 ? 1 : 0;
        newPart.quality = 0;
        newPart.parent_dp_id = parent ? parent.doc_part_id : null;
        if (destParttype.childPartTypes && destParttype.childPartTypes.length) {
          newPart.childParts = [];
          utils.setupChildPartTypes(
            newPart,
            destParttype,
            this.partsHistory,
            this.docHierarchies,
            this.wordChecks
          );
        }
        this.ddCount++;
        if (this.draggedParent) {
          this.deleteRow(
            source.added.element,
            this.draggedParent.partType,
            this.draggedParent.part
          );
        } else {
          this.deleteRow(source.added.element);
        }
        destParttype.parts = destParttype.parts.filter(
          (x) => x.doc_part_id !== source.added.element.doc_part_id
        );
        let callback = this.changeOrder;
        this.ddCount += 2;
        this.saveRow(
          newPart,
          newPart.text,
          newPart.notes,
          null,
          newPart.is_essential,
          newPart.attributes,
          newPart.quality,
          destParttype,
          function () {
            callback(source, destParttype);
          }
        );
      } else if (source.moved) {
        this.ddCount++;
        this.changeOrder(source, destParttype);
      }
    },
    changeOrder: function (e, parttype, focusPartIndex, focusPartParent) {
      if (this.readOnly) return;

      let data = parttype.parts.map((r, i) => {
        let ret = {};
        ret.sequence = i + 1;
        ret.type = parttype.type;
        ret.doc_part_id = r.doc_part_id;
        return ret;
      });
      let orig = this.item
        ? this.item.parttypes
            .find((pt) => pt.type === parttype)
            .parts.find((p) => p.doc_part_id === this.document.doc_part_id)
        : null;
      this.isLoading = true;
      axios
        .post("document/savedocpartsequence/", data)
        .then((resp) => {
          if (orig) {
            if (resp.data.Status === "OK" && resp.data.RowsUpdated) {
              orig.parts = this.document.parts;
            } else {
              this.document.parts = orig.parts;
            }
          }
          if (
            resp.data.Status === "OK" &&
            focusPartIndex !== null &&
            focusPartIndex >= 0
          ) {
            this.inLineEdit(
              parttype,
              parttype.parts[focusPartIndex],
              parttype.parts[focusPartIndex].subParts[0],
              true,
              parttype.parts[focusPartIndex].attributes.length
                ? parttype.parts[focusPartIndex].attributes[0]
                : null
            );
            this.editInProgress.parent = focusPartParent;
          }
          this.updateRankAttributes(parttype, resp.data.Data.updatedAttributes);
          this.response = resp.data;
          this.checkLoading();
          this.draggedParent = null;
        })
        .catch((err) => {
          if (orig) this.document[parttype].parts = orig[parttype].parts;
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error. Please reload the page" };
          }
          this.checkLoading();
          this.draggedParent = null;
        });
    },
    updateRankAttributes(parttype, updates) {
      updates.forEach((a) => {
        let pt = parttype.parts.find((x) => x.doc_part_id === a.dp_id);
        let att = pt ? pt.attributes.find((x) => x.tpa_id === a.tpa_id) : null;
        if (att) {
          att.text = a.value;
          if (!att.dpa_id) att.dpa_id = a.dpa_id;
        }
      });
    },
    refreshFlexScore() {
      axios
        .get("document/getFlexScore/" + this.document.doc_id)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            if (resp.data.Data) {
              this.document.flexCategories = resp.data.Data.flexCategories;
              this.document.flexscore = resp.data.Data.flexscore;
              this.document.flexIncomplete = resp.data.Data.flexIncomplete;
            } else {
              this.document.flexCategories = [];
              this.document.flexscore = null;
              this.document.flexIncomplete = true;
            }
            this.setCheckSummary();
          }
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    newWorkingPattern(html, wordCount) {
      let newVal = utils.partToSubParts(html);
      let wpPartType = this.document.parttypes.find(
        (pt) => pt.type === "Working Pattern"
      );
      wpPartType.parts[0].subParts = newVal;
      wpPartType.parts[0].text = html;
      wpPartType.wordCount = wordCount;
      this.isLoading = true;
      this.refreshFlexScore();
    },
    saveFlex(flexModel) {
      if (
        this.roleFlex.docPart &&
        this.roleFlex.docPart.parts &&
        this.roleFlex.docPart.parts.length !== 0
      ) {
        const flexPart = this.roleFlex.docPart.parts[0];
        this.saveRow(
          flexPart,
          flexModel.workingPatternHtml,
          flexModel.workingPatternNotes,
          null,
          flexPart.is_essential,
          flexPart.attributes,
          flexPart.quality,
          this.roleFlex.docPart
        );
      }

      let pts = this.document.parttypes;
      const updateAttr = (pHead, outcome, outcomeColour) => {
        if (pHead) {
          const newOutcome = outcome
            ? `<div class="chip-${outcomeColour}">${outcome}</div>`
            : "";
          if (pHead.parts[0].attributes[1].text !== newOutcome) {
            pHead.parts[0].attributes[1].text = newOutcome;
            pHead.parts[0].attributes[1].isDirty = true;
            this.inLineTextSave(pHead, pHead.parts[0]);
          }
        }
      };
      flexModel.sections.forEach((s) => {
        let pt = pts.find((t) => t.type === s.name);
        let pth = pts.find((t) => t.type === s.name + " Header");
        let ptn = pts.find((t) => t.type === s.name + " Notes");
        if (
          pt &&
          pt.parts[0].subParts.length &&
          pt.parts[0].subParts[0].text !== s.outcomeDesc
        ) {
          pt.parts[0].subParts[0].text = s.outcomeDesc;
          pt.parts[0].text = s.outcomeDesc;
          this.inLineTextSave(pt, pt.parts[0]);
        }
        if (ptn && ptn.parts[0].attributes[1].text !== s.comment) {
          ptn.parts[0].attributes[1].text = s.comment;
          ptn.parts[0].attributes[1].isDirty = true;
          this.inLineTextSave(ptn, ptn.parts[0]);
        }
        updateAttr(pth, s.outcome, s.outcomeColour);
      });
      flexModel.options.forEach((o) => {
        let pt = pts.find((t) => t.type === "FO " + o.sectionName);
        let pth = pts.find((t) => t.type === "FO " + o.sectionName + " Header");
        let ptn = pts.find((t) => t.type === "FO " + o.sectionName + " Notes");
        if (pt) {
          if (o.activeQuestions.filter((q) => q.outputText).length) {
            pt.parts[0].subParts = o.activeQuestions
              .filter((q) => q.outputText)
              .map((q) => {
                return { editing: false, text: q.outputText, type: "listitem" };
              });
          } else if (o.outcome) {
            pt.parts[0].subParts = [
              { editing: false, text: "Not applicable", type: "listitem" },
            ];
          } else {
            pt.parts[0].text = "";
            pt.parts[0].subParts = [];
          }
          this.inLineTextSave(pt, pt.parts[0]);
        }
        if (ptn && ptn.parts[0].attributes[1].text !== o.comment) {
          ptn.parts[0].attributes[1].text = o.comment;
          ptn.parts[0].attributes[1].isDirty = true;
          this.inLineTextSave(ptn, ptn.parts[0]);
        }
        updateAttr(pth, o.outcome, o.outcomeColour);
      });

      let jt = pts.find((t) => t.type === "Advert Job Title");
      if (
        jt &&
        jt.parts &&
        jt.parts.length &&
        this.$loginState.user.settings.some(
          (s) =>
            s.setting === "flex_update_advert_jobtitle" && s.value === "true"
        )
      ) {
        let currentJT = jt.parts[0].text;
        const joiner = " - ";
        const lastHyphen = currentJT.lastIndexOf(joiner);
        if (lastHyphen > 0) currentJT = currentJT.substring(0, lastHyphen);

        //get list of distinct option labels
        const labels = [
          ...new Set(
            [].concat.apply(
              [],
              flexModel.options.map((o) =>
                o.activeQuestions
                  .filter(
                    (q) =>
                      q.shortLabel && (q.selected === true || q.selected > 0)
                  )
                  .map((q) => q.shortLabel)
              )
            )
          ),
        ];

        const labelsCsv = labels.join(", ");
        const newJT = `${currentJT}${joiner}${labelsCsv}`;
        jt.parts[0].text = newJT;
        jt.parts[0].subParts = [{ editing: false, text: newJT, type: "text" }];
        this.inLineTextSave(jt, jt.parts[0]);
      }

      this.refreshFlexScore();
    },
    highlightSection(issue) {
      this.unhighlightSection();
      issue.items.forEach((t) => {
        t.instances.forEach((i) => {
          this.highlightParts(i.partsAffected);
        });
      });
    },

    highlightParts(partsAffected) {
      partsAffected.forEach((pa) => {
        this.document.parttypes.forEach((pt) => {
          pt.parts.forEach((p) => {
            if (pa === p.doc_part_id) p.highlightSection = true;
            p.childParts.forEach((cp) => {
              if (pa === cp.doc_part_id) cp.highlightSection = true;
            });
          });
        });
      });
    },
    unhighlightSection() {
      this.document.parttypes.forEach((pt) => {
        pt.parts.forEach((p) => {
          p.highlightSection = false;
          p.childParts.forEach((cp) => (cp.highlightSection = false));
        });
      });
    },
    showPartHistory(p, pt, collab) {
      this.ptDeleteHistory = null;
      this.headerHistoryShow = false;
      this.history = this.partsHistory
        ? this.partsHistory.find((pa) => pa.key === p.doc_part_id)
        : null;
      if (this.history) {
        this.history.part = p;
        this.history.partType = pt;
        p.highlightSection = true;
      }
      this.$emit("showPartHistory", p, pt, !!collab);
      this.unhighlightSection();
    },
    showDeletedPartHistory(pt) {
      this.history = null;
      this.headerHistoryShow = false;
      this.ptDeleteHistoryPT = pt;
      this.ptDeleteHistory = this.partsHistory
        ? this.partsHistory.filter((pa) => pa.isDeleted && pa.name === pt.type)
        : null;
      this.$emit("showDeletedPartHistory", pt);
      this.unhighlightSection();
    },
    showHint(pt, why, text) {
      this.hint.text =
        text ||
        (why
          ? pt.tmpl_part_metadata.why_text
          : pt.tmpl_part_metadata.how_to_text);
      this.hint.icon = why ? "mdi-lightbulb-outline" : "mdi-help";
      this.hint.title = why ? "Insights" : "Guidance";
      this.hint.subtitle = pt ? pt.type : "Document";
      this.hint.show = true;
    },
    partHasHover(pt, p) {
      if (!this.readOnly) {
        if (this.hoveredPart) {
          this.hoveredPart.hover = false;
          this.hoveredPart = null;
        }
        this.hoveredPart = p;
        p.hover = true;
      }
    },
    partLostHover(pt, p) {
      p.hover = false;
    },
    reviewChanges(partType, part) {
      let ph = this.partsHistory.find((pa) => pa.key === part.doc_part_id);
      this.review.changes = ph ? ph.reviewVersions : [];
      this.review.show = this.review.changes.length > 0;
      this.review.part = part;
      this.review.partType = partType;
    },
    revertAttributes(original, updates) {
      original.forEach((a) => {
        let updated = updates.find((c) => c.tpa_id === a.tpa_id);
        if (updated && a.text !== updated.text) {
          a.text = updated.text;
          a.isDirty = true;
        }
      });
    },
    reviewRevert(change, part, partType) {
      this.revertAttributes(part.attributes, change.attributes);
      this.saveRow(
        part,
        change.previous,
        part.notes,
        null,
        change.previous_is_essential,
        part.attributes,
        part.quality,
        partType
      );
      this.review.show = false;
    },
    getPartHistory(part) {
      let ph = this.partsHistory.find((pa) => pa.key === part.doc_part_id);
      return ph ? ph.reviewVersions : [];
    },
    userInitials() {
      return this.$loginState.user.name
        .split(" ")
        .map((x) => x.substr(0, 1))
        .join("");
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/vars";

.v-application.theme--light {
  .essential-select::v-deep {
    color: rgba(56, 106, 163, 0.801);
    .v-input__slot {
      color: rgba(56, 106, 163, 0.801);
      background-color: transparent !important;
    }

    &.unselected {
      background-color: rgb(225 93 8 / 9%);
    }

    &.v-input--is-focused {
      background-color: rgba(56, 106, 163, 0.1);
    }
  }
}

.v-application.theme--dark {
  .essential-select::v-deep {
    color: rgba(217, 235, 255, 0.801);
    .v-input__slot {
      color: rgba(217, 235, 255, 0.801);
      background-color: transparent !important;
    }

    &.unselected {
      background-color: rgb(225 93 8 / 9%);
    }

    &.v-input--is-focused {
      background-color: rgba(56, 106, 163, 0.1);
    }
  }
}

.on-hover {
  font-weight: bold;
}

.rm-context-menu {
  * {
    font-family: "Manrope";
    font-size: 14px;
    line-height: 22px;
  }

  .grammarHint {
    font-size: 13px;
    font-style: italic;
  }

  .altContainer {
    max-height: 150px;
    overflow-y: scroll;
  }

  ::v-deep .languageCorrectionText .v-label {
    font-size: 13px;
  }
}

.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
  .v-application--is-ltr & {
    .v-input--selection-controls__input {
      margin-right: 0;
      margin-left: 8px;
    }
  }
  .v-application--is-rtl & {
    .v-input--selection-controls__input {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}

.section_next_text {
  color: #5c5c5c52 !important;
  font-family: "Manrope", sans-serif !important;

  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
}

.section_inactive {
  color: #5c5c5c52 !important;
  display: none;
  font-family: "Manrope", sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.section_header {
  h2 {
    font-family: "Manrope", sans-serif !important;
  }
  font-family: "Manrope", sans-serif !important;
  margin: 0 auto;
  margin-left: 89px;
  margin-right: 90px;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 25px;
  flex: 1 1 auto;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
  color: #3e3e3e;
}

//compensation for change in vuetify 2.4 https://github.com/vuetifyjs/vuetify/issues/12848#issuecomment-754694282
.row {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

//new UI specific styling
.v-application:not(.uitheme-oldui) {
  .editsection {
    padding-left: 0;
    padding-bottom: 30px;
  }
  .editsection.readOnly {
    padding-left: 0;
    box-shadow: none;
  }

  // .part-locked {
  //   cursor: not-allowed;
  // }

  .partBorder {
    border: solid rgba(255, 255, 255, 0) 1px;
    border-radius: 4px;
  }
  .partBorderPersist {
    border: solid 1px rgba(#979797, 0.3);
    border-radius: 4px;
  }

  .CentralTitle,
  .CentralHeaderPart {
    text-align: center;
    justify-content: center;
  }

  .CentralHeaderPart::v-deep textarea,
  .CentralTitle::v-deep textarea {
    text-align: center;
  }

  .row-part.CentralTitle,
  .row-part.CentralHeaderPart,
  .row-part.Title,
  .row-part.SubHeaderPart,
  .row-part.AttrHeaderPart {
    margin-top: 1px;
  }

  .row-part.HeaderPart {
    margin-top: 10px;
  }

  hr.section-divider {
    margin: 10px 15px;
    opacity: 0.5;
  }

  .row-part.CentralTitle,
  .row-part.Title {
    .col-icons {
      padding-top: 16px;
    }
    ::v-deep .clearContent {
      padding-top: 9px;
    }
  }

  .row-part.CentralHeaderPart,
  .row-part.SubHeaderPart {
    .col-icons {
      padding-top: 10px;
    }
    ::v-deep .clearContent {
      padding-top: 3px;
    }
  }

  .row-part.HeaderPart {
    .col-icons {
      padding-top: 12px;
    }
    ::v-deep .clearContent {
      padding-top: 6px;
    }
  }

  .row-part.SpaceBefore {
    margin-top: 50px;
  }

  .row-part.SpaceAfter {
    margin-bottom: 50px;
  }

  .attrAreaBlank {
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px;
    color: #ed8a8a !important;
  }
  .optional .attrAreaBlank {
    color: #a7a7a7 !important;
  }
  .col.editArea,
  .col.editAreaReadOnly,
  .col.editAreaBlank,
  .row--dense > .col.editAreaBlank {
    margin-left: 10px;
    margin-right: 5px;
    padding: 0 10px;
  }

  .editAreaBlank {
    outline: none;
    color: #ed8a8a !important;

    border: solid #ed8a8a30 1px;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 50px;
  }

  .editAreaBlank.optional {
    color: #a7a7a7 !important;
    border-color: #d1d1d1;
  }

  .partBorderHover,
  .partBorderEdit {
    border: solid 1px rgba(#979797, 0.5) !important;
    border-radius: 4px;
  }

  .editAreaBlank.TagList,
  .partBorderHover.TagList,
  .partBorderEdit.TagList {
    border-color: transparent !important;
    cursor: unset;
  }

  .partBorderEdit {
    border: solid 1px $primary-color-light;
    box-shadow: inset 0 0 2px $primary-color-light;
  }

  .v-icon.locked {
    font-size: 20px !important;
  }

  .partActionHolder {
    float: right;
    &.hasAttributes {
      width: 125px;
    }
  }

  .part-action {
    float: right;
    margin-top: 2px;
    margin-right: 5px;
    width: 20px;
    height: 20px;
    > button {
      font-size: 20px !important;
      color: #7d93b4 !important;
    }
  }

  .part.hasEssential {
    .partActionHolder {
      margin-right: 20px;
    }
    .part-action {
      margin-top: 5px;
    }
  }

  .col-icons {
    flex: 0 0 20px;
    padding: 6px 10px 0 0;
    display: flex;
    justify-content: flex-end;
    > div {
      display: flex;
      align-items: flex-start;
      > div {
        width: 16px;
      }
    }
  }

  @media (min-width: 960px) {
    .col-icons {
      flex: 0 0 80px;
    }
  }

  .LineBefore .col-icons {
    padding-top: 26px;
  }

  .part-data {
    display: flex;
    align-items: flex-start;
    padding: 2px;
    padding-bottom: 4px;
    letter-spacing: 0;
    .locked {
      margin: 2px 10px 0 10px;
    }
    &.CentralTitle .locked,
    &.CentralHeaderPart .locked,
    &.Title .locked,
    &.SubHeaderPart .locked,
    &.AttrHeaderPart .locked,
    &.HeaderPart .locked {
      margin-top: 8px;
    }
  }

  .notesIcon {
    font-size: 20px !important;
    color: #7d93b4 !important;
  }

  .gutter {
    display: none;
    // &.Gutter {
    //   display: flex;
    //   height: 0;
    //   padding-top: 0;
    //   top: 20px;
    //   left: -50px;
    //   position: relative;
    //   color: #ce8705ba;
    //   width: 0px;
    // }
    &.InnerGutter {
      display: flex;
      font-weight: bold;
      font-size: 14px;
      width: 0px;
      height: 0;
      padding-top: 0;
      > div {
        width: 30px;
      }
    }
  }

  .subPartGutter {
    display: none;
    width: 0px;
    // &.Gutter {
    //   display: flex;
    //   height: 0;
    //   padding-top: 0;
    //   top: 10px;
    //   left: -180px;
    //   position: relative;
    //   color: #ce8705ba;
    //   width: 0px;
    // }
    &.InnerGutter {
      display: flex;
      width: 200px;
      white-space: nowrap;
      font-weight: bold;
      font-size: 14px;
    }
  }

  .InnerGutter .part-text {
    padding-left: 16px;
  }
}

//old UI specific styling
.v-application.uitheme-oldui {
  .editsection {
    max-width: 90%;
    border-radius: 12px;
    margin-left: 15%;
  }

  .editsection.readOnly {
    margin-left: 5%;
    box-shadow: none;
  }

  .CentralTitle,
  .CentralHeaderPart {
    text-align: center;
  }

  .CentralHeaderPart::v-deep textarea,
  .CentralTitle::v-deep textarea,
  .DocumentHdr::v-deep textarea {
    text-align: center;
  }

  .partBorder {
    border: solid rgba(255, 255, 255, 0) 1px;
  }

  .editArea,
  .editAreaReadOnly {
    margin-left: 10px;
  }

  .col.editArea,
  .col.editAreaReadOnly,
  .col.editAreaBlank,
  .row--dense > .col.editAreaBlank {
    margin-left: 20px;
    padding: 4px 4px;
  }

  .editArea,
  .editAreaReadOnly {
    white-space: pre-wrap;
  }

  .editAreaBlank {
    outline: none;
    color: #ed8a8a !important;
    box-shadow: 0 0 5px #ed8a8a30;
    border: solid #ed8a8a30 1px;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 50px;
  }

  .partBorderHover,
  .partBorderEdit {
    box-shadow: 0 0 10px #9ecaed;
    border: solid #9ecaed 1px;
  }

  .col-icons {
    flex: 0 0 54px;
    padding: 3px;
    display: flex;
    align-items: center;
    > div {
      width: 16px;
    }
  }

  .part-action {
    float: right;
    margin-right: 5px;
    width: 18px;
    height: 22px;
  }

  .col-notes {
    &.inner {
      margin-right: -35px;
    }
  }

  .essential {
    max-height: 20px;
    font-style: italic;
  }

  .notesIcon {
    font-size: 1.1rem !important;
    color: rgba(56, 106, 163, 0.801) !important;
  }

  .notesIcon:hover {
    color: black !important;
  }

  .gutter {
    display: flex;
    height: 0;
    padding-top: 0;
    top: 10px;
    left: -120px;
    position: relative;
    &.Gutter {
      color: #ce8705ba;
      width: 0px;
    }
    &.NoGutter {
      display: none !important;
    }
  }

  .subPartGutter {
    position: absolute;
    left: -80px;
    color: #ce8705ba;
  }
}

.col-part {
  flex: 1 1 auto;
}

.col-notes {
  display: flex;
  flex: 0 0 50px;
  width: 50px;
  padding-top: 5px;
  align-items: flex-start;
  //   justify-content: center;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  &.inner {
    margin-right: -51px;
    padding-left: 7px;
  }
}

.part-text {
  flex-grow: 1;
  max-width: 100%;
}

.part.essential {
  width: 90px;
  max-width: 90px;
  margin-left: 10px;
}

.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
  border: 1px solid #4299e1;
}

.highlightSection {
  background-color: lightgray;
  border: solid grey 2px;
}

.v-application.theme--dark {
  .highlightSection {
    background-color: #616161;
  }

  .partBorderEdit {
    border: solid 1px $primary-color-dark;
    box-shadow: inset 0 0 2px $primary-color-dark;
  }

  .part-action {
    > button {
      color: white !important;
    }
  }
}

.part-action-sub {
  margin-left: 20px;
}
.workingPattern {
  margin-top: -4px;
  margin-right: -4px;
}

.dragHandle {
  color: rgba(56, 106, 163, 0.801) !important;
  cursor: move;
  padding: 0 !important;
  margin-top: -4px;
  margin-right: -4px;
  &.inner {
    margin: 0;
    width: 40px;
    max-width: 40px;
    // margin-left: -40px;
  }
}
.dragHandleDisabled {
  cursor: default;
}
.dragHandle:hover {
  border: solid grey 1px;
  background-color: lightgrey;
  border-radius: 0.25rem;
}
.sub-part {
  max-width: 40px;
}
.data-content {
  margin-top: -5px;
  padding-top: 0;
}
.data-content-2 {
  margin-top: -7px;
  min-height: auto !important;
  max-height: auto;
  padding-bottom: 0;
}
.data-label {
  font-size: 0.6rem;
  padding: 0 5px 0 16px;
  color: darkgray;
}
.data-value {
  font-size: 0.9rem;
}
.data-value-2 {
  font-size: 0.8rem;
  font-style: italic;
  padding-bottom: 10px;
}
.workflowIcon {
  position: relative;
  left: -68px;
  bottom: 74px;
  font-size: 1.7rem;
  color: lightgrey;
}
.workflowIcon:hover {
  color: grey;
  text-shadow: 3px 3px 2px #9ecaed;
  cursor: pointer;
}
.pdfIcon {
  position: relative;
  left: 55px;
  top: 11px;
  height: 25px;
}

::v-deep {
  .CentralHeaderPart > span,
  .CentralHeaderPart textarea,
  .CentralHeaderPart .highlights,
  .SubHeaderPart > span,
  .SubHeaderPart textarea,
  .SubHeaderPart .highlights {
    font-weight: normal;
    font-size: 1.125rem;
    line-height: normal;
  }

  .CentralHeaderPart.emptyText > span,
  .CentralHeaderPart.emptyText textarea,
  .CentralHeaderPart.emptyText .highlights,
  .SubHeaderPart.emptyText > span,
  .SubHeaderPart.emptyText textarea,
  .SubHeaderPart.emptyText .highlights {
    font-size: 1rem;
    line-height: normal;
  }

  .CentralTitle > span,
  .CentralTitle textarea,
  .CentralTitle .highlights,
  .Title > span,
  .Title textarea,
  .Title .highlights {
    font-size: 1.625rem;
    font-weight: bold;
    line-height: normal;
  }

  .HeaderPart > span,
  .HeaderPart textarea,
  .HeaderPart .highlights {
    font-weight: bold;
    font-size: 1.25rem;
    line-height: normal;
  }

  .HeaderPart.emptyText > span,
  .HeaderPart.emptyText textarea,
  .HeaderPart.emptyText .highlights {
    font-weight: normal;
    font-size: 1rem;
  }

  .AttrHeaderPart > span,
  .AttrHeaderPart textarea {
    font-weight: bold;
    font-size: 1rem;
    line-height: normal;
  }

  .AttrHeaderPart.emptyText > span,
  .AttrHeaderPart.emptyText textarea {
    font-weight: normal;
    font-size: 1.1rem;
  }

  .DocumentHdr > span,
  .DocumentHdr textarea,
  .DocumentHdr .highlights {
    font-size: 1.25rem;
    font-style: italic;
  }

  .hierarchy div.row {
    .col span:not(:empty) {
      display: block;
      padding: 10px 20px 10px 10px;
      font-weight: bold;
      font-size: 1rem;
      border: 1px solid black;
      border-radius: 4px;
      min-width: 180px;
      margin-bottom: 10px;
      text-align: center;
      background: lightblue;
      position: relative;
      left: -15px;
    }
    .thisJob span:not(:empty) {
      display: block;
      padding: 10px 20px 10px 10px;
      font-weight: bold;
      font-size: 1rem;
      border: 1px solid black;
      border-radius: 4px;
      min-width: 180px;
      margin-bottom: 10px;
      text-align: center;
      background: yellow;
      position: relative;
      left: -15px;
    }
    .hierHeading span:not(:empty) {
      display: block;
      padding: 5px;
      min-width: 180px;
      background: white;
      position: relative;
      left: -32px;
      border: none;
    }
  }

  .partBorderEdit {
    .hierarchy .emptyText {
      max-width: 350px;
      background: white;
      border: 1px lightgrey dotted;
      margin-right: 20px;
      padding: 10px;
    }
  }

  .hierarchy .emptyText {
    display: block;
    min-width: 180px;
    background: white;
    position: relative;
    left: -32px;
    padding: 10px !important;
  }

  .ContentTitle > span,
  .ContentTitle textarea,
  .ContentTitle .highlights {
    font-size: 1.2rem;
    margin-bottom: -20px;
  }

  .AttrHeader > span,
  .AttrHeader textarea {
    font-size: 1.1rem;
    font-style: italic;
    font-weight: bold;
  }
}

.GutterLabel {
  color: #ce8705ba;
  padding-left: 5px;
  padding-right: 5px;
}
.HeaderPart {
  font-weight: 700;
  font-size: 18px;
}
.AttrHeaderPart {
  font-weight: 700;
  font-size: 14px;
}
.WorkingPattern {
  line-height: 18px;
}
.ListPart {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  max-width: 30px;
}
.ListPartDrag {
  max-width: 30px;
}
.AttrHeaderIndent {
  max-width: 30px;
}
.headerCell {
  padding: 10px 10px 10px 0;
}
.headerCellSmall {
  padding: 0 10px;
  font-size: 0.75rem;
  color: #946206da;
}
@media print {
  .list-group {
    margin-top: -30px;
  }
}
.col.part {
  padding: 1px;
}

.emptyText {
  font-style: italic;
  font-weight: 300 !important;
}
.v-input--selection-controls {
  margin-top: 3px;
}
label,
.v-label,
.v-input--selection-controls .v-input__slot > .v-label {
  font-size: 10px;
}
.v-messages {
  max-height: 1px;
}
.desirableText {
  color: #aaa;
  font-weight: 300;
  font-style: italic;
}
.v-list-item__action {
  margin: 1px 0;
}
div .subPartRow:not(first-child) {
  margin-left: -4px;
}

.partBorderHover {
  cursor: pointer;
}

.allSubParts {
  padding-left: 3px;
  &.indent {
    padding-left: 6%;
  }
}

.subPartHeader {
  font-weight: bold;
  font-size: 15px;
}
</style>
<style>
div.chip-red,
div.chip-orange,
div.chip-green {
  display: inline-block;
  padding: 0 20px;
  height: 30px;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  min-width: 110px;
  border-radius: 6px;
  color: #f1f1f1;
}
div.chip-red {
  background-color: rgba(255, 0, 0, 0.15) !important;
  color: red !important;
}
div.chip-orange {
  background-color: rgba(255, 165, 0, 0.15) !important;
  color: orange !important;
}
div.chip-green {
  background-color: rgba(0, 128, 0, 0.15) !important;
  color: green !important;
}

.nlpData {
  background-color: transparent;
  text-decoration-style: wavy;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: rgb(47, 0, 255);
}
</style>