<template>
    <v-card>
        <v-card-title class="d-flex align-center">
            <v-btn v-if="view === 'lookupDetail'" @click="view = 'lookupList'" color="primary" outlined class="mr-5">
                <v-icon left>mdi-arrow-left</v-icon>Back
            </v-btn>
            <v-btn v-if="view === 'upload'" @click="cancelUpload(); view = 'lookupDetail'" color="primary" outlined class="mr-5">
                <v-icon left>mdi-arrow-left</v-icon>Back
            </v-btn>
            Edit Lookups
            <v-spacer></v-spacer>
            <v-select v-if="mappedUploadData.length" label="Show" dense v-model="uploadDataFilter" outlined hide-details
                :items="['All', 'Valid Only', 'Issues Only']" style="max-width: 150px;" class="mr-4">
            </v-select>
            <v-btn icon large class="btn-background" @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <div class="doc-view-font">
                <div v-if="view === 'lookupList'">
                    <div style="max-height:600px; overflow:hidden scroll">
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th>Key</th>
                                    <th>Type</th>
                                    <th>Auto Order Position</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, idx) in lookupKeys" :key="idx">
                                    <td>{{ item.key }}</td>
                                    <td>{{ item.type }}</td>
                                    <th>{{ item.auto_order_position }}</th>
                                    <td>
                                        <v-icon @click="editLookup(item)">
                                            mdi-cog
                                        </v-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                </div>
                <div v-else-if="view === 'lookupDetail'" style="height:600px; overflow:hidden scroll">
                    <v-row style="padding-top:20px"
                        v-if="editingLookup.items && editingLookup.items.every(i => i.isNew)">
                        <v-col cols="6">
                            <v-text-field label="Lookup Key" v-model="editingLookup.key" @input="selectionValid"
                                dense></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-select label="Lookup Type" v-model="editingLookup.id" @change="selectionValid"
                                :items="lookup_types" item-value="lookup_type_id" item-text="name" dense></v-select>
                        </v-col>
                    </v-row>
                    <v-row style="padding-top:20px" v-else>
                        <v-col cols="6">{{ editingLookup.key }}</v-col>
                        <v-col cols="6">{{ editingLookup.type }}</v-col>
                    </v-row>
                    <v-row style="padding-top:10px" dense
                        v-if="editingLookup.isValid && editingLookup.type === 'Dependent List'">
                        <v-col cols="12">
                            <div>
                                <v-btn small color="primary" @click="addNewDependentNode" style="margin-bottom:10px">Add
                                    New Item</v-btn>
                                <v-treeview ref="treeview" item-key="lk_id" dense open-all selectable
                                    :items="editingLookup.items">
                                    <template slot="label" slot-scope="{ item }">
                                        <div v-if="item.editing === true" style="display:inline-flex; width: 100%">
                                            <v-text-field dense v-model="item.name"
                                                @input="item.isDirty = true;"></v-text-field>
                                            <v-icon title="Cancel" color="grey lighten-2"
                                                @click="cancelEditDependent(item)">mdi-close-thick</v-icon>
                                            <v-icon title="Save" color="grey lighten-2"
                                                @click="confirmEditDependent(item)">mdi-check-bold</v-icon>
                                        </div>
                                        <div v-else style="display:inline">
                                            {{ item.name }}
                                        </div>
                                        <v-btn small icon v-if="item.parent_id === null">
                                            <v-icon @click="addNewTreeItem(item)"
                                                small>mdi-subdirectory-arrow-right</v-icon>
                                        </v-btn>
                                    </template>
                                </v-treeview>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row v-else dense>
                        <v-col v-if="editingLookup.isValid" cols="12">
                            <div style="width:100%; padding-top:10px">
                                <v-row dense>
                                    <v-col cols="1"></v-col>
                                    <v-col cols="4">Value</v-col>
                                    <v-col cols="4">Title</v-col>
                                    <v-col cols="2">Auto Order Position</v-col>
                                    <v-col cols="1">Actions</v-col>
                                </v-row>
                                <draggable :list="editingLookup.items" :handle="'.dragHandle'"
                                    :group="{ name: `items` }" style="width:100% !important"
                                    :disabled="editingLookup.items.some(x => x.editing)"
                                    @change="(e) => { return moveLookup(e); }">
                                    <v-row dense v-for="(item, idx) in editingLookup.items" :key="idx">
                                        <v-col cols="1">
                                            <v-icon v-if="!editingLookup.items.some(x => x.editing)" style="cursor:move"
                                                class="dragHandle">mdi-drag-variant</v-icon>
                                        </v-col>
                                        <v-col cols="4">
                                            <div v-if="item.editing === true" style="display:inline-flex; width: 100%">
                                                <v-text-field dense v-model="item.value"
                                                    @input="item.isDirty = true;"></v-text-field>
                                            </div>
                                            <div v-else>
                                                {{ item.value }}
                                            </div>
                                        </v-col>
                                        <v-col cols="4">
                                            <div v-if="item.editing === true" style="display:inline-flex; width: 100%">
                                                <v-text-field dense v-model="item.title"
                                                    @input="item.isDirty = true;"></v-text-field>
                                            </div>
                                            <div v-else>
                                                {{ item.title ?? "" }}
                                            </div>
                                        </v-col>
                                        <v-col cols="2">
                                            <div>
                                                {{ item.auto_order_position ?? "" }}
                                            </div>
                                        </v-col>
                                        <v-col cols="1">
                                            <v-icon v-if="!item.editing" @click="editLookupItem(item)">mdi-cog</v-icon>
                                            <v-icon v-if="!item.editing"
                                                @click="deleteLookupItem(item)">mdi-delete</v-icon>
                                            <v-icon v-if="item.editing" title="Cancel"
                                                @click="cancelEdit(item)">mdi-close-thick</v-icon>
                                            <v-icon v-if="item.editing" title="Save"
                                                @click="confirmEdit(item)">mdi-check-bold</v-icon>
                                        </v-col>
                                    </v-row>
                                </draggable>
                            </div>
                        </v-col>
                        <br />
                    </v-row>
                </div>
                <div v-else-if="view === 'upload'">
                    <v-row dense v-if="!uploadData">
                        <v-col cols="12">
                            <v-alert type="info" text dense>
                                Please upload an XLS spreadsheet containing the data to be imported.
                                Data will only be loaded from the first tab on the spreadsheet and
                                the first row is expected to contain column headings.
                            </v-alert>
                        </v-col>
                        <v-col cols="8" dense>
                            <v-file-input v-model="inputFile" :accept="fileTypes" @change="selectFile"
                                label="Select File" class="btn"></v-file-input>
                        </v-col>
                        <v-col cols="4" dense class="d-flex justify-end align-center">
                        </v-col>
                    </v-row>
                    <v-data-table v-else :headers="uploadDataHeaders" dense :sort-by="sortBy" :sort-desc="sortDesc"
                        :items="filteredMappedUploadedData" hide-default-header>
                        <template v-slot:header="{ props }">
                            <thead class="v-data-table-header">
                                <tr>
                                    <th v-for="(head, hi) in props.headers" :key="'h' + hi" @click="setUploadSort(head)"
                                        :class="head.sortable === false ? '' : 'sortable'">
                                        <template>
                                            <span>
                                                {{ head.text }}
                                            </span>
                                            <v-icon small v-if="head.sortable !== false && head.value === sortBy">{{
                                                sortDesc ? "mdi-arrow-down" : "mdi-arrow-up"
                                            }}</v-icon>

                                            <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-icon v-if="head.source" small
                                                                v-on="{ ...on, ...tooltip }" v-bind="attrs"
                                                                class="ml-2">mdi-cog</v-icon>
                                                            <v-icon v-else small v-on="{ ...on, ...tooltip }"
                                                                v-bind="attrs" color="warning"
                                                                class="ml-2">mdi-alert</v-icon>
                                                        </template>
                                                        <span v-if="head.source">Change source column</span>
                                                        <span v-else>Select a source column</span>
                                                    </v-tooltip>
                                                </template>
                                                <v-list dense>
                                                    <v-list-item v-for="(uh, uhi) in uploadData.headers"
                                                        :key="'uh' + uhi" @click="setUploadSource(head, uh)">
                                                        <v-list-item-title>
                                                            {{ uh }}
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </template>
                                    </th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:item="{ item }">
                            <tr>
                                <td v-for="col in uploadDataHeaders" :key="col.value">
                                    <span v-html="item[col.value].value" style="font-size: 0.8rem;"></span>
                                    <v-tooltip bottom v-if="item[col.value].issues.length !== 0">
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-icon small v-on="{ ...tooltip }" color="warning"
                                                class="ml-1">mdi-alert</v-icon>
                                        </template>
                                        <div v-html="item[col.value].issues.join('<br />')"></div>
                                    </v-tooltip>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </div>

                <v-row class="d-flex justify-space-between px-4 mt-3 mb-2">
                    <div>
                        <v-btn v-if="view === 'lookupList'" color="primary" @click="addItem" class="ml-2">Add
                            New</v-btn>
                        <v-btn v-if="view === 'lookupDetail'" color="primary" outlined @click="addNewLookup()">Add
                            New</v-btn>
                        <v-btn v-if="view === 'lookupDetail' && editingLookup.isValid" color="primary" outlined
                            @click="view = 'upload'" class="ml-2">Upload Lookups</v-btn>
                    </div>
                    <div>
                        <v-btn v-if="view === 'lookupDetail'" @click="save" color="primary"
                            :disabled="!isDirty">Save</v-btn>
                        <v-btn v-if="view === 'upload' && uploadData" @click="processImport" color="primary"
                            class="ml-2">Import {{
                                validUploadData.length }} Lookups</v-btn>
                    </div>
                </v-row>

                <Notification :notification="notification" />
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import Notification from "@/components/common/SnackBar.vue";

export default {
    name: "LookupAdmin",
    props: {
        lookup_type: { type: Number },
        lookup_key: { type: String }
    },
    data: function () {
        return {
            view: "lookupList",
            lookup_types: [],
            lookups: [],
            lookupKeys: [],
            editingLookup: {},
            positionsDirty: false,
            allowAddSub: false,
            notification: {
                text: "",
                type: "success"
            },
            fileTypes: ".xls,.xlsx,.ods,.csv",
            inputFile: null,
            selectedFiles: [],
            filesUploading: false,
            progressInfos: [],
            uploadData: null,
            uploadDataFilter: 'All',
            uploadDataHeaders: [
                { text: "Value", value: "value", defaultSource: "value", source: null, sort: this.sortByProperty("value") },
                { text: "Title", value: "title", defaultSource: "title", source: null, sort: this.sortByProperty("title") },
            ],
            mappedUploadData: [],
            sortBy: "key",
            sortDesc: false,
        };
    },
    components: { draggable, Notification },
    created() {
        this.init();
    },
    watch: {
        lookup_type() {
            this.openRequested();
        },
        lookup_key() {
            this.openRequested();
        }
    },
    computed: {
        validUploadData() {
            return this.mappedUploadData.filter(d => !this.uploadDataHeaders.some(h => !h.source || d[h.value].issues.length));
        },
        invalidUploadData() {
            return this.mappedUploadData.filter(d => this.uploadDataHeaders.some(h => !h.source || d[h.value].issues.length));
        },
        filteredMappedUploadedData() {
            if (this.uploadDataFilter === 'Issues Only')
                return this.invalidUploadData;
            else if (this.uploadDataFilter === 'Valid Only')
                return this.validUploadData;
            else
                return this.mappedUploadData;
        },
        isDirty() {
            return this.positionsDirty || this.editingLookup.items?.some(l => l.isDirty);
        }
    },
    methods: {
        close() {
            this.cancelUpload();
            this.$emit('close');
        },
        triggerNotification(text, type) {
            this.notification = {
                text: text,
                type: type
            }
        },
        moveLookup() {
            this.positionsDirty = true;
            this.editingLookup.items = this.editingLookup.items.map((x, xIdx) => {
                return {
                    ...x,
                    auto_order_position: (xIdx + 1)
                }
            });
        },
        saveLookupOrder() {
            let data = this.editingLookup.items;
            axios
                .post("admin/saveLookupOrder/", data)
                .then(resp => {
                    if (resp) {
                        this.triggerNotification("Order Saved", "success");
                        this.positionsDirty = false;
                    } else {
                        this.triggerNotification("Something went wrong", "error");
                    }
                })
                .catch(err => {
                    this.triggerNotification(err, "error");
                });
        },
        selectionValid() {
            this.editingLookup.isValid = this.editingLookup.key !== "" && this.editingLookup.id !== null;
        },
        addItem() {
            this.view = "lookupDetail";
            this.editingLookup = {
                key: "",
                type: null,
                id: null,
                items: [],
                isNew: true,
                isValid: false
            }
        },
        baseLookupObj(key, parent, temporaryId) {
            return {
                children: [],
                editing: true,
                id: `${parent ?? 0}|${temporaryId}`,
                isDirty: true,
                key: key,
                ld_id: 0,
                lds_id: 0,
                lk_id: 0,
                title: "",
                name: "",
                previousValue: "",
                previousTitle: "",
                value: "",
                parent_id: parent ? parent : null
            }
        },
        addNewDependentNode() {
            let newObj = {
                children: [],
                editing: true,
                id: null,
                isDirty: true,
                key: this.editingLookup.key,
                ld_id: 0,
                lds_id: 0,
                lk_id: 0,
                title: "",
                name: "",
                previousValue: "",
                previousTitle: "",
                value: "",
                parent_id: null
            }
            this.editingLookup.items.push(newObj);
        },
        cancelEditDependent(item) {
            item.value = item.previousValue;
            item.title = item.previousTitle;
            item.editing = false;
        },
        confirmEditDependent(item) {
            item.editing = false;
            if (item.name === item.previousValue) {
                return;
            }

            let depItemType = this.lookup_types.find(x => x.name === "Dependent List Item");
            let depListType = this.lookup_types.find(x => x.name === "Dependent List");

            let req = {};

            if (item.parent_id) {
                let parent = this.editingLookup.items.find(x => x.lk_id === item.parent_id);
                if (parent) {
                    req = {
                        item: {
                            ...item,
                            lookup_type: Number(depItemType.lookup_type_id),
                            key: parent.name,
                            value: item.name
                        },
                        parent: {
                            ...parent,
                            lookup_type_main: Number(depListType.lookup_type_id),
                            lookup_type_link: Number(depItemType.lookup_type_id)
                        }
                    }
                }
            } else {
                req = {
                    item: {
                        ...item,
                        value: item.name,
                        lookup_type: Number(depListType.lookup_type_id),
                        lookup_type_main: Number(depListType.lookup_type_id),
                        lookup_type_link: Number(depItemType.lookup_type_id)
                    },
                    parent: null
                }
            }

            axios
                .post("admin/saveDependentLookup/", req)
                .then(resp => {
                    console.log(resp);
                    this.triggerNotification("Saved", "success");
                })
                .catch(err => {
                    console.log(err);
                });

        },
        addNewLookup(value = "", title = "") {
            let addOrdered = this.editingLookup.items.some(x => x.auto_order_position);
            let newObj = {
                isNew: true,
                editing: true,
                id: 0,
                isDirty: true,
                key: this.editingLookup.key,
                lk_id: 0,
                lookup_type: this.editingLookup.id,
                name: this.editingLookup.key,
                previousValue: "",
                previousTitle: "",
                value: value,
                title: title,
                auto_order_position: addOrdered ? this.editingLookup.items.length + 1 : null,
                previous_auto_order_position: null
            }
            this.editingLookup.items.push(newObj);
            return newObj;
        },
        cancelEdit(item) {
            item.value = item.previousValue;
            item.title = item.previousTitle;
            item.auto_order_position = item.previous_auto_order_position;
            item.editing = false;
        },
        async confirmEdit(item) {
            item.editing = false;
            if (item.value === item.previousValue && item.title === item.previousTitle) {
                return;
            }

            if (item.isNew) {
                let lookupType = this.lookup_types.find(x => x.lookup_type_id == this.editingLookup.id) || this.lookup_types[0];
                item.id = Number(lookupType.lookup_type_id);
                item.lookup_type = Number(lookupType.lookup_type_id);
            }

            await axios
                .post("admin/saveLookup/", item)
                .then(resp => {
                    this.update(resp, item);
                    this.triggerNotification("Saved", "success");
                })
                .catch(err => {
                    console.log(err);
                });
        },
        update(resp, item) {
            let idx;
            if (item.lk_id === 0) {
                idx = this.editingLookup.items.indexOf(item);
                if (item.isNew) {
                    item.isNew = false;
                }
            } else {
                idx = this.editingLookup.items.findIndex(x => x.lk_id === item.lk_id);
            }

            idx > -1 ? this.editingLookup.items.splice(idx, 1, resp.data) : this.editingLookup.items.push(resp.data);
        },
        addNewTreeItem(item) {
            //let parent = this.editingLookup.items.find(x => x.lk_id === item.parent_id);

            let newObj = this.baseLookupObj(item.key, item.lk_id, item.children.length);

            /*let cloned = JSON.parse(JSON.stringify(item));
            cloned = {
                ...cloned,
                name: "Testing " + parent.children.length,
                id: parent.children.length,
                lk_id: 0,
                locked: false,
                editing: true,
                previousValue: ""
            }*/
            item.children.push(newObj);
        },
        deleteLookup(item) {
            let idx = this.editingLookup.items.findIndex(x => x.lk_id === item.data.lk_id);
            if (idx > -1) {
                this.editingLookup.items.splice(idx, 1);
            }
        },
        deleteLookupItem(item) {
            if (confirm("Delete Lookup Value - " + item.key)) {
                axios
                    .post("admin/deleteLookup/", item)
                    .then(resp => {
                        this.deleteLookup(resp, item);
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        },
        editLookupItem(item) {
            item.editing = true;
        },
        openRequested() {
            if (this.lookup_type && this.lookup_key) {
                const key = this.lookupKeys.find(l => l.type_id == this.lookup_type && l.key == this.lookup_key);
                if (key)
                    this.editLookup(key);
            }
        },
        editLookup(item) {
            this.view = "lookupDetail";
            item.children = item.children.sort((pt1, pt2) => { return pt1.auto_order_position > pt2.auto_order_position ? 1 : (pt1.auto_order_position < pt2.auto_order_position ? -1 : 0) })
            this.editingLookup = {
                key: item.key,
                type: item.type,
                id: item.type_id,
                auto_order_position: item.auto_order_position,
                items: item.children,
                isNew: false,
                isValid: true
            }

            if (this.editingLookup.type === 'Dependent List') {
                let placeholder = this.baseLookupObj(item.key, null, item.children.length);
                this.editingLookup.items.push(placeholder);
            }
        },
        init() {
            axios
                .get("admin/clientLookups/")
                .then(resp => {
                    this.setup(resp.data)
                })
                .catch(err => {
                    console.log(err);
                });
        },
        setup(data) {
            this.lookup_types = data.lookup_types;
            this.lookups = data.lookups;
            this.lookupKeys = data.lookups_grouped.filter(x => x.type !== 'Dependent List Item');

            this.openRequested();
        },

        async save() {
            for (let index = 0; index < this.editingLookup.items.length; index++) {
                const lookup = this.editingLookup.items[index];
                if (lookup.isDirty)
                    await this.confirmEdit(lookup);
            }
            if (this.positionsDirty)
                this.saveLookupOrder();

            if (!this.lookupKeys.find(l => l.type_id == this.editingLookup.id && l.key == this.editingLookup.key)) {
                let lookupType = this.lookup_types.find(x => x.lookup_type_id == this.editingLookup.id)
                this.lookupKeys.push({
                    type_id: this.editingLookup.id,
                    type: lookupType?.name,
                    key: this.editingLookup.key,
                    children: this.editingLookup.items
                })
            }
        },
        selectFile() {
            this.progressInfos.splice(0);
            this.selectedFiles.splice(0);
            let allowed = this.fileTypes.split(",");
            if (event.target.files) {
                for (let i = 0; i < event.target.files.length; i++) {
                    let allow = allowed.some((x) =>
                        event.target.files[i].name.includes(x)
                    );
                    if (allow) {
                        this.selectedFiles.push(event.target.files[i]);
                        this.progressInfos[i] = {
                            percentage: 0,
                            fileName: event.target.files[i].name,
                        };
                    }
                }
            }
            this.uploadMulti();
        },
        cancelUpload() {
            this.progressInfos.splice(0);
            this.selectedFiles.splice(0);
            this.mappedUploadData.splice(0);
            this.uploadData = null;
            this.inputFile = null;
        },
        uploadMulti() {
            this.filesUploading = true;
            let formData = new FormData();

            for (var i = 0; i < this.selectedFiles.length; i++) {
                let file = this.selectedFiles[i];
                formData.append("files[]", file);
            }

            let options = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };

            try {
                axios
                    .post("user/uploadUsers", formData, options)
                    .then((resp) => {
                        this.filesUploading = false;
                        if (resp.data.Data) {
                            this.uploadData = resp.data.Data;
                            this.uploadDataHeaders.forEach(h => {
                                h.source = this.uploadData.headers.find(sh => sh.toLowerCase().includes(h.defaultSource));
                            });
                            this.mapAndvalidateUploadData();
                        }
                    })
                    .catch((err) => {
                        this.triggerNotification("Error uploading - " + err, "error");
                        this.message = "Could not upload the file:" + err;
                        this.filesUploading = false;
                    });
            } catch (err) {
                console.log(err);
            }
        },
        setUploadSource(head, source) {
            head.source = source;
            this.mapAndvalidateUploadData();
        },
        setUploadSort(head) {
            if (head.sortable === false) return;

            if (this.sortBy === head.value) {
                this.sortDesc = !this.sortDesc;
            } else {
                this.sortBy = head.value;
            }
        },
        sortByProperty(property) {
            return function (a, b) {
                if (a[property] > b[property]) return 1;
                else if (a[property] < b[property]) return -1;

                return 0;
            };
        },
        async mapAndvalidateUploadData() {
            if (!this.uploadData || !this.uploadData.data || this.uploadData.data.length === 0)
                return;

            this.mappedUploadData.splice(0);

            const mappedUploadData = this.uploadData.data.map(d => {
                const output = {};
                this.uploadDataHeaders.forEach(h => {
                    const val = h.source ? (d[h.source] || '').trim() : '';
                    const issues = [];
                    if (!val)
                        issues.push(`Missing`);
                    output[h.value] = {
                        value: val,
                        issues: issues
                    }
                });
                return output;
            });

            this.mappedUploadData.push(...mappedUploadData);
        },
        async processImport() {
            for (let index = 0; index < this.validUploadData.length; index++) {
                const data = this.validUploadData[index];
                const newLookup = this.addNewLookup(data.value.value, data.title.value);
                await this.confirmEdit(newLookup);
            }
            this.cancelUpload();
            this.view = 'lookupDetail';
        }
    },
};
</script>
<style scoped lang="scss"></style>