<template>
  <div>
    <div class="flex-container" v-show="!isLoading">
      <div
        v-show="$vuetify.breakpoint.smAndUp && !showHierarchies"
        class="hierarchies-collapsed"
      >
        <v-btn
          fab
          elevation="2"
          x-small
          title="Show Advanced Filters"
          @click="showHierarchies = !showHierarchies"
          absolute
          color="primary"
          class="showHierarchies"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>

      <v-navigation-drawer
        v-if="$vuetify.breakpoint.smAndUp"
        v-model="showHierarchies"
        absolute
        class="left-col"
      >
        <v-btn
          fab
          elevation="2"
          x-small
          title="Show Hierarchies"
          @click="showHierarchies = !showHierarchies"
          absolute
          right
          color="primary"
          class="showHierarchies"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title class="d-flex align-center pl-1 pt-5">
          <span class="ml-2">Filter By Hierarchy</span>
        </v-toolbar-title>

        <HierarchySearch
          v-show="showHierarchies"
          :items="documentsForSearch"
          :preSelected="preSelectedHierarchies"
          :hierarchyTypes="hierarchyTypes"
          :classifierTypes="classifierTypes"
          @filterChanged="doHierarchyFilter"
        ></HierarchySearch>
      </v-navigation-drawer>
      <v-container
        v-if="selectedView"
        fluid
        :class="{
          'right-col': true,
          hierarchiesCollapsed: !showHierarchies && $vuetify.breakpoint.smAndUp,
          hierarchiesPinned: showHierarchies && $vuetify.breakpoint.lgAndUp,
        }"
      >
        <v-row class="py-4 pl-4">
          <v-col
            ><v-toolbar
              elevation="1"
              color="transparent"
              :extension-height="80"
            >
              <v-toolbar-title>Job Architecture</v-toolbar-title>
              <v-menu bottom close-on-click nudge-bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon title="Add Hierarchy" v-bind="attrs" v-on="on">
                    <v-icon>add_circle</v-icon>
                  </v-btn> </template
                ><v-list>
                  <v-list-item
                    v-for="(h, hi) in hierarchies"
                    :key="'ha' + hi"
                    @click="addHierarchy(h)"
                  >
                    <v-list-item-title>Add {{ h.label }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-divider vertical class="mr-3"></v-divider>
              <v-menu bottom close-on-click>
                <template v-slot:activator="{ on, attrs }">
                  <v-toolbar-title v-bind="attrs" v-on="on"
                    >{{ selectedView.name }}<v-icon class="mx-1" large>mdi-menu-down</v-icon>
                  </v-toolbar-title> </template
                ><v-list class="selectViewMenu">
                  <v-list-item v-if="views.some((x) => x.restricted)">
                    <v-list-item-title class="subtitle-1"
                      >My Views:</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item
                    v-for="(v, vi) in views.filter((x) => x.restricted)"
                    :key="'vir' + vi"
                    @click="selectView(v)"
                  >
                    <v-list-item-content class="ml-3">{{
                      v.name
                    }}</v-list-item-content
                    ><v-list-item-icon
                      ><v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on"
                            >mdi-information-outline</v-icon
                          >
                        </template>
                        <div v-html="v.description"></div> </v-tooltip
                    ></v-list-item-icon>
                  </v-list-item>
                  <v-list-item v-if="views.some((x) => x.restricted)">
                    <v-list-item-title class="subtitle-1"
                      >Standard Views:</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item
                    v-for="(v, vi) in views.filter((x) => !x.restricted)"
                    :key="'vi' + vi"
                    @click="selectView(v)"
                  >
                    <v-list-item-content
                      :class="views.some((x) => x.restricted) ? 'ml-3' : ''"
                      >{{ v.name }}</v-list-item-content
                    ><v-list-item-icon
                      ><v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on"
                            >mdi-information-outline</v-icon
                          >
                        </template>
                        <div v-html="v.description"></div> </v-tooltip
                    ></v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-divider
							v-if="permissions.saveViewMain || permissions.saveViewOwn" vertical class="mr-3"></v-divider>
              <v-menu
                v-if="permissions.saveViewMain || permissions.saveViewOwn"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-badge
                    :color="selectedView.isDirty ? 'warning' : 'transparent'"
                    icon="mdi-content-save"
                    overlap
                  >
                    <v-btn
                      small
                      icon
                      v-bind="attrs"
                      v-on="on"
                      title="Save Actions Available"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn></v-badge
                  >
                </template>
                <v-list>
                  <v-list-item
                    v-if="
                      selectedView.isDirty &&
                      ((permissions.saveViewMain && !selectedView.restricted) ||
                        (permissions.saveViewOwn && selectedView.restricted))
                    "
                  >
                    <v-list-item-content
                      ><v-btn small width="100%" @click="saveDefinition"
                        >Save View</v-btn
                      ></v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item
                    v-if="permissions.saveViewMain || permissions.saveViewOwn"
                  >
                    <v-list-item-content
                      ><v-btn small width="100%" @click="saveDefinitionAs"
                        >Save View As...</v-btn
                      ></v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item v-if="selectedView.isDirty">
                    <v-list-item-content
                      ><v-btn small width="100%" @click="resetDefinition"
                        >Reset View</v-btn
                      ></v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item
                    v-if="
                      (selectedView.restricted && permissions.saveViewOwn) ||
                      (permissions.saveViewMain &&
                        !selectedView.definition.isBase)
                    "
                  >
                    <v-list-item-content
                      ><v-btn small width="100%" @click="deleteDefinition"
                        >Delete View</v-btn
                      ></v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item v-if="selectedView.isDirty"
                    >Changes:</v-list-item
                  >
                  <v-list-item v-if="selectedView.isDirty">
                    <ul>
                      <li
                        v-for="(c, ci) in getDefinitionChanges()"
                        :key="'dc' + ci"
                        class="caption"
                      >
                        {{ c }}
                      </li>
                    </ul>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-divider vertical class="mx-3"></v-divider>
              <v-spacer></v-spacer
              ><v-btn
                v-if="
                  defAttrVisible('attributeFiltersVisible') ||
                  defAttrVisible('nameChangeFiltersVisible')
                "
                icon
                small
                class="mx-2"
                title="Show Filters"
                @click="showFilters"
                ><v-icon>mdi-filter-menu-outline</v-icon></v-btn
              ><v-btn
                v-if="permissions.changeSettings"
                icon
                small
                class="mx-2"
                title="Report Designer"
                @click="showDesigner"
                ><v-icon>mdi-tools</v-icon></v-btn
              >
              <v-divider
							v-if="selectedView.definition.viewType === 'catalogue' && !layout?.detailRow?.hierarchyHeadings" vertical ></v-divider><v-btn
                v-if="selectedView.definition.viewType === 'catalogue' && !layout?.detailRow?.hierarchyHeadings"
                icon
                small
                class="mx-2"
                title="Download To xlsx"
                @click="exportToExcel"
                ><v-icon>mdi-file-excel-box</v-icon></v-btn
              >
              <v-divider vertical class="mr-2"></v-divider>
              <v-btn
                v-for="(v, vi) in primaryViews"
                :key="'pv' + vi"
                icon
                small
                :title="v.name"
                class="ml-3"
                @click="selectView(v)"
              >
                <v-icon
                  :color="selectedView.name === v.name ? 'blue darken-4' : ''"
                  >{{ v.definition.primary.icon }}</v-icon
                >
              </v-btn>
              <v-divider
                v-if="permissions.saveShortcuts"
                vertical
                class="mx-3"
              ></v-divider
              ><v-btn
                v-if="permissions.saveShortcuts"
                icon
                class="mr-0"
                small
                title="Customise Shortcuts"
                @click="openShortcuts"
                ><v-icon>mdi-cog</v-icon></v-btn
              >
              <!-- <template v-slot:extension v-if="viewDefinition.showFilters">
                <v-toolbar-items style="width: 100%">
                  <v-container fluid>
                    <v-row v-if="defAttrVisible('attributeFiltersVisible')">
                      <v-col
                        v-for="(f, fi) in compareOptionsV.filter(
                          (x) => x.listValues && x.listValues.length
                        )"
                        :key="'f' + fi"
                        style="max-width: 20%; min-width: 15%"
                      >
                        <v-select
                          dense
                          hide-details
                          outlined
                          :items="f.listValues.map((x) => x.value)"
                          v-model="f.filterValues"
                          :label="f.name + ' Filter'"
                          @change="selectJDs()"
                          multiple
                          clearable
                          :title="f.name + ' Filter'"
                        >
                          <template v-slot:prepend>
                            <v-icon
                              v-if="f.tpa_id && f.listValues.length < 30"
                              small
                              @click="editAttributes(f)"
                              >mdi-format-list-numbered</v-icon
                            >
                          </template>
                          <template v-slot:label>
                            <span style="font-size: 13px">{{
                              f.name + " Filter"
                            }}</span>
                          </template>
                          <template v-slot:selection="{ item, index }">
                            <span
                              style="font-size: 13px"
                              v-if="item && index === 0"
                              class="blue--text text-caption"
                            >
                              ({{ f.filterValues.length }} item{{
                                f.filterValues.length > 1 ? "s" : ""
                              }})
                            </span>
                          </template>
                          <template v-slot:item="{ item }">
                            <span style="font-size: 13px">{{ item }}</span>
                          </template>
                        </v-select></v-col
                      ></v-row
                    >
                    <v-row v-if="defAttrVisible('nameChangeFiltersVisible')"
                      ><v-col
                        cols="2"
                        class="float-right"
                        v-if="selectedView.definition.nameChangeFiltersVisible"
                        ><v-select
                          v-model="selectedView.definition.nameChangeFilters"
                          multiple
                          dense
                          clearable
                          hide-details
                          outlined
                          label="Filter rows with name changes to:"
                          :items="getNameChangeTypes()"
                          @change="changeViewSetting('nameChangeFilters')"
                        >
                          <template v-slot:label>
                            <span style="font-size: 13px"
                              >Name change filter</span
                            >
                          </template>
                          <template v-slot:selection="{ item, index }">
                            <span
                              style="font-size: 13px"
                              v-if="item && index === 0"
                              class="blue--text text-caption"
                            >
                              ({{
                                selectedView.definition.nameChangeFilters.length
                              }}
                              item{{
                                selectedView.definition.nameChangeFilters
                                  .length > 1
                                  ? "s"
                                  : ""
                              }})
                            </span>
                          </template>
                          <template v-slot:item="{ item }">
                            <span style="font-size: 13px">{{ item.text }}</span>
                          </template></v-select
                        >
                      </v-col></v-row
                    ></v-container
                  ></v-toolbar-items
                >
              </template> -->
            </v-toolbar></v-col
          >
        </v-row>
        <div
          v-if="!haveData && currentViewHierarchy"
          class="d-flex justify-center mt-6"
        >
          <v-alert type="info" width="500">
            <div class="d-flex justify-space-between align-center">
              <div>
                No {{ utils.pluralise(currentViewHierarchy.label) }} exist
              </div>
              <v-btn
                color="white"
                small
                outlined
                @click="addHierarchy(currentViewHierarchy)"
                >Create {{ currentViewHierarchy.label }}</v-btn
              >
            </div>
          </v-alert>
        </div>
        <v-card v-if="haveData && selectedView.definition.viewType === 'list'">
          <v-card-text>
            <v-data-table
              :headers="lvColumnsFiltered"
              :items="groupedDocs"
              :sort-by.sync="listView.sortColumn"
              :sort-desc.sync="listView.sortDesc"
              :dense="listView.density == 'dense'"
              :class="['mt-2', listView.density]"
              :page.sync="listView.currentPage"
              hide-default-footer
              :items-per-page="listView.itemsPerPage"
              :fixed-header="!!listView.tableHeight"
              :height="listView.tableHeight"
            >
              <template v-slot:top>
                <v-row class="mb-2"
                  ><v-col>
                    <!-- <v-spacer></v-spacer -->
                    <v-chip
                      small
                      v-for="(f, fi) in filterList"
                      :key="'f' + fi"
                      close
                      @click:close="removeFilter(f)"
                    >
                      {{ f.title }}</v-chip
                    ></v-col
                  ><!--<v-col
                    cols="2"
                    class="float-right"
                    v-if="selectedView.definition.nameChangeFiltersVisible"
                    ><v-select
                      v-model="selectedView.definition.nameChangeFilters"
                      multiple
                      dense
                      clearable
                      hide-details
                      outlined
                      label="Filter rows with name changes to:"
                      :items="getNameChangeTypes()"
                      @change="changeViewSetting('nameChangeFilters')"
                    >
                      <template v-slot:label>
                        <span style="font-size: 13px">Name change filter</span>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <span
                          style="font-size: 13px"
                          v-if="item && index === 0"
                          class="blue--text text-caption"
                        >
                          ({{
                            selectedView.definition.nameChangeFilters.length
                          }}
                          item{{
                            selectedView.definition.nameChangeFilters.length > 1
                              ? "s"
                              : ""
                          }})
                        </span>
                      </template>
                      <template v-slot:item="{ item }">
                        <span style="font-size: 13px">{{ item.text }}</span>
                      </template></v-select
                    >
                  </v-col>
                  <v-col
                    class="text-right"
                    cols="1"
                    v-if="selectedView.definition.listviewColumnPickerVisible"
                  >
                    <v-menu offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          title="Hide/Show Columns"
                          v-on="on"
                          icon
                          class="btn-background ml-3"
                        >
                          <v-icon>mdi-format-columns</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense class="pa-3">
                        <v-list-item>
                          <v-list-item-title class="subtitle-1"
                            >Group By:</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item
                          v-for="field in listView.columns.filter(
                            (c) =>
                              !c.measure &&
                              (!c.ht_id ||
                                c.ht_id === selectedView.definition.ht_id)
                          )"
                          :key="field.value"
                        >
                          <v-switch
                            class="v-input--reverse v-input--expand"
                            style="width: 200px"
                            dense
                            v-model="
                              selectedView.definition.listViewColumnsEnabled
                            "
                            :label="field.text"
                            :value="field.value"
                            :disabled="field.value === 'job_count'"
                            @change="
                              changeViewSetting('listViewColumnsEnabled')
                            "
                          ></v-switch>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title class="subtitle-1"
                            >Measures:</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item
                          v-for="field in listView.columns.filter(
                            (c) =>
                              c.measure &&
                              (!c.ht_id ||
                                c.ht_id === selectedView.definition.ht_id)
                          )"
                          :key="field.value"
                        >
                          <v-switch
                            class="v-input--reverse v-input--expand"
                            style="width: 200px"
                            dense
                            v-model="
                              selectedView.definition.listViewColumnsEnabled
                            "
                            :label="field.text"
                            :title="field.measure.title"
                            :value="field.value"
                            :disabled="field.value === 'job_count'"
                            @change="
                              changeViewSetting('listViewColumnsEnabled')
                            "
                          ></v-switch>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>-->
                </v-row>
              </template>
              <!-- eslint-disable vue/valid-v-slot -->
              <template
                v-for="(h, hi) in lvColumnsFiltered"
                v-slot:[`header.${h.value}`]="{ header }"
                ><div
                  style="white-space: nowrap"
                  :key="h.value"
                  :title="header.measure ? header.measure.title : null"
                >
                  {{ header.text }}
                  <v-menu
                    v-if="header.filterSettings"
                    v-model="header.filterSettings.show"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-x
                    @input="openListviewFilter(header.filterSettings)"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="blue" small v-bind="attrs" v-on="on" icon>
                        <v-icon small>
                          {{
                            header.filterSettings.isActive
                              ? "mdi-filter-settings"
                              : "mdi-filter-outline"
                          }}</v-icon
                        >
                      </v-btn>
                    </template>

                    <v-card>
                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              >{{ header.text }} Filters</v-list-item-title
                            >
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn
                              small
                              @click="filterClear(header.filterSettings)"
                            >
                              Clear
                            </v-btn>
                          </v-list-item-action>
                          <v-list-item-action>
                            <v-btn
                              small
                              @click="header.filterSettings.show = false"
                            >
                              Close
                            </v-btn>
                          </v-list-item-action>
                          <v-list-item-action>
                            <v-btn
                              small
                              @click="filterHeading(header.filterSettings)"
                            >
                              Apply
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              ><v-text-field
                                placeholder="type to filter values"
                                hide-details
                                append-icon="mdi-check-all"
                                @click:append="
                                  filterSearchAll(header.filterSettings)
                                "
                                @input="filterSearch(header.filterSettings)"
                                dense
                                clearable
                                v-model="header.filterSettings.searchText"
                              ></v-text-field
                            ></v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action
                            v-if="header.filterSettings.pages > 1"
                          >
                            <v-pagination
                              v-model="header.filterSettings.page"
                              :length="header.filterSettings.pages"
                              :total-visible="7"
                            ></v-pagination>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      <v-divider></v-divider>
                      <v-list style="max-height: 100px; overflow: auto">
                        <v-list-item
                          dense
                          v-for="(v, vi) in header.filterSettings.values.filter(
                            (x) => !x.visible && x.selected
                          )"
                          :key="'head' + hi + 'vs' + vi"
                        >
                          <v-list-item-content>
                            <v-switch
                              v-model="v.selected"
                              color="blue"
                              :label="`${v.text} (${v.count})`"
                            ></v-switch>
                            <!-- @change="filterHeading(header.filterSettings)" -->
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-list
                        style="max-height: 400px; overflow: none"
                        @wheel.stop="
                          filterScroll($event, header.filterSettings)
                        "
                      >
                        <v-list-item
                          dense
                          v-for="(v, vi) in header.filterSettings.values.filter(
                            (x) =>
                              x.visible && x.page === header.filterSettings.page
                          )"
                          :key="'head' + hi + 'v' + vi"
                        >
                          <v-list-item-content>
                            <v-switch
                              v-model="v.selected"
                              color="blue"
                              :label="`${v.text} (${v.count})`"
                            ></v-switch>
                            <!-- @change="filterHeading(header.filterSettings)" -->
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                  <v-simple-checkbox
                    v-if="h.value === 'selected'"
                    :key="h.value"
                    v-model="h.selected"
                    @click="selectAllListViewJob(h)"
                  ></v-simple-checkbox>
                  <!-- :disabled="!h.selected && groupedDocs.length > 30" -->
                </div>
              </template>
              <!-- eslint-enable vue/valid-v-slot -->
              <template v-slot:item="{ item }">
                <tr>
                  <td v-for="col in lvColumnsFiltered" :key="col.value">
                    <div v-if="col.value === 'selected'">
                      <v-simple-checkbox
                        v-model="item.selected"
                        @click="selectListviewJob(item)"
                      ></v-simple-checkbox>
                    </div>
                    <MeasureCount
                      v-else-if="col.measure"
                      :item="item"
                      :selectable="col.value === 'job_count'"
                      :measure="col.measure"
                      :displayType="selectedView.definition.measureDisplayType"
                      @click="selectListviewJob(item, true)"
                      @showDocList="openDocListMenu"
                    ></MeasureCount>
                    <div v-else-if="col.value === 'doc_status_text'">
                      <DocStatus :doc_id="item.doc_id" sideAddendum></DocStatus>
                    </div>
                    <div
                      v-else-if="item[col.value + 'isNode']"
                      class="col_header"
                    >
                      <cHierarchyNodeDisplay
                        :node="item[col.value + 'isNode']"
                        :listviewColumn="col"
                        :listviewItem="item"
                        :displayNameHistory="
                          selectedView.definition.nameChangeFilters.length > 0
                        "
                        @action="doMenuAction($event)"
                      ></cHierarchyNodeDisplay>
                    </div>
                    <div
                      v-else
                      class="col_header"
                      @contextmenu="openContextMenu($event, item, col)"
                    >
                      {{ item[col.value] }}
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <div
              class="d-flex justify-space-between footer-actions primary-background"
            >
              <PageDescription
                :totalItems="groupedDocs.length"
                :pageSize="listView.itemsPerPage"
                :currentPage="listView.currentPage"
              />
              <Pagination
                :totalItems="groupedDocs.length"
                :pageSize="listView.itemsPerPage"
                :currentPage="listView.currentPage"
                @pageNavigation="listView.currentPage = $event"
              />
              <div>
                <v-btn
                  color="primary"
                  small
                  outlined
                  class="mx-2"
                  @click="exportLVToCSV"
                >
                  <span>Export</span>
                </v-btn>
                <v-menu offset-y v-if="listView.pageSize === 0" z-index="301">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="!$loginState.readOnly"
                      outlined
                      small
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ itemsPerPageText }}
                      <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list dense>
                    <v-list-item
                      v-for="item in itemsPerPageOptions"
                      :key="item.value"
                      @click="setLVPageSize(item.value)"
                    >
                      <v-list-item-title>
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card
          v-if="haveData && selectedView.definition.viewType === 'grouped'"
        >
          <v-card-text>
            <v-container>
              <v-row v-if="filterList.length">
                <v-col>
                  <v-chip
                    small
                    v-for="(f, fi) in filterList"
                    :key="'f' + fi"
                    close
                    @click:close="removeFilter(f)"
                    @click="showFilters"
                  >
                    {{ f.title }}</v-chip
                  ></v-col
                >
              </v-row>
              <v-row ref="columnHeadings" class="tableHeader">
                <v-col
                  v-for="(c, ci) in groupedViewLevelColumns[0]"
                  :key="'lc' + ci"
                  :cols="c.cols"
                  >{{ c.text }}</v-col
                >
              </v-row>
              <v-row v-for="(r1, r1i) in groupedViewDocs" :key="'r1_' + r1i">
                <v-col>
                  <v-row ref="rowHeading_1">
                    <v-col
                      v-for="(c, ci) in groupedViewLevelColumns[0]"
                      :key="'r1_' + r1i + 'c' + ci"
                      :cols="c.cols"
                    >
                      <div v-if="c.ht_id" @click="groupViewDrilldown(r1)">
                        <cHierarchyNodeDisplay
                          :node="r1.node"
                          :allowExport="true"
                          :allowExpand="true"
                          :hideDescriptor="true"
                          displayBorder
                          @action="doMenuAction($event)"
                        ></cHierarchyNodeDisplay>
                      </div>
                      <MeasureCount
                        v-else-if="c.measure"
                        :item="r1"
                        :selectable="c.value === 'job_count'"
                        :measure="c.measure"
                        :displayType="
                          selectedView.definition.measureDisplayType
                        "
                        :highlightSmall="true"
                        @click="selectGroupedJob(r1)"
                        @showDocList="openDocListMenu"
                      ></MeasureCount>
                      <div v-else-if="r1.node.descriptor" style="width: 100%">
                        <div
                          style="cursor: pointer; width: 90%; float: left"
                          @click.stop="editHierarchyDescriptor(r1.node)"
                        >
                          {{
                            c.isDescriptor
                              ? r1.node.descriptor.description
                              : r1[c.value]
                          }}
                          <v-icon class="text-right" small>mdi-pencil</v-icon
                          ><v-spacer />
                        </div>
                        <v-icon
                          v-if="r1.node.descriptor.hasHistory"
                          title="view history"
                          color="blue-grey-darken-3"
                          style="float: right; margin-right: 20px"
                          @click="getNodeHistory(r1.node)"
                          >mdi-history</v-icon
                        >
                      </div></v-col
                    >
                  </v-row>
                  <div
                    ref="rowHeading_1_detail_rows"
                    v-if="r1.show && r1.rows.length"
                  >
                    <v-row ref="detailColumnHeadings" class="flex-nowrap">
                      <v-col cols="3"></v-col>
                      <v-col
                        v-for="(c, ci) in groupedViewDetailColumns.filter(
                          (x) => !x.ht_id
                        )"
                        :key="'lcd' + ci"
                        :cols="c.cols"
                        class="tableHeader"
                        >{{ c.text }}</v-col
                      >
                    </v-row>
                    <v-row
                      v-for="(dr, dri) in r1.rows"
                      :key="'r1_' + r1i + 'dr_' + dri"
                      class="flex-nowrap"
                    >
                      <v-col></v-col>
                      <v-col
                        v-for="(dc, dci) in groupedViewDetailColumns.filter(
                          (x) => !x.ht_id
                        )"
                        :key="'r1_' + r1i + 'dr_' + dri + 'dc' + dci"
                      >
                        {{ dr[dc.value] }}</v-col
                      >
                    </v-row>
                  </div>
                  <div
                    ref="rowHeading_1_nodes"
                    v-else-if="r1.show && r1.nodes.length"
                  >
                    <v-row
                      ref="rowHeading_1_node"
                      v-for="(r2, r2i) in r1.nodes"
                      :key="'r1_' + r1i + 'r2_' + r2i"
                    >
                      <v-col>
                        <v-row>
                          <v-col
                            v-for="(dc, dci) in groupedViewLevelColumns[1]"
                            :key="'r1_' + r1i + 'r2_' + r2i + 'dc' + dci"
                            :cols="dc.cols"
                            :style="{ paddingLeft: dc.ht_id ? '40px' : '' }"
                          >
                            <div
                              v-if="dc.ht_id"
                              @click="groupViewDrilldown(r2)"
                            >
                              <cHierarchyNodeDisplay
                                :node="r2.node"
                                :allowExport="true"
                                :allowExpand="true"
                                :hideDescriptor="true"
                                displayBorder
                                @action="doMenuAction($event)"
                              ></cHierarchyNodeDisplay>
                            </div>
                            <MeasureCount
                              v-else-if="dc.measure"
                              :item="r2"
                              :selectable="dc.value === 'job_count'"
                              :measure="dc.measure"
                              :displayType="
                                selectedView.definition.measureDisplayType
                              "
                              :highlightSmall="true"
                              @click="selectGroupedJob(r2)"
                              @showDocList="openDocListMenu"
                            ></MeasureCount>
                            <div
                              v-else-if="r2.node.descriptor"
                              style="width: 100%"
                            >
                              <div
                                style="cursor: pointer; width: 90%; float: left"
                                @click.stop="editHierarchyDescriptor(r2.node)"
                              >
                                {{
                                  dc.isDescriptor
                                    ? r2.node.descriptor.description
                                    : r2[dc.value]
                                }}
                                <v-icon class="text-right" small
                                  >mdi-pencil</v-icon
                                >
                              </div>
                              <v-icon
                                v-if="r2.node.descriptor.hasHistory"
                                title="view history"
                                color="blue-grey-darken-3"
                                style="float: right; margin-right: 20px"
                                @click="getNodeHistory(r2.node)"
                                >mdi-history</v-icon
                              >
                            </div></v-col
                          >
                        </v-row>
                        <div
                          ref="rowHeading_2_detail_rows"
                          v-if="r2.show && r2.rows.length"
                        >
                          <v-row ref="detailColumnHeadings" class="flex-nowrap">
                            <v-col cols="3"></v-col>
                            <v-col
                              v-for="(c, ci) in groupedViewDetailColumns.filter(
                                (x) => !x.ht_id
                              )"
                              :key="'lcd' + ci"
                              :cols="c.cols"
                              class="tableHeader"
                              >{{ c.text }}</v-col
                            >
                          </v-row>
                          <v-row
                            v-for="(dr, dri) in r2.rows"
                            :key="'r1_' + r1i + 'r2_' + r2i + 'dr_' + dri"
                            class="flex-nowrap"
                          >
                            <v-col></v-col>
                            <v-col
                              v-for="(
                                dc, dci
                              ) in groupedViewDetailColumns.filter(
                                (x) => !x.ht_id
                              )"
                              :key="
                                'r1_' +
                                r1i +
                                'r2_' +
                                r2i +
                                'rd1_' +
                                dri +
                                'dc' +
                                dci
                              "
                            >
                              {{ dr[dc.value] }}</v-col
                            >
                          </v-row>
                        </div>
                        <div
                          ref="rowHeading_2_nodes"
                          v-else-if="r2.show && r2.nodes.length"
                        >
                          <v-row
                            ref="rowHeading_2_node"
                            v-for="(r3, r3i) in r2.nodes"
                            :key="'r1_' + r1i + 'r2_' + r2i + 'r3_' + r3i"
                          >
                            <v-col>
                              <v-row>
                                <v-col
                                  v-for="(
                                    dc, dci
                                  ) in groupedViewLevelColumns[2]"
                                  :key="
                                    'r1_' +
                                    r1i +
                                    'r2_' +
                                    r2i +
                                    'r3_' +
                                    r3i +
                                    'dc' +
                                    dci
                                  "
                                  :cols="dc.cols"
                                  :style="{
                                    paddingLeft: dc.ht_id ? '80px' : '',
                                  }"
                                >
                                  <div
                                    v-if="dc.ht_id"
                                    @click="groupViewDrilldown(r3)"
                                  >
                                    <cHierarchyNodeDisplay
                                      :node="r3.node"
                                      :allowExport="true"
                                      :allowExpand="true"
                                      :hideDescriptor="true"
                                      displayBorder
                                      @action="doMenuAction($event)"
                                    ></cHierarchyNodeDisplay>
                                  </div>
                                  <MeasureCount
                                    v-else-if="dc.measure"
                                    :item="r3"
                                    :selectable="dc.value === 'job_count'"
                                    :measure="dc.measure"
                                    :displayType="
                                      selectedView.definition.measureDisplayType
                                    "
                                    @click="selectGroupedJob(r3)"
                                    @showDocList="openDocListMenu"
                                  ></MeasureCount>
                                  <div
                                    v-else-if="r3.node.descriptor"
                                    style="width: 100%"
                                  >
                                    <div
                                      style="
                                        cursor: pointer;
                                        width: 90%;
                                        float: left;
                                      "
                                      @click.stop="
                                        editHierarchyDescriptor(r3.node)
                                      "
                                    >
                                      {{
                                        dc.isDescriptor
                                          ? r3.node.descriptor.description
                                          : r3[dc.value]
                                      }}
                                      <v-icon class="text-right" small
                                        >mdi-pencil</v-icon
                                      >
                                    </div>
                                    <v-icon
                                      v-if="r3.node.descriptor.hasHistory"
                                      title="view history"
                                      color="blue-grey-darken-3"
                                      style="float: right; margin-right: 20px"
                                      @click="getNodeHistory(r3.node)"
                                      >mdi-history</v-icon
                                    >
                                  </div></v-col
                                >
                              </v-row>
                              <div
                                ref="rowHeading_3_detail_rows"
                                v-if="r3.show && r3.rows.length"
                              >
                                <v-row
                                  ref="detailColumnHeadings"
                                  class="flex-nowrap"
                                >
                                  <v-col cols="3"></v-col>
                                  <v-col
                                    v-for="(
                                      c, ci
                                    ) in groupedViewDetailColumns.filter(
                                      (x) => !x.ht_id
                                    )"
                                    :key="'lcd' + ci"
                                    :cols="c.cols"
                                    class="tableHeader"
                                    >{{ c.text }}</v-col
                                  >
                                </v-row>
                                <v-row
                                  v-for="(dr, dri) in r3.rows"
                                  :key="
                                    'r1_' +
                                    r1i +
                                    'r2_' +
                                    r2i +
                                    'r3_' +
                                    r3i +
                                    'dr_' +
                                    dri
                                  "
                                  class="flex-nowrap"
                                >
                                  <v-col cols="3"></v-col>
                                  <v-col
                                    v-for="(
                                      dc, dci
                                    ) in groupedViewDetailColumns.filter(
                                      (x) => !x.ht_id
                                    )"
                                    :key="
                                      'r1_' +
                                      r1i +
                                      'r2_' +
                                      r2i +
                                      'r3_' +
                                      r3i +
                                      'rd1_' +
                                      dri +
                                      'dc' +
                                      dci
                                    "
                                    :cols="dc.cols ? dc.cols : ''"
                                  >
                                    <MeasureCount
                                      v-if="dc.measure"
                                      :item="dr"
                                      :selectable="dc.value === 'job_count'"
                                      :measure="dc.measure"
                                      :displayType="
                                        selectedView.definition
                                          .measureDisplayType
                                      "
                                      @click="selectGroupedJob(dr)"
                                      @showDocList="openDocListMenu"
                                    ></MeasureCount>
                                    <div v-else>
                                      {{ dr[dc.value] }}
                                    </div></v-col
                                  >
                                </v-row>
                              </div>
                              <div
                                ref="rowHeading_3_nodes"
                                v-else-if="r3.show && r3.nodes.length"
                              >
                                <v-row
                                  ref="rowHeading_2_node"
                                  v-for="(r4, r4i) in r3.nodes"
                                  :key="
                                    'r1_' +
                                    r1i +
                                    'r2_' +
                                    r2i +
                                    'r3_' +
                                    r3i +
                                    'r4_' +
                                    r4i
                                  "
                                >
                                  <v-col>
                                    <v-row>
                                      <v-col
                                        v-for="(
                                          dc, dci
                                        ) in groupedViewLevelColumns[3]"
                                        :key="
                                          'r1_' +
                                          r1i +
                                          'r2_' +
                                          r2i +
                                          'r3_' +
                                          r3i +
                                          'r4_' +
                                          r4i +
                                          'dc' +
                                          dci
                                        "
                                        :cols="dc.cols"
                                        :style="{
                                          paddingLeft: dc.ht_id ? '120px' : '',
                                        }"
                                      >
                                        <div
                                          v-if="dc.ht_id"
                                          @click="groupViewDrilldown(r4)"
                                        >
                                          <cHierarchyNodeDisplay
                                            :node="r4.node"
                                            :allowExport="true"
                                            :allowExpand="true"
                                            :hideDescriptor="true"
                                            displayBorder
                                            @action="doMenuAction($event)"
                                          ></cHierarchyNodeDisplay>
                                        </div>
                                        <MeasureCount
                                          v-else-if="dc.measure"
                                          :item="r4"
                                          :selectable="dc.value === 'job_count'"
                                          :measure="dc.measure"
                                          :displayType="
                                            selectedView.definition
                                              .measureDisplayType
                                          "
                                          @click="selectGroupedJob(r4)"
                                          @showDocList="openDocListMenu"
                                        ></MeasureCount>
                                        <div
                                          v-else-if="r4.node.descriptor"
                                          style="width: 100%"
                                        >
                                          <div
                                            style="
                                              cursor: pointer;
                                              width: 90%;
                                              float: left;
                                            "
                                            @click.stop="
                                              editHierarchyDescriptor(r4.node)
                                            "
                                          >
                                            {{
                                              dc.isDescriptor
                                                ? r4.node.descriptor.description
                                                : r4[dc.value]
                                            }}
                                            <v-icon class="text-right" small
                                              >mdi-pencil</v-icon
                                            >
                                          </div>
                                          <v-icon
                                            v-if="r4.node.descriptor.hasHistory"
                                            title="view history"
                                            color="blue-grey-darken-3"
                                            style="
                                              float: right;
                                              margin-right: 20px;
                                            "
                                            @click="getNodeHistory(r4.node)"
                                            >mdi-history</v-icon
                                          >
                                        </div></v-col
                                      >
                                    </v-row>
                                    <div
                                      ref="rowHeading_4_detail_rows"
                                      v-if="r4.show && r4.rows.length"
                                    >
                                      <v-row
                                        ref="detailColumnHeadings"
                                        class="flex-nowrap"
                                      >
                                        <v-col cols="3"></v-col>
                                        <v-col
                                          v-for="(
                                            c, ci
                                          ) in groupedViewDetailColumns.filter(
                                            (x) => !x.ht_id
                                          )"
                                          :key="'lcd' + ci"
                                          :cols="c.cols"
                                          class="tableHeader"
                                          >{{ c.text }}</v-col
                                        >
                                      </v-row>
                                      <v-row
                                        v-for="(dr, dri) in r4.rows"
                                        :key="
                                          'r1_' +
                                          r1i +
                                          'r2_' +
                                          r2i +
                                          'r3_' +
                                          r3i +
                                          'r4_' +
                                          r4i +
                                          'dr_' +
                                          dri
                                        "
                                        class="flex-nowrap"
                                      >
                                        <v-col cols="3"></v-col>
                                        <v-col
                                          v-for="(
                                            dc, dci
                                          ) in groupedViewDetailColumns.filter(
                                            (x) => !x.ht_id
                                          )"
                                          :key="
                                            'r1_' +
                                            r1i +
                                            'r2_' +
                                            r2i +
                                            'r3_' +
                                            r3i +
                                            'r4_' +
                                            r4i +
                                            'rd1_' +
                                            dri +
                                            'dc' +
                                            dci
                                          "
                                          :cols="dc.cols ? dc.cols : ''"
                                        >
                                          <MeasureCount
                                            v-if="dc.measure"
                                            :item="dr"
                                            :selectable="
                                              dc.value === 'job_count'
                                            "
                                            :measure="dc.measure"
                                            :displayType="
                                              selectedView.definition
                                                .measureDisplayType
                                            "
                                            @click="selectGroupedJob(dr)"
                                            @showDocList="openDocListMenu"
                                          ></MeasureCount>
                                          <div v-else>
                                            {{ dr[dc.value] }}
                                          </div></v-col
                                        >
                                      </v-row>
                                    </div>
                                    <div
                                      ref="rowHeading_4_nodes"
                                      v-else-if="r4.show && r4.nodes.length"
                                    ></div>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
        <v-card
          v-if="haveData && selectedView.definition.viewType === 'catalogue'"
        >
          <v-card-text>
            <v-row>
              <v-col>
                <v-row v-if="filterList.length || dragTarget.show">
                  <v-col v-if="filterList.length">
                    <v-chip
                      small
                      v-for="(f, fi) in filterList"
                      :key="'f' + fi"
                      close
                      @click:close="removeFilter(f)"
                    >
                      {{ f.title }}</v-chip
                    ></v-col
                  >
                  <!--<v-col
                    ><v-breadcrumbs :items="drilldownFilters" divider="-">
                      <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                          @click="removeDrilldownFilter(item)"
                        >
                          {{ item.value.toUpperCase() }}
                        </v-breadcrumbs-item>
                      </template></v-breadcrumbs
                    ></v-col
                  >-->
                  <v-col v-if="dragTarget.show && dragTarget.buckets.length">
                    <v-row>
                      <v-col
                        v-for="(b, bi) in dragTarget.buckets"
                        :key="'dtb' + bi"
                        :offset="dragTarget.buckets.length === 1 ? 6 : null"
                        ><v-card
                          class="drag-target"
                          v-on:drop="addAddition($event, b)"
                          v-on:dragover="allowDrop($event, b)"
                          ><v-toolbar
                            color="teal lighten-5"
                            height="32"
                            elevation="1"
                          >
                            <v-toolbar-title
                              ><v-text-field
                                dense
                                hide-details
                                v-model="b.title"
                                :full-width="false"
                                v-if="b.edit"
                                @blur="b.edit = false"
                                @keypress.enter="b.edit = false"
                                @keypress.escape="b.edit = false"
                              ></v-text-field
                              ><span
                                v-else
                                @click="b.edit = true"
                                style="font-size: 13px"
                                >{{ b.title }}</span
                              ></v-toolbar-title
                            ><v-divider vertical class="mx-1"></v-divider>
                            <v-btn
                              small
                              icon
                              @click="removeBucket(b)"
                              title="Delete workspace"
                              ><v-icon>mdi-delete-forever</v-icon></v-btn
                            ><v-divider vertical class="ml-1"></v-divider>
                            <v-spacer></v-spacer
                            ><span style="font-size: 13px">{{
                              !b.additions.length
                                ? "empty"
                                : b.additions.length +
                                  " " +
                                  b.subject +
                                  (b.additions.length > 1 ? "s" : "")
                            }}</span
                            ><v-divider vertical class="mx-1"></v-divider>
                            <v-btn
                              v-if="b.additions.length"
                              small
                              icon
                              @click="removeAdditionAll(b)"
                              title="Remove all items"
                              ><v-icon>mdi-playlist-remove</v-icon></v-btn
                            ><v-divider vertical class="ml-1"></v-divider>
                            <v-spacer></v-spacer
                            ><v-divider vertical class="mr-2"></v-divider>
                            <v-btn
                              small
                              icon
                              @click="compareBucketDocs(b)"
                              :title="
                                'Compare Documents for selected ' +
                                b.subject +
                                's'
                              "
                              ><v-icon>mdi-content-duplicate</v-icon></v-btn
                            ><v-divider v-if="permissions.nodeMoveMerge" vertical class="mx-2"></v-divider>
                            <v-btn v-if="permissions.nodeMoveMerge"
                              small
                              icon
                              @click="moveBucket(b)"
                              :title="'Bulk Move selected ' + b.subject + 's'"
                              ><v-icon>mdi-file-move</v-icon></v-btn
                            ><v-divider vertical class="mx-2"></v-divider>
                            <v-btn
                              small
                              icon
                              @click="b.show = !b.show"
                              :title="
                                b.show ? 'Hide Contents' : 'Show Contents'
                              "
                              ><v-icon>{{
                                b.show ? "mdi-chevron-up" : "mdi-chevron-down"
                              }}</v-icon></v-btn
                            >
                          </v-toolbar>
                          <v-card-text v-if="b.show" class="pa-2">
                            <v-chip
                              x-small
                              class="mr-2"
                              label
                              close
                              @click="viewNodeDoc($event, a)"
                              @click:close="removeAddition(b, a.node)"
                              v-for="(a, ai) in b.additions"
                              :key="'dtb' + bi + 'a' + ai"
                              >{{ a.title }}</v-chip
                            >
                          </v-card-text></v-card
                        >
                      </v-col></v-row
                    ></v-col
                  >
                </v-row>
                <v-row v-if="layout && layout.headerRow" class="flex-nowrap">
                  <v-col
                    v-if="layout.leftPanel.cols"
                    :cols="layout.leftPanel.cols"
                    class="axis-background"
                    ><v-row
                      ><template
                        v-if="
                          layout.leftPanel.colsHierarchy +
                            layout.leftPanel.colsTitle >
                          0
                        "
                        ><v-col
                          :cols="
                            layout.leftPanel.colsHierarchy +
                            layout.leftPanel.colsTitle
                          "
                          class="leftpanel-column-container"
                          ><v-row dense>
                            <!--:style="vc.style"-->
                            <v-col
                              @click="showDesigner"
                              class="column-heading"
                              style="cursor: pointer"
                              :title="vc.name + ' (click to change)'"
                              v-for="(vc, vci) in verCompareOptions"
                              :key="'vc' + vci"
                              :cols="
                                layout.detailRow.rowHeading.titleWidths[vci]
                                  .cols
                              "
                              >{{ vc.name }}</v-col
                            ></v-row
                          ></v-col
                        ></template
                      ><v-col
                        v-if="layout.leftPanel.colsDescriptor"
                        :cols="layout.leftPanel.colsDescriptor"
                        class="leftpanel-column-container"
                        >Descriptor</v-col
                      ><v-col
                        v-if="layout.leftPanel.colsTotal"
                        :cols="layout.leftPanel.colsTotal"
                        class="leftpanel-column-container"
                        ><!--Totals--><v-row dense justify="space-between"
                          ><v-col
                            v-if="display.showLeafCount"
                            class="measure-column"
                            :cols="
                              layout.detailRow.rowHeading.measureWidths[0].cols
                            "
                            ><div
                              class="leaf_node_count"
                              :title="display.total_col.leafCountTitle"
                            >
                              {{ display.total_col.leafCountLabel }}
                            </div></v-col
                          ><v-col
                            v-for="(m, mi) in visibleMeasures"
                            :key="'mh' + mi"
                            :cols="
                              layout.detailRow.rowHeading.measureWidths[
                                mi + (display.showLeafCount ? 1 : 0)
                              ].cols
                            "
                            class="measure-column"
                            ><MeasureCount
                              :measure="m"
                              isTitle
                              :longTitle="
                                selectedView.definition.longMeasureTitle
                              "
                            ></MeasureCount
                          ></v-col> </v-row></v-col></v-row
                    ><v-row
                      ><v-col
                        v-if="
                          layout.leftPanel.colsHierarchy +
                            layout.leftPanel.colsTitle >
                          0
                        "
                        :cols="
                          layout.leftPanel.colsHierarchy +
                          layout.leftPanel.colsTitle
                        "
                        class="leftpanel-column-container"
                      ></v-col
                      ><v-col
                        v-if="layout.leftPanel.colsDescriptor"
                        :cols="layout.leftPanel.colsDescriptor"
                        class="leftpanel-column-container"
                      ></v-col
                      ><v-col
                        v-if="layout.leftPanel.colsTotal"
                        :cols="layout.leftPanel.colsTotal"
                        class="leftpanel-column-container"
                        ><!--<v-row dense justify="space-between"
                          ><v-col
                            v-if="display.showLeafCount"
                            class="measure-column"
                            :cols="
                              layout.detailRow.rowHeading.measureWidths[0].cols
                            "
                            ><div
                              class="leaf_node_count"
                              :title="display.total_col.leafCountTitle"
                            >
                              {{ display.total_col.leafCountLabel }}
                            </div></v-col
                          ><v-col
                            v-for="(m, mi) in visibleMeasures"
                            :key="'mh' + mi"
                            :cols="
                              layout.detailRow.rowHeading.measureWidths[
                                mi + (display.showLeafCount ? 1 : 0)
                              ].cols
                            "
                            class="measure-column"
                            ><MeasureCount
                              :measure="m"
                              isTitle
                              :longTitle="
                                selectedView.definition.longMeasureTitle
                              "
                            ></MeasureCount
                          ></v-col> </v-row>--></v-col
                      ></v-row
                    ></v-col
                  >
                  <v-col
                    v-if="layout.rightPanel.cols"
                    :cols="layout.rightPanel.cols"
                    class="axis-background"
                    ><v-row
                      ><v-col
                        ><v-row dense class="column-heading"
                          ><v-col
                            ><span
                              @click="showDesigner"
                              style="cursor: pointer"
                              title="click to change columns"
                              >{{
                                horCompareOptions
                                  .map(
                                    (hc) =>
                                      getCompareOptionDef(hc, "compareOptionsH")
                                        .name
                                  )
                                  .join(" > ")
                              }}</span
                            >
                          </v-col></v-row
                        >
                      </v-col>
                      <v-col
                        v-if="dragTarget.show"
                        cols="1"
                        v-on:drop="addBucketPlus($event)"
                        v-on:dragover="allowDrop"
                      >
                        <v-icon
                          @click="addBucket"
                          title="Pending Items - click to add a Workspace, or drag and drop item"
                          class="drag-target float-right"
                          >mdi-cart-variant</v-icon
                        ></v-col
                      ></v-row
                    ><v-row
                      v-for="(hr, hri) in layout.hierarchyHeaderRows"
                      :key="'hr' + hri"
                      style="overflow: hidden; margin-top: 0"
                      :ref="hr.ref"
                      class="flex-nowrap"
                      ><v-col
                        v-for="(c, ci) in hr.columns"
                        :key="'hr' + hri + 'c' + ci"
                        :cols="c.cols"
                        :class="c.class"
                        :style="{
                          minWidth: c.cols * 150 + 'px',
                          paddingBottom: '0px',
                        }"
                      >
                        <div style="position: relative">
                          <div
                            v-if="hri > 0"
                            style="
                              margin-left: 50%;
                              border-left: 1px solid grey;
                              height: 10px;
                              width: 1px;
                              position: relative;
                              top: 0;
                            "
                          ></div>
                          <cHierarchyNodeDisplay
                            v-if="c.node"
                            :node="c.node"
                            @action="doMenuAction($event)"
                          ></cHierarchyNodeDisplay>
                          <div
                            style="
                              margin-left: 50%;
                              border-left: 1px solid grey;
                              height: 10px;
                              width: 1px;
                            "
                          ></div>
                          <div
                            v-if="c.childsets && c.childsets.length > 1"
                            :style="{
                              marginLeft: c.lineMarginLeft,
                              borderTop: '1px solid grey',
                              height: '1px',
                              width: c.lineWidth,
                            }"
                          ></div></div></v-col
                    ></v-row>
                    <v-row
                      v-for="(hr, hri) in layout.groupHeaderRow.hRows"
                      :key="'ghrh' + hri"
                      :ref="hr.ref"
                      style="overflow: hidden"
                      class="flex-nowrap"
                      dense
                      ><v-col :style="{ paddingLeft: 20 * hri + 'px' }">
                        <div class="rowHeader">
                          <v-icon
                            small
                            dense
                            @click="
                              removeGroupHeader(
                                hr,
                                'horCompareOptions',
                                'compareOptionsH',
                                horCompareOptions[0],
                                0
                              )
                            "
                            >mdi-close-circle</v-icon
                          >{{ hr.value }}
                        </div></v-col
                      ></v-row
                    >
                    <v-row
                      :style="{
                        overflow: 'hidden',
                        marginTop:
                          layout.hierarchyHeaderRows.length ||
                          layout.groupHeaderRow.hRows.length
                            ? '0'
                            : '',
                      }"
                      :ref="layout.headerRow.ref"
                      class="flex-nowrap"
                      ><v-col
                        v-for="(c, ci) in layout.headerRow.columns"
                        :key="'c' + ci"
                        :cols="c.cols"
                        :class="c.class"
                        :style="c.colCSS"
                        ><v-row
                          dense
                          v-for="(hc, hci) in c.headerColumns"
                          :key="'c' + ci + 'hc' + hci"
                          ><v-col>
                            <HierarchyViewColumnHeading
                              :item="hc"
                              axis="horCompareOptions"
                              :indentNode="
                                layout.hierarchyHeaderRows.length > 0
                              "
                              :catalogueDocType="catalogueDocType"
                              @openDocumentID="openDocumentID"
                              @openDocActionsMenuContext="
                                openDocActionsMenuContext
                              "
                              @showMatches="showMatches"
                              @selectJob="selectJob"
                              @doMenuAction="doMenuAction"
                              @openDocument="openDocument"
                              @copyDocument="$emit('copyDocument', $event)"
                            ></HierarchyViewColumnHeading> </v-col
                        ></v-row>
                        <div
                          v-if="c.showLine"
                          class="gridline"
                        ></div> </v-col></v-row
                  ></v-col>
                </v-row>
                <v-row
                  v-if="layout && layout.groupHeaderRow.vRows.length"
                  ref="groupHeaderRow"
                  style="border-bottom: 1px solid gray"
                >
                  <v-col
                    v-if="layout.leftPanel.cols"
                    :cols="layout.leftPanel.cols"
                    class="axis-background"
                  >
                    <v-row
                      v-for="(r, ri) in layout.groupHeaderRow.vRows"
                      :key="'gr' + ri"
                    >
                      <v-col
                        v-for="(vc, vci) in verCompareOptions.filter(
                          (x) => x.ht_id
                        )"
                        :key="'vc' + vci"
                        :cols="layout.leftPanel.colsTitle"
                        class="leftpanel-column-container"
                        :style="{ paddingLeft: 20 * ri + 'px' }"
                      >
                        <div
                          class="rowHeader"
                          :title="r.name + ' - ' + r.value"
                        >
                          <v-icon
                            small
                            dense
                            @click="
                              removeGroupHeader(
                                r,
                                'verCompareOptions',
                                'compareOptionsV',
                                vc,
                                vci
                              )
                            "
                            >mdi-close-circle</v-icon
                          >
                          {{ r.value }}
                        </div>
                      </v-col>
                      <v-col
                        v-if="layout.leftPanel.colsDescriptor"
                        :cols="layout.leftPanel.colsDescriptor"
                        class="leftpanel-column-container"
                      >
                        <div class="rowHeader">{{ r.column }}</div>
                      </v-col>
                      <v-col
                        v-if="layout.leftPanel.colsTotal"
                        :cols="layout.leftPanel.colsTotal"
                        class="leftpanel-column-container"
                      >
                        <v-row justify="space-between" dense
                          ><v-col
                            v-if="display.showLeafCount"
                            class="measure-column"
                            :cols="
                              layout.detailRow.rowHeading.measureWidths[0].cols
                            "
                            ><div class="leaf_node_count">
                              {{ r.leaf_node_count }}
                            </div></v-col
                          ><v-col
                            v-for="(m, mi) in visibleMeasures"
                            :key="'mh' + mi"
                            :cols="
                              layout.detailRow.rowHeading.measureWidths[
                                mi + (display.showLeafCount ? 1 : 0)
                              ].cols
                            "
                            class="measure-column"
                            ><MeasureCount
                              :item="r"
                              :measure="m"
                              :displayType="
                                selectedView.definition.measureDisplayType
                              "
                              :selectable="m.type === 'job_count'"
                              @click="selectJob(r)"
                              @showDocList="openDocListMenu"
                            ></MeasureCount
                          ></v-col> </v-row
                      ></v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    v-if="layout.rightPanel.cols"
                    :cols="layout.rightPanel.cols"
                  >
                    <v-row
                      v-for="(r, ri) in layout.groupHeaderRow.vRows"
                      :key="'grd' + ri"
                      class="flex-nowrap"
                      :ref="layout.groupHeaderRow.ref"
                      style="overflow: hidden"
                    >
                      <v-col
                        v-for="(c, ci) in r.columns"
                        :key="'grd' + ri + 'c' + ci"
                        :cols="c.cols"
                        :style="{ minWidth: 150 * c.cols + 'px' }"
                        ><v-row dense
                          ><v-col
                            v-for="(sc, sci) in c.subColumns"
                            :key="'grd' + ri + 'c' + ci + 'sc' + sci"
                          >
                            <HierarchyViewColumnDetail
                              :item="sc"
                              :detailDef="layout.detailRow.detail"
                              :visibleMeasures="visibleMeasures"
                              :measureDisplayType="
                                selectedView.definition.measureDisplayType
                              "
                              :showDetails="detCompareOptions.length > 0"
                              :catalogueDocType="catalogueDocType"
                              :vScroll="vScroll"
                              @selectJob="selectJob"
                              @openDocListMenu="openDocListMenu"
                              @openDocActionsMenuContext="
                                openDocActionsMenuContext
                              "
                              @openDocument="openDocument"
                              @copyDocument="$emit('copyDocument', $event)"
                              @showMatches="showMatches"
                              @dragStart="dragStart"
                              @doMenuAction="doMenuAction"
                              @viewNodeDoc="viewNodeDoc"
                              @removeAdditionNode="removeAdditionNode"
                            ></HierarchyViewColumnDetail> </v-col></v-row
                      ></v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row
                  v-if="layout && layout.detailRow"
                  style="height: 500px"
                  class="flex-nowrap"
                  @wheel.stop="wheelVScroll"
                  ref="detailRow"
                >
                  <v-col
                    v-if="layout.leftPanel.cols"
                    :cols="layout.leftPanel.cols"
                    class="axis-background"
                  >
                    <v-row>
                      <v-col
                        v-if="layout.detailRow.hierarchyHeadings"
                        :cols="layout.leftPanel.colsHierarchy"
                      >
                        <v-row
                          v-for="(l, li) in layout.detailRow.hierarchyHeadings
                            .pageLevels"
                          :key="'l' + li"
                          class="flex-nowrap"
                        >
                          <v-col
                            :cols="l.cols"
                            class="d-flex"
                            :style="{
                              height: l.height,
                            }"
                          >
                            <cHierarchyNodeDisplay
                              v-if="l.node"
                              :node="l.node"
                              displayBorder
                              @action="doMenuAction($event)"
                            ></cHierarchyNodeDisplay>
                            <div
                              v-if="l.levels && l.levels.length"
                              style="width: 1px; position: relative; top: 0"
                            >
                              <svg width="12" height="3">
                                <line
                                  x1="0"
                                  y1="0"
                                  x2="12"
                                  y2="0"
                                  style="stroke: black; stroke-width: 2"
                                />
                              </svg>
                            </div>
                          </v-col>
                          <v-col v-if="l.levels.length" :cols="l.childCols">
                            <v-row
                              v-for="(l1, l1i) in l.levels"
                              :key="'l' + li + 'l1' + l1i"
                              class="flex-nowrap"
                            >
                              <v-col
                                :cols="l1.cols"
                                class="d-flex"
                                :style="{
                                  height: l1.height,
                                }"
                              >
                                <div
                                  style="
                                    width: 2px;
                                    height: calc(100% + 24px);
                                    position: relative;
                                    left: -24px;
                                    top: -12px;
                                  "
                                >
                                  <svg width="24" height="100%">
                                    <line
                                      :x1="l1.isFirst ? 0 : 12"
                                      y1="24"
                                      x2="24"
                                      y2="24"
                                      style="stroke: black; stroke-width: 2"
                                    />
                                    <line
                                      v-if="l1.vLineY2"
                                      x1="12"
                                      :y1="l1.vLineY1"
                                      x2="12"
                                      :y2="l1.vLineY2"
                                      style="stroke: black; stroke-width: 2"
                                    />
                                  </svg>
                                </div>
                                <cHierarchyNodeDisplay
                                  v-if="l1.node"
                                  :node="l1.node"
                                  displayBorder
                                  @action="doMenuAction($event)"
                                ></cHierarchyNodeDisplay
                              ></v-col>
                              <v-col
                                v-if="l1.levels.length"
                                :cols="l1.childCols"
                              >
                                <v-row
                                  v-for="(l2, l2i) in l1.levels"
                                  :key="'l' + li + 'l1' + l1i + 'l2' + l2i"
                                  :class="l2.class"
                                >
                                  <v-col
                                    :cols="l2.cols"
                                    class="d-flex"
                                    :style="{
                                      height: l2.height,
                                    }"
                                  >
                                    <div
                                      style="
                                        width: 2px;
                                        height: calc(100% + 24px);
                                        position: relative;
                                        left: -24px;
                                        top: -12px;
                                      "
                                    >
                                      <svg width="24" height="100%">
                                        <line
                                          :x1="l2.isFirst ? 0 : 12"
                                          y1="24"
                                          x2="24"
                                          y2="24"
                                          style="stroke: black; stroke-width: 2"
                                        />
                                        <line
                                          v-if="l2.vLineY2"
                                          x1="12"
                                          :y1="l2.vLineY1"
                                          x2="12"
                                          :y2="l2.vLineY2"
                                          style="stroke: black; stroke-width: 2"
                                        />
                                      </svg>
                                    </div>
                                    <cHierarchyNodeDisplay
                                      v-if="l2.node"
                                      :node="l2.node"
                                      displayBorder
                                      @action="doMenuAction($event)"
                                    ></cHierarchyNodeDisplay
                                  ></v-col>
                                  <v-col
                                    v-if="l2.levels.length"
                                    :cols="l2.childCols"
                                  >
                                    <v-row
                                      v-for="(l3, l3i) in l2.levels"
                                      :key="
                                        'l' +
                                        li +
                                        'l1' +
                                        l1i +
                                        'l2' +
                                        l2i +
                                        'l3' +
                                        l3i
                                      "
                                      class="flex-nowrap"
                                    >
                                      <v-col
                                        :cols="l3.cols"
                                        class="d-flex"
                                        :style="{
                                          height: l3.height,
                                        }"
                                      >
                                        <div
                                          style="
                                            width: 2px;
                                            height: calc(100% + 24px);
                                            position: relative;
                                            left: -24px;
                                            top: -12px;
                                          "
                                        >
                                          <svg width="24" height="100%">
                                            <line
                                              :x1="l3.isFirst ? 0 : 12"
                                              y1="24"
                                              x2="24"
                                              y2="24"
                                              style="
                                                stroke: black;
                                                stroke-width: 2;
                                              "
                                            />
                                            <line
                                              v-if="l3.vLineY2"
                                              x1="12"
                                              :y1="l3.vLineY1"
                                              x2="12"
                                              :y2="l3.vLineY2"
                                              style="
                                                stroke: black;
                                                stroke-width: 2;
                                              "
                                            />
                                          </svg>
                                        </div>
                                        <cHierarchyNodeDisplay
                                          v-if="l3.node"
                                          :node="l3.node"
                                          displayBorder
                                          @action="doMenuAction($event)"
                                        ></cHierarchyNodeDisplay
                                      ></v-col>
                                      <v-col :cols="l3.childCols"></v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col
                        ><v-row
                          v-for="(h, hi) in layout.detailRow.rowHeadings.filter(
                            (x) =>
                              x.dIndexStart <= vScroll.dEnd &&
                              x.dIndexEnd >= vScroll.dStart
                          )"
                          :key="'h' + hi"
                        >
                          <v-col
                            v-if="
                              !layout.detailRow.hierarchyHeadings &&
                              layout.leftPanel.colsTitle
                            "
                            :class="h.class"
                            class="leftpanel-column-container"
                            :cols="layout.leftPanel.colsTitle"
                            :style="{
                              paddingLeft:
                                20 * layout.groupHeaderRow.vRows.length + 'px',
                            }"
                            ><v-row dense
                              ><v-col
                                v-for="(hc, hci) in h.headerColumns"
                                :key="'h' + hi + 'hc' + hci"
                                :cols="
                                  layout.detailRow.rowHeading.titleWidths[hci]
                                    .cols
                                "
                                class="standard-column"
                                ><template v-if="hc.visible">
                                  <HierarchyViewColumnHeading
                                    :item="hc"
                                    :indentNode="false"
                                    axis="verCompareOptions"
                                    :catalogueDocType="catalogueDocType"
                                    @openDocumentID="openDocumentID"
                                    @openDocActionsMenuContext="
                                      openDocActionsMenuContext
                                    "
                                    @showMatches="showMatches"
                                    @selectJob="selectJob"
                                    @doMenuAction="doMenuAction"
                                    @openDocument="openDocument"
                                    @copyDocument="
                                      $emit('copyDocument', $event)
                                    "
                                  ></HierarchyViewColumnHeading>
                                </template> </v-col
                            ></v-row>
                            <v-row
                              dense
                              v-for="(g, gi) in h.detailCountHeader.filter(
                                (x) =>
                                  x.dIndex >= vScroll.dStart &&
                                  x.dIndex <= vScroll.dEnd &&
                                  x.dIndex > vScroll.dStart
                              )"
                              :key="'h' + hi + 'g' + gi"
                            >
                              <v-col cols="12" class="standard-column">
                                <div class="label_cell">
                                  {{ " " }}
                                  <!-- {{ g.dIndex + ' - ' + h.dIndexStart + ' ' + vScroll.dStart }} -->
                                </div></v-col
                              >
                            </v-row>
                          </v-col>
                          <v-col
                            v-if="layout.leftPanel.colsDescriptor"
                            :cols="layout.leftPanel.colsDescriptorDetail"
                            class="leftpanel-column-container"
                          >
                            <div
                              :class="setDescriptorHeight(h)"
                              @click.stop="editHierarchyDescriptor(h.node)"
                              :title="
                                h.node.descriptor
                                  ? h.node.descriptor.description
                                  : ''
                              "
                            >
                              {{
                                h.node.descriptor
                                  ? h.node.descriptor.description
                                  : ""
                              }}
                            </div></v-col
                          >
                          <v-col
                            v-if="layout.leftPanel.colsTotalDetail"
                            :cols="layout.leftPanel.colsTotalDetail"
                            class="leftpanel-column-container"
                          >
                            <v-row
                              dense
                              justify="space-between"
                              v-if="
                                h.dIndexStart <= vScroll.dEnd &&
                                h.dIndexStart >= vScroll.dStart
                              "
                            >
                              <v-col
                                v-if="display.showLeafCount"
                                :cols="
                                  layout.detailRow.rowHeading.measureWidths[0]
                                    .cols
                                "
                                class="measure-column"
                                ><div class="leaf_node_count">
                                  {{ h.leaf_node_count }}
                                </div></v-col
                              >
                              <v-col
                                v-for="(m, mi) in visibleMeasures"
                                :key="'h' + hi + 'm' + mi"
                                :cols="
                                  layout.detailRow.rowHeading.measureWidths[
                                    mi + (display.showLeafCount ? 1 : 0)
                                  ].cols
                                "
                                class="measure-column"
                              >
                                <MeasureCount
                                  :item="h"
                                  :measure="m"
                                  :displayType="
                                    selectedView.definition.measureDisplayType
                                  "
                                  :selectable="m.type === 'job_count'"
                                  @click="selectJob(h)"
                                  @showDocList="openDocListMenu"
                                ></MeasureCount
                              ></v-col>
                            </v-row> </v-col
                        ></v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    v-if="layout.rightPanel.cols"
                    :cols="layout.rightPanel.cols"
                    ><v-row style="overflow: hidden" ref="detailH"
                      ><v-col>
                        <v-row
                          class="flex-nowrap"
                          style="min-height: 50px"
                          v-for="(d, di) in layout.detailRow.rowDetails.filter(
                            (x) =>
                              x.dIndexStart <= vScroll.dEnd &&
                              x.dIndexEnd >= vScroll.dStart
                          )"
                          :key="'d' + di"
                        >
                          <v-col
                            v-for="(c, ci) in d.columns"
                            :key="'d' + di + 'c' + ci"
                            :cols="c.cols"
                            :style="c.colCSS"
                            ><v-row dense
                              ><v-col
                                v-for="(sc, sci) in c.subColumns"
                                :key="'d' + di + 'c' + ci + 'sc' + sci"
                              >
                                <HierarchyViewColumnDetail
                                  :item="sc"
                                  :detailDef="layout.detailRow.detail"
                                  :visibleMeasures="visibleMeasures"
                                  :measureDisplayType="
                                    selectedView.definition.measureDisplayType
                                  "
                                  :showDetails="detCompareOptions.length > 0"
                                  :catalogueDocType="catalogueDocType"
                                  :vScroll="vScroll"
                                  @selectJob="selectJob"
                                  @openDocListMenu="openDocListMenu"
                                  @openDocActionsMenuContext="
                                    openDocActionsMenuContext
                                  "
                                  @openDocument="openDocument"
                                  @copyDocument="$emit('copyDocument', $event)"
                                  @showMatches="showMatches"
                                  @dragStart="dragStart"
                                  @doMenuAction="doMenuAction"
                                  @viewNodeDoc="viewNodeDoc"
                                  @removeAdditionNode="removeAdditionNode"
                                ></HierarchyViewColumnDetail> </v-col></v-row></v-col></v-row></v-col></v-row
                  ></v-col>

                  <v-col
                    style="
                      overflow: auto;
                      padding: 12px 0px;
                      height: 100%;
                      margin-left: -10px;
                    "
                    ref="scrollContainer"
                    @scroll="setVScroll_"
                  >
                    <!-- -->
                    <div ref="scrollBar" style="height: 20px; width: 1px"></div>
                  </v-col>
                </v-row>
                <v-row v-if="layout && layout.headerRow" class="flex-nowrap">
                  <v-col
                    v-if="layout.leftPanel.cols"
                    :cols="layout.leftPanel.cols"
                    class="scrollCol"
                    ><v-row><v-col class="scrollCol"></v-col></v-row
                  ></v-col>
                  <v-col
                    v-if="layout.rightPanel.cols"
                    :cols="layout.rightPanel.cols"
                    class="scrollCol"
                  >
                    <v-row
                      style="overflow: auto"
                      ref="colfoot"
                      class="flex-nowrap"
                      @scroll="setHScroll"
                      ><v-col
                        v-for="(c, ci) in layout.headerRow.columns"
                        :key="'cf' + ci"
                        :cols="c.cols"
                        class="scrollCol"
                        :style="{ minWidth: 150 * c.cols + 'px' }"
                      ></v-col></v-row
                  ></v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card v-if="selectedView.definition.viewType === 'audit'">
          <v-card-title>{{ nodeHistoryReport.label }}</v-card-title>
          <v-card-actions>
            <v-row dense>
              <v-col>
                <DateRangePicker
                  :DateRange="nodeHistoryReport.dateRangeFilter"
                  label="Items Updated Between"
                  @updated="filterAuditReportDateRange"
                ></DateRangePicker>
              </v-col>
              <v-col>
                <v-select
                  multiple
                  outlined
                  dense
                  hide-details
                  label="Items Updated By"
                  v-model="nodeHistoryReport.userFilter"
                  clearable
                  :items="nodeHistoryReport.userList"
                  @change="filterAuditReport()"
                ></v-select>
              </v-col>
              <v-col cols="2">
                <v-btn dense @click="showAuditReportChanges(true)"
                  ><v-icon>mdi-eye</v-icon> Show All Changes</v-btn
                >
              </v-col>
              <v-col cols="2">
                <v-btn dense @click="showAuditReportChanges(false)"
                  ><v-icon>mdi-eye-off</v-icon> Hide All Changes</v-btn
                >
              </v-col>
            </v-row></v-card-actions
          >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <HierarchyTreeNodeAudit
                    :nodes="nodeHistoryReport.nodes"
                    :levels="4"
                  ></HierarchyTreeNodeAudit>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
        <v-card v-if="selectedView.definition.viewType === 'audit_data'">
          <v-card-title>{{ nodeAuditData.label }}</v-card-title>
          <v-card-actions>
            <v-row dense>
              <v-col>
                <DateRangePicker
                  :DateRange="nodeAuditData.dateRangeFilter"
                  label="Updates Between"
                  @updated="filterAuditDataDateRange"
                ></DateRangePicker>
              </v-col>
              <v-col>
                <v-select
                  multiple
                  outlined
                  dense
                  hide-details
                  label="Type"
                  v-model="nodeAuditData.typeFilter"
                  clearable
                  :items="nodeAuditData.typeList"
                  @change="filterAuditData()"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  multiple
                  outlined
                  dense
                  hide-details
                  label="Updates By"
                  v-model="nodeAuditData.userFilter"
                  clearable
                  :items="nodeAuditData.userList"
                  @change="filterAuditData()"
                ></v-select>
              </v-col>
              <v-col v-if="nodeAuditData.reasonList.length">
                <v-select
                  multiple
                  outlined
                  dense
                  hide-details
                  label="Change Reason"
                  v-model="nodeAuditData.reasonFilter"
                  clearable
                  :items="nodeAuditData.reasonList"
                  @change="filterAuditData()"
                ></v-select>
              </v-col>
              <!-- <v-col cols="2">
                <v-btn dense @click="showAuditReportChanges(true)"
                  ><v-icon>mdi-eye</v-icon> Show All Changes</v-btn
                >
              </v-col>
              <v-col cols="2">
                <v-btn dense @click="showAuditReportChanges(false)"
                  ><v-icon>mdi-eye-off</v-icon> Hide All Changes</v-btn
                >
              </v-col> -->
            </v-row></v-card-actions
          >
          <v-card-text v-if="nodeAuditData.data">
            <v-data-table
              :headers="nodeAuditData.headers"
              :items="nodeAuditData.items"
              single-expand
              :expanded.sync="nodeAuditData.expanded"
              item-key="hierarchy_transaction_id"
              show-expand
              class="elevation-1"
              @click:row="selectAuditRow"
            >
              <template v-slot:[`item.transaction_description`]="{ item }">
                <div
                  v-html="getAuditDescriptionHTML(item.transaction_description)"
                ></div>
              </template>
              <template v-slot:[`item.user_name`]="{ item }">
                <v-chip dark small :title="item.user_email">
                  {{ item.user_name }}
                </v-chip>
              </template>
              <template v-slot:[`item.transaction_reason_type`]="{ item }">
                <v-chip label small outlined v-if="item.transaction_reason_type"
                  >{{ item.transaction_reason_type
                  }}<v-tooltip v-if="item.transaction_reason_notes" bottom
                    ><template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" right>
                        mdi-message-text
                      </v-icon>
                    </template>
                    <span style="max-width: 500px">
                      {{ item.transaction_reason_notes }}
                    </span></v-tooltip
                  ></v-chip
                >
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-container>
                    <v-row
                      dense
                      v-for="(n, ni) in item.nodes_affected"
                      :key="'t' + item.hierarchy_transaction_id + 'n' + ni"
                    >
                      <v-col cols="1">-</v-col>
                      <v-col :cols="n.difference ? 6 : 11"
                        ><div
                          v-html="getAuditDescriptionHTML(n.effect_description)"
                        ></div>
                      </v-col>
                      <v-col v-if="n.difference"
                        ><div v-html="n.difference"></div
                      ></v-col> </v-row
                  ></v-container>
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-card v-if="selectedView.definition.viewType === 'reviews'">
          <v-card-title>Reviews</v-card-title>
          <v-card-text>
            <v-container v-for="(t, ti) in reviewDocs" :key="'t' + ti">
              <v-row
                ><v-col
                  ><h3 @click="t.show = !t.show">
                    {{ t.type
                    }}<v-badge :content="t.docs.length">
                      <v-icon>{{
                        t.show ? "mdi-chevron-up" : "mdi-chevron-down"
                      }}</v-icon></v-badge
                    >
                  </h3></v-col
                ></v-row
              >
              <div v-if="t.show">
                <v-row v-for="(r, ri) in t.docs" :key="'t' + ti + 'd' + ri">
                  <v-col
                    style="
                      border: solid 1px gray;
                      border-radius: 10px;
                      background-color: #eceff1;
                    "
                    class="mb-4"
                  >
                    <v-row @click="r.showNextLevel = !r.showNextLevel">
                      <v-col cols="2"
                        ><v-btn outlined small @click="openDocument(r)"
                          ><v-icon>mdi-file-eye</v-icon>
                          {{ r.created_date }}</v-btn
                        >
                      </v-col>
                      <v-col cols="2">
                        {{ r.nodesParent[0].level_name }}
                      </v-col>
                      <v-col>
                        {{ r.nodesParent[0].hierarchy_node_name }}
                      </v-col>
                      <v-col cols="1"
                        ><v-menu
                          v-model="r.showComments"
                          :close-on-content-click="false"
                          :nudge-width="400"
                          offset-x
                          v-if="r.overall_comment_count"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              title="View Comments"
                              v-on="on"
                              >mdi-comment-alert</v-icon
                            >
                          </template>
                          <v-card>
                            <v-list>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title
                                    >{{
                                      r.nodesParent[0].level_name
                                    }}
                                    Comments</v-list-item-title
                                  >
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-icon @click="r.showComments = false"
                                    >mdi-close-thick</v-icon
                                  >
                                </v-list-item-action>
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-list-item>
                                <v-list-item-content>
                                  <div style="height: 100%; position: relative">
                                    <div
                                      style="
                                        overflow-y: scroll;
                                        max-height: 200px;
                                      "
                                    >
                                      <DocumentComments
                                        :document="r"
                                        hideHeader
                                      ></DocumentComments>
                                    </div>
                                  </div>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-menu>
                      </v-col>
                      <v-col cols="2">
                        {{
                          r.nodesMain.length +
                          " " +
                          utils.pluralise(r.nodesMain[0].level_name)
                        }}
                      </v-col>
                      <v-col cols="1">
                        <v-icon>{{
                          r.showNextLevel
                            ? "mdi-chevron-up"
                            : "mdi-chevron-down"
                        }}</v-icon>
                      </v-col>
                    </v-row>
                    <v-row v-if="r.showNextLevel">
                      <v-col cols="2">
                        <div style="padding-left: 15px">
                          <div
                            v-for="(a, ai) in r.roles"
                            :key="'rd' + r.doc_id + 'r' + ai"
                          >
                            {{
                              a.role_type +
                              (a.role_users.length > 1 ? "s" : "")
                            }}:
                            <div
                              class="review-user"
                              v-for="(c, ci) in a.role_users"
                              :key="'rd' + r.doc_id + 'r' + ai + 'c' + ci"
                            >
                              {{ c }}
                            </div>
                          </div>
                        </div></v-col
                      >
                      <v-col cols="2"
                        ><h4>
                          {{ utils.pluralise(r.nodesMain[0].level_name) }}:
                        </h4></v-col
                      >
                      <v-col>
                        <v-row
                          v-for="(rm, rmi) in r.nodesMain"
                          :key="'t' + ti + 'd' + ri + 'm' + rmi"
                        >
                          <v-col>
                            <v-row
                              v-for="(n, ni) in ['name', 'description']"
                              dense
                              :key="'t' + ti + 'd' + ri + 'm' + rmi + '_' + ni"
                            >
                              <v-col>
                                <h4 v-if="n === 'name'">
                                  {{ rm[n + "_original"] }}
                                </h4>
                                <span v-else>{{
                                  rm[n + "_original"]
                                }}</span></v-col
                              >
                              <v-col cols="1">
                                <v-icon
                                  v-if="
                                    n !== 'description' ||
                                    rm.name_status !== rm[n + '_status']
                                  "
                                  :color="
                                    rm[n + '_status'] === 'Change Applied'
                                      ? 'green'
                                      : rm[n + '_status'] ===
                                        'Change Not Applied'
                                      ? 'red'
                                      : ''
                                  "
                                  :title="rm[n + '_status'] + ' to ' + n"
                                  >mdi-{{
                                    rm[n + "_status"] === "Change Applied"
                                      ? "check-circle"
                                      : rm[n + "_status"] ===
                                        "Change Not Applied"
                                      ? "alert-circle"
                                      : "cancel"
                                  }}</v-icon
                                ><v-menu
                                  v-model="rm.showComments"
                                  :close-on-content-click="false"
                                  :nudge-width="400"
                                  offset-x
                                  v-if="rm.part_comment_count && ni === 0"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-if="rm.part_comment_count && ni === 0"
                                      v-bind="attrs"
                                      title="View Comments"
                                      v-on="on"
                                      >mdi-comment-alert</v-icon
                                    >
                                  </template>
                                  <v-card>
                                    <v-list>
                                      <v-list-item>
                                        <v-list-item-content>
                                          <v-list-item-title
                                            >{{
                                              rm.level_name
                                            }}
                                            Comments</v-list-item-title
                                          >
                                        </v-list-item-content>
                                        <v-list-item-action>
                                          <v-icon
                                            @click="rm.showComments = false"
                                            >mdi-close-thick</v-icon
                                          >
                                        </v-list-item-action>
                                      </v-list-item>
                                      <v-divider></v-divider>
                                      <v-list-item>
                                        <v-list-item-content>
                                          <div
                                            style="
                                              height: 100%;
                                              position: relative;
                                            "
                                          >
                                            <div
                                              style="
                                                overflow-y: scroll;
                                                max-height: 200px;
                                              "
                                            >
                                              <DocumentComments
                                                :document="rm"
                                                :doc_part_id="rm.dp_id"
                                                hideHeader
                                              ></DocumentComments>
                                            </div>
                                          </div>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-list>
                                  </v-card>
                                </v-menu>
                                <!-- {{ rm[n + '_status'] !== 'No change' ? rm[n + '_status'] : ''}} -->
                              </v-col>
                              <v-col>
                                <span
                                  v-if="
                                    rm[n + '_status'] === 'Change Not Applied'
                                  "
                                  v-html="rm[n + '_html']"
                                ></span>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-card-text>
        </v-card>
        <!-- <v-row
          ><v-col><div id="tree"></div></v-col
        ></v-row>
        <v-row
          ><v-col><div id="htree"></div></v-col
        ></v-row>
        <v-row
          ><v-col><div id="vtree"></div></v-col
        ></v-row> -->

        <v-menu
          v-model="contextMenu.show"
          absolute
          :position-x="contextMenu.posX"
          :position-y="contextMenu.posY"
          offset-y
          :close-on-content-click="false"
          nudge-width="300"
        >
          <v-card>
            <v-card-text v-if="contextMenu.busy">
              <v-row>
                <v-col cols="5"></v-col>
                <v-col>
                  <v-progress-circular indeterminate size="50" color="grey">
                  </v-progress-circular>
                </v-col>
                <v-col cols="5"></v-col>
              </v-row>
            </v-card-text>
            <v-list dense v-else>
              <v-list-item>
                <v-list-item-title
                  ><div class="menuSubject">
                    {{ contextMenu.title }}
                  </div></v-list-item-title
                ></v-list-item
              >
              <v-list-item
                v-for="(a, ai) in contextMenu.actions"
                :key="'cma' + ai"
                @click="doContextMenuAction(a)"
              >
                <v-list-item-icon>
                  <v-icon>{{ a.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ a.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
        <DocListMenu
          :context="docListMenu"
          @openDocument="openDocument"
          @copyDocument="$emit('copyDocument', $event)"
          @assignHierarchy="assignHierarchy"
          @itemRemoved="removeFromDocList"
          showAttributeCompare
          @editDocs="editDocs"
          @openDocumentSummary="openDocumentSummary"
          :showTagCompare="usesTags"
          @compareTags="getDocumentTags"
        ></DocListMenu>

        <v-menu
          v-model="measurePicker.show"
          absolute
          :position-x="measurePicker.posX"
          :position-y="measurePicker.posY"
          offset-y
          :close-on-content-click="false"
          nudge-width="300"
        >
          <v-card max-height="400">
            <v-list dense>
              <v-list-item>
                <v-list-item-title>Visible Measures</v-list-item-title>
                <v-list-item-action>
                  <v-icon title="Close" @click="measurePicker.show = false"
                    >mdi-close</v-icon
                  ></v-list-item-action
                >
              </v-list-item>
              <v-list-item v-for="(m, mi) in measures" :key="'me' + mi">
                <v-list-item-title
                  ><v-chip label outlined small
                    ><v-icon left small>mdi-tune-variant</v-icon
                    >{{ m.title }} ({{ m.aggregation }})</v-chip
                  ></v-list-item-title
                ><v-list-item-action
                  ><v-switch
                    hide-details
                    v-model="selectedView.definition.measuresVisible"
                    :value="m.type"
                    @change="changeViewSetting('measuresVisible')"
                  ></v-switch>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
        <v-menu
          v-model="shortcuts.show"
          absolute
          :position-x="shortcuts.posX"
          :position-y="shortcuts.posY"
          offset-y
          :close-on-content-click="false"
          nudge-width="300"
        >
          <v-card max-height="800"
            ><v-toolbar>
              <v-toolbar-title>Manage Shortcuts</v-toolbar-title>
              <v-spacer>
                <v-btn small @click="addShortcut" class="ml-3"
                  >Add Shortcut</v-btn
                ></v-spacer
              >
              <v-btn
                small
                @click="saveShortcuts"
                :disabled="!shortcuts.isDirty"
                class="mx-3"
                >Save</v-btn
              >
              <v-btn small @click="cancelShortcuts">{{
                shortcuts.isDirty ? "Cancel" : "Close"
              }}</v-btn>
            </v-toolbar>
            <v-list dense
              ><v-list-item
                ><v-list-item-content
                  ><v-row
                    ><v-col></v-col><v-col cols="2">Position</v-col
                    ><v-col cols="2">Icon</v-col><v-col cols="2">Default</v-col
                    ><v-col cols="1"></v-col></v-row></v-list-item-content
              ></v-list-item>
              <v-divider></v-divider>
              <v-radio-group v-model="shortcuts.default">
                <template v-for="(s, si) in shortcuts.editItems">
                  <v-list-item :key="'sc' + si">
                    <v-list-item-content
                      ><v-row
                        ><v-col
                          ><v-select
                            v-if="s.isNew"
                            :items="
                              views.filter(
                                (v) =>
                                  !shortcuts.editItems.some(
                                    (x) =>
                                      x.name === v.name && x.name !== s.name
                                  )
                              )
                            "
                            item-text="name"
                            item-value="name"
                            v-model="s.name"
                            outlined
                            label="New Shortcut basis"
                            hide-details
                            dense
                          ></v-select
                          ><v-chip
                            v-else
                            label
                            outlined
                            :color="s.delete ? 'red' : ''"
                            ><v-icon left>{{ s.icon }}</v-icon
                            >{{ s.name }}</v-chip
                          ></v-col
                        ><v-col cols="2"
                          ><v-text-field
                            outlined
                            :disabled="s.delete"
                            :color="s.delete ? 'red' : ''"
                            dense
                            hide-details
                            type="number"
                            v-model="s.position"
                            @change="shortcuts.isDirty = true"
                          ></v-text-field></v-col
                        ><v-col cols="2"
                          ><v-select
                            hide-details
                            :disabled="s.delete"
                            :color="s.delete ? 'red' : ''"
                            outlined
                            @change="shortcuts.isDirty = true"
                            dense
                            :items="
                              [
                                'mdi-view-sequential',
                                'mdi-view-comfy',
                                'mdi-file-tree-outline',
                                'mdi-receipt-text',
                                'mdi-radar',
                                'mdi-card-multiple-outline',
                                'people',
                                'transfer_within_a_station',
                                'elevator',
                                'account_balance',
                                'mdi-vector-combine',
                                'mdi-file-cabinet',
                                'mdi-alarm-panel',
                                'mdi-artboard',
                                'mdi-ballot',
                                'mdi-blur-linear',
                                'cube',
                              ].filter(
                                (i) =>
                                  !primaryViews.some(
                                    (x) => x.definition.primary?.icon === x
                                  )
                              )
                            "
                            v-model="s.icon"
                            ><template v-slot:item="{ item }"
                              ><v-icon>{{ item }}</v-icon> </template
                            ><template v-slot:selection="{ item }"
                              ><v-icon>{{ item }}</v-icon>
                            </template></v-select
                          ></v-col
                        ><v-col cols="2"
                          ><v-radio
                            :disabled="s.delete"
                            :value="s.name"
                            @click="shortcuts.isDirty = true"
                            :color="s.delete ? 'red' : ''"
                          ></v-radio
                        ></v-col>
                        <v-col cols="1"
                          ><v-icon
                            :title="
                              s.delete ? 'Undo Remove' : 'Remove Shortcut'
                            "
                            :color="s.delete ? 'red' : ''"
                            @click="toggleRemoveShortcut(s)"
                            >{{
                              s.delete ? "mdi-delete-off" : "mdi-delete"
                            }}</v-icon
                          ></v-col
                        ></v-row
                      ></v-list-item-content
                    >
                  </v-list-item>
                  <v-divider v-if="s.isNew" :key="'scd' + si"></v-divider
                ></template>
              </v-radio-group>
            </v-list>
          </v-card>
        </v-menu>
        <ResponseHandler :serviceResponse="response"></ResponseHandler>
      </v-container>
    </div>
    <Loading
      :isVisible="isLoading || isBuilding || isSaving || isLoadingData"
    />
    <v-dialog
      v-model="tagsDialogue.show"
      :max-width="tagsDialogue.items.length === 1 ? '800px' : ''"
      scrollable
    >
      <v-card v-if="tagsDialogue.show">
        <v-card-title><v-badge
        bordered
        color="deep-purple accent-4"
      >
        <template v-slot:badge>
          <span>Beta Feature</span>
        </template>
          <h4 v-if="tagsDialogue.items.length === 1">
            Selected Document {{ tagsDialogue.subject }}
          </h4>
          <h4 v-else>{{ tagsDialogue.subject }} Comparison</h4>
		</v-badge>
          <v-spacer></v-spacer>
          <!-- <v-btn
            color="primary"
            v-if="tagsDialogue.items.length === 1"
            @click="identifySimilarJobs(tagsDialogue.items[0])"
            >Identify Similar Jobs</v-btn
          > -->
          <v-btn color="primary" @click="tagsDialogue.show = false"
            >Close</v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row v-if="tagsDialogue.matchBands.length">
              <v-col style="position: relative; min-height: 300px">
                <div
                  v-for="(b, bi) in tagsDialogue.matchBands"
                  :key="'mb' + bi"
                  :style="{
                    height: 3 * b.pct + 'px',
                    width: '8%',
                    margin: '1%',
                    position: 'absolute',
                    bottom: '40px',
                    left: (750 * bi) / 10 + 'px',
                    backgroundColor: 'blue',
                    color: 'white',
                  }"
                >
                  {{ b.count > 0 ? b.count + " docs" : "" }}
                </div>
                <div
                  v-for="(b, bi) in tagsDialogue.matchBands"
                  :key="'mbh' + bi"
                  :style="{
                    height: '40px',
                    width: '8%',
                    margin: '1%',
                    position: 'absolute',
                    bottom: 0,
                    left: (750 * bi) / 10 + 'px',
                    color: 'blue',
                  }"
                >
                  {{ b.name }}
                </div>
              </v-col>
            </v-row>
            <v-row v-if="tagsDialogue.items.length">
              <v-col :cols="tagsDialogue.items.length === 1 ? 5 : 2">
                <v-row
                  dense
                  class="dialogueRowTags"
                  v-if="tagsDialogue.items.length > 1"
                  ><v-col></v-col
                ></v-row>
                <v-row dense class="dialogueRowTags"><v-col></v-col></v-row>
                <v-row dense class="dialogueRowTags"><v-col></v-col></v-row>
                <v-row
                  v-if="tagsDialogue.sections.length === 0"
                  dense
                  class="dialogueRowTags"
                  ><v-col
                    >No tgas are present for selected documents</v-col
                  ></v-row
                >
                <v-row
                  dense
                  v-for="(sc, sci) in tagsDialogue.sections"
                  :key="'sc_' + sci"
                  ><v-col
                    ><v-row dense class="dialogueRowTags"
                      ><v-col
                        ><h3>{{ sc.section_title }}</h3></v-col
                      ></v-row
                    >
                    <v-row
                      class="dialogueRowTags"
                      dense
                      v-for="(s, si) in sc.tags"
                      :key="'sc_' + sci + 's' + si"
                    >
                      <v-col class="pl-5">{{ s.value }}</v-col></v-row
                    >
                  </v-col></v-row
                >
              </v-col>
              <v-col :cols="tagsDialogue.items.length === 1 ? 6 : 10">
                <v-row
                  dense
                  class="dialogueRowTags"
                  v-if="tagsDialogue.items.length > 1"
                  ><v-col cols="1"
                    ><v-btn
                      small
                      @click="dialogueNextPage(tagsDialogue, false)"
                      :disabled="tagsDialogue.page === 0"
                      ><v-icon>mdi-chevron-left</v-icon></v-btn
                    ></v-col
                  ><v-col
                    ><h3 style="text-align: center">
                      Selected Documents {{ this.tagsDialogue.pageText }}:
                    </h3></v-col
                  >
                  <v-col cols="1"
                    ><v-btn
                      small
                      @click="dialogueNextPage(tagsDialogue, true)"
                      :disabled="tagsDialogue.page === tagsDialogue.pages - 1"
                      ><v-icon>mdi-chevron-right</v-icon></v-btn
                    ></v-col
                  ></v-row
                >
                <v-row dense class="dialogueRowTags flex-nowrap">
                  <v-col
                    class="dialogueColumnTop"
                    :cols="tagsDialogue.colSize"
                    v-for="(d, di) in tagsDialogue.items.filter(
                      (x) => x.page === tagsDialogue.page
                    )"
                    :key="'dc1_' + di"
                  >
                    <!-- <v-chip small label outlined @click="openDocumentSummary($event, d)"> -->
                    <v-chip small label outlined @click="openDocument(d)">{{
                      d.system_number
                    }}</v-chip
                    ><DocStatus
                      :doc_id="d.doc_id"
                      style="margin-left: 10px"
                    ></DocStatus>
                    <!-- <v-btn small class="float-end" @click="editDocSkills(d)"
                      >Edit Tags</v-btn
                    > -->
                  </v-col></v-row
                >
                <v-row dense class="dialogueRowTags flex-nowrap">
                  <v-col
                    class="dialogueColumn"
                    :cols="tagsDialogue.colSize"
                    v-for="(d, di) in tagsDialogue.items.filter(
                      (x) => x.page === tagsDialogue.page
                    )"
                    :key="'dc2_' + di"
                    ><v-tooltip top
                      ><template v-slot:activator="{ on, attrs }"
                        ><span v-bind="attrs" v-on="on">{{
                          d.doc_name
                        }}</span></template
                      ><span>{{ d.doc_name }}</span></v-tooltip
                    ></v-col
                  ></v-row
                >
                <v-row dense class="flex-nowrap">
                  <v-col
                    class="dialogueColumnBottom"
                    :cols="tagsDialogue.colSize"
                    v-for="d in tagsDialogue.items.filter(
                      (x) => x.page === tagsDialogue.page
                    )"
                    :key="'dc3_' + d.system_number"
                  >
                    <v-row
                      v-for="(s, si) in d.sections"
                      :key="'dc3_' + d.system_number + 's' + si"
                      dense
                      ><v-col>
                        <v-row dense class="dialogueRowTags"
                          ><v-col></v-col
                        ></v-row>
                        <v-row
                          v-for="(t, ti) in s.tags"
                          :key="'dc3_' + d.system_number + 's' + si + 't' + ti"
                          dense
                          class="dialogueRowTags"
                          ><v-col style="text-align: center">
							<v-icon v-if="t.val && !s.maxRating" color="green">mdi-check-circle</v-icon>
                            <v-chip v-else-if="t.val" label small>
                              <v-icon small color="green"
                                >mdi-check-circle</v-icon
                              >
                              <div
                                v-if="s.maxRating"
                                style="
                                  height: 100%;
                                  float: right;
                                  position: relative;
                                  border: 3px solid transparent;
                                "
                              >
                                <div
                                  v-for="i in s.maxRating"
                                  :key="'d' + i"
                                  :style="{
                                    height: (i * 100) / s.maxRating + '%',
                                    backgroundColor:
                                      t.val.rating_number >= i ? 'green' : null,
                                    border:
                                      t.val.rating_number < i
                                        ? 'red solid 1px'
                                        : null,
                                    marginLeft: i > 1 ? '1px' : '6px',
                                    top: 100 - (i * 100) / s.maxRating + '%',
                                  }"
                                  :title="t.val.rating_name"
                                  style="
                                    width: 5px;
                                    float: left;
                                    position: relative;
                                  "
                                ></div></div
                            ></v-chip>
                            <!-- <v-chip v-if="s.val" label>
                              <v-icon
                                small
                                left
                                @click="removeDocSkill(d, s.value)"
                                >mdi-close-circle</v-icon
                              >
                              {{ s.level }}
                              <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon right v-bind="attrs" v-on="on">
                                    mdi-chevron-down
                                  </v-icon>
                                </template>
                                <v-list>
                                  <v-list-item>
                                    <v-list-item-title
                                      >Proficiency level:</v-list-item-title
                                    > </v-list-item
                                  ><v-divider></v-divider>
                                  <v-list-item
                                    v-for="(l, li) in skillLevels"
                                    :key="'dc1_' + di + 'sl' + li"
                                    @click="updateSkillLevel(s, l)"
                                  >
                                    <v-list-item-title
                                      >{{ l.level
                                      }}<v-icon v-if="l.level === s.level"
                                        >mdi-check</v-icon
                                      ></v-list-item-title
                                    >
                                  </v-list-item>
                                </v-list>
                              </v-menu></v-chip
                            >
                            <v-menu offset-y v-else>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on">
                                  mdi-plus-circle
                                </v-icon>
                              </template>
                              <v-list>
                                <v-list-item>
                                  <v-list-item-title
                                    >Add at level:</v-list-item-title
                                  > </v-list-item
                                ><v-divider></v-divider>
                                <v-list-item
                                  v-for="(l, li) in skillLevels"
                                  :key="'dc1_' + di + 'sl' + li"
                                  @click="addDocSkill(d, null, ci, li)"
                                >
                                  <v-list-item-title>{{
                                    l.level
                                  }}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu> -->
                          </v-col></v-row
                        ></v-col
                      >
                    </v-row>
                  </v-col></v-row
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editDialogue.show"
      scrollable
      :max-width="editDialogue.items.length === 1 ? '1000px' : ''"
    >
      <!-- :max-width="editDialogue.items.length === 1 ? '500px' : ''" -->
      <v-card v-if="editDialogue.show">
        <!-- <v-card-title v-if="editDialogue.items.length === 1">
          <h4>
            {{ "Update " + editDialogue.items[0].system_number }}
          </h4>
          <br />
          <h4>
            {{ editDialogue.items[0].doc_name }}
          </h4>
        </v-card-title>
        <v-card-title v-else> -->
        <v-card-title>
          <h4>
            Detail Comparison - select the documents and properties to be
            updated
          </h4>
        </v-card-title>
        <v-card-text>
          <v-row v-if="editDialogue.items.length >= 1">
            <v-col cols="2">
              <v-row dense class="dialogueRow"><v-col>:</v-col></v-row>
              <v-row dense class="dialogueRow"
                ><v-col><h3>Properties:</h3></v-col></v-row
              >
              <v-row dense class="dialogueRow"><v-col></v-col></v-row>
              <v-row
                dense
                v-for="(a, ai) in editDialogue.columns"
                :key="'aa1_' + ai"
                :class="{
                  dialogueRow: a.tpa_id,
                  dialogueRowHierarchy: a.ht_id,
                }"
                ><v-col
                  ><v-badge
                    v-if="editDialogueCheckCount(a.value_column)"
                    :content="editDialogueCheckCount(a.value_column)"
                    ><span style="font-weight: bold; font-size: 15px">{{
                      a.label
                    }}</span></v-badge
                  >
                  <span v-else style="font-weight: bold; font-size: 15px">{{
                    a.label
                  }}</span
                  ><v-btn
                    v-if="editDialogue.items.length > 1"
                    x-small
                    class="float-right"
                    :title="
                      'Apply ' +
                      a.label +
                      ' updates for all ' +
                      editDialogue.items.length +
                      ' documents'
                    "
                    @click="editDialogueCheckAll(a.value_column)"
                  >
                    <v-icon small
                      >mdi-checkbox-multiple-marked-outline</v-icon
                    ></v-btn
                  >
                </v-col></v-row
              >
            </v-col>
            <v-col cols="7">
              <v-row dense class="dialogueRow"
                ><v-col cols="1"
                  ><v-btn
                    small
                    @click="dialogueNextPage(editDialogue, false)"
                    :disabled="editDialogue.page === 0"
                    ><v-icon>mdi-chevron-left</v-icon></v-btn
                  ></v-col
                ><v-col
                  ><h3 style="text-align: center">
                    Selected Documents {{ this.editDialogue.pageText }}:
                  </h3></v-col
                >
                <v-col cols="1"
                  ><v-btn
                    small
                    @click="dialogueNextPage(editDialogue, true)"
                    :disabled="editDialogue.page === editDialogue.pages - 1"
                    ><v-icon>mdi-chevron-right</v-icon></v-btn
                  ></v-col
                ></v-row
              >
              <v-row dense class="dialogueRow flex-nowrap">
                <v-col
                  class="dialogueColumnTop"
                  :cols="editDialogue.colSize"
                  v-for="(d, di) in editDialogue.items.filter(
                    (x) => x.page === editDialogue.page
                  )"
                  :key="'dd1_' + di"
                  ><v-chip small label outlined @click="openDocument(d)">{{
                    d.system_number
                  }}</v-chip
                  ><DocStatus
                    :doc_id="d.doc_id"
                    style="margin-left: 10px"
                  ></DocStatus></v-col
              ></v-row>
              <v-row dense class="dialogueRow flex-nowrap">
                <v-col
                  class="dialogueColumnBottom"
                  :cols="editDialogue.colSize"
                  v-for="(d, di) in editDialogue.items.filter(
                    (x) => x.page === editDialogue.page
                  )"
                  :key="'dd2_' + di"
                  ><v-tooltip top
                    ><template v-slot:activator="{ on, attrs }"
                      ><span v-bind="attrs" v-on="on">{{
                        d.doc_name
                      }}</span></template
                    ><span>{{ d.doc_name }}</span></v-tooltip
                  ></v-col
                ></v-row
              >
              <v-row
                class="flex-nowrap"
                :class="{
                  dialogueRow: a.tpa_id,
                  dialogueRowHierarchy: a.ht_id,
                }"
                dense
                v-for="(a, ai) in editDialogue.columns"
                :key="'aa2_' + ai + 'attr' + ai"
                ><v-col
                  :class="{
                    dialogueColumnBottom:
                      ai === editDialogue.columns.length - 1,
                    dialogueColumn: ai !== editDialogue.columns.length - 1,
                    dialogueColumnSame: a.same,
                  }"
                  :cols="editDialogue.colSize"
                  v-for="(d, di) in editDialogue.items.filter(
                    (x) => x.page === editDialogue.page
                  )"
                  :key="'aa2_' + ai + 'd' + di"
                >
                  <v-checkbox
                    v-if="a.ht_id"
                    v-model="d['change_' + a.value_column]"
                    dense
                    hide-details
                    :title="
                      a.tpa_id
                        ? d[a.value_column]
                        : d[a.value_column + '_label']
                    "
                    @click="setCanSave"
                  >
                    <template v-slot:label>
                      <li
                        v-for="(hl, hli) in d[a.value_column + '_label'].split(
                          ' > '
                        )"
                        :key="'aa2_' + ai + 'attr' + ai + 'hl' + hli"
                        :title="hl"
                        class="dialogueColumnCheckBoxRow"
                        :style="{
                          marginLeft: hli * 15 + 'px',
                          listStyleType: hli ? 'disclosure-closed' : 'none',
                        }"
                      >
                        {{ hl }}
                      </li>
                    </template></v-checkbox
                  >
                  <v-checkbox
                    v-else
                    v-model="d['change_' + a.value_column]"
                    dense
                    hide-details
                    :label="
                      a.tpa_id
                        ? d[a.value_column]
                        : d[a.value_column + '_label']
                    "
                    :title="
                      a.tpa_id
                        ? d[a.value_column]
                        : d[a.value_column + '_label']
                    "
                    @click="setCanSave"
                  ></v-checkbox> </v-col
              ></v-row>
            </v-col>
            <v-col cols="3">
              <v-row dense class="dialogueRow"><v-col></v-col></v-row>
              <v-row dense class="dialogueRow"><v-col></v-col></v-row>
              <v-row dense class="dialogueRow"
                ><v-col style="text-align: center"
                  >Enter the new values for each property:</v-col
                ></v-row
              >
              <v-row
                dense
                v-for="(a, ai) in editDialogue.columns.filter((x) => x.tpa_id)"
                :key="'av' + ai"
                :class="{
                  dialogueRow: a.tpa_id,
                  dialogueRowHierarchy: a.ht_id,
                }"
                ><v-col v-if="a.values.length"
                  ><v-select
                    :items="a.values"
                    :class="a.isDirty ? 'changedSelect' : ''"
                    v-model="a.value"
                    :label="a.label + ':'"
                    outlined
                    dense
                    hide-details
                    prepend-icon="mdi-plus"
                    @input="attributePicked(a)"
                    @click:prepend="addAttribute(a)"
                  ></v-select></v-col
                ><v-col cols="1" v-if="!a.values.length"></v-col
                ><v-col cols="11" v-if="!a.values.length"
                  ><DatePicker
                    v-if="a.value_data_type === 'date'"
                    v-model="a.value"
                    :css_class="a.isDirty ? 'changedSelect' : ''"
                    :label="a.label"
                    @input="attributePicked(a)"
                  ></DatePicker>
                  <v-text-field
                    v-else
                    outlined
                    :class="a.isDirty ? 'changedSelect' : ''"
                    dense
                    hide-details
                    :label="a.label + ':'"
                    v-model="a.value"
                    @input="attributePicked(a)"
                  ></v-text-field
                ></v-col>
              </v-row>
              <v-row
                dense
                v-for="(h, hi) in editDialogue.columns.filter((x) => x.ht_id)"
                :key="'hv' + hi"
                :class="{
                  dialogueRow: h.tpa_id,
                  dialogueRowHierarchy: h.ht_id,
                }"
                ><v-col cols="1"></v-col
                ><v-col cols="11">
                  <div
                    class="subjectPicker selectable"
                    :class="h.isDirty ? 'changed' : ''"
                    @click="pickHierarchy(h, editDialogue)"
                  >
                    <div class="subjectText">
                      <li
                        v-for="(hl, hli) in h.hLabel"
                        :key="'hv' + hi + 'hl' + hli"
                        :title="hl"
                        class="dialogueColumnCheckBoxRow"
                        :style="{
                          marginLeft: hli * 15 + 'px',
                          listStyleType: hli ? 'disclosure-closed' : 'none',
                        }"
                      >
                        {{ hl }}
                      </li>
                    </div>
                    <!-- <v-icon class="subjectIcon">mdi-pencil-box-outline</v-icon> -->
                    <v-btn
                      x-small
                      class="subjectIcon"
                      :title="h.label + ' Picker'"
                      >...</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- <v-container v-else>
            <v-row
              v-for="(a, ai) in editDialogue.columns.filter((x) => x.tpa_id)"
              :key="'as' + ai"
              :class="{
                dialogueRow: a.tpa_id,
                dialogueRowHierarchy: a.ht_id,
              }"
            >
              <v-col v-if="a.values.length">
                <v-select
                  :items="a.values"
                  :class="a.isDirty ? 'changedSelect' : ''"
                  v-model="a.value"
                  :label="a.label + ':'"
                  outlined
                  dense
                  hide-details
                  prepend-icon="mdi-plus"
                  @input="attributePicked(a)"
                  @click:prepend="addAttribute(a)"
                ></v-select> </v-col
              ><v-col cols="1" v-if="!a.values.length"></v-col
              ><v-col cols="11" v-if="!a.values.length"
                ><DatePicker
                  v-if="a.value_data_type === 'date'"
                  v-model="a.value"
                  :css_class="a.isDirty ? 'changedSelect' : ''"
                  :label="a.label"
                  @input="attributePicked(a)"
                ></DatePicker
                ><v-text-field
                  v-else
                  outlined
                  :class="a.isDirty ? 'changedSelect' : ''"
                  dense
                  hide-details
                  :label="a.label + ':'"
                  v-model="a.value"
                  @input="attributePicked(a)"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row
              v-for="(h, hi) in editDialogue.columns.filter((x) => x.ht_id)"
              :key="'hv' + hi"
              :class="{
                dialogueRow: h.tpa_id,
                dialogueRowHierarchy: h.ht_id,
              }"
              ><v-col cols="1"></v-col
              ><v-col cols="11">
                <div
                  class="subjectPicker selectable"
                  :class="h.isDirty ? 'changed' : ''"
                  @click="pickHierarchy(h, editDialogue)"
                >
                  <div class="subjectText">
                    <span style="font-size: 11px; font-style: italic"
                      >{{ h.label }}:</span
                    >
                    <li
                      v-for="(hl, hli) in h.hLabel"
                      :key="'hv' + hi + 'hl' + hli"
                      :title="hl"
                      class="dialogueColumnCheckBoxRow"
                      :style="{
                        marginLeft: hli * 15 + 'px',
                        listStyleType: hli ? 'disclosure-closed' : 'none',
                      }"
                    >
                      {{ hl }}
                    </li>
                  </div>
                  <v-btn
                    x-small
                    class="subjectIcon"
                    :title="h.label + ' Picker'"
                    >...</v-btn
                  >
                </div></v-col
              >
            </v-row>
          </v-container> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="!editDialogue.canSave"
            @click="saveDocs"
            >Apply Changes</v-btn
          >
          <v-btn color="primary" @click="editDialogue.show = false"
            >Close</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="docSkillDialogue.show" max-width="1000px">
      <v-card v-if="docSkillDialogue.show">
        <v-card-title
          >Skills for {{ docSkillDialogue.doc.system_number }} -
          {{ docSkillDialogue.doc.doc_name }}<v-spacer></v-spacer>
          <v-btn @click="saveDocSkills" color="info">Save</v-btn>
          <v-btn @click="docSkillDialogue.show = false" color="info"
            >Close</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-container>
            <v-row
              v-for="(dsc, li) in docSkillDialogue.sections"
              :key="'dsc' + li"
            >
              <v-col cols="2"
                ><h4>{{ dsc.category }}</h4></v-col
              >
              <v-col>
                <v-autocomplete
                  v-model="dsc.skills"
                  :items="skills.filter((x) => x.category === dsc.category)"
                  filled
                  chips
                  return-object
                  color="blue-grey lighten-2"
                  item-text="name"
                  item-value="name"
                  multiple
                  small-chips
                  deletable-chips
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :color="getDocSkill(dsc.skills, data.item.name).colour"
                      :text-color="
                        getDocSkill(dsc.skills, data.item.name).textColour
                      "
                      label
                      @click="data.select"
                    >
                      <v-icon
                        small
                        left
                        @click="
                          removeDocSkill(docSkillDialogue.doc, data.item.name)
                        "
                      >
                        mdi-close-circle
                      </v-icon>
                      {{ data.item.name }}
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon label v-bind="attrs" v-on="on"
                            >mdi-chevron-down</v-icon
                          >
                        </template>
                        <v-list>
                          <v-list-item>
                            <v-list-item-title
                              >Proficiency level:</v-list-item-title
                            > </v-list-item
                          ><v-divider></v-divider>
                          <v-list-item
                            v-for="(l, li) in skillLevels"
                            :key="'dc1_' + data.item.level + 'sl' + li"
                            @click="
                              setSkillLevel(dsc.skills, data.item.name, l)
                            "
                          >
                            <v-list-item-title
                              >{{ l.level
                              }}<v-icon
                                v-if="
                                  l.level ===
                                  getDocSkill(dsc.skills, data.item.name).level
                                "
                                >mdi-check</v-icon
                              ></v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog> -->
    <v-dialog v-model="hierarchyDescriptorDialogue.show" max-width="800px">
      <v-card v-if="hierarchyDescriptorDialogue.node">
        <v-card-title
          >{{ hierarchyDescriptorDialogue.label }} Description:
          {{ hierarchyDescriptorDialogue.node.name }}</v-card-title
        >
        <v-card-text
          ><v-textarea
            outlined
            clearable
            v-model="hierarchyDescriptorDialogue.text"
          ></v-textarea
        ></v-card-text>
        <v-card-actions
          ><v-spacer></v-spacer
          ><v-btn @click="saveHierarchyDescriptor">Save</v-btn>
          <v-btn @click="hierarchyDescriptorDialogue.show = false"
            >Cancel</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
    <v-dialog v-model="attributeAddDialogue.show" max-width="800px">
      <v-card v-if="attributeAddDialogue.show">
        <v-card-title>{{ attributeAddDialogue.label }}</v-card-title>
        <v-card-text
          ><v-text-field
            outlined
            v-model="attributeAddDialogue.text"
          ></v-text-field
        ></v-card-text>
        <v-card-actions
          ><v-alert v-if="!isDemo" dense type="warning"
            >Feature coming soon...</v-alert
          ><v-spacer></v-spacer
          ><v-btn :disabled="!isDemo" @click="saveAttribute">Save</v-btn>
          <v-btn @click="attributeAddDialogue.show = false"
            >Cancel</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
    <HierarchyNodeHistory :node="historyNode"> </HierarchyNodeHistory>
    <v-dialog v-model="nodeReviewDialogue.show">
      <v-card v-if="nodeReviewDialogue.node">
        <v-card-title
          ><span>{{ nodeReviewDialogue.node.label }} Review Summary</span>
          <v-spacer></v-spacer>
          <v-btn @click="closeHierarchyNodeReview">Close</v-btn></v-card-title
        >
        <v-divider></v-divider><br />
        <v-card-text>
          <HierarchyNodeReview
            :reviewNode="decorateReviewNode(nodeReviewDialogue.node, true)"
            :nodeChildLabel="nodeReviewDialogue.node.nextLevel"
            :nodeParentLabel="nodeReviewDialogue.node.prevLevel"
            showAllReviews
            @action="doMenuAction"
            @openDocument="openDocument"
            @nodeSaved="nodeSaved"
          ></HierarchyNodeReview
        ></v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="nodeDescriptorView.show">
      <v-card
        v-if="nodeDescriptorView.node && nodeDescriptorView.view === 'initiate'"
      >
        <v-card-title>
          <v-row
            ><v-col cols="2"> {{ nodeDescriptorView.labels[0] }}:</v-col
            ><v-col cols="8">
              <span style="font-weight: bold">
                {{ nodeDescriptorView.node.name }}</span
              > </v-col
            ><v-col
              ><v-spacer></v-spacer
              ><v-btn @click="reviewHierarchy" class="mr-4"
                >Initiate Review</v-btn
              >
              <v-btn @click="nodeDescriptorView.show = false"
                >Close</v-btn
              ></v-col
            ></v-row
          ></v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          <v-row
            v-if="
              nodeDescriptorView.node.reviewStatus &&
              nodeDescriptorView.node.reviewStatus.docsAsParent_open &&
              nodeDescriptorView.node.reviewStatus.docsAsParent_open.length
            "
          >
            <v-col cols="2"><h3>Open Reviews</h3></v-col>
            <v-col
              ><v-row>
                <v-col
                  v-for="rd in nodeDescriptorView.node.reviewStatus
                    .docsAsParent_open"
                  :key="'dpr' + rd.doc_id"
                  cols="2"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }"
                      ><v-btn
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        small
                        @click="openDocument(rd)"
                        ><v-icon>mdi-file-eye</v-icon>
                        {{ rd.created_date }}</v-btn
                      >
                    </template>
                    <div
                      v-for="(a, ai) in rd.roles"
                      :key="'rd' + rd.doc_id + 'r' + ai"
                      style="padding-top: 5px"
                    >
                      {{ a.role_type + (a.role_users.length > 1 ? "s" : "") }}:
                      <div
                        class="review-user"
                        style="color: gray"
                        v-for="(c, ci) in a.role_users"
                        :key="'rd' + rd.doc_id + 'r' + ai + 'c' + ci"
                      >
                        {{ c }}
                      </div>
                    </div>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            v-if="
              nodeDescriptorView.node.reviewStatus &&
              nodeDescriptorView.node.reviewStatus.docsAsParent_completed &&
              nodeDescriptorView.node.reviewStatus.docsAsParent_completed.length
            "
          >
            <v-col cols="2"><h3>Completed Reviews</h3></v-col>
            <v-col
              ><v-row>
                <v-col
                  v-for="rd in nodeDescriptorView.node.reviewStatus
                    .docsAsParent_completed"
                  :key="'dpr' + rd.doc_id"
                  cols="2"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }"
                      ><v-btn
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        small
                        @click="openDocument(rd)"
                        ><v-icon>mdi-file-eye</v-icon>
                        {{ rd.created_date }}</v-btn
                      >
                    </template>
                    <div
                      v-for="(a, ai) in rd.roles"
                      :key="'rd' + rd.doc_id + 'r' + ai"
                      style="padding-top: 5px"
                    >
                      {{ a.role_type + (a.role_users.length > 1 ? "s" : "") }}:
                      <div
                        class="review-user"
                        style="color: gray"
                        v-for="(c, ci) in a.role_users"
                        :key="'rd' + rd.doc_id + 'r' + ai + 'c' + ci"
                      >
                        {{ c }}
                      </div>
                    </div>
                  </v-tooltip>
                </v-col></v-row
              ></v-col
            >
          </v-row>
          <v-row class="flex-nowrap">
            <v-col cols="2"
              ><h3>
                {{ utils.pluralise(nodeDescriptorView.labels[1]) }}
              </h3></v-col
            >
            <v-col>
              <v-row>
                <v-col
                  v-for="(n, ni) in nodeDescriptorView.node.nodes"
                  :key="'nd' + ni"
                  cols="3"
                >
                  <div
                    style="
                      border: solid 1px gray;
                      border-radius: 5px;
                      padding: 10px;
                      height: 100%;
                    "
                  >
                    <!-- style="/*border: solid 1px gray; border-radius: 5px; margin: 0px 6px;*/"  -->

                    <v-row class="flex-nowrap">
                      <v-col>
                        <v-tooltip
                          v-if="
                            n.reviewStatus && n.reviewStatus.docs_open.length
                          "
                          top
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              small
                              left
                              @click.stop="showHierarchyNodeReview(n)"
                              v-bind="attrs"
                              v-on="on"
                              :color="
                                n.reviewStatus.hasData ? 'orange' : 'blue'
                              "
                            >
                              mdi-eye-settings
                            </v-icon>
                          </template>
                          <span
                            >Currently {{ n.reviewStatus.docs_open[0].status
                            }}{{
                              n.reviewStatus.hasData
                                ? " (has feedback)"
                                : " (no feedback)"
                            }}</span
                          > </v-tooltip
                        ><span style="font-weight: bold">{{ n.name }}</span>
                      </v-col>
                      <v-col
                        cols="2"
                        v-for="(m, mi) in visibleMeasures"
                        :key="'nd' + ni + 'm' + mi"
                        class="measure-column"
                      >
                        <MeasureCount :item="n" :measure="m"></MeasureCount>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>{{ n.description }} </v-col>
                    </v-row>
                    <v-row v-if="n.nodes && n.nodes.length">
                      <v-col>
                        <v-row @click="n.showNodes = !n.showNodes"
                          ><v-col cols="10"
                            >{{ nodeDescriptorView.labels[2] }}s ({{
                              n.nodes.length
                            }})</v-col
                          >
                          <v-col>
                            <v-icon v-if="n.showNodes">mdi-chevron-up</v-icon>
                            <v-icon v-else>mdi-chevron-down</v-icon></v-col
                          >
                        </v-row>
                        <div v-if="n.showNodes">
                          <v-row
                            v-for="(nn, nni) in n.nodes"
                            :key="'nd' + ni + 'ndn' + nni"
                          >
                            <v-col>{{ nn.name }} </v-col>
                            <v-col
                              cols="2"
                              v-for="(m, mi) in visibleMeasures"
                              :key="'nd' + ni + 'ndn' + nni + 'm' + mi"
                            >
                              <MeasureCount
                                :item="nn"
                                :measure="m"
                              ></MeasureCount>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        v-if="nodeDescriptorView.node && nodeDescriptorView.view === 'existing'"
      >
        <v-card-title>
          {{ nodeDescriptorView.labels[0] }} Review Feedback<v-spacer></v-spacer
          ><v-btn @click="reviewHierarchy" class="mr-4"
            >Initiate New Review</v-btn
          >
          <v-btn @click="nodeDescriptorView.show = false"
            >Close</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-container>
            <v-row
              style="
                border: solid 1px gray;
                border-radius: 5px;
                padding: 10px;
                height: 100%;
                margin-bottom: 10px;
              "
            >
              <v-col cols="4" style="border-right: solid grey 2px"
                ><h3>
                  <cHierarchyNodeDisplay
                    :node="nodeDescriptorView.node"
                    hideReview
                    @action="doMenuAction($event)"
                  ></cHierarchyNodeDisplay></h3
              ></v-col>
              <v-col cols="8">
                <v-row class="feedback-row">
                  <v-col cols="2"><h3>Review</h3></v-col>
                  <v-col cols="10"
                    ><h3>{{ nodeDescriptorView.labels[0] }} Comments</h3></v-col
                  > </v-row
                ><v-row
                  v-for="(rd, rdi) in nodeDescriptorView.node.reviewStatus
                    .docsAsParent_open"
                  :key="'rdo' + rdi"
                  class="feedback-row"
                >
                  <v-col cols="2"
                    ><v-tooltip right>
                      <template v-slot:activator="{ on, attrs }"
                        ><v-btn
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          small
                          @click="openDocument(rd)"
                          ><v-icon>mdi-file-eye</v-icon>
                          {{ rd.created_date }}</v-btn
                        >
                      </template>
                      <div
                        v-for="(a, ai) in rd.activity"
                        :key="'rd' + rd.doc_id + 'a' + ai"
                      >
                        {{ a }}
                      </div>
                    </v-tooltip>
                    <div
                      v-for="(a, ai) in rd.roles"
                      :key="'rd' + rd.doc_id + 'r' + ai"
                      style="padding-top: 5px"
                    >
                      {{ a.role_type + (a.role_users.length > 1 ? "s" : "") }}:
                      <div
                        class="review-user"
                        v-for="(c, ci) in a.role_users"
                        :key="'rd' + rd.doc_id + 'r' + ai + 'c' + ci"
                      >
                        {{ c }}
                      </div>
                    </div>
                  </v-col>
                  <v-col v-if="rd.overall_comment_count">
                    <div style="height: 100%; position: relative">
                      <div style="overflow-y: scroll; max-height: 200px">
                        <DocumentComments
                          :document="rd"
                          hideHeader
                        ></DocumentComments>
                      </div>
                    </div>
                  </v-col>
                  <v-col v-else> No feedback comments </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                ><h2>
                  {{ utils.pluralise(nodeDescriptorView.labels[1]) }}
                  within {{ nodeDescriptorView.node.name }}:
                </h2></v-col
              >
              <v-col cols="3"
                ><v-switch
                  class="mt-0 mb-4"
                  v-model="nodeDescriptorView.showFeedbackOnly"
                  :label="
                    'Only show ' +
                    nodeDescriptorView.labels[1] +
                    ' items with feedback'
                  "
                ></v-switch></v-col
            ></v-row>
            <HierarchyNodeReview
              v-for="(n, ni) in nodeDescriptorView.node.nodes.filter(
                (x) =>
                  !nodeDescriptorView.showFeedbackOnly ||
                  x.reviewStatus.docs_open.some((d) => d.hasData)
              )"
              :key="'ndr' + ni"
              :reviewNode="n"
              :nodeChildLabel="nodeDescriptorView.labels[2]"
              nestedMode
              @action="doMenuAction"
              @openDocument="openDocument"
              @nodeSaved="nodeSaved"
            ></HierarchyNodeReview>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sideBySide.show">
      <DocumentSideBySide
        v-if="sideBySide.show"
        :items="sideBySide.items"
        :isDialogue="true"
        @close="sideBySide.show = false"
        @openContext="openDocActionsMenuContext"
      ></DocumentSideBySide>
    </v-dialog>
    <v-dialog v-model="fullCompareView.show" style="background-color: white">
      <v-card>
        <v-card-title>
          Document Comparison
          <v-spacer></v-spacer>
          <v-switch
            v-model="fullCompareView.highlightChanges"
            label="Highlight Differences"
            class="mr-4"
          ></v-switch>
          <v-btn
            icon
            large
            class="btn-background"
            @click="fullCompareView.show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <DocumentVersionCompare
            class="pt-6 px-2"
            style="height: 100%"
            v-if="fullCompareView.show"
            :compareList="fullCompareView.items"
            :highlightChanges="fullCompareView.highlightChanges"
            hideCompareVersion
            multiDoc
            showWorkflowActions
            @openDocument="openDocument"
            @copyDocument="$emit('copyDocument', $event)"
          >
          </DocumentVersionCompare>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-badge
      v-if="compareDocs.length"
      color="green"
      left
      overlap
      :content="compareDocs.length"
      style="position: fixed; left: 35px; bottom: 25px"
    >
      <v-chip label color="blue darken-2" dark @click="editDocs()">
        Selected
        {{
          catalogueDocType.docType + (compareDocs.length > 1 ? "s" : "")
        }}</v-chip
      ><v-chip
        label
        dark
        color="blue darken-2"
        style="margin-left: 2px"
        @click="compareDocDetailsFull(compareDocs)"
      >
        <v-icon title="Full compare view">mdi-content-duplicate</v-icon></v-chip
      >
      <v-chip
        label
        color="blue darken-2"
        dark
        @click="editDocs()"
        style="margin-left: 2px"
        ><v-icon title="Show Details">mdi-view-week</v-icon></v-chip
      ><v-chip
        label
        dark
        color="blue darken-2"
        style="margin-left: 2px"
        :disabled="compareDocs.length > 15"
        @click="compareDocDetails(compareDocs)"
      >
        <v-icon title="Side-by-side view">mdi-compare</v-icon></v-chip
      >
      <v-chip
        v-if="usesTags"
        label
        dark
        color="blue darken-2"
        style="margin-left: 2px"
        @click="getDocumentTags(compareDocs)"
      >
        <v-icon :title="tagsDialogue.subject + ' Comparison'"
          >mdi-tag-multiple</v-icon
        ></v-chip
      >
      <v-chip
        label
        dark
        color="blue darken-2"
        style="margin-left: 2px"
        @click="openDocListMenu($event)"
      >
        <v-icon title="Selected document list"
          >mdi-view-list-outline</v-icon
        ></v-chip
      ><v-chip
        label
        dark
        color="blue darken-2"
        style="margin-left: 2px"
        @click="resetCompare"
        title="Clear"
      >
        <v-icon>mdi-close-circle-outline</v-icon></v-chip
      >
    </v-badge>

    <AdminHierarchyNodeDetail
      :node="hierarchyEditDialogue.node"
      :mode="hierarchyEditDialogue.mode"
      :action="hierarchyEditDialogue.action"
      :show="hierarchyEditDialogue.show"
      :showAddNodeDescriptor="hierarchyEditDialogue.showAddNodeDescriptor"
      :multiNodes="hierarchyEditDialogue.multiNodes"
      @close="hierarchyEditDialogue.show = false"
      @saved="nodeUpdated"
      @picked="nodePicked"
      @deleted="hierarchyEditDialogue.show = false"
    >
    </AdminHierarchyNodeDetail>

    <AdminHierarchyAdd
      :ht_id="hierarchyAddDialogue.ht_id"
      :show="hierarchyAddDialogue.show"
      @close="hierarchyAddDialogue.show = false"
      @saved="nodeUpdated"
    >
    </AdminHierarchyAdd>
    <DocActionsMenu
      :context="docActionsMenuContext"
      @openDocument="openDocument"
      @copyDocument="$emit('copyDocument', $event)"
    ></DocActionsMenu>
    <SaveViewDefinition
      :currentView="viewDefinition.view"
      :page="page"
      :saveTrigger="viewDefinition.saveTrigger"
      :saveAsTrigger="viewDefinition.saveAsTrigger"
      :inactiveTrigger="viewDefinition.inactiveTrigger"
      :permissions="permissions"
      @viewsLoaded="loadViews"
      @viewAdded="viewAdded"
      @viewInactive="viewInactive"
      @viewSaved="viewSaved"
    ></SaveViewDefinition>
    <AttributeValueAdmin
      :show="attributeAdmin.show"
      :attribute="attributeAdmin.attribute"
      :rawValues="attributeAdmin.rawValues"
      @updated="lookupUpdated"
      @created="lookupCreated"
    ></AttributeValueAdmin>
    <DocSummary
      :doc_id="documentSummary.doc_id"
      :trigger="documentSummary.trigger"
      @openDocument="openDocument"
      @copyDocument="$emit('copyDocument', $event)"
    ></DocSummary>
    <AdminHierarchyDetail
      :node="hr_idDialogue.node"
      :show="hr_idDialogue.show"
      @close="hr_idDialogue.show = false"
      @saved="hr_idDialogue.show = false"
    ></AdminHierarchyDetail>
    <v-bottom-sheet
      v-model="viewDefinition.showDesigner"
      hide-overlay
      persistent
    >
      <v-sheet
        ><v-card class="pl-4" v-if="selectedView">
          <v-tabs v-model="viewDefinition.tab">
            <v-tab> Filters </v-tab>
            <v-tab> Designer </v-tab>
            <v-tab v-if="permissions.changeSettings"> Settings </v-tab
            ><v-spacer></v-spacer
            ><v-icon class="pr-4" @click="viewDefinition.showDesigner = false"
              >mdi-close</v-icon
            >
          </v-tabs>
          <v-tabs-items v-model="viewDefinition.tab">
            <v-tab-item class="pa-4"
              ><v-container fluid>
                <v-row v-if="defAttrVisible('attributeFiltersVisible')">
                  <v-col
                    v-for="(f, fi) in compareOptionsV.filter(
                      (x) => x.listValues && x.listValues.length
                    )"
                    :key="'f' + fi"
                    cols="2"
                  >
                    <v-autocomplete
                      dense
                      hide-details
                      outlined
                      :items="f.listValuesFiltered"
                      v-model="f.filterValues"
                      @change="selectJDs(false, f)"
                      multiple
                      clearable
                      :title="f.name + ' Filter'"
                    >
                      <template v-slot:prepend>
                        <v-icon
                          v-if="f.tpa_id && f.listValues.length < 30"
                          small
                          @click="editAttributes(f)"
                          >mdi-format-list-numbered</v-icon
                        >
                      </template>
                      <template v-slot:label>
                        <span style="font-size: 13px">{{ f.name }}</span>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <span
                          style="font-size: 13px"
                          v-if="item && index === 0"
                          class="blue--text text-caption"
                          :title="f.filterValues.join(', ')"
                        >
                          ({{ f.filterValues.length }} item{{
                            f.filterValues.length > 1 ? "s" : ""
                          }})
                        </span>
                      </template>
                      <!--<template v-slot:item="{ item }">
                        <span style="font-size: 13px">{{ item }}</span>
                      </template> -->
                    </v-autocomplete></v-col
                  ></v-row
                >
                <v-row v-if="defAttrVisible('nameChangeFiltersVisible')"
                  ><v-col
                    cols="2"
                    class="float-right"
                    v-if="selectedView.definition.nameChangeFiltersVisible"
                    ><v-select
                      v-model="selectedView.definition.nameChangeFilters"
                      multiple
                      dense
                      clearable
                      hide-details
                      outlined
                      label="Filter rows with name changes to:"
                      :items="getNameChangeTypes()"
                      @change="changeViewSetting('nameChangeFilters')"
                    >
                      <template v-slot:label>
                        <span style="font-size: 13px">Name change filter</span>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <span
                          style="font-size: 13px"
                          v-if="item && index === 0"
                          class="blue--text text-caption"
                        >
                          ({{
                            selectedView.definition.nameChangeFilters.length
                          }}
                          item{{
                            selectedView.definition.nameChangeFilters.length > 1
                              ? "s"
                              : ""
                          }})
                        </span>
                      </template>
                      <template v-slot:item="{ item }">
                        <span style="font-size: 13px">{{ item.text }}</span>
                      </template></v-select
                    >
                  </v-col></v-row
                >
              </v-container>
            </v-tab-item>
            <v-tab-item class="pa-4"
              ><v-container fluid>
                <v-row>
                  <v-col>
                    <v-row v-if="defAttrVisible('verCompareOptions')">
                      <v-col cols="2"
                        >Vertical Axis Columns
                        <v-icon
                          small
                          @click="
                            removeAxisCompareOptionAll('verCompareOptions')
                          "
                          >mdi-delete</v-icon
                        ></v-col
                      >
                      <v-col
                        cols="2"
                        v-if="!verCompareOptions.length"
                        style="border-bottom: 2px solid lightgray"
                        ><HierarchyViewColumnPicker
                          :columnsAvailable="compareOptionsV"
                          :index="0"
                          :columnType="'verCompareOptions'"
                          :columnSubject="'vertical axis'"
                          @addColumn="addAxisCompareOption"
                        ></HierarchyViewColumnPicker
                      ></v-col>
                      <v-col v-else
                        ><v-row style="border-bottom: 2px solid lightgray">
                          <v-col
                            v-for="(vc, vci) in verCompareOptions"
                            :key="'vc' + vci"
                            cols="3"
                          >
                            <HierarchyViewColumnPicker
                              :column="
                                getCompareOptionDef(vc, 'compareOptionsV')
                              "
                              :columnsAvailable="
                                getOptionsAvailable('vertical')
                              "
                              :index="vci"
                              :allowRemove="true"
                              :allowAdd="verCompareOptions.length <= 6"
                              :allowStyling="permissions.saveViewMain"
                              :columnType="'verCompareOptions'"
                              :columnSubject="'vertical axis'"
                              @changeColumn="setAxisCompareOption"
                              @addColumn="addAxisCompareOption"
                              @removeColumn="removeAxisCompareOption"
                              @changeColumnStyle="setAxisCompareOptionStyle"
                            ></HierarchyViewColumnPicker> </v-col></v-row
                      ></v-col>
                    </v-row>
                    <v-row v-if="defAttrVisible('horCompareOptions')"
                      ><v-col cols="2"
                        >Horizontal Axis Columns
                        <v-icon
                          small
                          @click="
                            removeAxisCompareOptionAll('horCompareOptions')
                          "
                          >mdi-delete</v-icon
                        ></v-col
                      ><v-col
                        cols="2"
                        v-if="!horCompareOptions.length"
                        style="border-bottom: 2px solid lightgray"
                        ><HierarchyViewColumnPicker
                          :columnsAvailable="compareOptionsH"
                          :index="0"
                          :columnType="'horCompareOptions'"
                          :columnSubject="'horizontal axis'"
                          @addColumn="addAxisCompareOption"
                        ></HierarchyViewColumnPicker></v-col
                      ><v-col v-else
                        ><v-row style="border-bottom: 2px solid lightgray"
                          ><v-col
                            cols="3"
                            v-for="(hc, hci) in horCompareOptions"
                            :key="'hc' + hci"
                          >
                            <HierarchyViewColumnPicker
                              :column="
                                getCompareOptionDef(hc, 'compareOptionsH')
                              "
                              :columnsAvailable="
                                getOptionsAvailable('horizontal')
                              "
                              :index="hci"
                              :allowRemove="true"
                              :allowAdd="horCompareOptions.length <= 3"
                              :allowStyling="permissions.saveViewMain"
                              :columnType="'horCompareOptions'"
                              :columnSubject="'horizontal axis'"
                              @changeColumn="setAxisCompareOption"
                              @addColumn="addAxisCompareOption"
                              @removeColumn="removeAxisCompareOption"
                              @changeColumnStyle="setAxisCompareOptionStyle"
                            ></HierarchyViewColumnPicker> </v-col></v-row></v-col
                    ></v-row>
                    <v-row v-if="defAttrVisible('detCompareOptions')"
                      ><v-col cols="2"
                        >Detail Axis Columns
                        <v-icon
                          small
                          @click="
                            removeAxisCompareOptionAll('detCompareOptions')
                          "
                          >mdi-delete</v-icon
                        ></v-col
                      ><v-col v-if="!detCompareOptions.length" cols="2"
                        ><HierarchyViewColumnPicker
                          :columnsAvailable="compareOptionsD"
                          :index="0"
                          :columnType="'detCompareOptions'"
                          :columnSubject="'drilldown'"
                          @addColumn="addAxisCompareOption"
                        ></HierarchyViewColumnPicker
                      ></v-col>
                      <v-col v-else
                        ><v-row>
                          <v-col
                            v-for="(dc, dci) in detCompareOptions"
                            :key="'dc' + dci"
                            cols="3"
                            ><HierarchyViewColumnPicker
                              :column="
                                getCompareOptionDef(dc, 'compareOptionsD')
                              "
                              :columnsAvailable="getOptionsAvailable('detail')"
                              :index="dci"
                              :allowRemove="true"
                              :allowAdd="detCompareOptions.length <= 7"
                              :columnType="'detCompareOptions'"
                              :columnSubject="'drilldown'"
                              @changeColumn="setAxisCompareOption"
                              @addColumn="addAxisCompareOption"
                              @removeColumn="removeAxisCompareOption"
                            ></HierarchyViewColumnPicker> </v-col></v-row></v-col
                    ></v-row>
                  </v-col>
                  <v-col cols="1" v-if="defAttrVisible('measurePickerVisible')">
                    <v-row dense
                      ><v-col @click="openMeasurePicker($event)"
                        >Measures
                        <v-icon title="Hide/Show Measures"
                          >mdi-format-columns</v-icon
                        >
                      </v-col></v-row
                    ></v-col
                  >
                  <v-col
                    cols="1"
                    v-if="defAttrVisible('listviewColumnPickerVisible')"
                  >
                    <v-row><v-col>Columns</v-col></v-row>
                    <v-row dense>
                      <v-col
                        class="text-right"
                        cols="1"
                        v-if="
                          selectedView.definition.viewType === 'list' &&
                          selectedView.definition.listviewColumnPickerVisible
                        "
                      >
                        <v-menu offset-y :close-on-content-click="false">
                          <template v-slot:activator="{ on }">
                            <!--<v-btn
                          icon
                          class="btn-background ml-3"
                        >
                        </v-btn>-->
                            <v-icon title="Hide/Show Columns" v-on="on"
                              >mdi-format-columns</v-icon
                            >
                          </template>
                          <v-list dense class="pa-3">
                            <v-list-item>
                              <v-list-item-title class="subtitle-1"
                                >Group By:</v-list-item-title
                              >
                            </v-list-item>
                            <v-list-item
                              v-for="field in listView.columns.filter(
                                (c) =>
                                  !c.measure &&
                                  (!c.ht_id ||
                                    c.ht_id === selectedView.definition.ht_id)
                              )"
                              :key="field.value"
                            >
                              <v-switch
                                class="v-input--reverse v-input--expand"
                                style="width: 200px"
                                dense
                                v-model="
                                  selectedView.definition.listViewColumnsEnabled
                                "
                                :label="field.text"
                                :value="field.value"
                                :disabled="field.value === 'job_count'"
                                @change="
                                  changeViewSetting('listViewColumnsEnabled')
                                "
                              ></v-switch>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title class="subtitle-1"
                                >Measures:</v-list-item-title
                              >
                            </v-list-item>
                            <v-list-item
                              v-for="field in listView.columns.filter(
                                (c) =>
                                  c.measure &&
                                  (!c.ht_id ||
                                    c.ht_id === selectedView.definition.ht_id)
                              )"
                              :key="field.value"
                            >
                              <v-switch
                                class="v-input--reverse v-input--expand"
                                style="width: 200px"
                                dense
                                v-model="
                                  selectedView.definition.listViewColumnsEnabled
                                "
                                :label="field.text"
                                :title="field.measure.title"
                                :value="field.value"
                                :disabled="field.value === 'job_count'"
                                @change="
                                  changeViewSetting('listViewColumnsEnabled')
                                "
                              ></v-switch>
                            </v-list-item>
                          </v-list>
                        </v-menu> </v-col></v-row
                  ></v-col> </v-row
              ></v-container>
            </v-tab-item>
            <v-tab-item class="pa-4" v-if="permissions.changeSettings"
              ><v-container fluid>
                <v-row
                  ><v-col cols="2"
                    ><v-select
                      hide-details
                      outlined
                      dense
                      v-model="selectedView.definition.tmpl_id"
                      label="Document Type"
                      :items="docTypeSummary"
                      item-value="tmpl_id"
                      item-text="docType"
                      @change="changeViewSetting('tmpl_id')"
                    ></v-select></v-col
                  ><v-col cols="1" v-if="defAttrVisible('measureDisplayType')"
                    ><v-select
                      hide-details
                      outlined
                      dense
                      v-model="selectedView.definition.measureDisplayType"
                      label="Measure Display"
                      :items="['chip', 'bar']"
                      @change="changeViewSetting('measureDisplayType')"
                    ></v-select></v-col
                  ><v-col cols="2" v-if="defAttrVisible('nodeSortProperty')"
                    ><v-select
                      hide-details
                      outlined
                      dense
                      clearable
                      v-model="selectedView.definition.nodeSortProperty"
                      label="Sort Nodes By:"
                      :items="['employee_percentage']"
                      @change="changeViewSetting('nodeSortProperty')"
                    ></v-select> </v-col
                  ><v-col v-if="defAttrVisible('groupedViewShowDescriptor')"
                    ><v-switch
                      v-model="
                        selectedView.definition.groupedViewShowDescriptor
                      "
                      label="Show Descriptor?"
                      @change="changeViewSetting('groupedViewShowDescriptor')"
                    ></v-switch></v-col></v-row
              ></v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import utils from "@/common/utils.js";
import HierarchySearch from "@/components/cHierarchySearch";
import { mapState } from "vuex";
// import * as d3 from "d3";
import dayJS from "dayjs";
// import * as similarity from "string-similarity";
// const d3 = require("d3");
import DocumentSideBySide from "@/components/cDocumentSideBySide.vue";
import Pagination from "@/components/cPagination";
import PageDescription from "@/components/cPageDescription";
import AdminHierarchyNodeDetail from "@/components/hierarchy/cAdminHierarchyNodeDetail";
import AdminHierarchyAdd from "@/components/hierarchy/cAdminHierarchyAdd";
import cHierarchyNodeDisplay from "@/components/hierarchy/cHierarchyNodeDisplay";
import axios from "axios";
import HierarchyTreeNodeAudit from "@/components/hierarchy/cHierarchyTreeNodeAudit";
import DateRangePicker from "@/components/common/DateRangePicker";
import DocumentComments from "@/components/comments/CommentsContainer";
import HierarchyNodeReview from "@/components/hierarchy/cHierarchyNodeReview";
import DocStatus from "@/components/common/DocStatus";
import DatePicker from "@/components/common/DatePicker";
import DocActionsMenu from "@/components/common/DocActionsMenu";
import MeasureCount from "@/components/cHierarchyViewMeasureCount.vue";
import DocumentVersionCompare from "@/components/audit/cDocumentVersionCompare";
import DocListMenu from "@/components/common/DocListMenu";
import HierarchyNodeHistory from "@/components/hierarchy/cHierarchyNodeHistory";
import SaveViewDefinition from "@/components/jobArchitecture/saveViewDefinition";
import AttributeValueAdmin from "@/components/jobArchitecture/attributeValueAdmin";
import DocSummary from "@/components/common/DocViewPopUp";
import AdminHierarchyDetail from "@/components/hierarchy/cAdminHierarchyDetail";
import HierarchyViewColumnHeading from "@/components/cHierarchyViewColumnHeading";
import HierarchyViewColumnDetail from "@/components/cHierarchyViewColumnDetail";
import HierarchyViewColumnPicker from "@/components/cHierarchyViewColumnPicker";

let _descriptors = {};
let _documents = {};
let _reviewNodes = {};
let _hierarchyTypeNodes = {};
// let _reviewDocuments = {};
const _newTitle = "< New >";
const _page = "cHierarchyView";

export default {
  name: "cHiearchyView",
  components: {
    ResponseHandler,
    HierarchySearch,
    DocumentSideBySide,
    Pagination,
    PageDescription,
    AdminHierarchyNodeDetail,
    AdminHierarchyAdd,
    cHierarchyNodeDisplay,
    HierarchyTreeNodeAudit,
    DateRangePicker,
    DocumentComments,
    HierarchyNodeReview,
    DocStatus,
    DatePicker,
    DocActionsMenu,
    MeasureCount,
    DocumentVersionCompare,
    DocListMenu,
    HierarchyNodeHistory,
    SaveViewDefinition,
    AttributeValueAdmin,
    DocSummary,
    AdminHierarchyDetail,
    HierarchyViewColumnHeading,
    HierarchyViewColumnDetail,
    HierarchyViewColumnPicker,
  },
  props: {},
  data: function () {
    return {
      utils: utils,
      page: _page,
      response: null,
      items: [],
      isDirty: false,
      isDemo: false,
      reviewDocs: [],
      documentsForSearch: [],
      hierarchyFilters: [],
      csvHeaders: [],
      dialog: false,
      options: { itemsPerPage: 15 },
      filteredItems: [],
      showHierarchies: false,
      defaultHierarchy: false,
      hierarchyTypes: [],
      jobTitles: [],
      selectedDocs: [],
      groupedDocs: [],
      groupedViewDocs: [],
      groupedViewLevelColumns: [],
      groupedViewDetailColumns: [],
      filterList: [],
      layout: null,
      compareOptionsV: [],
      compareOptionsH: [],
      compareOptionsD: [],
      compareCriteria: [],
      transactionTypes: [],
      //   horizontalCompareOption: null,
      //   verticalCompareOption: null,
      horCompareOptions: [],
      verCompareOptions: [],
      detCompareOptions: [],
      //   detailCompareOption: null,
      compareDocs: [],
      compareSkillMatrix: [],
      sideBySide: { show: false, items: [] },
      fullCompareView: { show: false, highlightChanges: false, items: [] },
      compareView: true,
      usesReviews: false,
      viewDefinition: {
        view: null,
        saveAsTrigger: 0,
        saveTrigger: 0,
        inactiveTrigger: 0,
        showDesigner: false,
        showFilters: false,
        tab: 0,
      },
      isBuilding: true,
      resetIsBuilding: false,
      doSetView: false,
      catalogueDocType: null,
      tmpl_id_default: null,
      usesTags: false,
      viewToSelect: null,
      measureFilters: [],
      display: {
        showLeafCount: false,
        v_col: { colWidth: 1 },
        h_col: { colWidth: 1 },
        total_col: {
          colWidth: 1,
          leafCountLabel: "",
          leafCountTitle: "",
        },
        z_col: {
          show: false,
          colWidth: 1,
          showLabel: false,
          colWidthLabel: 0,
        },
      },
      isSaving: false,
      isLoadingData: false,
      isDataLoaded: false,
      haveData: false,
      selectedView: null,
      primaryViews: [],
      preSelectedHierarchies: [],
      views: [],
      permissions: {
        saveViewMain: true,
        saveViewOwn: true,
        changeSettings: true,
        saveShortcuts: false,
      },
      vScroll: {
        rows: 0,
        pageSize: 0,
        pageHeight: 0,
        start: 0,
        end: 0,
        scrollValue: 0,
        pages: 1,
        pageText: "",
        doSetup: false,
        dStart: 0,
        dEnd: 0,
        dMax: 0,
        inProgress: false,
      },
      drilldownFilters: [],
      editDialogue: {
        show: false,
        canSave: false,
        items: [],
        columns: [],
        page: 0,
        pageSize: 4,
        colSize: 3,
      },
      tagsDialogue: {
        show: false,
        items: [],
        sections: [],
        page: 0,
        subject: "",
        pageSize: 8,
        colSize: 2,
        matchBands: [],
      },
      //   docSkillDialogue: {
      //     show: false,
      //     sections: [],
      //     doc: null,
      //   },
      hierarchyDescriptorDialogue: {
        show: false,
        node: null,
        label: "",
        text: "",
      },
      attributeAddDialogue: {
        show: false,
        label: "",
        text: "",
        attribute: null,
      },
      historyNode: null,
      nodeReviewDialogue: {
        show: false,
        node: null,
      },
      nodeHistoryReport: {
        show: false,
        label: "",
        nodes: [],
        dateRangeFilter: [],
        userFilter: [],
        userList: [],
      },
      nodeAuditData: {
        show: false,
        label: "",
        ht_id: null,
        data: [],
        dateRangeFilter: [],
        userFilter: [],
        userList: [],
        typeFilter: [],
        typeList: [],
        reasonFilter: [],
        reasonList: [],
        headers: [
          { text: "Date", value: "transaction_date" },
          { text: "Type", value: "transaction_sub_type" },
          { text: "User", value: "user_name" },
          { text: "Description", value: "transaction_description" },
          { text: "Reason", value: "transaction_reason_type" },
          { text: "", value: "data-table-expand" },
        ],
        expanded: [],
      },
      hierarchyEditDialogue: {
        show: false,
        mode: "edit",
        node: null,
        showAddNodeDescriptor: false,
        multiNodes: [],
        bucket: null,
      },
      hierarchyAddDialogue: {
        show: false,
        ht_id: null,
      },
      contextMenu: {
        show: false,
        busy: false,
        item: null,
        title: null,
        actions: [],
        posX: 0,
        posY: 0,
      },
      docListMenu: {
        show: false,
        busy: false,
        title: null,
        documents: [],
        matchSets: [],
        posX: 0,
        posY: 0,
        matchColumn: false,
        hierarchySuggestions: {
          show: false,
          hierarchyType: null,
          response: null,
        },
        documentType: this.catalogueDocType?.docType,
      },
      measurePicker: {
        show: false,
        busy: false,
        title: null,
        posX: 0,
        posY: 0,
      },
      shortcuts: {
        show: false,
        busy: false,
        title: null,
        default: null,
        items: [],
        editItems: [],
        isDirty: false,
        posX: 0,
        posY: 0,
      },
      attributeAdmin: {
        show: 0,
        attribute: null,
        rawValues: [],
      },
      docActionsMenuContext: {
        show: false,
        busy: false,
        document: null,
        posX: 0,
        posY: 0,
      },
      documentSummary: {
        trigger: null,
        doc_id: null,
      },
      hr_idDialogue: {
        show: false,
        node: null,
      },
      dragTarget: {
        show: true,
        buckets: [],
      },
      dragItem: null,
      visibleMeasures: [],
      measures: [
        {
          column: "job_count",
          type: "job_count",
          title: "Job Descriptions",
          abbreviation: "JDs",
          aggregation: "count",
          format: "0",
          aggregation_quantity_column: null,
          aggregation_exclude_zero: false,
          visible: true,
          alignRight: false,
        },
        {
          column: "employee_count",
          type: "employee_count",
          title: "Employees",
          abbreviation: "EEs",
          aggregation: "sum",
          format: "0",
          aggregation_quantity_column: null,
          aggregation_exclude_zero: false,
          visible: true,
          alignRight: false,
        },
        {
          column: "salary",
          type: "salary",
          title: "Salary",
          abbreviation: "Sal",
          aggregation: "mean",
          format: "£1k",
          aggregation_quantity_column: "employee_count",
          aggregation_exclude_zero: true,
          alignRight: false,
          visible: false,
        },
      ],
      attributes: [
        {
          column: "grade",
          title: "Grade",
          values: [
            { value: "PB1", sort_key: "PB1" },
            { value: "LPB1", sort_key: "LPB1" },
            { value: "LPB2", sort_key: "LPB2" },
            { value: "LPB3", sort_key: "LPB3" },
            { value: "SPB1", sort_key: "SPB1" },
            { value: "SPB2", sort_key: "SPB2" },
            { value: "SPB3", sort_key: "SPB3" },
            { value: "EB1", sort_key: "EB1" },
            { value: "EB2", sort_key: "EB2" },
          ],
        },
        {
          column: "level",
          title: "Level",
          values: [
            { value: "Manager", sort_key: "Manager" },
            {
              value: "Individual Conributer",
              sort_key: "Individual Conributer",
            },
            { value: "Technical", sort_key: "Technical" },
            { value: "Executives", sort_key: "Executives" },
            { value: "Specialist", sort_key: "Specialist" },
          ],
        },
        {
          column: "band",
          title: "Band",
          values: [
            { value: "PB", sort_key: "PB" },
            { value: "LPB", sort_key: "LPB" },
            { value: "SPB", sort_key: "SPB" },
            { value: "EB", sort_key: "EB" },
          ],
        },
        {
          column: "job_code",
          title: "Job Code",
          values: [],
          generateList: ["A", "B", "C", "D"],
        },
      ],
      //   skills: [
      //     { name: "Project Management", category: "Organisational" },
      //     { name: "Team Management", category: "Organisational" },
      //     { name: "Data Analysis", category: "Technical" },
      //     { name: "Web Development", category: "Technical" },
      //     { name: "Application Development", category: "Technical" },
      //     { name: "Sales Management", category: "Organisational" },
      //     { name: "Test Planning", category: "Organisational" },
      //     { name: "Software Testing", category: "Technical" },
      //     { name: "Test Automation", category: "Technical" },
      //     { name: "Technical Writing", category: "Technical" },
      //     { name: "Sales Training", category: "Technical" },
      //     { name: "Transact-SQL", category: "Technical" },
      //   ],
      //   skillCats: [
      //     { category: "Organisational", colour: "green darken-2" },
      //     { category: "Technical", colour: "light-green lighten-2" },
      //   ],
      //   skillLevels: [
      //     {
      //       level: "expert",
      //       colour: "green darken-2",
      //       textColour: "white",
      //       score: 3,
      //     },
      //     {
      //       level: "practioner",
      //       colour: "light-green lighten-2",
      //       textColour: "black",
      //       score: 2,
      //     },
      //     { level: "novice", colour: "orange", textColour: "black", score: 1 },
      //   ],
      listView: {
        show: false,
        columns: [],
        filteredItems: [],
        sortColumn: null,
        sortDesc: null,
        pageSize: 0,
        density: "dense",
        currentPage: 1,
        itemsPerPage: 10,
        tableHeight: null,
        renderCount: 0,
      },
      hierarchyDescriptors: [],
      nodeDescriptorView: {
        show: false,
        node: null,
        view: "initiate",
        labels: [],
        showFeedbackOnly: false,
      },
      viewTypes: [
        {
          type: "catalogue",
          settings: [
            "measuresVisible",
            "measureDisplayType",
            // "verticalCompareOption",
            // "horizontalCompareOption",
            "verCompareOptions",
            "horCompareOptions",
            "detCompareOptions",
            // "detailCompareOption",
            "attributeFilters",
            "hierarchyFilters",
            "measureFilter",
            "drilldownFilters",
            "attributeFiltersVisible",
            "compareDVisible",
            "measurePickerVisible",
          ],
        },
        {
          type: "grouped",
          settings: [
            "groupedViewShowDescriptor",
            "measuresVisible",
            "nodeSortProperty",
            "measureDisplayType",
            // "verticalCompareOption",
            "attributeFilters",
            "hierarchyFilters",
            "attributeFiltersVisible",
            "measurePickerVisible",
          ],
        },
        {
          type: "list",
          settings: [
            "listViewColumnsEnabled",
            "measureDisplayType",
            "hierarchyFilters",
            "columnFilters",
            "listviewColumnPickerVisible",
            "nameChangeFiltersVisible",
          ],
        },
        { type: "", settings: [] },
      ],
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    // isLoading(val) {
    //   if (!val) this.fetchData();
    // },
    selectedView(val) {
      if (val) this.initSetView();
    },
    $route() {},
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.docs.loading,
      cleanItems: (state) => state.docs.docsList,
      hierarchies: (state) => state.docs.hierarchyTypes,
      classifierTypes: (state) => state.docs.classifierTypes,
      docStatusSummary: (state) => state.docs.docStatusSummary,
      docTypeSummary: (state) => state.docs.docTypeSummary,
      useTranslation: (state) => state.docs.useTranslation,
    }),
    lvColumnsFiltered() {
      const columns = [];
      return [
        ...columns,
        { text: "", value: "selected", sortable: false, selected: false },
        ...this.listView.columns.filter(
          (c) =>
            this.selectedView.definition.listViewColumnsEnabled.indexOf(
              c.value
            ) >= 0
        ),
      ];
    },
    itemsPerPageOptions() {
      return [
        { text: "10 Rows", value: 10 },
        { text: "20 Rows", value: 20 },
        { text: "30 Rows", value: 30 },
        { text: "50 Rows", value: 50 },
      ];
    },
    itemsPerPage() {
      if (this.listView.pageSize !== 0) return this.listView.pageSize;
      else return this.listView.itemsPerPage;
    },
    itemsPerPageText() {
      const option = this.itemsPerPageOptions.find(
        (o) => o.value == this.listView.itemsPerPage
      );
      return option ? option.text : `$(itemsPerPage) Items`;
    },
    currentPageItems() {
      if (this.listView.itemsPerPage < 0) return this.filteredItems;

      const pageFrom =
        this.listView.itemsPerPage * (this.listView.currentPage - 1);
      const pageTo = this.listView.itemsPerPage * this.listView.currentPage;
      return this.filteredItems.slice(pageFrom, pageTo);
    },
    currentViewHierarchy() {
      return (
        this.hierarchyTypes.find(
          (h) => h.ht_id === this.selectedView.definition.ht_id
        ) ||
        this.hierarchyTypes.find(
          (h) => h.ht_name.toLowerCase().indexOf("job") >= 0
        )
      );
    },
  },
  created() {
    // this.fetchData();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "docs/update" && mutation.payload.doc_id && state) {
        let newVal = mutation.payload;
        if (["DELETED", "ARCHIVED"].some((x) => x === newVal.doc_status)) {
          let ind = _documents.findIndex((d) => d.doc_id === newVal.doc_id);
          if (ind >= 0) {
            _documents.splice(ind, 1);
            ind = this.compareDocs.findIndex((d) => d.doc_id === newVal.doc_id);
            if (ind >= 0) {
              this.compareDocs.splice(ind, 1);
              if (this.compareDocs.length === 0) {
                this.resetCompare();
              } else if (this.editDialogue.items?.length) {
                ind = this.editDialogue.items.findIndex(
                  (d) => d.doc_id === newVal.doc_id
                );
                if (ind >= 0) this.editDialogue.items.splice(ind, 1);
              }
            }
            this.selectJDs();
          }
        } else {
          // get job cat view of doc
          let self = this;
          let possibleError = false;
          this.isLoadingData = true;

          axios
            .get("document/jobcataloguedoc/" + newVal.doc_id)
            .then((resp) => {
              possibleError = true;
              if (resp.data.Data)
                self.afterDocumentUpdate(self, resp.data.Data.docs);
              this.isLoadingData = false;
            })
            .catch((err) => {
              if (possibleError) {
                alert("Code Error");
              } else if (err.response && err.response.status === 401) {
                this.$emit("sessionExpired", err);
              } else {
                alert(err.response ? err.response.data.message : err);
              }
              console.log(err);
              this.isLoadingData = false;
            });
        }
      }
    });
  },
  beforeDestroy: function () {
    this.unsubscribe();
  },
  updated() {
    this.$nextTick(function () {
      this.afterInit();
      if (!this.isLoadingData && this.doSetView) {
        this.doSetView = false;
        this.setView();
        return;
      }
    });
  },
  mounted() {},
  methods: {
    getRowNo() {
      this.listView.renderCount++;
      return "1";
    },
    setLVPageSize(value) {
      if (this.listView.pageSize === 0) {
        localStorage.setItem("jcRPP", value);
        this.listView.itemsPerPage = value;
      }
    },
    buildNodeDescriptorView(node, showSubLevel) {
      this.nodeDescriptorView.labels = [node.label];
      let data = {
        name: node.name,
        type: node.label,
        hierarchy_node_id: node.hierarchy_node_id,
        description: node.descriptor.description,
        active: true,
        nodes: [],
      };
      if (!node.nodes?.length && !node.rows?.length) {
        this.getNodes(node.level + 1, node.filterKeys || [], node);
      }
      if (node.nodes?.length) {
        data.labels.push(node.nodes[0].label);
        node.nodes.forEach((n) => {
          let displayNode = {
            name: n.name,
            type: n.label,
            hierarchy_node_id: n.node.hierarchy_node_id,
            ht_id: n.node.ht_id,
            level: n.node.level,
            description: n.node.descriptor?.description,
            reviewStatus: this.getHierarchyNodeReviewStatus(
              n.node.hierarchy_node_id
            ),
            nodes: [],
            showNodes: false,
          };
          this.appendMeasureColumns(displayNode, 0, n);
          this.appendNodeHierarchyLevels(displayNode, n);
          if (showSubLevel) {
            if (!n.nodes?.length && !n.rows?.length) {
              this.getNodes(n.level + 1, n.filterKeys, n);
            }
            data.labels.push(n.nodes[0].label);
            n.nodes.forEach((nn) => {
              let subNode = {
                name: nn.name || "UNKNOWN",
                type: nn.label,
                hierarchy_node_id: nn.node.hierarchy_node_id,
                ht_id: nn.node.ht_id,
                level: nn.node.level,
                description: nn.node.descriptor?.description,
              };
              this.appendMeasureColumns(subNode, 0, nn);
              this.appendNodeHierarchyLevels(subNode, nn);
              displayNode.nodes.push(subNode);
            });
          }
          data.nodes.push(displayNode);
        });
      }
      this.nodeDescriptorView.node = data;
      this.nodeDescriptorView.show = true;
    },
    decorateReviewNode(n, showSubLevel) {
      let sumFunc = (p, c) => {
        this.measures.forEach((m) => {
          p[m.type] += c[m.type];
        });
        return p;
      };
      let tot = this.appendMeasureColumns(null, 0);
      let tots = _documents
        .filter(
          (x) =>
            x["ht_id_" + n.ht_id + "_" + n.level + "_nodeID"] ===
            n.hierarchy_node_id
        )
        .reduce(sumFunc, tot);
      if (n.reviewStatus) n.reviewStatus.editMode = false;
      this.appendMeasureColumns(n, 0, tots);
      (n.description = n.descriptor ? n.descriptor.description : null),
        (n.showNodes = false);
      if (showSubLevel && n.nodes) {
        n.nodes.forEach((nn) => {
          let tot2 = this.appendMeasureColumns(null, 0);
          let ntots = _documents
            .filter(
              (x) =>
                x["ht_id_" + nn.ht_id + "_" + nn.level + "_nodeID"] ===
                nn.hierarchy_node_id
            )
            .reduce(sumFunc, tot2);
          this.appendMeasureColumns(nn, 0, ntots);
        });
      }
      return n;
    },
    buildNodeDescriptorViewReview(node, showSubLevel, view) {
      let isNode = (nodes, hierarchy_node_id) => {
        let found = nodes.find(
          (x) => x.hierarchy_node_id === hierarchy_node_id
        );
        if (!found) {
          nodes.forEach((n) => {
            if (!found && n.nodes) found = isNode(n.nodes, hierarchy_node_id);
          });
        }
        return found;
      };
      let _node = isNode(
        _hierarchyTypeNodes.find((x) => x.ht_id === node.ht_id).nodes,
        node.hierarchy_node_id
      );

      this.nodeDescriptorView.labels = [_node.label];
      if (_node.nodes?.length) {
        this.nodeDescriptorView.labels.push(_node.nodes[0].label);
        _node.nodes.forEach((n) => {
          this.decorateReviewNode(n, showSubLevel);
          if (showSubLevel && n.nodes) {
            this.nodeDescriptorView.labels.push(n.nodes[0].label);
          }
        });
      }
      this.getNodeReviewActivity(_node, () => {
        this.nodeDescriptorView.node = _node;
        this.nodeDescriptorView.show = true;
        this.nodeDescriptorView.view = view || "initiate";
      });
    },
    exportNodeDescriptors(node, showSubLevel) {
      let data = `"${node.label.toUpperCase()}:","${node.name}"\n\n`;
      if (!node.nodes?.length && !node.rows?.length) {
        this.getNodes(node.level + 1, node.filterKeys, node);
      }
      if (node.nodes?.length) {
        let line1 = `"${node.nodes[0].label.toUpperCase()}:"`;
        let line2 = `"EMPLOYEES:"`;
        let line3 = `"DESCRIPTION:"`;
        let line4 = [];
        let separator = ",";
        let line4fill = `""`;
        node.nodes.forEach((n) => {
          if (showSubLevel) {
            if (!n.nodes?.length && !n.rows?.length) {
              this.getNodes(n.level + 1, n.filterKeys, n);
            }
            if (!line4.length) {
              line4.push(`"${n.nodes[0].label.toUpperCase()}:"`);
            }
            line4.forEach((l) => (l += `,`));
            n.nodes.forEach((nn, nni) => {
              if (line4.length - 1 < nni) line4.push(line4fill);
              line4[nni] += `,"${nn.name || "UNKNOWN"} [${nn.employee_count}]"`;
            });
            line4.forEach((ll, li) => {
              if (li >= n.nodes.length) {
                line4[li] = ll + `,""`;
              }
            });
            line4fill += `,`;
          }
          line1 += `${separator}"${n.name}"`;
          line2 += `${separator}${n.employee_count}`;
          let desc = n.node.descriptor
            ? n.node.descriptor.description || ""
            : "";
          line3 += `${separator}"${desc
            .replace(/"/g, '""')
            .replace(/\n/g, " ")}"`;
        });
        data += `${line1}\n${line2}\n${line3}\n` + line4.join(`\n`);
      }
      utils.downloadFile(
        data,
        `${node.label} - ${node.name} Descriptors.csv`,
        "text/csv;encoding:utf-8"
      );
    },
    exportLVToCSV() {
      let cols = this.lvColumnsFiltered
        .map((x, xi) => {
          return {
            text: x.text,
            value: x.value,
            sequence: x.ht_id ? xi * -1 : xi,
          };
        })
        .sort((a, b) =>
          a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0
        );
      let data = cols.map((h) => '"' + h.text + '"').join(",");
      data += "\n";

      this.groupedDocs.forEach((d) => {
        data += cols
          .map((h) =>
            utils.csvEscape(utils.removeTags(d[h.value]).replace(/\n/g, "|"))
          )
          .join(",");
        data += "\n";
      });

      utils.downloadFile(data, "JobFamily.csv", "text/csv;encoding:utf-8");
    },
    afterInit() {
      if (this.resetIsBuilding) {
        this.isBuilding = false;
        this.resetIsBuilding = false;
      }
      if (
        this.selectedView?.definition.viewType !== "catalogue" ||
        this.isLoadingData
      )
        return;
      this.setUpVscroll_();
      const isLoadingData = this.isLoadingData;
      this.isLoadingData = false;
      if (isLoadingData) {
        this.buildCompare();
      }
    },
    setHScroll() {
      //   let hs = this.$refs.colhead.scrollLeft;
      let hd = this.$refs.detailH.scrollLeft;
      let fs = this.$refs.colfoot.scrollLeft;
      if (fs !== hd) {
        this.$refs[this.layout.headerRow.ref].scrollLeft = fs;
        this.layout.hierarchyHeaderRows.forEach((hr) => {
          this.$refs[hr.ref].forEach((r) => (r.scrollLeft = fs));
        });
        // this.layout.groupHeaderRow.vRows.forEach((gh) => {
        //   this.$refs[gh.ref].forEach((r) => (r.scrollLeft = fs));
        // });
        if (this.layout.groupHeaderRow.vRows.length) {
          this.$refs[this.layout.groupHeaderRow.ref].forEach((h) => {
            h.scrollLeft = fs;
          });
        }
        this.$refs.detailH.scrollLeft = fs;
      }
    },
    wheelVScroll(data) {
      if (data) {
        this.setPage_(data.deltaY >= 0);
      }
    },
    setVScroll_(event) {
      if (event.target.closest(".resizable-component")) return;
      if (this.vScroll.inProgress) {
        this.vScroll.inProgress = false;
        return;
      }
      let sl = this.$refs.scrollContainer.scrollTop;
      let max = this.$refs.scrollContainer.scrollHeight;
      if (sl === 0) {
        this.vScroll.dStart = 0;
      } else {
        this.vScroll.dStart = parseInt((this.vScroll.dMax * sl) / max);
      }
      this.getNextPageSize();
    },
    setUpVscroll_() {
      if (this.vScroll.doSetup && this.$refs.detailRow) {
        const pos = this.$refs.detailRow.getBoundingClientRect();
        const detailRowHeight = window.innerHeight - pos.top - 30;
        this.$refs.detailRow.style.height = detailRowHeight + "px";
        this.vScroll.pageHeight = detailRowHeight;
        this.vScroll.dStart = 0;
        this.vScroll.pageSize = this.getNextPageSize();
        let rowHeight = detailRowHeight / this.vScroll.pageSize;
        this.$refs.scrollBar.style.height =
          this.vScroll.dMax * (rowHeight + 5) + "px";
        this.vScroll.doSetup = false;
      }
    },
    setVisibleHeaderColumns(all) {
      if (this.verCompareOptions.length <= 1) return;
      const start = this.vScroll.dStart;
      const end = this.vScroll.dEnd;
      let headerValues = [];
      this.layout.detailRow.rowHeadings
        .filter((x) => all || (x.dIndexEnd >= start && x.dIndexStart <= end))
        .forEach((x) => {
          if (headerValues.length === 0) {
            x.headerColumns.forEach((hc) => (hc.visible = true));
          } else {
            let newVal = false;
            x.headerColumns.forEach((hc, hci) => {
              if (hc.value !== headerValues[hci]) newVal = true;
              hc.visible = newVal;
            });
          }
          headerValues = x.headerColumns.map((hc) => hc.value);
        });
    },
    getNextPageSize() {
      let i = this.vScroll.dStart;
      let available = this.vScroll.pageHeight;
      let usedHeight = 0;
      let endI = i; //this.vScroll.dMax;
      this.layout.detailRow.rowHeadings.some((x) => {
        if (x.dIndexEnd >= i) {
          if (x.dIndexStart === x.dIndexEnd) {
            usedHeight += 50;
            if (usedHeight < available) {
              endI = x.dIndexStart;
            }
          } else {
            let isFullSection = !x.detailCountHeader.length;
            if (!isFullSection && endI > i) usedHeight += 16;
            usedHeight += isFullSection ? 50 : 34;
            if (usedHeight < available) {
              if (!isFullSection) {
                endI++;
                x.detailCountHeader
                  .filter((d) => d.dIndex >= endI)
                  .some((d) => {
                    usedHeight += 34;
                    if (usedHeight < available) {
                      endI = d.dIndex;
                    }
                    return usedHeight >= available;
                  });
              } else {
                endI = x.dIndexEnd;
              }
            } else {
              return true;
            }
          }
        }
        return usedHeight >= available;
      });
      this.vScroll.dEnd = endI;
      if (
        this.vScroll.dEnd >= this.vScroll.dMax &&
        this.vScroll.dStart > 0 &&
        usedHeight + 34 < available
      ) {
        this.vScroll.dEnd = this.vScroll.dMax;
        return this.getPreviousPageSize();
      } else {
        this.setCurrentHierarchyPage();
        this.setVisibleHeaderColumns();
        return this.vScroll.dEnd - this.vScroll.dStart + 1;
      }
    },
    getPreviousPageSize() {
      let i = this.vScroll.dEnd;
      let available = this.vScroll.pageHeight;
      let usedHeight = 0;
      let startI = i;
      this.layout.detailRow.rowHeadings
        .slice()
        .reverse()
        .some((x) => {
          if (x.dIndexStart <= i) {
            if (x.dIndexStart === x.dIndexEnd) {
              usedHeight += 50;
              if (usedHeight < available) {
                startI = x.dIndexStart;
              }
            } else {
              let isFullSection = !x.detailCountHeader.length;
              if (!isFullSection && startI < i) usedHeight += 16;
              x.detailCountHeader
                .filter((d) => d.dIndex <= startI)
                .slice()
                .reverse()
                .some((d) => {
                  usedHeight += 34;
                  if (usedHeight < available) {
                    startI = d.dIndex;
                  }
                  return usedHeight >= available;
                });
              usedHeight += isFullSection ? 50 : 34;
              if (usedHeight < available) {
                startI--;
              }
            }
          }
          return usedHeight >= available;
        });
      this.vScroll.dStart = startI;
      if (
        this.vScroll.dStart <= 0 &&
        this.vScroll.dEnd < this.vScroll.dMax &&
        usedHeight + 34 < available
      ) {
        this.vScroll.dStart = 0;
        return this.getNextPageSize();
      } else {
        this.setCurrentHierarchyPage();
        this.setVisibleHeaderColumns();
        return this.vScroll.dEnd - this.vScroll.dStart + 1;
      }
    },
    setNodeActiveStatus(node, parent, makeInactive) {
      if (node.active === undefined) node.active = 1;
      if (makeInactive) node.active = 0;
      node.canDeactivate = !!node.active;
      node.canReactivate = !node.canDeactivate && (!parent || parent?.active);
      if (node.nodes) {
        node.nodes.forEach((n) => {
          this.setNodeActiveStatus(n, node, makeInactive);
        });
      }
    },
    setCurrentHierarchyPage() {
      if (this.layout.detailRow.hierarchyHeadings) {
        const dStart = this.vScroll.dStart;
        const dEnd = this.vScroll.dEnd;
        let heightCalc = (l) => {
          return !l.detailCountHeader
            ? 50
            : l.detailCountHeader
                .filter((x) => x >= dStart && x <= dEnd)
                .reduce((p) => p + 34, l.dIndexStart >= dStart ? 50 : 16);
        };
        const vco = this.verCompareOptions.find((x) => x.ht_id);
        let mapLevels = (levels) => {
          return levels
            .filter((x) => x.dIndexStart <= dEnd && x.dIndexEnd >= dStart)
            .map((l, li) => {
              let childLevels = mapLevels(l.levels);
              let height = childLevels.length
                ? childLevels.reduce((p, c) => p + c.heightVal, 0)
                : heightCalc(l);
              let isFirst = l.isFirst || li === 0;

              let currLevel = vco.hierarchyLevels.find(
                (h) => h.hLevel === l.level
              );
              let nextLevel = vco.hierarchyLevels.find(
                (h) => h.hLevel === l.level + 1
              );
              let prevLevel =
                l.level > 1
                  ? vco.hierarchyLevels.find((h) => h.hLevel === l.level - 1)
                  : null;
              let hierarchyDescriptor = currLevel.usesDescriptor
                ? this.getHierarchyTypeNode(l.ht_id, l.hierarchy_node_id)
                : null;
              let nodeRaw = this.getNodeData(l.ht_id, l.hierarchy_node_id);
              let node = {
                level: l.level,
                hierarchy_node_id: l.hierarchy_node_id,
                name: l.value,
                descriptor: hierarchyDescriptor,
                ht_id: l.ht_id,
                label: currLevel.ht_label,
                nextLevel: nextLevel ? nextLevel.ht_label : null,
                previousLevel: prevLevel ? prevLevel.ht_label : null,
                canAdd: !this.isDemo && l.level === vco.linklevel - 1,
                canAddDocumentType:
                  !this.isDemo && !nextLevel && l.job_count === 0
                    ? this.catalogueDocType.docType
                    : null,
                reviewStatus: this.getHierarchyNodeReviewStatus(
                  l.hierarchy_node_id
                ),
                showNodes: null,
                history: nodeRaw ? nodeRaw.transactions : [],
                nameHistory: nodeRaw ? nodeRaw.previous_names : [],
              };
              this.appendMeasureColumns(node);
              this.appendNodeHierarchyLevels(node, l);
              this.setNodeActiveStatus(node);

              return {
                cols: l.cols,
                value: l.value,
                hierarchy_node_id: l.hierarchy_node_id,
                ht_id: l.ht_id,
                level: l.level,
                heightVal: height,
                height: height + "px",
                childCols: l.childCols,
                isFirst: isFirst,
                isLast: l.isLast,
                vLineY1: isFirst && !l.isLast ? 24 : 0,
                vLineY2:
                  l.isLast && !isFirst ? 24 : isFirst && l.isLast ? 0 : "100%",
                levels: childLevels,
                class: l.detailCountHeader?.length ? "" : "flex-nowrap",
                hierarchyDescriptor: hierarchyDescriptor,
                node: node,
              };
            });
        };
        this.layout.detailRow.hierarchyHeadings.pageLevels = mapLevels(
          this.layout.detailRow.hierarchyHeadings.levels
        );
      }
    },
    setScroll() {
      this.vScroll.inProgress = true;
      let max = this.$refs.scrollContainer.scrollHeight;
      this.$refs.scrollContainer.scrollTop =
        (max * this.vScroll.dStart) / this.vScroll.dMax;
    },
    setPage_(plus) {
      if (
        plus &&
        this.vScroll.dStart <= this.vScroll.dMax &&
        this.vScroll.dEnd < this.vScroll.dMax
      ) {
        this.vScroll.dStart = this.vScroll.dEnd + 1;
        this.getNextPageSize();
        this.setScroll();
      } else if (!plus && this.vScroll.dEnd > 0 && this.vScroll.dStart > 0) {
        this.vScroll.dEnd = this.vScroll.dStart - 1;
        this.getPreviousPageSize();
        this.setScroll();
      }
    },
    deriveCols(hTextMax, dTextMax) {
      const vco = this.verCompareOptions[0];
      let label = this.catalogueDocType.docType;
      if (this.display.showLeafCount && vco) {
        label = vco.drillDownLevels
          ? vco.drillDownLevels[vco.drillDownLevels.length - 1].ht_label
          : vco.hierarchyLevels[vco.hierarchyLevels.length - 1].ht_label;
        this.display.total_col.leafCountLabel =
          label
            .split(" ")
            .filter((x) => x)
            .map((x) => x.substr(0, 1))
            .join("") + "s";
        this.display.total_col.leafCountTitle = `Number of ${label}s in Hierarchy`;
      }

      const hco = this.horCompareOptions[0];

      this.display.h_col.colWidth = !hco
        ? 0
        : dTextMax > 80
        ? 5
        : dTextMax > 60
        ? 4
        : dTextMax > 40
        ? 3
        : hTextMax > 12 || dTextMax > 12
        ? 2
        : 1;
    },
    drawOrgChart() {
      return;
    },
    selectJDs(restoring, filterTrigger) {
      this.isBuilding = true;
      let filters = this.compareOptionsV
        .filter((x) => x.filterValues.length)
        .concat(this.compareOptionsH.filter((x) => x.filterValues.length));
      let hfilters = this.hierarchyFilters
        .filter((x) => x.selected.length)
        .map((x) => {
          return {
            ht_id: x.ht_id,
            label: x.label,
            selected: x.selected.map((s) => {
              return {
                value: Number(s.id.split("_")[0]),
                name: s.value,
              };
            }),
          };
        });
      let cfilters = this.lvColumnsFiltered
        .filter(
          (x) =>
            x.filterSettings && x.filterSettings.values.some((v) => v.selected)
        )
        .map((v) => {
          return {
            column: v.value,
            filterValues: v.filterSettings.values
              .filter((vv) => vv.selected)
              .map((vv) => vv.text),
          };
        });
      let selectedDocs = _documents.filter((d) => {
        d.selected = this.compareDocs.some((x) => x.doc_id === d.doc_id);
        if (
          filters.length === 0 &&
          hfilters.length === 0 &&
          this.drilldownFilters.length === 0 &&
          cfilters.length === 0 &&
          !this.measureFilter?.type
        ) {
          return true;
        } else {
          if (
            this.measureFilter &&
            this.measureFilter.type &&
            (d[this.measureFilter.type] || 0) === 0
          )
            return false;
          let result = true;
          hfilters.forEach((hf) => {
            if (
              !hf.selected.some((s) => {
                return d[`ht_id_${hf.ht_id}`] === s.value;
              })
            )
              result = false;
          });
          this.drilldownFilters.forEach((df) => {
            let val = d[df.column] || "UNCLASSIFIED";
            if (val !== df.value) result = false;
          });
          return (
            result &&
            filters.every((f) => {
              return f.filterValues.some((fv) => d[f.column] === fv);
            }) &&
            (this.drilldownFilters.length === 0 ||
              this.drilldownFilters.every(
                (df) => (d[df.column] || "UNCLASSIFIED") === df.value
              )) &&
            (cfilters.length === 0 ||
              cfilters.every((f) => {
                return f.filterValues.some((fv) => d[f.column] === fv);
              }))
          );
        }
      });
      if (this.selectedView.definition.nameChangeFilters?.length) {
        let nfs = this.selectedView.definition.nameChangeFilters;
        if (this.selectedView.definition.ht_id) {
          selectedDocs = selectedDocs.filter((d) =>
            nfs.some((f) => d[f]?.length)
          );
          // } else {
          // 	selectedDocs = selectedDocs.filter(d => d[col].length && d[col].some(f => tfs.some(v => v === f)));
        }
      }
      if (this.selectedView.definition.transactionTypeFilters?.length) {
        let tfs = this.selectedView.definition.transactionTypeFilters;
        if (this.selectedView.definition.ht_id) {
          let col = `ht_id_${this.selectedView.definition.ht_id}_transactions`;
          selectedDocs = selectedDocs.filter(
            (d) => d[col].length && d[col].some((f) => tfs.some((v) => v === f))
          );
          // } else {
          // 	selectedDocs = selectedDocs.filter(d => d[col].length && d[col].some(f => tfs.some(v => v === f)));
        }
      }
      this.selectedDocs = selectedDocs;
      this.filterList = filters.map((f) => {
        return {
          title: `${f.name} = [${f.filterValues.join(", ")}]`,
          name: f.name,
        };
      });
      hfilters
        .filter((x) => x.selected.length)
        .forEach((f) => {
          this.filterList.push({
            title: `${f.label} = [${
              f.selected.length > 3
                ? f.selected.length + " items"
                : f.selected.map((x) => x.name).join(", ")
            }]`,
            name: f.label,
            ht_id: f.ht_id,
          });
        });
      this.drilldownFilters.forEach((df) => {
        let htF = this.filterList.find((x) => x.ht_id === df.ht_id && x.level);
        if (htF) {
          htF.title += " -> " + df.value;
        } else {
          this.filterList.push({
            title: `${df.name} = ${df.value}`,
            name: df.name,
            ht_id: df.ht_id,
            level: df.level,
          });
        }
      });
      this.lvColumnsFiltered
        .filter(
          (x) =>
            x.filterSettings && x.filterSettings.values.some((v) => v.selected)
        )
        .forEach((f) => {
          let values = f.filterSettings.values.filter((v) => v.selected);
          this.filterList.push({
            title: `${f.text} = [${
              values.length > 3
                ? values.length + " items"
                : values.map((x) => x.text).join(", ")
            }]`,
            name: f.text,
            column: f,
          });
        });
      if (this.selectedView.definition.nameChangeFilters.length) {
        let defs = this.getNameChangeTypes();
        this.filterList.push({
          title: `Name Changes to: [${this.selectedView.definition.nameChangeFilters
            .map((x) => defs.find((d) => d.value === x).text)
            .join(", ")}]`,
          name: "Name Changes",
          column: "nameChangeFilters",
        });
      }
      if (this.selectedView.definition.viewType === "catalogue") {
        const appliedFilterCount = this.compareOptionsV.filter(
          (x) => x.filterValues?.length > 0
        ).length;
        this.compareOptionsV
          .filter((x) => x.listValues && x.listValues.length)
          .forEach((x) => {
            if (x.filterValues?.length && appliedFilterCount === 1) {
              x.listValuesFiltered = x.listValues.map((x) => x.value);
            } else if (x.column !== filterTrigger?.column) {
              x.listValuesFiltered = x.listValues
                .filter((v) =>
                  selectedDocs.some((d) => d[x.column] === v.value)
                )
                .map((x) => x.value);
            }
          });
        this.buildCompare();
      } else {
        this.buildGroupedDocs();
      }
      this.resetIsBuilding = true;
      if (!restoring) this.updateViewDefinition();
    },
    getAggregateMeasures() {
      let aggregateColumns = this.measures.filter(
        (m) => m.aggregation === "mean"
      );
      return aggregateColumns;
    },
    getMeasureColumns(visibleOnly) {
      let ms = this.measures.map((m) => m.type);
      if (visibleOnly)
        ms = ms.filter((m) => this.visibleMeasures.some((vm) => vm.type === m));
      if (this.display.showLeafCount) ms.push("leaf_node_count");
      return ms;
    },
    appendMeasureColumns(subject, defaultValue, source) {
      let item = subject || {};
      this.measures.map((m) => {
        item[m.type] =
          source && source[m.type] !== undefined
            ? Number(source[m.type])
            : defaultValue !== undefined
            ? defaultValue
            : null;
      });
      if (!subject) return item;
    },
    appendMatchColumns(subject, matchColumns, match_key) {
      let item = subject || {};
      item.match_key = match_key || "";
      item.matchColumns = matchColumns || [];
      item.selected = this.compareCriteria.some(
        (x) => x.match_key === item.match_key
      );
      if (!subject) return item;
    },
    appendAggregateColumns(subject, defaultValue, source) {
      let item = subject || {};
      this.measures
        .filter((m) => m.aggregation === "mean")
        .forEach((m) => {
          item[m.type + "_mean"] =
            source && source[m.type + "_mean"] !== undefined
              ? source[m.type + "_mean"]
              : defaultValue !== undefined
              ? defaultValue
              : null;
          item[m.type + "_mean_number"] =
            source && source[m.type + "_mean_number"] !== undefined
              ? Number(source[m.type + "_mean_number"])
              : defaultValue !== undefined
              ? defaultValue
              : null;
          item[m.type + "_mean_value_count"] =
            source && source[m.type + "_mean_value_count"] !== undefined
              ? Number(source[m.type + "_mean_value_count"])
              : defaultValue !== undefined
              ? defaultValue
              : null;
          item[m.type + "_std_dev"] =
            source && source[m.type + "_std_dev"] !== undefined
              ? Number(source[m.type + "_std_dev"])
              : defaultValue !== undefined
              ? defaultValue
              : null;
          item[m.type + "_category"] =
            source && source[m.type + "_category"] !== undefined
              ? source[m.type + "_category"]
              : null;
        });
      if (!subject) return item;
    },
    buildGroupedDocs() {
      let hchyCols = this.lvColumnsFiltered.filter((c) => c.ht_id);
      let sortCol = hchyCols.find((x) => x.level === 1)?.value;
      let sortFunc = (a, b) =>
        a[sortCol] > b[sortCol] ? 1 : a[sortCol] < b[sortCol] ? -1 : 0;
      let groupCols = this.lvColumnsFiltered
        .filter((x) => x.isAttribute || x.ht_id)
        .map((x) => x.value);
      hchyCols.forEach((x) => {
        groupCols.push(x.value + "nodeID");
      });
      let isGroupedView = this.selectedView.definition.viewType === "grouped";
      let groups = this.groupByKeys(
        this.selectedDocs,
        groupCols,
        this.getMeasureColumns(),
        null,
        this.getAggregateMeasures()
      );
      let groupedDocs = groups.rows.map((x) => {
        x.selected = this.compareCriteria.some(
          (c) => c.match_key === x.match_key
        );
        if (!isGroupedView) {
          hchyCols.forEach((c) => {
            let nextLevel = hchyCols.find(
              (h) => h.ht_id === c.ht_id && h.level === c.level + 1
            );
            let prevLevel = hchyCols.find(
              (h) => h.ht_id === c.ht_id && h.level === c.level - 1
            );
            let hierarchyDescriptor = c.usesDescriptor
              ? this.getHierarchyTypeNode(c.ht_id, x[c.value + "nodeID"])
              : null;
            let nodeRaw = this.getNodeData(c.ht_id, x[c.value + "nodeID"]);
            let node = {
              level: c.level,
              hierarchy_node_id: x[c.value + "nodeID"],
              //hierarchy_node_id_parent: prevLevel ? x[prevLevel.value + "nodeID"] : null,
              name: x[c.value],
              descriptor: hierarchyDescriptor,
              ht_id: c.ht_id,
              label: c.text,
              nextLevel: nextLevel ? nextLevel.text : null,
              previousLevel: prevLevel ? prevLevel.text : null,
              canAdd:
                !this.isDemo &&
                c.level ===
                  this.hierarchyTypes.find((y) => y.ht_id === c.ht_id)
                    .linklevel -
                    1,
              canAddDocumentType:
                !this.isDemo && !nextLevel && x.job_count === 0
                  ? this.catalogueDocType.docType
                  : null,
              reviewStatus: this.getHierarchyNodeReviewStatus(
                x[c.value + "nodeID"]
              ),
              show: false,
              showNodes: null,
              history: nodeRaw ? nodeRaw.transactions : [],
              nameHistory: nodeRaw ? nodeRaw.previous_names : [],
            };
            this.appendMeasureColumns(node);
            this.appendNodeHierarchyLevels(node);
            this.setNodeActiveStatus(node);
            x[c.value + "isNode"] = node;
          });
        }
        return x;
      });
      this.groupedDocs = groupedDocs.sort(sortFunc);
      this.listViewMatchKeys = groups.keyValues.map((x) => x.key);
      if (isGroupedView) {
        this.buildGroupedViewDocs();
      } else {
        this.measures.forEach((m) => {
          let mc = this.listView.columns.find((c) => c.value === m.type);
          if (mc && !mc.filterSettings.values.length) {
            mc.filterSettings.values = this.distinctValues(groupedDocs, [
              m.type,
            ])
              .map((x, xi) => {
                return {
                  text: x[m.type],
                  selected: false,
                  visible: true,
                  available: true,
                  count: x._count,
                  searchValues: [x[m.type]],
                  page: parseInt(xi / 7) + 1,
                };
              })
              .sort((a, b) =>
                Number(a.text) > Number(b.text)
                  ? 1
                  : Number(a.text) < Number(b.text)
                  ? -1
                  : 0
              );
            mc.filterSettings.pages =
              parseInt((mc.filterSettings.values.length - 1) / 7) + 1;
          }
          if (mc)
            groups.keyValues.push({
              key: m.type,
              values: mc.filterSettings.values.map((x) => x.text),
            });
        });
      }

      this.groupsKeyValues = groups.keyValues;
      this.resetIsBuilding = true;
    },
    openListviewFilter(filterSettings) {
      if (!filterSettings.show) {
        filterSettings.values
          .filter((v) => v.selected && !v.applied)
          .forEach((v) => (v.selected = false));
        filterSettings.values
          .filter((v) => !v.selected && v.applied)
          .forEach((v) => (v.selected = true));
        return;
      }
      filterSettings.values
        .filter((v) => !v.selected && v.applied)
        .forEach((v) => (v.applied = false));
      if (filterSettings.mostRecent) return;
      let usedValues = this.groupsKeyValues.find(
        (x) => x.key === filterSettings.column
      )?.values;
      let valueCount = 0;
      if (
        usedValues.length === filterSettings.values.length ||
        (this.activeFilterCount === 1 && filterSettings.isActive)
      ) {
        valueCount = filterSettings.values.length;
        filterSettings.values.forEach((x, xi) => {
          x.visible = true;
          x.page = parseInt(xi / 7) + 1;
        });
      } else {
        filterSettings.values.forEach((v) => {
          v.visible = usedValues.some((uv) => uv === v.text);
          v.page = v.visible ? parseInt(valueCount / 7) + 1 : 0;
          if (v.visible) valueCount++;
        });
      }
      filterSettings.pages = parseInt((valueCount - 1) / 7) + 1;
      filterSettings.page = 1;
    },
    buildFilterSettingsValues(keyValues) {
      this.lvColumnsFiltered
        .filter((c) => c.filterSettings)
        .forEach((c) => {
          let usedValues = keyValues.find((x) => x.key === c.value)?.values;
          let valueCount = 0;
          if (usedValues) {
            c.filterSettings.values.forEach((v) => {
              v.available = usedValues.some((uv) => uv === v.text);
              v.page = v.available ? parseInt(valueCount / 7) + 1 : 0;
              if (v.available) valueCount++;
            });
          } else {
            valueCount = c.filterSettings.values.length;
          }
          c.filterSettings.pages = parseInt((valueCount - 1) / 7) + 1;
          c.filterSettings.page = 1;
        });
    },
    buildGroupedViewDocs() {
      let groupedViewDetailColumns = this.listView.columns.filter(
        (x) => x.isAttribute || (x.ht_id && x.level === 1)
      );
      this.selectedView.definition.measuresVisible
        .map((m) => this.measures.find((x) => x.type === m))
        .forEach((m) => {
          groupedViewDetailColumns.push({
            text: m.abbreviation,
            value: m.type,
            sortable: true,
            cols: 1,
            measure: m,
          });
        });
      let groupedViewLevelColumns = this.listView.columns
        .filter((x) => x.ht_id === this.selectedView.definition.ht_id)
        .map((x) => {
          x.cols = 3;
          let ret = [x];
          if (this.selectedView.definition.groupedViewShowDescriptor) {
            ret.push({
              text: "Description",
              value: "description",
              isDescriptor: true,
              measure: null,
            });
          }
          this.measures
            .filter((m) =>
              this.selectedView.definition.measuresVisible.some(
                (x) => x === m.type
              )
            )
            .forEach((m) => {
              ret.push({
                text: this.selectedView.definition.groupedViewShowDescriptor
                  ? m.abbreviation
                  : m.title,
                value: m.type,
                sortable: true,
                cols: 1,
                measure: m,
              });
            });
          return ret;
        });
      this.groupedViewLevelColumns = groupedViewLevelColumns;
      this.groupedViewDetailColumns = groupedViewDetailColumns;
      let groupedViewDocs = this.getNodes(1, []);
      this.groupedViewDocs = groupedViewDocs;
      this.resetIsBuilding = true;
    },
    groupViewDrilldown(groupItem) {
      if (
        !groupItem.show &&
        !groupItem.nodes?.length &&
        !groupItem.rows?.length
      ) {
        this.getNodes(groupItem.level + 1, groupItem.filterKeys, groupItem);
      }
      groupItem.show = !groupItem.show;
      groupItem.node.show = !groupItem.node.show;
    },
    getNodes(level, parentKeys, parent, doNested) {
      if (level - 1 < this.groupedViewLevelColumns.length) {
        let levelCol = this.groupedViewLevelColumns[level - 1][0];
        let sk = levelCol.value;
        let sortFunc = (a, b) => (a[sk] > b[sk] ? 1 : a[sk] < b[sk] ? -1 : 0);
        let nodes = this.groupByKeys(
          !parent
            ? this.groupedDocs
            : this.groupedDocs.filter((x) =>
                parentKeys.every((k) => x[k.col] === k.value)
              ),
          [sk, sk + "nodeID"],
          this.getMeasureColumns(),
          null,
          this.getAggregateMeasures()
        )
          .rows.sort(sortFunc)
          .map((x) => {
            x.show = false;
            x.name = x[sk];
            x.label = levelCol.text;
            return x;
          });
        nodes.forEach((n) => {
          n.keyVal = (parent ? parent.keyVal : "") + n.keyVal;
          n.filterKeys = parentKeys.concat([{ col: sk, value: n[sk] }]);
          n.level = level;
          n.selected = false;
          n.nodes = [];
          n.rows = [];
          let nextLevel =
            this.groupedViewLevelColumns.length > level
              ? this.groupedViewLevelColumns[level][0]
              : null;
          let prevLevel =
            level > 1 ? this.groupedViewLevelColumns[level - 2][0] : null;
          let hierarchyDescriptor = levelCol.usesDescriptor
            ? this.getHierarchyTypeNode(levelCol.ht_id, n[sk + "nodeID"])
            : null;
          let nodeRaw = this.getNodeData(levelCol.ht_id, n[sk + "nodeID"]);
          let node = {
            level: n.level,
            hierarchy_node_id: n[sk + "nodeID"],
            name: n[sk],
            descriptor: hierarchyDescriptor,
            ht_id: levelCol.ht_id,
            label: n.label,
            nextLevel: nextLevel ? nextLevel.text : null,
            previousLevel: prevLevel ? prevLevel.text : null,
            canAdd:
              !this.isDemo &&
              n.level ===
                this.hierarchyTypes.find((y) => y.ht_id === levelCol.ht_id)
                  .linklevel -
                  1,
            canAddDocumentType:
              !this.isDemo && !nextLevel && n.job_count === 0
                ? this.catalogueDocType.docType
                : null,
            reviewStatus: this.getHierarchyNodeReviewStatus(n[sk + "nodeID"]),
            show: false,
            filterKeys: n.filterKeys,
            showNodes: null,
            history: nodeRaw ? nodeRaw.transactions : [],
            nameHistory: nodeRaw ? nodeRaw.previous_names : [],
          };
          this.appendMeasureColumns(node);
          this.appendAggregateColumns(node, 0, n);
          this.appendNodeHierarchyLevels(node, n);
          this.setNodeActiveStatus(node);
          n.node = node;
          if (doNested) {
            this.getNodes(level + 1, n.filterKeys, n, doNested);
          }
        });
        if (this.selectedView.definition.nodeSortProperty) {
          nodes.sort(
            (a, b) =>
              b[this.selectedView.definition.nodeSortProperty] -
              a[this.selectedView.definition.nodeSortProperty]
          );
        }
        if (parent) {
          parent.nodes = nodes;
        } else {
          return nodes;
        }
      } else {
        parent.rows = this.groupedDocs
          .filter((x) => parentKeys.every((k) => x[k.col] === k.value))
          .map((x) => {
            x.filterKeys = parentKeys.concat(
              this.groupedViewDetailColumns
                .filter((c) => c.isAttribute)
                .map((c) => {
                  return { col: c.value, value: x[c.value] };
                })
            );
            x.level = 999;
            return x;
          });
      }
    },
    removeDrilldownFilter(item) {
      let f = this.drilldownFilters.findIndex(
        (x) => x.column === item.column && x.value === item.value
      );
      if (f < this.drilldownFilters.length - 1)
        this.removeFilter(this.drilldownFilters[f + 1]);
    },
    removeFilter(f) {
      [this.compareOptionsV, this.compareOptionsH].forEach((o) => {
        let fl = o.find((x) => x.name === f.name);
        if (fl) {
          fl.filterValues = [];
          if (fl.currentDrillDownLevel !== undefined)
            fl.currentDrillDownLevel = 1;
        }
      });
      let hfilter = this.hierarchyFilters.find((x) => x.ht_id === f.ht_id);
      if (hfilter) {
        hfilter.selected = [];
        this.setPreSelectHierarchyFilters();
      }
      let lvfilter = this.lvColumnsFiltered.find(
        (x) => x.value === f.column?.value
      );
      if (lvfilter) {
        lvfilter.filterSettings.values.forEach((x) => {
          x.selected = false;
          x.applied = false;
        });
        lvfilter.filterSettings.isActive = false;
      }
      if (
        this.drilldownFilters.length &&
        f.level &&
        this.drilldownFilters.some((x) => x.ht_id === f.ht_id)
      ) {
        this.drilldownFilters = this.drilldownFilters.filter(
          (x) => x.ht_id !== f.ht_id
        );
        this.compareOptionsV
          .filter((x) => x.ht_id === f.ht_id && x.hLevel > 1)
          .forEach((x) => (x.hidden = true));
        this.compareOptionsH
          .filter((x) => x.ht_id === f.ht_id && x.hLevel > 1)
          .forEach((x) => (x.hidden = true));

        let hcoIndex = this.horCompareOptions.findIndex(
          (x) => x.ht_id === f.ht_id
        );
        if (hcoIndex >= 0) {
          let hco = this.horCompareOptions[hcoIndex];
          if (hco.hLevel) {
            hco = this.compareOptionsH.find(
              (x) => x.ht_id === f.ht_id && x.hLevel === 1
            );
            this.horCompareOptions.splice(hcoIndex, 1, hco);
          }
          if (hco.currentDrillDownLevel) {
            hco.currentDrillDownLevel = 1;
            this.selectedView.definition.horCompareOptions[hcoIndex] =
              this.getViewCompareOption(hco);
          }
        }
        let vcoIndex = this.verCompareOptions.findIndex(
          (x) => x.ht_id === f.ht_id
        );
        if (vcoIndex >= 0) {
          let vco = this.verCompareOptions[vcoIndex];
          if (vco.hLevel) {
            vco = this.compareOptionsV.find(
              (x) => x.ht_id === f.ht_id && x.hLevel === 1
            );
            this.verCompareOptions.splice(vcoIndex, 1, vco);
          }
          if (vco.currentDrillDownLevel) {
            vco.currentDrillDownLevel = 1;
            this.selectedView.definition.verCompareOptions[vcoIndex] =
              this.getViewCompareOption(vco);
          }
        }
      }
      if (f.column === "nameChangeFilters") {
        this.selectedView.definition.nameChangeFilters.splice(
          0,
          this.selectedView.definition.nameChangeFilters.length
        );
      }
      this.updateViewDefinition();
      this.selectJDs();
    },
    doDrilldown(item, compareOptionName, restoring) {
      if (!item.drilldown) return;
      let compareOption = this[compareOptionName].find((x) => x.ht_id);
      let viewCompareOption = this.selectedView.definition[
        compareOptionName
      ].find((x) => x.column === item.drilldown.column);
      let cols =
        item.dIndexEnd === undefined
          ? []
          : this.layout.detailRow.rowDetails
              .find(
                (x) =>
                  x.dIndexStart === item.dIndexStart &&
                  x.dIndexEnd === item.dIndexEnd
              )
              .columns.filter((x) => this.measures.some((m) => x[m.type]))
              .map((x) => {
                let matchColumns = [
                  this.getColumnName(compareOption),
                  x.col.column,
                ];
                let ret = {
                  cols: x.cols,
                  visible: true,
                  keyVal: x.keyVal,
                };
                this.appendMatchColumns(
                  ret,
                  matchColumns,
                  this.buildMatchKey(
                    matchColumns[0],
                    item.drilldown.value + "||" + x.keyVal
                  )
                );
                this.appendMeasureColumns(ret, 0, x);
                this.appendAggregateColumns(ret, 0, x);
                return ret;
              });
      this.drilldownFilters
        .filter(
          (x) =>
            x.ht_id === compareOption.ht_id &&
            x.columns?.length &&
            x.compareOptionName === compareOptionName
        )
        .forEach((x) => {
          x.columns.forEach(
            (c) => (c.visible = cols.some((v) => v.keyVal === c.keyVal))
          );
        });
      let f = {
        column: this.getColumnName(compareOption),
        value: item.drilldown.value,
        ht_id: compareOption.ht_id,
        level: compareOption.currentDrillDownLevel,
        name: compareOption.name,
        leaf_node_count: item.leaf_node_count,
        columns: cols,
        compareOptionName: compareOptionName,
      };
      this.appendMatchColumns(
        f,
        [this.getColumnName(compareOption)],
        this.buildMatchKey(
          this.getColumnName(compareOption),
          item.drilldown.value
        )
      );
      this.appendMeasureColumns(f, 0, item);
      this.appendAggregateColumns(f, 0, item);
      this.drilldownFilters.push(f);
      compareOption.currentDrillDownLevel++;
      viewCompareOption.currentDrillDownLevel =
        compareOption.currentDrillDownLevel;
      this.selectJDs(restoring);
    },
    removeGroupHeader(
      ghRow,
      compareOptionName,
      optionsCollection,
      option,
      optionIndex
    ) {
      let compareOption = this[optionsCollection].find(
        (x) => x.column === option.column
      );
      let toRemove = this.drilldownFilters
        .filter(
          (x) =>
            x.ht_id === ghRow.ht_id &&
            x.level >= ghRow.level &&
            x.compareOptionName === compareOptionName
        )
        .map((x) => x.level);
      toRemove.forEach((x) => {
        let i = this.drilldownFilters.findIndex(
          (f) =>
            f.ht_id === ghRow.ht_id &&
            f.level === x &&
            f.compareOptionName === compareOptionName
        );
        this.drilldownFilters.splice(i, 1);
      });
      if (ghRow.columns) {
        // vgroupHeader
        let currentFilter = this.drilldownFilters.find(
          (f) =>
            f.ht_id === ghRow.ht_id &&
            f.level === ghRow.level - 1 &&
            f.compareOptionName === compareOptionName
        );
        if (currentFilter)
          this.drilldownFilters
            .filter(
              (x) =>
                x.ht_id === compareOption.ht_id &&
                x.columns?.length &&
                x.compareOptionName === compareOptionName
            )
            .forEach((x) => {
              x.columns.forEach(
                (c) =>
                  (c.visible = currentFilter.columns.some(
                    (v) => v.keyVal === c.keyVal
                  ))
              );
            });
      }
      if (compareOption.currentDrillDownLevel !== undefined) {
        compareOption.currentDrillDownLevel = ghRow.level;
        this.selectedView.definition[compareOptionName][
          optionIndex
        ].currentDrillDownLevel = compareOption.currentDrillDownLevel;
      }
      this.selectJDs();
    },
    buildCompare() {
      if (this.isLoadingData) return;
      if (!this.selectedDocs?.length) {
        this.layout = null;
      } else {
        this.isBuilding = true;
        let docs = this.selectedDocs;
        let hCompare = this.horCompareOptions;
        let vCompare = this.verCompareOptions;
        let zCompare = this.detCompareOptions.length
          ? this.detCompareOptions[0]
          : null;
        let hideVerticalAxis = vCompare.length === 0;
        if (hideVerticalAxis) {
          vCompare = [{ column: "_", name: "Hidden", hidden: true }];
        }
        let hideHorizontalAxis = hCompare.length === 0;
        if (hideHorizontalAxis) {
          hCompare = [{ column: "_", name: "Hidden", hidden: true }];
        }
        if (!hCompare.length || !vCompare.length) return;
        this.dragTarget.show = zCompare?.isLeafNode;
        let isBreakdown = vCompare.some((x) => x.isBreakdown);
        this.visibleMeasures = this.selectedView.definition.measuresVisible.map(
          (m) => this.measures.find((x) => x.type === m)
        );

        this.display.showLeafCount =
          vCompare.length === 1 &&
          !!vCompare[0].ht_id &&
          !!vCompare[0].currentDrillDownLevel &&
          !hideVerticalAxis;
        if (!this.display.showLeafCount) {
          docs = docs.filter((x) => x.job_count);
        }
        let setComparerRule = (comparer) => {
          let ht =
            comparer.ht_id && !comparer.isHierarchyTree
              ? this.hierarchyTypes.find((ht) => ht.ht_id === comparer.ht_id)
              : null;
          let nextLevel =
            ht &&
            comparer.drillDownLevels &&
            comparer.drillDownLevels.length > comparer.currentDrillDownLevel
              ? comparer.drillDownLevels.find(
                  (dl) => dl.hLevel === comparer.currentDrillDownLevel + 1
                )
              : null;
          let prevLevel =
            ht && comparer.drillDownLevels && comparer.currentDrillDownLevel > 1
              ? comparer.drillDownLevels.find(
                  (dl) => dl.hLevel === comparer.currentDrillDownLevel - 1
                )
              : null;
          let currLevel =
            ht && comparer.drillDownLevels
              ? comparer.drillDownLevels.find(
                  (dl) => dl.hLevel === comparer.currentDrillDownLevel
                )
              : null;
          if (ht) {
            comparer.noderule = {
              ht: ht,
              nextLevel: nextLevel,
              prevLevel: prevLevel,
              currLevel: currLevel,
            };
          }
        };

        vCompare.forEach((c) => setComparerRule(c));
        hCompare.forEach((c) => setComparerRule(c));
        this.detCompareOptions.forEach((c) => setComparerRule(c));
        let axisList = [vCompare.filter((x) => x.column)];
        hCompare.filter((x) => x.column).forEach((x) => axisList.push([x]));
        //axisList.push(hCompare.filter((x) => x.column));
        if (this.detCompareOptions.length)
          axisList.push(this.detCompareOptions);
        let sumDataV = this.groupByKeysNested(
          docs,
          axisList,
          this.getMeasureColumns(),
          null,
          this.getAggregateMeasures()
        );
        let vCols = sumDataV[sumDataV.collectionName];
        let sumDataH = this.groupByKeysNested(
          docs,
          hCompare.filter((x) => x.column).map((x) => [x]),
          //   [hCompare.filter((x) => x.column)],
          this.getMeasureColumns(),
          null,
          this.getAggregateMeasures()
        );
        let hCols = sumDataH[sumDataH.collectionName].filter(
          (x) => x.job_count
        );
        let setSDCategory = (item, mean, stdev, column) => {
          let value = Number(item[column + "_mean_number"]);
          item[column + "_category"] =
            value < mean - stdev
              ? "_low"
              : value > mean + stdev
              ? "_high"
              : "_mid";
          item.categories.forEach((cat) => {
            cat[column + "_category"] =
              cat[column + "_mean_number"] < mean - stdev
                ? "_low"
                : cat[column + "_mean_number"] > mean + stdev
                ? "_high"
                : "_mid";
          });
        };
        let hIsHierarchyTree = hCompare[0].isHierarchyTree;
        let vIsHierarchyTree = vCompare[0].isHierarchyTree;
        let groupColWidths = (compareOptions, padding) => {
          let columns = compareOptions.map((o) => {
            let c = {
              length:
                o.maxLength -
                0.4 * (o.maxLength - (o.maxLengthMean || o.maxLength)) +
                padding +
                (o.noderule ? 6 : 0) +
                (o.noderule?.nextLevel ? 6 : 0) +
                (o.noderule?.currLevel?.usesDescriptor ? 6 : 0),
              percent: "",
              cols: 0,
            };
            if (o.noderule?.prevLevel && c.length < 50) c.length = 50;
            return c;
          });
          const total = columns.reduce((p, c) => p + c.length, 0);
          columns.forEach((w) => {
            w.percent = (100 * w.length) / total + "%";
            w.cols = Math.round((12 * w.length) / total) || 1;
          });
          if (columns.length) {
            while (columns.reduce((p, c) => p + c.cols, 0) > 12) {
              let index = columns.findIndex(
                (v) =>
                  v.cols ===
                  columns.reduce((p, c) => (c.cols > p ? c.cols : p), 0)
              );
              columns[index].cols -= 1;
            }
            while (columns.reduce((p, c) => p + c.cols, 0) < 12) {
              let index = columns.findIndex(
                (v) =>
                  v.cols ===
                  columns.reduce(
                    (p, c) => (c.cols > 0 && c.cols < p ? c.cols : p),
                    12
                  )
              );
              columns[index].cols += 1;
            }
          }
          return {
            columns: columns,
            totalWidth: total,
          };
        };
        let dWidths = groupColWidths(this.detCompareOptions, 6);
        let vWidths = groupColWidths(vCompare, 6);
        let vMWidths = groupColWidths(
          this.getMeasureColumns(true).map((m) => {
            const maxLen = vCols.reduce(
              (p, v) => (v[m + "maxLength"] > p ? v[m + "maxLength"] : p),
              0
            );
            return {
              maxLength: maxLen,
            };
          }),
          8
        );
        let vSectionWidth = groupColWidths(
          [
            { maxLength: vWidths.totalWidth },
            { maxLength: vMWidths.totalWidth },
          ],
          0
        );
        let hcForWidth = hCompare.length ? hCompare[hCompare.length - 1] : null;
        let hTextMax = hcForWidth
          ? hcForWidth.maxLengthMean +
            (hcForWidth.maxLength - hcForWidth.maxLengthMean) / 2
          : 0;
        this.deriveCols(hTextMax, dWidths.totalWidth);
        let standardCols = [
          { title: "", isHeader: true, cols: 1, leftPanel: true },
          { title: "#Docs #Emps", isHeader: true, cols: 1, leftPanel: true },
        ];
        if (vCompare.some((x) => x.showSimCount)) {
          standardCols.push({
            title: "#Similar JDs",
            isHeader: true,
            cols: 1,
            leftPanel: true,
          });
        }
        let title = this.detCompareOptions.length
          ? this.detCompareOptions.map((x) => x.name).join(", ") + " within "
          : "";
        title +=
          vCompare.map((x) => x.name).join(", ") +
          (title.length ? " and " : " by ") +
          hCompare.map((x) => x.name).join(", ");
        title +=
          " - " +
          this.selectedDocs.length +
          " documents" +
          (_documents.length > this.selectedDocs.length ? " (filtered)" : "");
        const maxHLeafs = 100;
        let usedHLeafs = 0;
        let expandSubHeaders = (parent, rowHeading) => {
          let leafCount = 0;
          if (parent.collectionName) {
            // rowHeading.children = parent[parent.collectionName];
            // rowHeading.children.forEach((c) => {
            //     c.rowHeadings.forEach(
            //       (rh) => (leafCount += expandSubHeaders(c, rh))
            //     );
            // });
            rowHeading.children = parent[parent.collectionName].filter((c) => {
              if (usedHLeafs >= maxHLeafs) return false;
              c.rowHeadings.forEach(
                (rh) => (leafCount += expandSubHeaders(c, rh))
              );
              return true;
            });
            rowHeading.include = rowHeading.children.length > 0;
          } else {
            leafCount = 1;
            usedHLeafs++;
            rowHeading.include = usedHLeafs <= maxHLeafs;
          }
          rowHeading.leafCount = leafCount;
          return leafCount;
        };
        let setSubHeaderWidths = (rowHeading, leafCount) => {
          if (!rowHeading.children) return;
          const lastIndex = rowHeading.children.length - 1;
          rowHeading.children.forEach((c, ci) => {
            const width =
              (100 * c.rowHeadings.reduce((p, c) => p + c.leafCount, 0)) /
              leafCount;
            c.showLine = false;
            c.colCSS = {
              maxWidth: width + "%",
              paddingLeft: (ci === 0 ? 0 : 5) + "px",
              paddingRight: (ci === lastIndex ? 0 : 5) + "px",
            };
            if (
              lastIndex > 0 &&
              ci !== lastIndex &&
              c.rowHeadings.some((h) => h.children?.length)
            ) {
              c.showLine = true;
            }
            c.rowHeadings.forEach((rh) =>
              setSubHeaderWidths(rh, rh.leafCount || 1)
            );
          });
        };
        let copySubHeaderWidths = (source, target) => {
          if (!source.children) return;
          source.children.forEach((c, ci) => {
            if (target.children.length > ci) {
              target.children[ci].colCSS = c.colCSS;
              target.children[ci].showLine = c.showLine;
              c.rowHeadings.forEach((rh, rhi) =>
                copySubHeaderWidths(rh, target.children[ci].rowHeadings[rhi])
              );
            }
          });
        };
        hCols.forEach((h) => {
          h.headerColumns = h.rowHeadings.map((x) => {
            expandSubHeaders(h, x);
            return x;
          });
          h.colWidth =
            this.display.h_col.colWidth * h.headerColumns[0].leafCount;
          h.headerColumns.forEach((hc) => setSubHeaderWidths(hc, hc.leafCount));
        });
        hCols = hCols.filter((x) => x.headerColumns.some((c) => c.include));
        if (usedHLeafs >= maxHLeafs) {
          this.response = {
            Status: "Warning",
            Message:
              "WARNING: The horizontal axis has exceeded the maximum number of columns (" +
              maxHLeafs +
              ") so columns have been truncated. Apply filters to reduce the number of columns",
          };
        } else {
          this.response = null;
        }
        let maxCategories = 0;
        let dMeasuresLengths = this.visibleMeasures.map((m) => {
          return { column: m.column, maxLength: 0, maxLengthMean: 0 };
        });
        let expandSubColumns = (
          parent,
          rowHeading,
          parentDataContainer,
          recurseLevel
        ) => {
          if (!recurseLevel) recurseLevel = 0;
          if (
            recurseLevel < this.horCompareOptions.length &&
            this.horCompareOptions.some(
              (ho) => ho.column + "s" === parent.collectionName
            )
          ) {
            rowHeading.children = parent[parent.collectionName]
              .filter((xr) => xr.rowHeadings.some((h) => h.include))
              .map((xr) => {
                let newData = null;
                let existing = parentDataContainer
                  ? parentDataContainer[parent.collectionName].find(
                      (r) => r.keyVal === xr.keyVal
                    )
                  : null;
                if (!existing) {
                  newData = JSON.parse(JSON.stringify(xr));
                  this.appendMeasureColumns(newData, 0);
                  newData.rowHeadings.forEach((rh) =>
                    this.appendMeasureColumns(rh, 0)
                  );
                }
                (existing || newData).rowHeadings
                  .filter((h, hi) => xr.rowHeadings[hi].include)
                  .forEach((rh) => {
                    expandSubColumns(xr, rh, existing, recurseLevel + 1);
                  });
                return existing || newData;
              });
          } else if (
            parentDataContainer?.collectionName ===
            this.detCompareOptions.map((x) => x.column).join("_") + "s"
          ) {
            // the detail axis
            rowHeading.categories = parentDataContainer[
              parentDataContainer.collectionName
            ]
              .filter((c) => {
                return c.job_count;
              })
              .sort((a, b) => {
                return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
              });
            if (rowHeading.categories.length > maxCategories)
              maxCategories = rowHeading.categories.length;

            rowHeading.categories.forEach((c) =>
              c.rowHeadings.forEach((h, hi) => {
                h.colWidthPct = dWidths.columns[hi].percent;
                h.colWidth = dWidths.columns[hi].cols;
              })
            );
            dMeasuresLengths.forEach((mm) => {
              const mLen = rowHeading.categories.reduce(
                (p, c) =>
                  c[mm.column + "maxLength"] > p
                    ? c[mm.column + "maxLength"]
                    : p,
                0
              );
              if (mLen > mm.maxLength) {
                mm.maxLength = mLen;
                mm.maxLengthMean = mLen;
              }
            });
          }
        };
        let getHColumns = (row) => {
          return hCols.map((g) => {
            let data = row.collectionName
              ? row[row.collectionName].find((x) => x.keyVal === g.keyVal)
              : g;
            maxCategories = 0;
            let det = {
              cols: 1 * this.display.h_col.colWidth,
              keyVal: data?.keyVal,
              subColumns: g.rowHeadings
                .filter((x) => x.include)
                .map((x) => {
                  let sc = data?.rowHeadings.find((xr) => xr.value === x.value);
                  if (!sc) {
                    sc = JSON.parse(JSON.stringify(x));
                    this.appendMeasureColumns(sc, 0);
                    this.appendAggregateColumns(sc, 0);
                  }
                  expandSubColumns(g, sc, data);
                  this.getAggregateMeasures().forEach((m) => {
                    setSDCategory(
                      sc,
                      row[m.type + "_mean_number"],
                      row[m.type + "_std_dev"],
                      m.type
                    );
                  });
                  return sc;
                }),
            };
            det.maxDetailCount = maxCategories;
            return det;
          });
        };

        let rowHeadings = [];
        let rowDetails = [];
        vCols.forEach((v) => {
          let rh = {
            headerColumns: v.rowHeadings,
            sortKey: v.sortKey,
            leaf_node_count: v.leaf_node_count,
          };
          this.appendMatchColumns(rh, v.matchColumns, v.match_key);
          this.appendMeasureColumns(rh, "", v);
          this.appendAggregateColumns(rh, "", v);

          rowHeadings.push(rh);
          rowDetails.push({
            row: v,
            sortKey: v.sortKey,
            columns: getHColumns(v),
          });
        });
        rowHeadings.forEach((x) => {
          x.headerColumns.forEach((c) => {
            c.visible = true;
          });
        });
        let dMWIdths = groupColWidths(dMeasuresLengths, 6);

        let rowDetailsFlat = !isBreakdown
          ? []
          : rowDetails.reduce((p, c) => {
              if (!p.length) {
                p = c.columns;
              } else {
                c.columns.forEach((col, ci) => {
                  if (this.measures.some((m) => col[m.type])) p[ci] = col;
                });
              }
              return p;
            }, []);
        //  -------------------------------------------------------------------------------------------------------------------------------------
        //  title / filters applied list
        //  -------------------------------------------------------------------------------------------------------------------------------------
        //  headerRow
        //  | leftPanel.cols                                                                    | rightPanel.cols                               |
        //  |-----------------------------------------------------------------------------------|-----------------------------------------------|
        //  ||lp.colsHierarchy + lp.colsTitle       | lp.colsDescriptor   |   lp.colsTotal     |||  <columnPicker>                             ||
        //  || <columnPicker>                       |    "Descriptor"     |     "TOTAL"        ||                                               |
        //  |-----------------------------------------------------------------------------------|[--------for-hierarchyHeaderRows---------------|
        //  ||lp.colsHierarchy + lp.colsTitle       | lp.colsDescriptor   |   lp.colsTotal     || [|          for columns                     |]|
        //  ||                                      |                     |--------------------||]                                              |
        //  ||                                      |                     || lc | jc | ec | s  ||-----------------------------------------------|
        //  |                                                                                   |[|         for headerRow.columns             |]|
        //  -------------------------------------------------------------------------------------------------------------------------------------
        //  groupHeaderRow
        //  | leftPanel.cols                                                                    | rightPanel.cols                               |
        //  |[--for-groupHeaderRow.vRows--------------------------------------------------------|[--for-groupHeaderRow.vRows--------------------|
        //  | |lp.colsTitle                         | lp.colsDescriptor   |   lp.colsTotal     || [|         for columns                       ||
        //  | |                                     |                     |--------------------||  | ...if  | ...if  | ...if  | ...if | ...if  ||
        //  | |                                     |                     || lc | jc | ec | s  || ]                                             |
        //  |]                                                                                  |]                                              |
        //  -------------------------------------------------------------------------------------------------------------------------------------
        //  detailRow
        //  | leftPanel.cols                                                                    | rightPanel.col                                |
        //  |-----------------------------------------------------------------------------------|                                               |
        //  | if lp.colsHierarchy          |                                                    |                                               |
        //  |[--for dr.hierarchyHeadings---|[---for-dr.rowHeadings------------------------------|-----------------------------------------------|
        //  | | -> nested hierarchy       || | lp.colsTitle |lp.colsDescDe| lp.colsTotalDetail |||[---for-dr.rowDetails]-----------------------||
        //  |]                             |]                             |--------------------||| [|         for columns                     |||
        //  |                              |                              || lc | jc | ec | s  |||  |----...if--------------------------------|||
        //  |                              |                              |[----for-categories-|||  || ...if | ...if | ...if | ...if | ...if ||||
        //  |                              |                              | |  jc  |  ec  |  s |||  |[---for-categories-----------------------|||
        //  |                              |                              |]                   |||  | | ...if | ...if | ...if | ...if | ..if ||||
        //  |                              |                              |[----for-detCntHdr--|||  |]                                        |||
        //  |                              |                              | |  jc  |  ec  |  s ||| ]                                          |||
        //  |                              |                              |]                   |||]                                           |||
        //  -------------------------------------------------------------------------------------------------------------------------------------
        //  scroll row
        //  | leftPanel.cols                                                                    | rightPanel.cols                               |
        //  |                                                                                   |-----------------------------------------------|
        //  |                                                                                   |[|         for headerRow.columns             |]|
        //  -------------------------------------------------------------------------------------------------------------------------------------
        const lenVCols = vWidths.totalWidth;
        const lenMCols = vMWidths.totalWidth;
        const lenLeftCols = lenVCols + lenMCols;
        let leftPanelCols = vIsHierarchyTree
          ? 8
          : lenLeftCols > 150 && lenLeftCols < 180
          ? 5
          : Math.round(lenLeftCols / 28) || 1;
        if (leftPanelCols === 1 && lenLeftCols > 28) leftPanelCols = 2;
        if (leftPanelCols > 11) leftPanelCols = 11;
        let leftPanelColsT =
          vIsHierarchyTree || !vCompare.length
            ? 0
            : Math.round((12 * lenVCols) / lenLeftCols);
        if (
          this.selectedView.definition.measureDisplayType === "bar" &&
          !vIsHierarchyTree
        )
          leftPanelColsT = 4;
        let leftPanelColsH = vIsHierarchyTree ? 10 : 0;
        if (hideHorizontalAxis) leftPanelCols = 12;
        else {
          if (hideVerticalAxis) leftPanelCols = 0;
        }
        let leftPanelColsTotal =
          vMWidths.columns.length === 0
            ? 0
            : leftPanelCols > 0
            ? 12 - leftPanelColsH - leftPanelColsT
            : 0;
        if (leftPanelColsTotal === 0) leftPanelColsT = 12;

        let horDrilldownFilters = this.drilldownFilters.filter(
          (x) =>
            hCompare.some((v) => v.ht_id === x.ht_id) &&
            x.compareOptionName === "horCompareOptions"
        );
        let verDrilldownFilters = this.drilldownFilters.filter(
          (x) =>
            vCompare.some((v) => v.ht_id === x.ht_id) &&
            x.compareOptionName === "verCompareOptions"
        );
        let layout = {
          title: title,
          leftPanel: {
            cols: leftPanelCols,
            colsTitle: leftPanelColsT,
            colsTotal: leftPanelColsTotal,
            colsTotalDetail: vIsHierarchyTree ? 12 : leftPanelColsTotal,
            colsHierarchy: leftPanelColsH,
            colsDescriptor: 0,
            colsDescriptorDetail: vIsHierarchyTree ? 6 : 0,
          },
          rightPanel: {
            cols: !hideHorizontalAxis ? 12 - leftPanelCols : 0,
            text: "",
          },
          hierarchyHeaderRows: hIsHierarchyTree
            ? Array.from(Array(hCompare[0].linklevel - 1)).map((hr, hri) => {
                let row = {
                  columns: [],
                  ref: "colhead" + hri,
                };
                let expansionFactor = this.display.h_col.colWidth;
                hCols.forEach((c) => {
                  let hval = c.sortKey.split(" > ")[hri];
                  if (
                    row.columns.length &&
                    row.columns[row.columns.length - 1].text === hval
                  ) {
                    row.columns[row.columns.length - 1].cols += expansionFactor;
                    row.columns[row.columns.length - 1].hItems.push(
                      c.ht_id ? c["ht_id_" + c.ht_id] : hval
                    );
                  } else {
                    row.columns.push({
                      text: hval,
                      isHeader: true,
                      cols: expansionFactor,
                      class: "title_cell",
                      hItems: [c.ht_id ? c["ht_id_" + c.ht_id] : hval],
                      hierarchyDescriptor: this.getHierarchyTypeNode(
                        c.ht_id,
                        c.hierarchy_node_id
                      ),
                      reviewStatus: this.getHierarchyNodeReviewStatus(
                        c.hierarchy_node_id
                      ),
                    });
                  }
                });
                return row;
              })
            : [],
          headerRow: {
            ref: "colhead",
            columns: hCols.map((h, hi) => {
              let colCSS = { minWidth: 150 * h.colWidth + "px" };
              if (hCompare.length > 1 && hi > 0)
                colCSS.borderLeft = hCompare.length + "px solid lightgray";
              if (hi === 0 && horDrilldownFilters.length)
                colCSS.paddingLeft = 20 * horDrilldownFilters.length + "px";
              rowDetails.forEach((rd) => {
                rd.columns[hi].cols = h.colWidth;
                rd.columns[hi].colCSS = colCSS;
                copySubHeaderWidths(
                  h.headerColumns[0],
                  rd.columns[hi].subColumns[0]
                );
              });
              return {
                title: h.title,
                cols: h.colWidth,
                colCSS: colCSS,
                class: h.node ? null : "title_cell",
                headerColumns: h.headerColumns,
              };
            }),
          },
          groupHeaderRow: {
            vRows: verDrilldownFilters.map((x) => {
              let r = {
                value: x.value,
                name: x.name,
                level: x.level,
                ht_id: x.ht_id,
                sortKey: x.sortKey,
                columns: getHColumns(x),
                leaf_node_count: x.leaf_node_count,
              };
              this.appendMeasureColumns(r, 0, x);
              this.appendMatchColumns(r, x.matchColumns, x.match_key);
              this.appendAggregateColumns(r, "", x);
              return r;
            }),
            ref: "grouphead",
            hRows: horDrilldownFilters,
          },
          detailRow: {
            rowHeadings: rowHeadings,
            hierarchyHeadings: vIsHierarchyTree ? { levels: [] } : null,
            rowDetails: rowDetails,
            rowHeading: {
              visible: vWidths.columns.length > 0,
              titleSectionWidthPct: vSectionWidth.columns[0].percent,
              measureSectionWidthPct: vSectionWidth.columns[1].percent,
              titleSectionWidthCols: vSectionWidth.columns[0].cols,
              measureSectionWidthCols: vSectionWidth.columns[1].cols,
              titleWidths: vWidths.columns,
              measureWidths: vMWidths.columns,
            },
            detail: {
              visible: dWidths.columns.length > 0,
              titleSectionWidth:
                (100 * dWidths.totalWidth) /
                  (dWidths.totalWidth + dMWIdths.totalWidth) +
                "%",
              measureSectionWidth:
                (100 * dMWIdths.totalWidth) /
                  (dWidths.totalWidth + dMWIdths.totalWidth) +
                "%",
              titleWidths: dWidths.columns,
              measureWidths: dMWIdths.columns,
              widthSplitMeasures: dMWIdths.columns.map(x => x.length / (dWidths.totalWidth + dMWIdths.totalWidth)),
              widthSplitTitle: dWidths.totalWidth / (dWidths.totalWidth + dMWIdths.totalWidth),
            },
          },
          breakdownRow: {
            columns: rowDetailsFlat,
            hierarchyHeadings: vIsHierarchyTree ? { levels: [] } : null,
            rowDetails: rowDetails,
          },
          //   footerRow: {},
        };
        if (layout.groupHeaderRow.vRows.length) {
          hCols.forEach((h, hi) => {
            layout.groupHeaderRow.vRows.forEach((r) => {
              r.columns[hi].cols = h.colWidth;
              copySubHeaderWidths(
                h.headerColumns[0],
                r.columns[hi].subColumns[0]
              );
            });
          });
        }
        let dIndex = 0;
        // set row title height
        layout.detailRow.rowHeadings.forEach((x, xi) => {
          x.detailCount = this.detCompareOptions.length
            ? layout.detailRow.rowDetails[xi].columns.reduce(
                (p, c) => (c.maxDetailCount > p ? c.maxDetailCount : p),
                0
              )
            : 0;
          x.detailCountHeader = Array.from(
            { length: x.detailCount - 1 },
            (v, i) => i
          ).map((i) => {
            return { dIndex: dIndex + i + 1 };
          });

          x.dIndexStart = dIndex;
          let setCategoryIndexes = (item, index) => {
            if (item.children) {
              item.children.forEach((c) =>
                c.rowHeadings.forEach((h) => setCategoryIndexes(h, index))
              );
            } else if (item.categories) {
              item.categories.forEach((cc, cci) => (cc.dIndex = index + cci));
            }
          };
          if (x.detailCount) {
            let i =
              dIndex +
              (this.detCompareOptions.length === 0 ||
              !layout.detailRow.detail.titleWidths.length
                ? 1
                : 0);
            layout.detailRow.rowDetails[xi].columns.forEach((c) =>
              c.subColumns.forEach((sc) => setCategoryIndexes(sc, i))
            );
            dIndex += x.detailCount - 1;
          }
          x.dIndexEnd = dIndex < 1 ? 0 : dIndex;
          layout.detailRow.rowDetails[xi].dIndexStart = x.dIndexStart;
          layout.detailRow.rowDetails[xi].dIndexEnd = x.dIndexEnd;
          dIndex++;
        });
        this.vScroll.dMax = dIndex - 1;

        // derive hierarchy children for layout
        if (layout.hierarchyHeaderRows.length) {
          let brow = layout.headerRow;
          for (let i = layout.hierarchyHeaderRows.length - 1; i >= 0; i--) {
            let trow = layout.hierarchyHeaderRows[i];
            brow.columns.forEach((bc) => {
              let val = bc.hItems ? bc.hItems[0] : bc.text;
              let tcol = trow.columns.find((x) =>
                x.hItems.some((y) => y === val)
              );
              if (!tcol.childsets) tcol.childsets = [];
              tcol.childsets.push(bc.childsets ? bc.childsets.length : 1);
            });
            brow = trow;
          }
          layout.hierarchyHeaderRows.forEach((r) => {
            r.columns.forEach((c) => {
              let totCols = 1;
              let lMargin = 46;
              let rMargin = 46;
              if (c.childsets) {
                totCols = c.childsets.reduce((p, c) => c + p, 0);
                lMargin = (46.5 * c.childsets[0]) / totCols;
                rMargin =
                  (46.5 * c.childsets[c.childsets.length - 1]) / totCols;
              }
              c.lineMarginLeft = `${lMargin}%`;
              c.lineWidth = `${100 - lMargin - rMargin}%`;
            });
          });
        }
        // derive row header hierarchy
        let levels = [];
        if (vIsHierarchyTree) {
          let getColFactor = (level) => {
            switch (vCompare[0].linklevel - level + 1) {
              case 1:
                return 12;
              case 2:
                return 6;
              case 3:
                return 4;
              case 4:
                return 3;
              default:
                return 2;
            }
          };
          let applyToLevel = (levels, col, pos, detailRow) => {
            let isLeaf = pos === vCompare[0].linklevel;
            let val = col.sortKey
              ? col.sortKey.split(" > ")[pos - 1]
              : "UNCLASSIFIED";
            let key = isLeaf ? col.keyVal : val;
            let level = levels.find((x) => x.key === key);
            if (!level) {
              if ((!val || val === "UNCLASSIFIED") && pos === 1) {
                val = "UNCLASSIFIED";
              }
              let colFactor = getColFactor(pos);
              level = {
                value: val,
                key: key,
                levels: [],
                ht_id: vCompare[0].ht_id,
                hierarchy_node_id:
                  col[`ht_id_${vCompare[0].ht_id}_${pos}_nodeID`],
                level: pos,
                cols: colFactor,
                childCols: 12 - colFactor,
                dIndexStart: detailRow.dIndexStart,
                dIndexEnd: detailRow.dIndexEnd,
                detailCount: detailRow.detailCount,
              };
              if (isLeaf) {
                level.detailCountHeader = detailRow.detailCountHeader
                  .filter((c) => c.dIndex)
                  .map((c) => c.dIndex);
              }
              levels.push(level);
            } else {
              if (level.dIndexStart > detailRow.dIndexStart)
                level.dIndexStart = detailRow.dIndexStart;
              if (level.dIndexEnd < detailRow.dIndexEnd)
                level.dIndexEnd = detailRow.dIndexEnd;
              level.detailCount += detailRow.detailCount;
            }
            return level;
          };
          vCols.forEach((c, di) => {
            let levs = levels;
            let detailRow = layout.detailRow.rowHeadings[di];
            for (let i = 1; i <= vCompare[0].linklevel; i++) {
              let lev = applyToLevel(levs, c, i, detailRow);
              levs = lev.levels;
            }
          });
          let setFirstLast = (levels) => {
            levels.forEach((x, xi) => {
              x.isFirst = xi === 0;
              x.isLast = xi === levels.length - 1;
              if (x.levels?.length) setFirstLast(x.levels);
            });
          };
          setFirstLast(levels);
          layout.detailRow.hierarchyHeadings.levels = levels;
          layout.detailRow.hierarchyHeadings.pageLevels = [];
        }

        this.visibleMeasures.forEach((m) => {
          m["max_value"] = layout.detailRow.rowHeadings.reduce(
            (a, b) =>
              a > b.headerColumns[0][m.type] ? a : b.headerColumns[0][m.type],
            0
          );
        });

        this.layout = layout;
      }

      this.resetIsBuilding = true;
      this.isBuilding = false;
      this.vScroll.doSetup = true;
    },
    showMatches() {
      // no longer wanted
    },
    selectJob(item) {
      if (this.compareCriteria.some((x) => x.match_key === item.match_key)) {
        this.compareCriteria = this.compareCriteria.filter(
          (x) => !(x.match_key === item.match_key)
        );
        item.selected = false;
      } else {
        this.compareCriteria.push({
          matchColumns: item.matchColumns,
          match_key: item.match_key,
        });
        item.selected = true;
      }
      this.addCompareDocs(item);
    },
    addCompareDocs(item) {
      this.getDocs(item).forEach((d) => {
        if (!item.selected) {
          let index = this.compareDocs.findIndex((x) => x.doc_id === d.doc_id);
          if (index >= 0) this.compareDocs.splice(index, 1);
        } else if (!this.compareDocs.some((x) => x.doc_id === d.doc_id)) {
          this.compareDocs.push(d);
        }
      });
    },
    resetSelectedGroupedJobs(level) {
      let reset = (nodes) => {
        nodes.forEach((n) => {
          if (n.level !== level && n.selected) n.selected = false;
          if (n.nodes) reset(n.nodes, level);
          if (n.rows) reset(n.rows, level);
        });
      };
      reset(this.groupedViewDocs);
      this.compareCriteria = this.compareCriteria.filter(
        (x) => x.level === level
      );
    },
    selectGroupedJob(item) {
      if (this.compareCriteria.some((x) => x.match_key === item.match_key)) {
        this.compareCriteria = this.compareCriteria.filter(
          (x) => x.match_key !== item.match_key
        );
        item.selected = false;
      } else {
        this.compareCriteria.push({
          matchColumns: item.matchColumns,
          match_key: item.match_key,
        });
        item.selected = true;
      }
      this.addCompareDocs(item);
    },
    buildGroupedMatch(l1, l2, l3, detail) {
      let match = "";
      if (!l1.doc_id) {
        match = detail?.keyVal || l1?.keyVal + l2?.keyVal + l3?.keyVal;
      } else {
        this.groupedViewDetailColumns
          .filter((x) => x.isAttribute || x.ht_id)
          .forEach((c) => {
            match += "|" + l1[c.value];
          });
      }
      return match;
    },
    selectListviewJob(item, notBound) {
      let match_key = item.match_key;
      if (this.compareCriteria.some((x) => x.match_key === match_key)) {
        this.compareCriteria = this.compareCriteria.filter(
          (x) => x.match_key !== match_key
        );
        if (notBound) item.selected = false;
      } else {
        this.compareCriteria.push({
          match_key: match_key,
          item: item,
        });
        if (notBound) item.selected = true;
      }
      this.addCompareDocs(item);
    },
    selectAllListViewJob(header) {
      this.compareCriteria.splice(0, this.compareCriteria.length);
      this.groupedDocs.forEach((x) => {
        if (x.selected !== header.selected) {
          x.selected = header.selected;
        }
        if (x.selected) {
          let match_key = x.match_key;
          this.compareCriteria.push({
            match_key: match_key,
            item: x,
          });
        }
      });
      this.compareDocs = this.selectedDocs;
    },
    resetCompare() {
      this.compareCriteria.splice(0, this.compareCriteria.length);
      this.compareView = true;
      let recurseReset = (item) => {
        if (item.selected) item.selected = false;
        if (item.children) {
          item.children.forEach((c) => {
            c.rowHeadings.forEach((rh) => recurseReset(rh));
          });
        }
        if (item.categories) {
          item.categories
            .filter((c) => c.selected)
            .forEach((c) => (c.selected = false));
        }
      };
      if (
        this.layout &&
        this.layout.headerRow &&
        this.layout.headerRow.columns
      ) {
        this.layout.headerRow.columns.forEach((r) =>
          r.headerColumns
            .filter((x) => x.selected)
            .forEach((x) => (x.selected = false))
        );
        this.layout.detailRow.rowHeadings
          .filter((x) => x.selected)
          .forEach((x) => (x.selected = false));
        this.layout.detailRow.rowDetails.forEach((r) =>
          r.columns.forEach((c) =>
            c.subColumns.forEach((x) => {
              recurseReset(x);
            })
          )
        );
      }
      if (
        this.layout &&
        this.layout.groupHeaderRow &&
        this.layout.groupHeaderRow.vRows
      ) {
        this.layout.groupHeaderRow.vRows.forEach((r) => {
          if (r.selected) r.selected = false;
          r.columns.forEach((c) =>
            c.subColumns.forEach((x) => {
              recurseReset(x);
            })
          );
        });
      }
      this.groupedDocs.forEach((x) => (x.selected = false));
      this.lvColumnsFiltered
        .filter((x) => x.selected)
        .forEach((x) => (x.selected = false));
      this.drilldownFilters
        .filter((x) => x.selected)
        .forEach((x) => (x.selected = false));
      this.resetSelectedGroupedJobs();
      this.compareDocs.splice(0, this.compareDocs.length);
    },
    compareDocDetails(items) {
      this.sideBySide.show = true;
      this.sideBySide.items = items || this.compareDocs;
    },
    compareDocDetailsFull(items) {
      this.fullCompareView.show = true;
      this.fullCompareView.items = items || this.compareDocs;
    },
    compareBucketDocs(bucket) {
      let docs = [];
      bucket.additions.forEach((a) => {
        let ds = this.getDocs(a);
        ds.forEach((d) => docs.push(d));
      });
      if (docs.length) this.compareDocDetailsFull(docs);
    },
    getDocumentTags(docs) {
      if (docs.length) {
        let request = {
          doc_ids: docs.map((x) => x.doc_id),
        };
        let possibleError = false;
        axios
          .post("document/getDocumentTags/", request)
          .then((resp) => {
            possibleError = true;
            if (resp.data.Status === "OK") {
              if (resp.data.Data) {
                if (resp.data.Data) {
                  this.compareDocTags(docs, resp.data.Data);
                }
              }
            }
            this.response = resp.data;
            this.isSaving = false;
          })
          .catch((err) => {
            if (possibleError) {
              alert("Code Error");
            } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
            this.isSaving = false;
          });
      }
    },
    compareDocTags(sourceDocs, data) {
      if (sourceDocs.length) {
        this.tagsDialogue.show = true;
        this.tagsDialogue.sections = data.structure;
        this.tagsDialogue.subject = data.structure
          .map((x) => x.section_title)
          .join(" / ");
        this.tagsDialogue.items = sourceDocs.map((cd) => {
          let d = JSON.parse(JSON.stringify(cd));
          let dSecs = data.documents.find(
            (dt) => dt.doc_id === d.doc_id
          )?.sections;
          d.sections = data.structure.map((s) => {
            let sec = {
              tpa_id: s.tpa_id,
              tag_type_id: s.tag_type_id,
              section_title: s.section_title,
              maxRating: s.maxRating,
              tags: s.tags.map((st) => {
                let dSec = dSecs?.find((x) => x.tpa_id === s.tpa_id);
                return {
                  tag_value_id: st.tag_value_id,
                  value: st.value,
                  val: dSec
                    ? dSec.tags.find((x) => x.tag_value_id === st.tag_value_id)
                    : null,
                };
              }),
            };
            return sec;
          });
          return d;
        });
        this.initPaging(this.tagsDialogue);
      }
    },
    setDocHierarchyValues(doc, hierarchyType, hr_id) {
      let hr = hierarchyType.values.find((v) => v.value === hr_id);
      doc["ht_id_" + hierarchyType.ht_id] = hr ? hr.value : null;
      doc["change_ht_id_" + hierarchyType.ht_id] = false;
      doc["ht_id_" + hierarchyType.ht_id + "_label"] = "";
      [1, 2, 3, 4, 5, 6]
        .filter((l) => l <= hierarchyType.linklevel)
        .forEach((i) => {
          // if (hierarchyType["col" + i] > 0) {
          let val = hr ? hr["level" + i] || "UNCLASSIFIED" : "UNCLASSIFIED";
          doc["ht_id_" + hierarchyType.ht_id + "_" + i + "_"] = val;
          doc["ht_id_" + hierarchyType.ht_id + "_text"] = val;
          doc["ht_id_" + hierarchyType.ht_id + "_label"] =
            (doc["ht_id_" + hierarchyType.ht_id + "_label"]
              ? doc["ht_id_" + hierarchyType.ht_id + "_label"] + " > "
              : "") + val;
          doc["ht_id_" + hierarchyType.ht_id + "_" + i + "_nodeID"] = hr
            ? hr["hierarchy_node_id_level" + i]
            : null;
          // }
        });
    },
    changeDocType(refreshDefinition) {
      this.fetchData(
        false,
        this.selectedView.definition.tmpl_id,
        refreshDefinition
      );
    },
    loadViews(data) {
      data.forEach((v) => (v.isDirty = false));
      this.views = data;
      let defaultHierarchy = this.hierarchies.find(
        (ht) => ht.label.toLowerCase().indexOf("job") >= 0
      );
      if (!defaultHierarchy) defaultHierarchy = this.hierarchies[0];
      this.defaultHierarchy = {
        ht_id: defaultHierarchy.ht_id,
        ht_name: defaultHierarchy.label,
      };

      let callback = () => {
        let viewToSelect = "";
        if (this.$route.name === "jobfamily") {
          viewToSelect = `${this.defaultHierarchy.ht_name} List View`;
        } else {
          const passedParams = this.$passedParams.get();
          viewToSelect = passedParams.view;

          if (!viewToSelect && this.shortcuts.items.length) {
            viewToSelect = this.shortcuts.items.find((x) => x.isDefault)?.name;
          }
          if (!viewToSelect) {
            viewToSelect = this.views.find((x) => x.isDefault)?.name;
          }
          if (!viewToSelect) {
            viewToSelect = `${this.defaultHierarchy.ht_name} Summary`;
          }
        }

        this.selectedView = this.views.find((x) => x.name === viewToSelect);

        this.viewToSelect = this.selectedView ? null : viewToSelect;

        this.fetchData(false, this.selectedView?.definition.tmpl_id);
      };
      this.getShortcuts(callback);
    },
    fetchData(isRefresh, tmpl_id, refreshDefinition) {
      if (this.isLoading) return;
      this.isLoadingData = true;
      let possibleError = false;
      let self = this;
      axios
        .get("document/jobcatalogue" + (tmpl_id ? "/" + tmpl_id : ""))
        .then((resp) => {
          possibleError = true;
          if (resp.data.Data)
            self.setupData(resp.data.Data, isRefresh, refreshDefinition);
          this.isLoadingData = false;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isLoadingData = false;
        });
    },
    appendHierarchiesToRawData(docs) {
      // only needed so that side hierarchy search works....
      docs.forEach((d) => {
        d.hierarchies = this.hierarchyTypes.map((ht) => {
          let ret = {
            ht_id: ht.ht_id,
            hr_id: d[`ht_id_${ht.ht_id}`],
          };
          let c = 1;
          for (let i = ht.linklevel; i > 0; i--) {
            ret[`hierarchy${i}`] = d[`ht_id_${ht.ht_id}_${c}_`];
            c++;
          }
          return ret;
        });
      });
    },
    setupDescriptors(data) {
      data.hierarchyTypeNodes.forEach((x) => {
        _descriptors["ht_o_" + x.ht_id] = x.descObject;
        //   descriptors["ht_l_" + x.ht_id] = x.descriptors;
        delete x.descObject;
        delete x.descriptors;
        x.transactionTypes = [];
        let trantypes = [];
        x.nodeList
          .filter((n) => n.transactions.length)
          .forEach((n) => {
            n.transactions
              .filter((t) => !trantypes.some((tt) => tt === t))
              ?.forEach((t) => trantypes.push(t));
          });
        trantypes.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));
        x.transactionTypes = trantypes;
      });
      //   this.transactionTypes = trantypes;
    },
    getTransactionTypes() {
      if (this.selectedView.ht_id) {
        return _hierarchyTypeNodes.find(
          (h) => h.ht_id === this.selectedView.ht_id
        ).transactionTypes;
      } else {
        let ret = [];
        _hierarchyTypeNodes.forEach(
          (h) => (ret = [...new Set([...ret, ...h.transactionTypes])])
        );
        ret.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));
        return ret;
      }
    },
    getNameChangeTypes() {
      let ht_id = this.selectedView.definition.ht_id;
      let mapLevels = (l) => {
        return {
          text: l.name,
          value: `ht_id_${ht_id}_${l.index}_previousNames`,
        };
      };
      if (ht_id) {
        return this.hierarchyTypes
          .find((h) => h.ht_id === ht_id)
          .levels.map(mapLevels);
      } else {
        let ret = [];
        this.hierarchyTypes.forEach((h) => {
          ht_id = h.ht_id;
          ret = [...ret, ...h.levels.map(mapLevels)];
        });
        return ret;
      }
    },
    setupData(data, isRefresh, refreshDefinition) {
      // "refreshDefinition" means that tmpl_id has change for current view
      //  so need to repopulate view properites based on new template
      if (data?.hierarchyTypeNodes) {
        this.setupDescriptors(data);
        _hierarchyTypeNodes = data.hierarchyTypeNodes;
      }
      this.setUpHierarchies();
      if (data.permissions) {
        this.permissions = data.permissions;
      }

      let tmpl_id = Number(data?.template || this.docTypeSummary[0].tmpl_id);
      this.catalogueDocType = this.docTypeSummary.find(
        (x) => x.tmpl_id === tmpl_id
      );
      if (!this.catalogueDocType) {
        alert("You are not permitted to view any documents");
        this.isLoadingData = false;
        this.isSaving = false;
        this.isBuilding = false;
        return;
      }
      this.tmpl_id_default = Number(data.tmpl_id_default);
      this.usesTags = data.usesTags;
      let docStatusList = data?.template ? [] : ["APPROVED", "DRAFT"];
      let docs = [];
      let compareOptions = [];
      let salaries = [15000, 12000, 20000, 25000, 32000, 40000, 50000];
      let reviewed = [
        { value: 0, sort_key: 0 },
        { value: 1, sort_key: 1 },
        { value: 2, sort_key: 2 },
        { value: 3, sort_key: 3 },
        { value: 4, sort_key: 4 },
        { value: 5, sort_key: 5 },
        { value: 6, sort_key: 6 },
      ];
      _reviewNodes = data.reviewNodes || [];
      let rdocs =
        data.reviewDocs.map((x) => {
          x.showNextLevel = false;
          x.showComments = false;
          x.nodesMain.forEach((n) => (n.showComments = false));
          return x;
        }) || [];
      this.reviewDocs = [
        {
          type: "Open",
          docs: rdocs.filter((x) => x.status_type === "Open"),
          show: false,
        },
        {
          type: "Completed",
          docs: rdocs.filter((x) => x.status_type === "Completed"),
          show: false,
        },
      ];

      let measureFilter = null;
      if (data?.template) {
        this.attributes = data.attributes;
        data.measures.forEach((m) => {
          m.alignRight = false;
        });
        this.measures = data.measures;
        this.measureFilters = data.measures
          .filter((x) => x.filterPresence)
          .map((x) => {
            return { title: x.title, type: x.type };
          });
        if (this.measureFilters.length) {
          this.measureFilters.unshift({
            title: "No Similarity Filter",
            type: null,
          });
          measureFilter = this.measureFilters[0];
        }
        // only needed so that side hierarchy search works....
        this.appendHierarchiesToRawData(data.docs);
        data.docs.forEach((d) => (d.selected = false));

        _documents = data.docs;
        this.haveData = _documents.length !== 0;
        // _reviewDocuments = data.reviewDocuments;
        this.documentsForSearch = data.docs.filter(x => x.doc_id > 0);
        this.isDemo = false;
      } else {
        this.isDemo = true;
        let setValuesDemo = (d, di, gender) => {
          this.attributes.forEach((a) => {
            d["change_" + a.value_column] = true;
            if (a.generateList && a.generateList.length) {
              d[a.column] =
                a.generateList[di % a.generateList.length] +
                a.generateList[di % (a.generateList.length - 1)] +
                ((di % 15) * 1000 + 11).toString().substr(0, 4);
              if (!a.values.includes(d[a.column])) a.values.push(d[a.column]);
            } else {
              d[a.column] = a.values[di % a.values.length].value;
            }
          });
          let empCount = gender === "Male" ? (di % 5) + 1 : 1;
          if (di % 15 === 1) empCount = 0; // wante some zero entries
          d.gender = gender;
          d.employee_count = empCount;
          d.job_count = gender === "Male" ? 1 : 0;
          d.salary = d.employee_count * salaries[di % salaries.length];
          d.last_reviewed = reviewed[di % reviewed.length].value;
          //   let y = di % 3;
          //   let z = this.skills.length - 1;
        };
        this.cleanItems
          .filter(
            (x) =>
              //   x.doc_type === tmplName &&
              x.tmpl_id === tmpl_id &&
              (!docStatusList.length ||
                docStatusList.some((s) => s === x.doc_status))
          )
          .forEach((x, xi) => {
            let d = JSON.parse(JSON.stringify(x));
            this.hierarchyTypes.forEach((ht) => {
              let hr = d.hierarchies.find((h) => h.ht_id === ht.ht_id);
              this.setDocHierarchyValues(d, ht, hr?.hr_id);
            });
            d.doc_name = (d.doc_name || "NO JOB TITLE").trim();
            d.id = d.doc_id;
            if (data?.template) {
              // let dAt = data.doc_attributes.find((da) => da.doc_id === d.doc_id);
              // setValues(d, dAt ? dAt.attributes : [], xi);
              delete d.hierarchies;
              let dd = data.docs.find((da) => da.doc_id === d.doc_id);
              let dAt = { ...d, ...dd };
              docs.push(dAt);
            } else {
              // demo generated
              setValuesDemo(d, xi, "Male");
              docs.push(d);
              d = JSON.parse(JSON.stringify(d));
              setValuesDemo(d, xi, "Female");
              docs.push(d);
            }
          });
        //   this.jobTitles = jobTitles;
        _documents = docs;
        this.haveData = _documents.length !== 0;
        this.documentsForSearch = docs;
      }
      this.attributes.forEach((a) => {
        let vals;
        if (a.values.length === 0) {
          vals = this.distinctValues(_documents, [a.column])
            .map((x) => {
              return { sort_key: x[a.column], value: x[a.column] };
            })
            .sort((a, b) =>
              a.value > b.value ? 1 : a.value < b.value ? -1 : 0
            );
          //   if (vals.length > 30) vals = [];
        }
        compareOptions.push({
          name: a.title,
          column: a.column,
          tpa_id: a.tpa_id,
          useSortKey: a.useSortKey,
          listValues: a.values.length ? a.values : vals,
          listValueLength: (a.values.length ? a.values : vals).length,
          listValuesFiltered: (a.values.length ? a.values : vals).map(
            (x) => x.value
          ),
          filterValues: [],
          sortKey: `1_${a.title}`,
          colWidth: 12,
          detail_prefix: a.catalogue_attribute_prefix,
        });
      });
      //   compareOptions.push({
      //     name: this.catalogueDocType.docType + " Title",
      //     column: "doc_name",
      //     showSimCount: true,
      //     showCheckbox: true,
      //     filterValues: [],
      //   colWidth: 12,
      //   });
      compareOptions.push({
        name: this.catalogueDocType.docType + " Document",
        column: "system_number",
        display_column: "doc_name",
        showSimCount: true,
        hideHorizontal: true,
        hideVertical: false,
        isDocument: true,
        showCheckbox: false,
        filterValues: [],
        sortKey: `1_1_0`,
        colWidth: 12,
      });
      this.hierarchyTypes.forEach((ht) => {
        let levels = [1, 2, 3, 4, 5, 6].filter((l) => l <= ht.linklevel); //.filter((i) => ht["col" + i] > 0);
        compareOptions.push({
          name: ht.label + " (Tree)",
          column: "ht_id_" + ht.ht_id,
          ht_id: ht.ht_id,
          ht_name: ht.ht_name,
          ht_label: ht.label,
          linklevel: ht.linklevel,
          filterValues: [],
          hideDDrilldown: true,
          hideHorizontal: true,
          isHierarchyTree: true,
          hierarchyLevels: levels.map((i) => {
            let usesDescriptor = ht["descriptor_h" + i];
            return {
              node_column: `ht_id_${ht.ht_id}_${i}_nodeID`,
              ht_label: ht["level" + i + "_name"],
              hLevel: i,
              usesDescriptor: usesDescriptor,
            };
          }),
          sortKey: `2_${ht.position}_2`,
          colWidth: 12,
        });
        compareOptions.push({
          name: ht.label + " (Drilldown)",
          column: `ht_id_${ht.ht_id}_1_`,
          ht_id: ht.ht_id,
          ht_name: ht.ht_name,
          ht_label: ht.label,
          linklevel: ht.linklevel,
          filterValues: [],
          hideDDrilldown: true,
          currentDrillDownLevel: 1,
          drillDownLevels: levels.map((i) => {
            let usesDescriptor = ht["descriptor_h" + i];
            return {
              column: `ht_id_${ht.ht_id}_${i}_`,
              node_column: `ht_id_${ht.ht_id}_${i}_nodeID`,
              ht_label: ht["level" + i + "_name"],
              hLevel: i,
              usesDescriptor: usesDescriptor,
            };
          }),
          sortKey: `2_${ht.position}_1`,
          colWidth: 12,
        });
        // compareOptions.push({
        //   name: ht["level" + ht.linklevel + "_name"],
        //   column: `ht_id_${ht.ht_id}_${ht.linklevel}_nodeID`,
        //   display_column: `ht_id_${ht.ht_id}_${ht.linklevel}_`,
        //   isLeafNode: true,
        //   ht_id: ht.ht_id,
        //   ht_name: ht.ht_name,
        //   ht_label: ht.label,
        //   linklevel: ht.linklevel,
        //   filterValues: [],
        //   hideHorizontal: true,
        //   hideVertical: true,
        //   colWidth: 12,
        // });
        levels.forEach((l) => {
          let colname = `ht_id_${ht.ht_id}_${l}_nodeID`;
          let vals = this.distinctValues(_documents, [colname]);
          compareOptions.push({
            name: `[${ht.ht_name}] ${ht["level" + l + "_name"]}`,
            column: colname,
            display_column: `ht_id_${ht.ht_id}_${l}_`,
            isLeafNode: true,
            ht_id: ht.ht_id,
            ht_name: ht.ht_name,
            ht_label: ht.label,
            linklevel: ht.linklevel,
            filterValues: [],
            listValueLength: vals.length,
            // hideHorizontal: true,
            // hideVertical: true,
            sortKey: `1_${ht.position}_${10 - l}`,
            colWidth: 12,
          });
        });
      });
      compareOptions.sort((a, b) =>
        a.sortKey > b.sortKey ? 1 : a.sortKey < b.sortKey ? -1 : 0
      );
      this.compareOptionsV = JSON.parse(
        JSON.stringify(compareOptions.filter((x) => !x.hideVertical))
      );
      this.compareOptionsH = JSON.parse(
        JSON.stringify(compareOptions.filter((x) => !x.hideHorizontal))
      );
      this.compareOptionsD = JSON.parse(
        JSON.stringify(compareOptions.filter((x) => !x.hideDDrilldown))
      );
      this.listView.itemsPerPage = Number(localStorage.getItem("jcRPP")) || 20;

      this.isLoadingData = false;
      this.isSaving = false;
      this.isDataLoaded = true;
      if (isRefresh) {
        this.selectJDs();
      } else {
        this.deriveListviewColumns();
        let defaultMeasures = this.measures
          .filter((x) => x.type === "job_count")
          .map((x) => x.type);
        let defaultCompare = (type, defaults) => {
          let def;
          if (defaults?.length) {
            let index = 0;
            while (!def && index < defaults.length) {
              let values = defaults[index].toLowerCase().split("|");
              def = this[type].find((x) =>
                values.every((v) => x.name.toLowerCase().indexOf(v) >= 0)
              );
              index++;
            }
          }
          return this.getViewCompareOption(def ? def : this[type][0]);
        };
        let curDef = this.selectedView?.definition;
        if (curDef) {
          let defaultLVColumns = curDef.listViewColumnsEnabled.length
            ? this.listView.columns
                .filter(
                  (x) => x.measure || x.isAttribute || x.ht_id === curDef.ht_id
                )
                .map((x) => x.value)
            : [];
          if (refreshDefinition) {
            curDef.measuresVisible = defaultMeasures;
            curDef.listViewColumnsEnabled = defaultLVColumns;
            curDef.horCompareOptions = [
              defaultCompare("compareOptionsH", ["management", "grade"]),
            ];
            curDef.verCompareOptions = [
              defaultCompare("compareOptionsV", ["Job|Drilldown"]),
            ];
            curDef.detCompareOptions = [];
            curDef.attributeFilters = [];
            curDef.columnFilters = [];
            curDef.hierarchyFilters = [];
            curDef.drilldownFilters = [];
            curDef.transactionTypeFilters = [];
            curDef.nameChangeFilters = [];
            curDef.measureFilter = measureFilter;
          } else {
            // make sure contents valid for changed doc type
            curDef.measuresVisible = curDef.measuresVisible.filter((m) =>
              this.measures.some((x) => x.type === m)
            );
            if (curDef.listViewColumnsEnabled.length) {
              curDef.listViewColumnsEnabled =
                curDef.listViewColumnsEnabled.filter((m) =>
                  defaultLVColumns.some((x) => x === m)
                );
              if (!curDef.listViewColumnsEnabled.length)
                curDef.measuresVisible = defaultLVColumns;
            }
            if (
              curDef.horCompareOptions.length === 1 &&
              !this.compareOptionsH.some(
                (h) => h.column === curDef.horCompareOptions[0].column
              )
            ) {
              curDef.horCompareOptions = [
                defaultCompare("compareOptionsH", ["management", "grade"]),
              ];
            }
            if (
              curDef.verCompareOptions.length === 1 &&
              !this.compareOptionsV.some(
                (h) => h.column === curDef.verCompareOptions[0].column
              )
            ) {
              curDef.verCompareOptions = [
                defaultCompare("compareOptionsV", ["Job|Drilldown"]),
              ];
            }
            if (
              !curDef.detCompareOptions ||
              !curDef.detCompareOptions.every((x) =>
                this.compareOptionsD.some((h) => h.column === x.column)
              )
            ) {
              curDef.detCompareOptions = [];
            }
            if (
              !curDef.measuresVisible.length &&
              !curDef.detCompareOptions.length
            )
              curDef.measuresVisible = defaultMeasures;
          }
        }
        let defaultViewDef = {
          viewType: "",
          ht_id: null,
          primary: null,
          isPrimary: false,
          isDefault: false,
          tmpl_id: this.tmpl_id_default,
          measuresVisible: defaultMeasures,
          groupedViewShowDescriptor: false,
          nodeSortProperty: null,
          measureDisplayType: "chip",
          listViewColumnsEnabled: [],
          horCompareOptions: [
            this.getViewCompareOption(this.compareOptionsH[0]),
          ],
          verCompareOptions: [
            this.getViewCompareOption(this.compareOptionsV[1]),
          ],
          detCompareOptions: [],
          attributeFilters: [],
          columnFilters: [],
          hierarchyFilters: [],
          drilldownFilters: [],
          transactionTypeFilters: [],
          nameChangeFilters: [],
          measureFilter: measureFilter,
          attributeFiltersVisible: true,
          compareDVisible: false,
          nameChangeFiltersVisible: false,
          listviewColumnPickerVisible: true,
          measurePickerVisible: true,
          isDirty: false,
          isBase: true,
        };
        let addView = (view) => {
          let existingView = this.views.find((x) => x.name === view.name);
          if (existingView) {
            existingView.definition = {
              ...defaultViewDef,
              ...existingView.definition,
            };
            return;
          }
          let vDef = {
            view_definition_id: null,
            name: view.name,
            isNew: false,
            restricted: false,
            description: "",
            definition: {
              ...defaultViewDef,
              ...view,
            },
          };
          vDef.description = this.getViewDescription(vDef.definition);
          this.views.push(vDef);
        };
        addView({
          name: `${this.defaultHierarchy.ht_name} Summary`,
          viewType: "catalogue",
          isDefault: !this.views.some((x) => x.isDefault),
          ht_id: this.defaultHierarchy.ht_id,
          primary: { icon: "mdi-view-comfy", position: 2 },
          //   horizontalCompareOption: defaultCompare("compareOptionsH", [
          //     "management",
          //     "grade",
          //   ]),
          horCompareOptions: [
            defaultCompare("compareOptionsH", ["management", "grade"]),
          ],
          //   verticalCompareOption: defaultCompare("compareOptionsV", [
          //     "Job|Drilldown",
          //   ]),
          verCompareOptions: [
            defaultCompare("compareOptionsV", ["Job|Drilldown"]),
          ],
          compareDVisible: true,
          //   detailCompareOption: defaultDcompare,
          detCompareOptions: [],
        });

        // get option with minumum values for performance
        // let opts = this.compareOptionsH
        //   .filter((x) => x.listValues && x.listValues.length)
        //   .sort((a, b) =>
        //     a.listValues.length > b.listValues.length
        //       ? 1
        //       : a.listValues.length < b.listValues.length
        //       ? -1
        //       : 0
        //   );
        addView({
          name: "Tree View",
          viewType: "catalogue",
          ht_id: this.defaultHierarchy.ht_id,
          primary: { icon: "mdi-file-tree-outline", position: 3 },
          horCompareOptions: [],
          verCompareOptions: [defaultCompare("compareOptionsV", ["Job|Tree"])],
          compareDVisible: true,
          detCompareOptions: [],
        });
        if (
          this.$loginState.permittedRoutes.some(
            (x) => x === "similarityAnalysis"
          )
        ) {
          addView({
            name: "Similarity Analysis",
            viewType: "link",
            ht_id: null,
            link: "similarityAnalysis",
            primary: { icon: "mdi-card-multiple-outline", position: 6 },
          });
        }
        this.hierarchyTypes.forEach((ht) => {
          let isDefault = ht.ht_id === this.defaultHierarchy?.ht_id;
          // default list hierarchy columns
          let listViewColumnsEnabled = this.listView.columns
            .filter((x) => x.measure || x.isAttribute || x.ht_id === ht.ht_id)
            .map((x) => x.value);
          addView({
            name: `${ht.ht_name} List View`,
            viewType: "list",
            ht_id: ht.ht_id,
            primary: isDefault
              ? { icon: "mdi-view-sequential", position: 1 }
              : null,
            listViewColumnsEnabled: listViewColumnsEnabled,
            nameChangeFiltersVisible: true,
          });
          addView({
            name: `${ht.ht_name} Descriptor View`,
            viewType: "grouped",
            ht_id: ht.ht_id,
            primary: isDefault
              ? { icon: "mdi-receipt-text", position: 4 }
              : null,
            listViewColumnsEnabled: listViewColumnsEnabled,
            groupedViewShowDescriptor: true,
          });
          addView({
            name: `${ht.ht_name} Audit Data`,
            viewType: "audit_data",
            ht_id: ht.ht_id,
            primary: isDefault ? { icon: "mdi-radar", position: 5 } : null,
          });
          //   addView({ name: `${ht.ht_name} Audit Report`, viewType: "audit", ht_id: ht.ht_id });
          if (this.usesReviews) {
            addView({
              name: `${ht.ht_name} Reviews`,
              viewType: "reviews",
              ht_id: ht.ht_id,
              primary: isDefault
                ? { icon: "mdi-comment-text-multiple", position: 5 }
                : null,
            });
          }
        });
        if (this.measures.some((m) => m.type === "employee_count")) {
          let empMeasures = ["employee_count"];
          addView({
            name: "Employee Distribution Matrix",
            viewType: "catalogue",
            ht_id: this.defaultHierarchy.ht_id,
            primary: { icon: "people", position: 7 },
            measuresVisible: [...empMeasures],
            // horizontalCompareOption: defaultCompare("compareOptionsH", [
            //   "management",
            //   "level",
            // ]),
            horCompareOptions: [
              defaultCompare("compareOptionsH", ["management", "level"]),
            ],
            // verticalCompareOption: defaultCompare("compareOptionsV", [
            //   "Job|Drilldown",
            // ]),
            verCompareOptions: [
              defaultCompare("compareOptionsV", ["Job|Drilldown"]),
            ],
            compareDVisible: true,
            detCompareOptions: [],
          });
          addView({
            name: "Employee Distribution by Level",
            viewType: "catalogue",
            ht_id: this.defaultHierarchy.ht_id,
            longMeasureTitle: true,
            primary: { icon: "transfer_within_a_station", position: 8 },
            measureDisplayType: "bar",
            measuresVisible: [...empMeasures],
            horCompareOptions: [],
            verCompareOptions: [
              defaultCompare("compareOptionsV", ["job level", "level"]),
            ],
            detCompareOptions: [],
          });
          if (this.measures.some((m) => m.type === "employee_percentage")) {
            empMeasures.push("employee_percentage");
            empMeasures.push("job_count");
            let listViewColumnsEnabled = this.listView.columns
              .filter(
                (x) =>
                  x.measure ||
                  x.isAttribute ||
                  x.ht_id === this.defaultHierarchy.ht_id
              )
              .map((x) => x.value);
            addView({
              name: "Employee Distribution by Family",
              viewType: "grouped",
              ht_id: this.defaultHierarchy.ht_id,
              longMeasureTitle: true,
              primary: { icon: "elevator", position: 9 },
              measuresVisible: [...empMeasures],
              listViewColumnsEnabled: listViewColumnsEnabled,
              nodeSortProperty: "employee_percentage",
            });
          }
        }

        this.setPrimaryViews();

        if (this.viewToSelect) {
          this.selectedView = this.views.find(
            (x) => x.name === this.viewToSelect
          );
          this.viewToSelect = null;
        } else this.setView();
      }
      //   }
    },
    deriveListviewColumns() {
      this.listView.columns = [];
      this.hierarchyTypes.forEach((ht) => {
        ht.usedLevels = 0;
        [1, 2, 3, 4, 5, 6]
          .filter((l) => l <= ht.linklevel)
          .forEach((l) => {
            // if (ht["use_h" + l]) {
            let usesDescriptor = ht["descriptor_h" + l];
            ht.usedLevels++;
            let col = ht[`level${l}_name`]
              ? ht[`level${l}_name`]
              : `${ht.label} Level ${ht.usedLevels}`;
            let column = `ht_id_${ht.ht_id}_${l}_`;
            this.listView.columns.push({
              text: col,
              value: column,
              ht_id: ht.ht_id,
              level: l,
              usesDescriptor: !!usesDescriptor,
              filterSettings: {
                show: false,
                column: column,
                values: [],
                active: false,
                searchText: "",
                page: 1,
                pages: 1,
              },
            });
            // }
          });
      });
      this.attributes.forEach((a) => {
        this.listView.columns.push({
          text: a.title,
          value: a.column,
          isAttribute: true,
          filterSettings: {
            show: false,
            column: a.column,
            values: [],
            active: false,
            searchText: "",
            page: 1,
            pages: 1,
          },
        });
      });
      this.listView.columns.forEach((c) => {
        if (c.isAttribute || c.ht_id) {
          c.filterSettings.values = this.groupBy(
            _documents,
            c.value,
            "job_count"
          ).map((g, gi) => {
            return {
              text: g[c.value],
              selected: false,
              visible: true,
              available: true,
              count: g.job_count,
              searchValues: (g[c.value] || "")
                .toString()
                .toLowerCase()
                .split(" ")
                .filter((x) => x),
              page: parseInt(gi / 7) + 1,
            };
          });
          c.filterSettings.pages =
            parseInt((c.filterSettings.values.length - 1) / 7) + 1;
        }
      });

      this.measures.forEach((m) => {
        this.listView.columns.push({
          text: m.abbreviation,
          value: m.type,
          measure: m,
          sortable: true,
          filterSettings: {
            show: false,
            column: m.type,
            values: [],
            active: false,
            searchText: "",
            page: 1,
            pages: 1,
          },
        });
      });
    },
    filterScroll(data, filterSettings) {
      if (data) {
        let page = filterSettings.page + (data.deltaY >= 0 ? 1 : -1);
        if (page > filterSettings.pages || page < 1) return;
        filterSettings.page = page;
      }
    },
    setFilterValue(item, column) {
      if (column && column.filterSettings) {
        let value = item[column.value];
        column.filterSettings.values.forEach((x) => {
          x.selected = x.text === value;
        });
        this.filterHeading(column.filterSettings);
      }
    },
    removeFilterValue(item, column) {
      if (column && column.filterSettings) {
        let value = item[column.value];
        column.filterSettings.values
          .filter((x) => x.text === value)
          .forEach((x) => (x.selected = false));
        this.filterHeading(column.filterSettings);
        this.updateViewDefinition();
      }
    },
    filterHeading(filterSettings) {
      filterSettings.values
        .filter((v) => v.selected)
        .forEach((v) => (v.applied = true));
      filterSettings.isActive = filterSettings.values.some((x) => x.selected);
      filterSettings.show = false;
      let activeCount = 0;
      this.lvColumnsFiltered
        .filter((c) => c.filterSettings)
        .forEach((c) => {
          c.filterSettings.mostRecent = false;
          if (c.filterSettings.isActive) activeCount++;
        });
      filterSettings.mostRecent = filterSettings.isActive;
      this.activeFilterCount = activeCount;
      this.updateViewDefinition();
      this.selectJDs();
    },
    filterSearchAll(filterSettings) {
      if (filterSettings.searchText) {
        let newVal = !filterSettings.values.every(
          (x) => !x.visible || x.selected
        );
        filterSettings.values.forEach((x) => {
          if (x.visible || !newVal) x.selected = newVal;
        });
      } else {
        let newVal = !filterSettings.values.every((x) => x.selected);
        filterSettings.values.forEach((x) => {
          x.selected = newVal;
        });
      }
    },
    filterSearch(filterSettings) {
      let index = 0;
      let search = (filterSettings.searchText || "")
        .toLowerCase()
        .split(" ")
        .filter((x) => x);
      filterSettings.values.forEach((x) => {
        const visible =
          !search.length ||
          search.every((s) => x.searchValues.some((v) => v.indexOf(s) >= 0)); // x.text.toLowerCase().indexOf(search) >= 0;
        x.visible = visible;
        if (visible) {
          x.page = parseInt(index / 7) + 1;
          index++;
        } else {
          x.page = 0;
        }
      });
      filterSettings.pages = parseInt((index - 1) / 7) + 1;
      filterSettings.page = 1;
    },
    filterClear(filterSettings) {
      filterSettings.searchText = "";
      filterSettings.values.forEach((x, xi) => {
        x.visible = true;
        x.selected = false;
        x.applied = false;
        x.page = parseInt(xi / 7) + 1;
      });
      filterSettings.pages =
        parseInt((filterSettings.values.length - 1) / 7) + 1;
      this.filterHeading(filterSettings);
    },
    doHierarchyFilter(filters) {
      if (
        this.hierarchyFilters.filter((x) => x.selected.length).length === 0 &&
        filters.filter((x) => x.selected.length).length === 0
      ) {
        // result of removing filter which has already been processed
        return;
      }
      this.hierarchyFilters = filters || [];
      this.selectJDs();
      this.updateViewDefinition();
      this.setPreSelectHierarchyFilters();
    },
    openDocumentID(id) {
      let doc = _documents.find((d) => d.system_number === id);
      if (doc) this.openDocument(doc);
    },
    openDocument(item) {
      this.$emit("openDocument", {
        system_number: utils.removeTags(item.system_number),
        doc_name: utils.removeTags(item.doc_name),
        doc_type: item.doc_type,
        viewAction: item.viewAction,
      });
    },
    openDocumentSummary(event, item) {
      this.documentSummary.doc_id = item.doc_id;
      this.documentSummary.trigger = event;
    },
    viewNodeDoc(event, item) {
      let docs = this.getDocs(item);
      if (docs.length === 1) {
        this.documentSummary.doc_id = docs[0].doc_id;
        this.documentSummary.trigger = event;
      } else if (docs.length > 1) {
        this.compareDocDetailsFull(docs);
      }
    },
    exportToCSV() {
      //   this.csvHeaders = JSON.parse(JSON.stringify(this.dtHeaders));
      this.csvHeaders.push({
        text: "Created By",
        value: "created_by",
      });
      let data =
        this.csvHeaders.map((h) => '"' + h.text + '"').join(",") + "\n";
      this.items.forEach((d) => {
        data +=
          this.csvHeaders
            .map((h) => utils.csvEscape(utils.removeTags(d[h.value])))
            .join(",") + "\n";
      });
      utils.downloadFile(data, "Documents.csv", "text/csv;encoding:utf-8");
    },

    setUpHierarchies() {
      if (
        !this.$store.state.hierarchies.loading &&
        this.$store.state.hierarchies.hierarchies.length
      ) {
        this.hierarchyTypes = JSON.parse(
          JSON.stringify(this.$store.state.hierarchies.hierarchies)
        );
      }
    },
    editHierarchyDescriptor(node) {
      if (!node.descriptor) return;
      this.hierarchyDescriptorDialogue.show = true;
      this.hierarchyDescriptorDialogue.node = node.descriptor;
      this.hierarchyDescriptorDialogue.label = node.descriptor.label;
      this.hierarchyDescriptorDialogue.text = node.descriptor.description;
    },
    saveHierarchyDescriptor() {
      let possibleError = false;

      axios
        .post("hierarchy/saveNodeDescription/", {
          hierarchy_node_id:
            this.hierarchyDescriptorDialogue.node.hierarchy_node_id,
          ht_id: this.hierarchyDescriptorDialogue.node.ht_id,
          description: this.hierarchyDescriptorDialogue.text,
        })
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.hierarchyDescriptorDialogue.node.description =
              this.hierarchyDescriptorDialogue.text;
            this.hierarchyDescriptorDialogue.show = false;
          }
          this.response = resp.data;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    getHierarchyTypeNode(ht_id, hierarchy_node_id) {
      return _descriptors["ht_o_" + ht_id]["hn" + hierarchy_node_id];
    },
    getNodeData(ht_id, hierarchy_node_id) {
      return _hierarchyTypeNodes
        .find((x) => x.ht_id === ht_id)
        .nodeList.find((x) => x.hierarchy_node_id === hierarchy_node_id);
    },
    getHierarchyNodeReviewStatus(hierarchy_node_id) {
      let rev = _reviewNodes.find(
        (x) => x.hierarchy_node_id === hierarchy_node_id
      );
      if (rev) rev.editMode = false;
      return rev;
    },
    addAttribute(a) {
      this.attributeAddDialogue.show = true;
      this.attributeAddDialogue.text = "";
      this.attributeAddDialogue.label = `Add new ${a.label} value:`;
      this.attributeAddDialogue.attribute = a;
    },
    saveAttribute() {
      if (!this.attributeAddDialogue.text) return;
      this.attributeAddDialogue.show = false;
      this.attributeAddDialogue.attribute.values.push(
        this.attributeAddDialogue.text
      );
    },
    addHierarchy(ht) {
      this.hierarchyAddDialogue.ht_id = ht.ht_id;
      this.hierarchyAddDialogue.show = true;
    },
    saveHierarchy() {
      if (!this.hierarchyAddDialogue.text) return;
      this.hierarchyAddDialogue.show = false;
      this.hierarchyAddDialogue.hierarchyType.values.push(
        this.hierarchyAddDialogue.text
      );
    },
    pickHierarchy(hierarchy, property) {
      let node = {
        ht_id: hierarchy.ht_id,
        level: hierarchy.linklevel,
        label: hierarchy.label,
        name: null,
        hierarchy_node_id: null,
        property: property,
      };
      this.setUpEditHierarchy(node, "pick");
    },
    doMenuAction(action, item, compareOption, nodeContainer) {
      switch (action.action) {
        case "edit":
        case "rename":
        case "move":
        case "merge":
        case "add":
        case "delete":
        case "deactivate":
        case "reactivate":
          this.setUpEditHierarchy(action.node, action.action, action);
          break;
        case "exportNodeDescriptors":
          this.exportNodeDescriptors(action.node);
          break;
        case "viewNodeDescriptors":
          //   this.buildNodeDescriptorView(action.node, true);
          this.buildNodeDescriptorViewReview(action.node, true);
          break;
        case "exportNodeDescriptorsNested":
          this.exportNodeDescriptors(action.node, true);
          break;
        case "editDescriptor":
        case "editHierarchyDescriptor":
          this.setUpEditHierarchy(action.node, "editDescriptor");
          break;
        case "drilldown":
          this.doDrilldown(item, compareOption);
          break;
        case "addHierarchyDocument":
          this.addHierarchyDocument(action.node, item);
          break;
        case "showReview":
          this.showHierarchyNodeReview(action.node);
          break;
        case "showReviewParent":
          this.buildNodeDescriptorViewReview(action.node, true, "existing");
          break;
        case "addFilter":
          this.setFilterValue(action.listviewItem, action.listviewColumn);
          break;
        case "removeFilter":
          this.removeFilterValue(action.listviewItem, action.listviewColumn);
          break;
        case "history":
          this.getNodeHistory(action.node);
          break;
        case "hr_id":
          this.hr_idDialogue.node = action.node;
          this.hr_idDialogue.show = true;
          break;
        case "addPending":
          this.dragItem = nodeContainer;
          if (!this.dragTarget.buckets.length) {
            this.addBucketPlus(null);
          } else {
            this.addAddition(null, this.dragTarget.buckets[0]);
          }
          break;
      }
    },
    setUpEditHierarchy(h, mode, action, multiNodes, bucket) {
      this.hierarchyEditDialogue.show = true;
      this.hierarchyEditDialogue.node = h;
      this.hierarchyEditDialogue.mode = mode;
      this.hierarchyEditDialogue.action = action;
      this.hierarchyEditDialogue.multiNodes = multiNodes || [];
      this.hierarchyEditDialogue.bucket = bucket || null;
      this.hierarchyEditDialogue.showAddNodeDescriptor =
        mode === "add" &&
        _hierarchyTypeNodes.some(
          (x) => x.ht_id === h.ht_id && x["descriptor_h" + h.level + 1]
        );
    },
    attributePicked(attribute) {
      attribute.isDirty = true;
      if (this.editDialogue.items.length === 1) {
        this.editDialogue.items[0]["change_" + attribute.value_column] = true;
      }
      this.setCanSave();
    },
    nodePicked(details) {
      let target = this.editDialogue.columns.find(
        (x) => x.ht_id === details.original.ht_id
      );
      if (target.value !== details.updated.hr_id) {
        let labels = [details.updated.node.name];
        if (details.updated.node.parents)
          details.updated.node.parents.forEach((p) => labels.unshift(p.name));
        target.value = details.updated.hr_id;
        target.text = details.updated.node.name;
        target.isDirty = true;
        target.hLabel = labels;
        if (this.editDialogue.items.length === 1) {
          this.editDialogue.items[0]["change_" + target.value_column] = true;
        }
      }
      this.setCanSave();
      this.hierarchyEditDialogue.show = false;
    },
    addHierarchyDocument(node, item) {
      let possibleError = false;
      this.isSaving = true;

      axios
        .post("document/createHierarchyDocument/", {
          hierarchy_node_id: node.hierarchy_node_id,
        })
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            let rh = this.layout.detailRow.rowHeadings.find(
              (x) => x.key === item.key
            );
            if (rh) rh.job_count = 1;
            item.job_count = 1;
            node.job_count = 1;
            node.canAddDocumentType = null;
            this.docAdded([resp.data.Data.document[0]]);
          }
          this.response = resp.data;
          this.isSaving = false;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isSaving = false;
        });
    },
    setCanSave() {
      this.editDialogue.canSave = this.editDialogue.columns.some(
        (c) =>
          c.isDirty &&
          //   (this.editDialogue.items.length === 1 ||
          this.editDialogue.items.some((d) => d["change_" + c.value_column])
        // )
      );
    },
    nodeUpdated(details) {
      let updateColumn = details.original
        ? `ht_id_${details.original.ht_id}_${details.original.level}_`
        : null;
      let matchColumn = `${updateColumn}nodeID`;
      //   let updateObject = null;
      let desc = null;
      switch (details.action) {
        case "rename":
        case "edit":
          _documents
            .filter(
              (d) => d[matchColumn] === details.original.hierarchy_node_id
            )
            .forEach((d) => (d[updateColumn] = details.updated.new_name));
          if (details.updated.affected_docs) {
            details.updated.affected_docs.forEach((d) => {
              let doc = _documents.find((x) => x.doc_id === d.doc_id);
              if (doc) doc.doc_name = d.doc_name;
            });
          }
          break;
        case "editDescriptor":
          desc = this.getHierarchyTypeNode(
            details.original.ht_id,
            details.original.hierarchy_node_id
          );
          if (desc) {
            desc.description = details.updated.description;
          }
          break;
        case "add":
        case "move":
        case "merge":
        case "reactivate":
          this.hierarchyEditDialogue.show = false;
          this.hierarchyAddDialogue.show = false;
          if (details.action === "move" && this.hierarchyEditDialogue.bucket) {
            this.removeAdditionAll(this.hierarchyEditDialogue.bucket);
            this.hierarchyEditDialogue.multiNodes.splice(
              0,
              this.hierarchyEditDialogue.multiNodes.length
            );
            this.hierarchyEditDialogue.bucket = null;
            this.afterNodeMove(details);
          } else {
            this.fetchData(true, this.selectedView.definition.tmpl_id);
          }
          return;
        case "delete":
        case "deactivate":
          _documents = _documents.filter(
            (d) => d[matchColumn] !== details.original.hierarchy_node_id
          );
          break;
      }
      this.hierarchyEditDialogue.show = false;
      this.hierarchyAddDialogue.show = false;
      this.selectJDs();
    },
    afterNodeMove: function (details) {
      // necessary because doc update happens asynchronously and may still be in progress
      const ht_id = details.updated.ht_id;
      let hn_id = details.updated.move_to_node.hierarchy_node_id;
      if (details.updated.new_nodes?.length) {
        this.addNewNodes(details.updated.new_nodes);
      }
      let parents = [
        {
          hierarchy_node_id: hn_id,
          name: details.updated.move_to_node.name,
          nodeCol: `ht_id_${ht_id}_${details.updated.move_to_node.level}_nodeID`,
          nameCol: `ht_id_${ht_id}_${details.updated.move_to_node.level}_`,
        },
      ];
      let parent = this.getNode(
        ht_id,
        details.updated.move_to_node.hierarchy_node_id_parent
      );
      while (parent) {
        parents.push({
          hierarchy_node_id: parent.hierarchy_node_id,
          name: parent.name,
          nodeCol: `ht_id_${ht_id}_${parent.level}_nodeID`,
          nameCol: `ht_id_${ht_id}_${parent.level}_`,
        });
        parent = this.getNode(ht_id, parent.hierarchy_node_id_parent);
      }

      details.updated.moved_nodes.forEach((mn) => {
        let node = this.getNode(details.updated.ht_id, mn);
        node.hierarchy_node_id_parent =
          details.updated.move_to_node.hierarchy_node_id;
        const col = `ht_id_${ht_id}_${details.updated.move_to_level}_nodeID`;
        _documents
          .filter((d) => d[col] === mn)
          .forEach((d) =>
            parents.forEach((p) => {
              d[p.nodeCol] = p.hierarchy_node_id;
              d[p.nameCol] = p.name;
            })
          );
      });
      this.selectJDs(true);
    },
    docAdded: function (documents) {
      if (documents.length) _documents.push(...documents);

      if (documents.length === 1 && documents[0]?.doc_id > 0)
        this.editDocs(documents);

      this.haveData = _documents.length !== 0;
    },
    nodeSaved(original, updates) {
      let descriptor = this.getHierarchyTypeNode(
        original.ht_id,
        original.hierarchy_node_id
      );
      descriptor.description = updates.description;
      original.name = updates.name;
      original.description = updates.description;
    },
    closeHierarchyNodeReview() {
      this.nodeReviewDialogue.node = null;
      this.nodeReviewDialogue.show = false;
    },
    showHierarchyNodeReview(node) {
      this.getNodeReviewActivity(node, () => {
        this.nodeReviewDialogue.node = node;
        this.nodeReviewDialogue.show = true;
      });
    },
    getNodeReviewActivity(node, callback) {
      let possibleError = false;
      let setViewActivity = (_node, docs) => {
        if (docs.length) {
          [
            "docs_open",
            "docs_completed",
            "docsAsParent_open",
            "docsAsParent_completed",
          ].forEach((t) => {
            _node.reviewStatus[t].forEach((d) => {
              let activity = docs.filter((r) => r.doc_id === d.doc_id);
              if (activity.length) {
                d.viewAction = activity[0].viewAction;
                d.activity = activity.map((a) => {
                  let detail =
                    Number(a.events) === 1
                      ? ` once by ${a.activity_by_name} on ${a.activity_date_first}`
                      : ` ${a.events} times by ${a.activity_by_name} between ${a.activity_date_first} and ${a.activity_date_last}`;
                  switch (a.activity_type) {
                    case "Create":
                      return `Created ${a.activity_date_first} by ${a.activity_by_name}`;
                    case "View":
                    case "Edit":
                      return `${a.activity_type}ed ${detail}`;
                    default:
                      return `${a.activity_type}: ${detail}`;
                  }
                });
              }
            });
          });
          _node.reviewStatus.docs_open.forEach((x) => x.tab === null);
          if (_node.nodes) {
            _node.nodes
              .filter((n) => n.reviewStatus)
              .forEach((n) => {
                setViewActivity(n, docs);
              });
          }
        }
      };
      axios
        .get("hierarchy/getHierarchyNodeReviews/" + node.hierarchy_node_id, {
          hierarchy_node_id: node.hierarchy_node_id,
        })
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK" && resp.data.Data) {
            setViewActivity(node, resp.data.Data);
            if (callback) callback();
          }
          this.response = resp.data;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isSaving = false;
        });
    },
    getNodeHistory(node) {
      this.historyNode = node;
    },
    findNode(nodes, node_id) {
      let n = nodes.find((x) => x.hierarchy_node_id === node_id);
      if (n) return n;
      let found = null;
      nodes.forEach((n) => {
        if (!found && n.nodes) {
          found = this.findNode(n.nodes, node_id);
        }
        if (found) return;
      });
      return found;
    },
    getNode(ht_id, node_id) {
      const ht = _hierarchyTypeNodes.find((x) => x.ht_id === ht_id);
      return this.findNode(ht.nodes, node_id);
    },
    addNewNodes(nodes) {
      const ht = _hierarchyTypeNodes.find((x) => x.ht_id === nodes[0].ht_id);
      let parentCollection = ht.nodes;
      nodes.sort((a, b) =>
        a.level > b.level ? 1 : a.level < b.level ? -1 : 0
      );
      nodes.forEach((nn) => {
        ht.nodeList.push(nn);
        let currentNode = this.findNode(ht.nodes, nn.hierarchy_node_id);
        if (!currentNode) {
          nn.nodes = [];
          parentCollection.push(nn);
          parentCollection = nn.nodes;
          _descriptors["ht_o_" + nn.ht_id]["hn" + nn.hierarchy_node_id] =
            nn.descriptor;
        } else {
          parentCollection = currentNode.nodes;
        }
      });
    },
    showAuditReport(ht_id) {
      let possibleError = false;
      axios
        .post("hierarchy/getHierarchyNodeAuditReport", { ht_id: ht_id })
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK" && resp.data.Data.length) {
            this.filterAuditReport(resp.data.Data);
            this.nodeHistoryReport.nodes = resp.data.Data;
            this.nodeHistoryReport.label =
              resp.data.Data[0].level_name + " History";
            this.nodeHistoryReport.show = true;
            let userList = [];
            let getUsers = (nodes) => {
              nodes.forEach((n) => {
                if (n.changes) {
                  n.changes.forEach((c) => {
                    if (!userList.some((l) => l === c.updated_by_name))
                      userList.push(c.updated_by_name);
                  });
                }
                if (n.nodes) {
                  getUsers(n.nodes);
                }
              });
            };
            getUsers(resp.data.Data);
            this.nodeHistoryReport.userList = userList;
          }
          this.response = resp.data;
          this.isBuilding = false;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isSaving = false;
        });
    },
    selectAuditRow(item, row) {
      row.expand(!row.isExpanded);
    },
    getAuditDescriptionHTML(text) {
      let html = "";
      text = text || "";
      let posS = text.indexOf('"');
      let posE = text.indexOf('"', posS + 1);
      let start = 0;
      while (posS >= 0 && posE > posS) {
        html += `${text.substring(
          start,
          posS - 1
        )} <div class="node-description">${text.substring(
          posS + 1,
          posE
        )}</div>`;
        start = posE + 1;
        posS = text.indexOf('"', start);
        posE = text.indexOf('"', posS + 1);
      }
      html += text.substring(start);
      return html;
    },
    showAuditData(ht_id) {
      this.nodeAuditData.ht_id = ht_id;
      if (this.nodeAuditData.dateRangeFilter.length < 2) {
        this.nodeAuditData.dateRangeFilter = [
          dayJS().subtract(1, "month").format("YYYY-MM-DD"),
          dayJS().format("YYYY-MM-DD"),
        ];
      }
      this.getAuditData();
    },
    filterAuditDataDateRange(range) {
      this.nodeAuditData.dateRangeFilter = range;
      this.getAuditData();
    },
    filterAuditData() {
      if (
        !this.nodeAuditData.userFilter.length &&
        !this.nodeAuditData.typeFilter.length &&
        !this.nodeAuditData.reasonFilter.length
      ) {
        this.nodeAuditData.items = this.nodeAuditData.data;
      } else {
        let users = this.nodeAuditData.userFilter;
        let types = this.nodeAuditData.typeFilter;
        let reasons = this.nodeAuditData.reasonFilter;
        this.nodeAuditData.items = this.nodeAuditData.data.filter(
          (x) =>
            (users.length === 0 || users.some((u) => u === x.user_name)) &&
            (types.length === 0 ||
              types.some((u) => u === x.transaction_sub_type)) &&
            (reasons.length === 0 ||
              reasons.some((u) => u === x.transaction_reason_type))
        );
      }
    },
    getAuditData() {
      let possibleError = false;
      let data = {
        ht_id: this.nodeAuditData.ht_id,
        from: this.nodeAuditData.dateRangeFilter.length
          ? this.nodeAuditData.dateRangeFilter[0]
          : null,
        to:
          this.nodeAuditData.dateRangeFilter.length === 2
            ? dayJS(this.nodeAuditData.dateRangeFilter[1])
                .add(1, "day")
                .format("YYYY-MM-DD")
            : null,
      };
      axios
        .post("hierarchy/getHierarchyNodeAuditData", data)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK" && resp.data.Data) {
            this.nodeAuditData.data = resp.data.Data;
            this.nodeAuditData.label = "Transactions";
            //   resp.data.Data[0].level_name + " History";
            this.nodeAuditData.show = true;
            let userList = [];
            let typeList = [];
            let reasonList = [];
            resp.data.Data.forEach((d) => {
              if (!userList.some((l) => l === d.user_name))
                userList.push(d.user_name);
              if (
                d.transaction_sub_type &&
                !typeList.some((l) => l === d.transaction_sub_type)
              )
                typeList.push(d.transaction_sub_type);
              if (
                d.transaction_reason_type &&
                !reasonList.some((l) => l === d.transaction_reason_type)
              )
                reasonList.push(d.transaction_reason_type);
            });
            this.nodeAuditData.userList = userList.sort((a, b) =>
              a.toLowerCase() > b.toLowerCase()
                ? 1
                : a.toLowerCase() < b.toLowerCase()
                ? -1
                : 0
            );
            this.nodeAuditData.typeList = typeList.sort((a, b) =>
              a > b ? 1 : a < b ? -1 : 0
            );
            this.nodeAuditData.reasonList = reasonList.sort((a, b) =>
              a > b ? 1 : a < b ? -1 : 0
            );
            if (reasonList.length === 0) {
              this.nodeAuditData.headers = this.nodeAuditData.headers.filter(
                (x) => x.value !== "transaction_reason_type"
              );
            }
            this.filterAuditData();
          }
          this.response = resp.data;
          this.isBuilding = false;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isSaving = false;
        });
    },
    showAuditReportChanges(show) {
      let setShow = (nodes) => {
        nodes.forEach((n) => {
          if (n.changes?.length) n.showChanges = show;
          if (n.nodes) {
            setShow(n.nodes);
          }
        });
      };
      setShow(this.nodeHistoryReport.nodes);
    },
    filterAuditReportDateRange(dateRange) {
      this.nodeHistoryReport.dateRangeFilter = dateRange.sort((a, b) =>
        a > b ? 1 : a < b ? -1 : 0
      );
      this.filterAuditReport();
    },
    filterAuditReport(nodes) {
      if (!nodes) nodes = this.nodeHistoryReport.nodes;
      let range = this.nodeHistoryReport.dateRangeFilter;
      let users = this.nodeHistoryReport.userFilter;
      let setVisible = (nodes) => {
        let hasVisible = false;
        nodes.forEach((n) => {
          n.visible = false;
          if (n.changes) {
            n.visible =
              range.length === 0 ||
              n.changes.some(
                (c) =>
                  (dayJS(c.updated_date).isSame(range[0]) ||
                    dayJS(c.updated_date).isAfter(range[0])) &&
                  (dayJS(c.updated_date).isSame(range[1]) ||
                    dayJS(c.updated_date).isBefore(range[1]))
              );
            if (n.visible) {
              n.visible =
                users.length === 0 ||
                n.changes.some((c) =>
                  users.some((u) => u === c.updated_by_name)
                );
            }
          }
          if (n.nodes) {
            if (setVisible(n.nodes)) n.visible = true;
          }
          if (n.visible) hasVisible = true;
        });
        return hasVisible;
      };
      setVisible(nodes);
    },
    groupBy: function (arr, key, measure, dateToMonth) {
      let list = [];
      arr.forEach(function (item) {
        let dKey = item[key];
        if (dateToMonth) dKey = dayJS(dKey).format("YYYY MM");
        let row = list.find((li) => li[key] === dKey);
        if (!row) {
          row = { count: 0 };
          row[key] = dKey;
          if (measure) row[measure] = 0;
          list.push(row);
        }
        if (measure) row[measure] += item[measure] || 0;
        row.count++;
      });
      return list.sort((a, b) =>
        a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0
      );
    },
    buildMatchKey: (col, value) => {
      return col + "|" + value + "|";
    },
    groupByKeys: function (
      arr,
      keys,
      measures,
      dateToMonth,
      sdMeasures,
      drilldownKey
    ) {
      let list = [];
      let addSDEnries = this.addAggregateColumns;
      let keyValues = keys.map((x) => {
        return { key: x, values: [] };
      });
      arr.forEach((item) => {
        let key = {},
          keyVal = "",
          match_key = "";
        keyValues.forEach((k, i) => {
          let dKey = item[k.key];
          if (dateToMonth && i === 0) dKey = dayJS(dKey).format("YYYY MM");
          key[k.key] = dKey;
          keyVal += "|" + dKey + "|";
          match_key += this.buildMatchKey(k.key, dKey);
          if (!k.values.some((v) => v === dKey)) k.values.push(dKey);
        });

        let row = list.find(function (li) {
          return li.keyVal === keyVal;
        });
        if (!row) {
          row = {
            keyVal: keyVal,
            matchColumns: keys,
            match_key: match_key,
          };
          keys.forEach(function (k) {
            row[k] = key[k];
          });
          measures.forEach(function (m) {
            row[m] = item[m] || 0;
          });
          if (drilldownKey) {
            row[drilldownKey + "s"] = [];
          }
          list.push(row);
        } else {
          measures.forEach(function (m) {
            row[m] += item[m] || 0;
          });
        }
        if (sdMeasures && sdMeasures.length) {
          sdMeasures.forEach(function (sm) {
            addSDEnries(row, item, sm);
          });
        }
        if (drilldownKey) {
          let dd = row[drilldownKey + "s"].find(
            (x) => x[drilldownKey] === item[drilldownKey]
          );
          if (!dd) {
            dd = {};
            dd[drilldownKey] = item[drilldownKey];
            measures.forEach(function (m) {
              dd[m] = item[m] || 0;
            });
            row[drilldownKey + "s"].push(dd);
          } else {
            measures.forEach(function (m) {
              dd[m] += item[m] || 0;
            });
          }
        }
      });
      let formatNumber = this.formatAggregate;
      if (sdMeasures && sdMeasures.length) {
        list.forEach((row) => {
          sdMeasures.forEach(function (sm) {
            let values = row[sm.type + "_sd_values"];
            const n = values.length;
            if (!n) return;
            const mean = values.reduce((a, b) => a + b) / n;
            row[sm.type + "_std_dev"] = Math.sqrt(
              values.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) /
                n
            );
            row[sm.type + "_mean"] = formatNumber(sm, mean);
            row[sm.type + "_mean_number"] = mean;
            row[sm.type + "_mean_value_count"] = n;
          });
        });
      }
      return { rows: list, keyValues: keyValues };
    },
    getColumnName: (compareOption) => {
      return compareOption.currentDrillDownLevel
        ? compareOption.drillDownLevels[compareOption.currentDrillDownLevel - 1]
            .column
        : compareOption.hidden
        ? "_"
        : compareOption.column;
    },
    getColumnNameNodeID: (compareOption) => {
      return compareOption.currentDrillDownLevel
        ? compareOption.drillDownLevels[compareOption.currentDrillDownLevel - 1]
            .node_column
        : compareOption.column;
    },
    appendNodeHierarchyLevels: function (node, source) {
      let ht = this.hierarchyTypes.find((x) => x.ht_id === node.ht_id);
      node.levels = ht?.h_levels;
      node.active = true;
      node.canReview = source?.canReview;
    },
    formatAggregate: function (measure, data) {
      let format = measure.format || "0";
      if (format !== "0") {
        let prefix = ["£", "$"].find((x) => format.indexOf(x) >= 0) || "";
        let suffix = ["k", "m"].find((x) => format.indexOf(x) >= 0) || "";
        if (suffix === "k") data = data / 1000;
        if (suffix === "m") data = data / 1000000;
        format = format.replace(prefix, "").replace(suffix, "");
        return prefix + data.toFixed(parseInt(format) || 0) + suffix;
      } else {
        return data;
      }
    },
    addAggregateColumns: function (row, item, measure) {
      if (!row[measure.type + "_sd_values"])
        row[measure.type + "_sd_values"] = [];
      let val = item[measure.type];
      if (val === null || val === undefined || val === "") return;
      val = Number(item[measure.type]);
      if (val === 0 && measure.aggregation_exclude_zero) return;
      if (measure.aggregation_quantity_column) {
        let avg = val / Number(item[measure.aggregation_quantity_column]);
        for (let e = 1; e <= item[measure.aggregation_quantity_column]; e++) {
          row[measure.type + "_sd_values"].push(avg);
        }
      } else {
        row[measure.type + "_sd_values"].push(item[val]);
      }
    },
    initHeaderColumn: function (
      colDef,
      data,
      keyValue,
      title,
      matchColumns,
      match_key,
      sortKey
    ) {
      let node = null;
      if (!colDef.isHierarchyTree && colDef.noderule?.ht) {
        let hierarchy_node_id =
          colDef.ht_id && colDef.currentDrillDownLevel
            ? data[colDef.keyCol + "nodeID"]
            : data[colDef.keyCol];
        let hierarchyDescriptor = colDef.noderule.currLevel?.usesDescriptor
          ? this.getHierarchyTypeNode(colDef.ht_id, hierarchy_node_id)
          : null;
        let nodeRaw = this.getNodeData(colDef.ht_id, hierarchy_node_id);
        if (nodeRaw) {
          node = {
            level: nodeRaw.level, // colDef.currentDrillDownLevel,
            hierarchy_node_id: hierarchy_node_id,
            name: title,
            descriptor: hierarchyDescriptor,
            ht_id: colDef.ht_id,
            label: nodeRaw.level_name, //colDef.noderule.currLevel.ht_label,
            nextLevel: colDef.noderule.nextLevel
              ? colDef.noderule.nextLevel.ht_label
              : null,
            previousLevel: colDef.noderule.prevLevel
              ? colDef.noderule.prevLevel.ht_label
              : null,
            canAdd:
              !this.isDemo &&
              colDef.currentDrillDownLevel ===
                this.hierarchyTypes.find((y) => y.ht_id === colDef.ht_id)
                  .linklevel -
                  1,
            canAddDocumentType:
              !this.isDemo && !colDef.noderule.nextLevel && data.job_count === 0
                ? this.catalogueDocType.docType
                : null,
            reviewStatus: this.getHierarchyNodeReviewStatus(hierarchy_node_id),
            drilldown: colDef.noderule.nextLevel
              ? {
                  column: colDef.column,
                  ht_id: colDef.ht_id,
                  value: title,
                  hLevel: colDef.currentDrillDownLevel,
                }
              : null,
            showNodes: null,
            leaf_node_count: 0,
            history: nodeRaw ? nodeRaw.transactions : [],
            nameHistory: nodeRaw ? nodeRaw.previous_names : [],
            actionPending: this.dragTarget.buckets.some((b) =>
              b.additions.some(
                (a) => a.node.hierarchy_node_id === hierarchy_node_id
              )
            ),
          };
          this.appendMeasureColumns(node);
          this.appendNodeHierarchyLevels(node);
          this.setNodeActiveStatus(node);
        }
      }
      let value = keyValue; //data[colDef.column];
      let valDef = colDef.values
        ? colDef.values.find((x) => x.value === value)
        : null;
      let doc = colDef.isDocument
        ? this.selectedDocs.find((x) => x.system_number === value)
        : null;
      let head = {
        value: value,
        sortKey: sortKey,
        title:
          valDef && valDef.title
            ? valDef.title
            : // : title + (doc ? " - " + doc.doc_name : ""),
            doc
            ? doc.doc_name
            : title,
        class: "col_header",
        isSelectable: !!colDef.showCheckbox,
        isDocument: !!colDef.isDocument,
        doc_id: doc ? doc.doc_id : null,
        drilldown: node?.drilldown,
        leaf_node_count: node?.leaf_node_count,
        node: node,
        cols: "",
        categories: [],
        visible: true,
        colWidth: colDef.colWidth,

        isLeafNode: !!colDef.isLeafNode,
        ht_id: colDef.ht_id,
        hLevel: colDef.currentDrillDownLevel,
        hierarchy_node_id: node?.hierarchy_node_id,
        colour: valDef ? valDef.colour : "",
        column_name: colDef.name,
        title_prefix: colDef.detail_prefix,
        style: colDef.style,
      };
      if (colDef.ht_id && colDef.hierarchyLevels) {
        // add in node IDs
        colDef.hierarchyLevels.forEach(
          (l) => (head[l.node_column] = data[l.node_column])
        );
      }
      head[colDef.keyCol] = value;
      this.appendMatchColumns(head, matchColumns, match_key);
      this.appendMeasureColumns(head, 0, data);
      this.appendAggregateColumns(head, 0, data);
      //   this.getAggregateMeasures().forEach((m) => {
      //     setSDCategory(
      //       head,
      //       h[m.type + "_mean_number"],
      //       h[m.type + "_std_dev"],
      //       m.type
      //     );
      //   });
      return head;
    },
    groupByKeysNested: function (
      rawData,
      axes,
      measures,
      dateToMonth,
      sdMeasures
    ) {
      let data = {};
      axes.forEach((a) => {
        let colWidth = 12 / a.length;
        if (colWidth < 2) colWidth = 2;
        a.forEach((k) => {
          k.keyCol = this.getColumnName(k);
          k.matchCol = this.getColumnNameNodeID(k);
          k.colWidth = colWidth;
          k.maxLength = 0;
          k.maxLengthMean = 0;
          k.maxLengthTot = 0;
          k.maxLengthCount = 0;
        });
        a.colChildren = a.map((k) => k.keyCol).join("_") + "s";
      });
      let findKey = (axis, data, item) => {
        if (!data[axis.colChildren]) {
          data[axis.colChildren] = [];
          data.collectionName = axis.colChildren;
        }
        let ignore = true;
        let rowKeys = axis.map((key) => {
          let keyValue = key.hidden ? "_" : item[key.keyCol]; // dummy key
          if (!keyValue && key.ht_id) {
            keyValue = "UNCLASSIFIED";
            let thisIgnore = false;
            if (key.currentDrillDownLevel)
              thisIgnore = item.doc_id < 0 && key.currentDrillDownLevel === 1;
            else thisIgnore = keyValue === null;
            if (!thisIgnore) ignore = false;
          } else {
            ignore = false;
          }
          if (dateToMonth) keyValue = dayJS(keyValue).format("YYYY MM");
          return keyValue;
        });
        let rowKeyData = rowKeys.join("|");
        let row = data[axis.colChildren].find((li) => li.keyVal === rowKeyData);
        if (!row) {
          let matchColumns = [];
          if (data.matchColumns)
            data.matchColumns.forEach((x) => matchColumns.push(x));
          let match_key = data.match_key || "";
          let rowTitle = "";
          let rowHeadings = [];
          let rowSortKey = axis
            .map((keyDef, ki) => {
              let sortKey = "";
              let keyValue = rowKeys[ki];
              if (keyDef.useSortKey && keyDef.listValues) {
                sortKey = keyDef.listValues.find(
                  (x) => x.value === keyValue
                )?.sort_key;
              } else if (keyDef.ht_id && !keyDef.currentDrillDownLevel) {
                sortKey = item["ht_id_" + keyDef.ht_id + "_label"];
              }
              sortKey = sortKey || keyValue;
              matchColumns.push(keyDef.matchCol);
              match_key += this.buildMatchKey(
                keyDef.matchCol,
                item[keyDef.matchCol]
              );
              let title = (keyDef.display_column
                ? item[keyDef.display_column]
                : keyValue) || "";
              if (title?.length > keyDef.maxLength)
                keyDef.maxLength = title.length;
              keyDef.maxLengthTot += title.length;
              keyDef.maxLengthCount++;
              keyDef.maxLengthMean =
                keyDef.maxLengthTot / keyDef.maxLengthCount;
              rowTitle += (rowTitle ? " - " : "") + title;
              rowHeadings.push(
                this.initHeaderColumn(
                  keyDef,
                  item,
                  keyValue,
                  title,
                  matchColumns,
                  match_key,
                  sortKey
                )
              );
              return sortKey;
            })
            .join("  |  ");
          row = {
            keyVal: rowKeyData,
            sortKey: rowSortKey,
            rowHeadings: rowHeadings,
            matchColumns: matchColumns,
            match_key: match_key,
            title: rowTitle,
            selected: false,
          };
          measures.forEach(function (m) {
            row[m] = item[m] || 0;
            row[m + "maxLength"] = row[m].toString().length;
            axis.forEach((a, ai) => {
              row.rowHeadings[ai][m] = item[m] || 0;
            });
          });
          if (!ignore) data[axis.colChildren].push(row);
        } else {
          measures.forEach(function (m) {
            row[m] += item[m] || 0;
            const len = row[m].toString().length;
            if (len > row[m + "maxLength"]) row[m + "maxLength"] = len;
            axis.forEach((a, ai) => {
              row.rowHeadings[ai][m] += item[m] || 0;
            });
          });
        }
        let addSDEnries = this.addAggregateColumns;
        if (sdMeasures && sdMeasures.length) {
          sdMeasures.forEach(function (sm) {
            addSDEnries(row, item, sm);
            row.rowHeadings.forEach((h) => addSDEnries(h, item, sm));
          });
        }
        return row;
      };
      let formatNumber = this.formatAggregate;
      let calculateAggregateValue = (row) => {
        sdMeasures.forEach(function (sm) {
          let values = row[sm.type + "_sd_values"];
          const n = values.length;
          if (!n) return;
          const mean = values.reduce((a, b) => a + b) / n;
          row[sm.type + "_std_dev"] = Math.sqrt(
            values.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
          );
          row[sm.type + "_mean"] = formatNumber(sm, mean);
          row[sm.type + "_mean_number"] = mean;
          row[sm.type + "_mean_value_count"] = n;
          row[sm.type + "_mean_maxLength"] =
            row[sm.type + "_mean"].toString().length;
        });
      };
      let calculateAggregateValues = (keyIndex, data) => {
        if (keyIndex >= axes.length) return;
        data[data.collectionName].forEach((row) => {
          row.rowHeadings.forEach((rh) => calculateAggregateValue(rh));
          calculateAggregateValue(row);
          calculateAggregateValues(keyIndex + 1, row);
        });
      };
      rawData.forEach((item) => {
        let lst = data;
        axes.forEach((a) => {
          lst = findKey(a, lst, item);
        });
      });
      let sortCollection = (dat) => {
        if (dat.collectionName) {
          dat[dat.collectionName].sort((a, b) =>
            a.sortKey > b.sortKey ? 1 : a.sortKey < b.sortKey ? -1 : 0
          );
          dat[dat.collectionName].forEach((x) => sortCollection(x));
        }
      };
      sortCollection(data);
      let firstHeader = axes[0].length === 1 ? axes[0][0] : null;
      if (
        firstHeader?.ht_id &&
        firstHeader.drillDownLevels?.length &&
        measures.some((m) => m === "leaf_node_count")
      ) {
        // get leaf_node_count
        let drillDownCol =
          firstHeader.drillDownLevels[firstHeader.currentDrillDownLevel - 1];
        let leafCol =
          firstHeader.drillDownLevels[firstHeader.drillDownLevels.length - 1];
        data[drillDownCol.column + "s"].forEach((k) => {
          k.leaf_node_count = this.distinctValues(
            rawData.filter(
              (x) =>
                x[drillDownCol.node_column] ===
                k.rowHeadings[0].hierarchy_node_id
            ),
            [leafCol.node_column]
          ).length;
          k.leaf_node_countmaxLength = k.leaf_node_count.toString().length;
          k.rowHeadings[0].leaf_node_count = k.leaf_node_count;
          if (k.rowHeadings[0].node)
            k.rowHeadings[0].node.leaf_node_count = k.leaf_node_count;
        });
        data[drillDownCol.column + "s"] = data[
          drillDownCol.column + "s"
        ].filter((x) => x.leaf_node_count || x.job_count);
      }
      if (sdMeasures && sdMeasures.length) {
        calculateAggregateValues(0, data);
      }
      return data;
    },
    groupByM: function (arr, key, measures) {
      let list = [];
      arr.forEach(function (item) {
        let row = list.filter(function (li) {
          return li[key] === item[key];
        })[0];
        if (!row) {
          row = {};
          row[key] = item[key];
          measures.forEach(function (m) {
            row[m] = 0;
          });
          list.push(row);
        }
        measures.forEach(function (m) {
          if (item[m] !== 0) row[m] = (row[m] || 0) + item[m];
        });
      });
      return list;
    },
    distinctValues: function (arr, keys) {
      let list = [];
      arr.forEach(function (item) {
        let row = list.filter(function (li) {
          let match = true;
          keys.forEach(function (k) {
            if (li[k] !== item[k]) match = false;
          });
          return match;
        })[0];
        if (!row) {
          row = { _count: 1 };
          keys.forEach(function (k) {
            row[k] = item[k];
          });
          list.push(row);
        } else {
          row._count++;
        }
      });
      return list;
    },
    getCompareOptionDef: function (source, type) {
      return this[type].find((x) => x.column === source.column);
    },
    getViewCompareOption: function (source) {
      let co = {
        column: source.column,
        style: source.style,
      };
      if (source.currentDrillDownLevel !== undefined) {
        co.currentDrillDownLevel = source.currentDrillDownLevel;
      }
      return co;
    },
    setViewCompareOption: function (compareOptionName) {
      let val = this[compareOptionName];
      this.selectedView.definition[compareOptionName] =
        this.getViewCompareOption(val);
    },
    setCompareOption: function (val, compareOptionName, item) {
      if (item === val) return;
      if (val.currentDrillDownLevel !== undefined)
        val.currentDrillDownLevel = 1;
      item = val;
      this.setViewCompareOption(compareOptionName);
      this.selectedView.isDirty = true;
      this.buildCompare();
    },
    setAxisCompareOption: function (type, val, index) {
      let currentOption = this[type][index];
      let newOption = this.getViewCompareOption(val);
      if (currentOption.column === newOption.column) return;
      if (newOption.currentDrillDownLevel !== undefined)
        newOption.currentDrillDownLevel = 1;
      this.changeAxis(type, index, 1, val, newOption);
    },
    setAxisCompareOptionStyle: function (type, style, index) {
      let currentOption = this[type][index];
      currentOption.style = style;
      this.changeAxis(
        type,
        index,
        1,
        currentOption,
        this.getViewCompareOption(currentOption)
      );
    },
    addAxisCompareOption: function (type, val, index) {
      if (val.currentDrillDownLevel !== undefined)
        val.currentDrillDownLevel = 1;
      let newOption = this.getViewCompareOption(val);
      this.changeAxis(type, index + 1, 0, val, newOption);
    },
    removeAxisCompareOption: function (type, index) {
      this.changeAxis(type, index, 1);
    },
    removeAxisCompareOptionAll: function (type) {
      this[type].splice(0, this[type].length);
      this.selectedView.definition[type].splice(
        0,
        this.selectedView.definition[type].length
      );
      this.buildCompare();
      this.updateViewDefinition();
    },
    changeAxis: function (type, index, remove, newVal, newOption) {
      if (newVal) {
        this[type].splice(index, remove, newVal);
        this.selectedView.definition[type].splice(index, remove, newOption);
      } else {
        this[type].splice(index, remove);
        this.selectedView.definition[type].splice(index, remove);
      }
      this.buildCompare();
      this.updateViewDefinition();
    },
    editDocs: function (items) {
      this.prepEditDialogue(this.editDialogue, items);
    },
    initPaging: function (dialogue) {
      dialogue.items.forEach((x, xi) => {
        x.page = parseInt(xi / dialogue.pageSize);
        dialogue.pages = x.page + 1;
        return x;
      });
      if (dialogue.items.length === 0) return;
      dialogue.show = true;
      dialogue.page = 0;
      this.dialoguePageText(dialogue);
    },
    prepEditDialogue: function (dialogue, items) {
      let itemList = null;
      if (items) {
        itemList = _documents.filter((x) =>
          items.some((i) => i.doc_id === x.doc_id)
        );
      } else {
        itemList = this.compareDocs;
      }
      dialogue.items = itemList.map((x) => {
        this.attributes.forEach((a) => {
          x["change_" + a.value_column] = false;
        });
        this.hierarchies.forEach((h) => {
          x["change_ht_id_" + h.ht_id] = false;
        });
        return x;
      });
      if (dialogue.items.length === 0) return;
      dialogue.canSave = false;
      dialogue.columns.splice(0, dialogue.columns.length);
      this.attributes
        .filter((a) => !a.editUnavailable)
        .forEach((a, ai) => {
          let val = dialogue.items[0][a.value_column];
          let same = dialogue.items.every((x) => x[a.value_column] === val);
          dialogue.columns.push({
            column: a.column,
            value_column: a.value_column,
            data_type: a.data_type,
            value_data_type: a.value_data_type,
            label: a.value_label,
            tpa_id: a.tpa_id || ai + 1,
            same: same,
            value: same ? val : null,
            values: a.values ? a.values.map((v) => v.value) : null,
            isDirty: false,
          });
        });
      this.hierarchyTypes.forEach((h) => {
        let val = dialogue.items[0]["ht_id_" + h.ht_id];
        let text = dialogue.items[0][`ht_id_${h.ht_id}_text`];
        let hLabel = dialogue.items[0][`ht_id_${h.ht_id}_label`].split(" > ");
        let same = dialogue.items.every((x) => x["ht_id_" + h.ht_id] === val);
        dialogue.columns.push({
          column: "ht_id_" + h.ht_id,
          value_column: "ht_id_" + h.ht_id,
          label: h.label,
          ht_id: h.ht_id,
          linklevel: h.linklevel,
          same: same,
          value: same ? val : null,
          text: same ? text : null,
          isDirty: false,
          hLabel: same ? hLabel : [],
        });
      });
      this.initPaging(dialogue);
    },
    afterDocumentUpdate: (context, updatedDocs) => {
      if (updatedDocs) {
        context.appendHierarchiesToRawData(updatedDocs);
        updatedDocs.forEach((x) => {
          let ind = _documents.findIndex((d) => d.doc_id === x.doc_id);
          if (ind >= 0) {
            _documents.splice(ind, 1, x);
          } else {
            _documents.push(x);
          }
        });
      }
      context.selectJDs();
    },
    saveDocs: function () {
      let updated = false;
      let singleDoc = false; //this.editDialogue.items.length === 1;
      let data = { updates: [] };
      let ht = null;
      this.editDialogue.items.forEach((d) => {
        this.editDialogue.columns.forEach((a) => {
          if (
            d[a.value_column] !== a.value &&
            (d["change_" + a.value_column] || singleDoc)
          ) {
            d[a.value_column] = a.value;
            updated = true;
            data.updates.push({
              doc_id: d.doc_id,
              tpa_id: a.tpa_id,
              ht_id: a.ht_id,
              value: a.value,
            });
            if (a.ht_id) {
              if (!ht || ht.ht_id !== a.ht_id)
                ht = this.hierarchyTypes.find((ht) => ht.ht_id === a.ht_id);
              this.setDocHierarchyValues(d, ht, a.value);
            }
          }
        });
      });
      let afterUpdate = (updatedDocs) => {
        this.resetCompare();
        this.afterDocumentUpdate(this, updatedDocs);
        this.editDialogue.show = false;
      };
      if (updated) {
        if (this.isDemo) {
          afterUpdate();
        } else {
          let possibleError = false;
          this.isSaving = true;

          axios
            .post("document/saveDocAttributes/", data)
            .then((resp) => {
              possibleError = true;
              if (resp.data.Status === "OK") {
                afterUpdate(resp.data.Data.docs);
              }
              this.response = resp.data;
              this.isSaving = false;
            })
            .catch((err) => {
              if (possibleError) {
                alert("Code Error");
              } else if (err.response && err.response.status === 401) {
                this.$emit("sessionExpired", err);
              } else {
                alert(err.response ? err.response.data.message : err);
              }
              console.log(err);
              this.isSaving = false;
            });
        }
      }
    },
    reviewHierarchy: function () {
      let data = { node: this.nodeDescriptorView.node };
      let possibleError = false;
      this.isSaving = true;

      axios
        .post("document/createHierarchyReviewDocument/", data)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.openDocument(resp.data.Data);
          }
          this.response = resp.data;
          this.isSaving = false;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isSaving = false;
        });
    },
    editDialogueCheckCount(attribute) {
      let col = "change_" + attribute;
      return this.editDialogue.items.filter((x) => x[col]).length;
    },
    editDialogueCheckAll(attribute) {
      let col = "change_" + attribute;
      let onCount = 0;
      let offCount = 0;
      this.editDialogue.items.forEach((x) => {
        x[col] ? onCount++ : offCount++;
      });
      let newVal = onCount >= offCount ? false : true;
      this.editDialogue.items.forEach((x) => (x[col] = newVal));
      this.setCanSave();
    },
    dialogueNextPage(dialogue, plus) {
      dialogue.page += plus ? 1 : -1;
      if (dialogue.page >= dialogue.pages) dialogue.page = dialogue.pages - 1;
      else if (dialogue.page < 0) dialogue.page = 0;
      this.dialoguePageText(dialogue);
    },
    dialoguePageText(dialogue) {
      dialogue.colSize = "";
      if (dialogue.pages === 1) {
        dialogue.pageText = `(1 to ${dialogue.items.length} of ${dialogue.items.length})`;
      } else {
        let endno = (dialogue.page + 1) * dialogue.pageSize;
        if (endno > dialogue.items.length) {
          endno = dialogue.items.length;
          dialogue.colSize = parseInt(12 / dialogue.pageSize);
        }
        dialogue.pageText = `(${
          dialogue.page * dialogue.pageSize + 1
        } to ${endno} of ${dialogue.items.length})`;
      }
    },
    // identifySimilarJobs(doc) {
    //   let matchBands = [
    //     {
    //       name: "91 - 100%",
    //       lower: 91,
    //       upper: 100,
    //       count: 0,
    //       pct: 0,
    //       items: [],
    //     },
    //     { name: "81 - 90%", lower: 81, upper: 90, count: 0, pct: 0, items: [] },
    //     { name: "90 - 80%", lower: 71, upper: 80, count: 0, pct: 0, items: [] },
    //     { name: "90 - 70%", lower: 61, upper: 70, count: 0, pct: 0, items: [] },
    //     { name: "90 - 60%", lower: 51, upper: 60, count: 0, pct: 0, items: [] },
    //     { name: "90 - 50%", lower: 41, upper: 50, count: 0, pct: 0, items: [] },
    //     { name: "90 - 40%", lower: 31, upper: 40, count: 0, pct: 0, items: [] },
    //     { name: "90 - 30%", lower: 21, upper: 30, count: 0, pct: 0, items: [] },
    //     { name: "11 - 20%", lower: 11, upper: 20, count: 0, pct: 0, items: [] },
    //     { name: "0 - 10%", lower: 0, upper: 10, count: 0, pct: 0, items: [] },
    //   ];
    //   let reqScore = 0;
    //   let reqSkills = doc.skills.map((x) => {
    //     let score =
    //       this.skillLevels.find((l) => l.level === x.level)?.score || 1;
    //     reqScore += score;
    //     return {
    //       name: x.name,
    //       score: score + 1,
    //     };
    //   });
    //   _documents.forEach((x) => {
    //     let score = 0;
    //     reqSkills.forEach((s) => {
    //       let hasSkill = x.skills.find((ds) => ds.name === s.name);
    //       if (hasSkill) {
    //         score += s.score;
    //         score -= Math.abs(
    //           s.score -
    //             this.skillLevels.find((l) => l.level === hasSkill.level)
    //               ?.score || 1
    //         );
    //       }
    //     });
    //     let result = parseInt((100 * score) / reqScore);
    //     let matchBand = matchBands.find(
    //       (b) => b.lower <= result && b.upper >= result
    //     );
    //     matchBand.count++;
    //     matchBand.items.push(x.system_number);
    //   });
    //   matchBands.forEach((x) => (x.pct = (100 * x.count) / _documents.length));
    //   this.tagsDialogue.matchBands = matchBands;
    //   let matches = _documents.filter(
    //     (x) =>
    //       x.skills.length === doc.skills.length &&
    //       doc.skills.every((ds) =>
    //         x.skills.some((xs) => xs.name === ds.name && xs.level === ds.level)
    //       )
    //   );

    //   alert(matches.length + " jobs with same skill profile");
    // },
    getNewTitle() {
      return _newTitle;
    },
    saveDefinition() {
      this.viewDefinition.view = this.selectedView;
      this.viewDefinition.saveTrigger++;
    },
    saveDefinitionAs() {
      let view = JSON.parse(JSON.stringify(this.selectedView));
      view.definition.primary = null;
      view.definition.isBase = false;
      this.viewDefinition.view = view;
      this.viewDefinition.saveAsTrigger++;
    },
    deleteDefinition() {
      this.viewDefinition.view = this.selectedView;
      this.viewDefinition.inactiveTrigger++;
    },
    viewAdded(view) {
      this.selectedView.definition = JSON.parse(
        JSON.stringify(this.selectedView.orginalDefinition)
      );
      this.selectedView.isDirty = false;
      view.isDirty = false;
      view.orginalDefinition = JSON.parse(JSON.stringify(view.definition));
      this.views.push(view);
      this.selectedView = view;
    },
    viewInactive(view) {
      let ind;
      let indP;
      if (view.view_definition_id) {
        ind = this.views.findIndex(
          (x) => x.view_definition_id === view.view_definition_id
        );
        indP = this.primaryViews.findIndex(
          (x) => x.view_definition_id === view.view_definition_id
        );
      }
      if (ind < 0) {
        ind = this.views.findIndex(
          (x) => x.name === view.name && x.restricted === view.restricted
        );
        indP = this.primaryViews.findIndex(
          (x) => x.name === view.name && x.restricted === view.restricted
        );
      }
      this.selectedView = null;
      this.views.splice(ind, 1);
      if (indP >= 0) this.primaryViews.splice(ind, 1);
      this.selectedView = this.views[0];
    },
    viewSaved(view) {
      let ind;
      if (view.view_definition_id) {
        ind = this.views.findIndex(
          (x) => x.view_definition_id === view.view_definition_id
        );
      }
      if (ind < 0) {
        ind = this.views.findIndex(
          (x) => x.name === view.name && x.restricted === view.restricted
        );
      }
      view.orginalDefinition = JSON.parse(JSON.stringify(view.definition));
      this.selectedView.isDirty = false;
      this.selectedView.view_definition_id = view.view_definition_id;
      view.isDirty = false;
      this.selectedView = view;
      this.views.splice(ind, 1, view);
    },
    resetDefinition() {
      this.selectedView.definition = JSON.parse(
        JSON.stringify(this.selectedView.orginalDefinition)
      );
      this.selectedView.isDirty = false;
      this.setView();
    },
    getDefinitionChanges() {
      let changes = [];
      if (!this.selectedView.orginalDefinition) return changes;
      let orig = this.selectedView.orginalDefinition;
      let current = this.selectedView.definition;
      if (orig.tmpl_id !== current.tmpl_id)
        changes.push("Document Type updated");
      if (
        orig.listviewColumnPickerVisible !== current.listviewColumnPickerVisible
      )
        changes.push("Column Picker visibility changed");
      if (orig.nameChangeFiltersVisible !== current.nameChangeFiltersVisible)
        changes.push("Name Change Filter visibility changed");

      if (orig.horCompareOptions?.length !== current.horCompareOptions?.length)
        changes.push("Horizontal Axis columns changed");
      else if (
        !orig.horCompareOptions?.every(
          (x, xi) => x.column === current.horCompareOptions[xi].column
        )
      )
        changes.push("Horizontal Axis subject changed");

      if (orig.verCompareOptions?.length !== current.verCompareOptions?.length)
        changes.push("Vertical Axis columns changed");
      else if (
        !orig.verCompareOptions?.every(
          (x, xi) => x.column === current.verCompareOptions[xi].column
        )
      )
        changes.push("Vertical Axis subject changed");

      if (orig.detCompareOptions?.length !== current.detCompareOptions?.length)
        changes.push("Detail Axis columns changed");
      else if (
        !orig.detCompareOptions?.every(
          (x, xi) => x.column === current.detCompareOptions[xi].column
        )
      )
        changes.push("Drilldown Axis subject changed");

      if (
        JSON.stringify(orig.listViewColumnsEnabled) !==
        JSON.stringify(current.listViewColumnsEnabled)
      )
        changes.push("Listview Columns changed");
      if (
        JSON.stringify(orig.measuresVisible) !==
        JSON.stringify(current.measuresVisible)
      )
        changes.push("Measures Visisble changed");
      if (orig.groupedViewShowDescriptor !== current.groupedViewShowDescriptor)
        changes.push("Node Descriptor visibility changed");
      if (orig.nodeSortProperty !== current.nodeSortProperty)
        changes.push("Node Sort changed");
      if (orig.measureDisplayType !== current.measureDisplayType)
        changes.push("Measure Display changed");
      if (
        JSON.stringify(orig.attributeFilters) !==
        JSON.stringify(current.attributeFilters)
      )
        changes.push("Filters changed");
      if (
        JSON.stringify(orig.hierarchyFilters) !==
        JSON.stringify(current.hierarchyFilters)
      )
        changes.push("Hierarchy Filters changed");
      if (
        JSON.stringify(orig.columnFilters) !==
        JSON.stringify(current.columnFilters)
      )
        changes.push("Column Filters changed");
      if (
        JSON.stringify(orig.drilldownFilters) !==
        JSON.stringify(current.drilldownFilters)
      )
        changes.push("Drilldown Filters changed");
      if (
        JSON.stringify(orig.transactionTypeFilters) !==
        JSON.stringify(current.transactionTypeFilters)
      )
        changes.push("Transaction Type Filters changed");
      if (
        JSON.stringify(orig.nameChangeFilters) !==
        JSON.stringify(current.nameChangeFilters)
      )
        changes.push("Name Change Filters changed");
      if (orig.measureFilter !== current.measureFilter)
        changes.push("Measure Filter changed");
      return changes;
    },
    setDefinitionDescription() {
      this.selectedView.description = this.getViewDescription(
        this.selectedView.definition
      );
    },
    getViewDescription(view) {
      let text = `<div class="container" style="min-width: 600px;">`;
      let docType = this.docTypeSummary.find((x) => x.tmpl_id === view.tmpl_id);
      let hryTpe = this.hierarchyTypes.find((x) => x.ht_id === view.ht_id);
      let hCompare = this.compareOptionsH
        .filter((x) =>
          view.horCompareOptions?.some((o) => o.column === x.column)
        )
        .map((x) => x.name)
        .join(", ");
      let vComp = this.compareOptionsV
        .filter((x) =>
          view.verCompareOptions?.some((o) => o.column === x.column)
        )
        .map((x) => x.name)
        .join(", ");
      let dComp = this.compareOptionsD
        .filter((x) =>
          view.detCompareOptions?.some((o) => o.column === x.column)
        )
        .map((x) => x.name)
        .join(", ");
      let visibleMeasures = view.measuresVisible
        ?.map((m) => this.measures.find((x) => x.type === m))
        .map((x) => `<li>${x.title}</li>`)
        .join("");
      let textVisibleMeasures = "";
      if (visibleMeasures) {
        textVisibleMeasures += `<div class="row"><div class="col">Showing${
          view.groupedViewShowDescriptor ? " Descriptors and" : ""
        }:<ul>`;
        textVisibleMeasures += visibleMeasures;
        textVisibleMeasures += `</ul></div></div>`;
      }
      let lvCols = view.listViewColumnsEnabled?.map((x) =>
        this.listView.columns.find((c) => c.value === x)
      );
      switch (view.viewType) {
        case "catalogue":
          text += `<div class="row"><div class="col">`;
          text += `<h3>Summary View based on ${docType.docType}s</h3>`;
          text += `</div></div>`;
          text += `<div class="row"><div class="col">`;
          text += "<h4>";
          text += vComp ? vComp : "";
          text += hCompare ? " by " + hCompare : "";
          text += dComp ? " by " + dComp : "";
          text += "</h4>";
          text += `</div></div>`;
          text += textVisibleMeasures;
          break;
        case "list":
          text += `<div class="row"><div class="col">`;
          text += `<h3>${hryTpe.label} Listview based on ${docType.docType}s</h3>`;
          text += `</div></div><div class="row">`;
          text += `<div class="col col-6">Grouped by:<ul>`;
          text += lvCols
            .filter((c) => !c.measure && (!c.ht_id || c.ht_id === view.ht_id))
            .map((x) => `<li>${x.text}</li>`)
            .join("");
          text += "</ul></div>";
          text += `<div class="col col-6">Showing:<ul>`;
          text += lvCols
            .filter((x) => x.measure)
            .map((x) => `<li>${x.text}</li>`)
            .join("");
          text += "</ul></div></div>";
          break;
        case "grouped":
          text += `<div class="row"><div class="col">`;
          text += `<h3>${hryTpe.label} Grouped view based on ${docType.docType}s</h3>`;
          text += `</div></div>`;
          text += textVisibleMeasures;
          break;
      }
      if (this.filterList.length) {
        text += `<div class="row"><div class="col">Filtered by:`;
        text += `<ul>`;
        text += this.filterList.map((f) => `<li>${f.name}</li>`).join("");
        text += "</ul></div></div>";
      }
      text += "</div>";
      return text;
    },
    showFilters() {
      if (this.viewDefinition.tab === 0) {
        this.viewDefinition.showDesigner = !this.viewDefinition.showDesigner;
      } else {
        this.viewDefinition.showDesigner = true;
        this.viewDefinition.tab = 0;
      }
    },
    showDesigner() {
      if (this.viewDefinition.tab > 0) {
        this.viewDefinition.showDesigner = !this.viewDefinition.showDesigner;
      } else {
        this.viewDefinition.showDesigner = true;
        this.viewDefinition.tab = 1;
      }
    },
    updateViewDefinition() {
      this.selectedView.isDirty = true;
      this.selectedView.definition.attributeFilters = this.compareOptionsV
        .filter((x) => x.filterValues.length)
        .map((f) => {
          return {
            column: f.column,
            filterValues: f.filterValues,
          };
        });
      this.selectedView.definition.hierarchyFilters = this.hierarchyFilters
        .filter((x) => x.selected.length)
        .map((x) => {
          return {
            ht_id: x.ht_id,
            label: x.label,
            selected: x.selected.map((s) => {
              return {
                id: s.id,
                value: s.value,
              };
            }),
          };
        });
      this.selectedView.definition.columnFilters = this.lvColumnsFiltered
        .filter(
          (x) =>
            x.filterSettings && x.filterSettings.values.some((v) => v.selected)
        )
        .map((v) => {
          return {
            column: v.value,
            filterValues: v.filterSettings.values
              .filter((vv) => vv.selected)
              .map((vv) => vv.text),
          };
        });
      this.selectedView.definition.drilldownFilters = this.drilldownFilters.map(
        (df) => {
          return {
            column: df.column,
            value: df.value,
            ht_id: df.ht_id,
            compareOptionName: df.compareOptionName,
            hLevel: df.level,
          };
        }
      );
      this.setDefinitionDescription();
    },
    defAttrVisible(name) {
      let vt = this.viewTypes.find(
        (x) => x.type === this.selectedView.definition.viewType
      );
      return vt?.settings.some((x) => x === name);
    },
    changeViewSetting(name) {
      this.selectedView.isDirty = true;
      switch (name) {
        case "measureDisplayType":
          this.buildCompare();
          this.updateViewDefinition();
          break;
        case "measuresVisible":
          if (this.selectedView.definition.viewType === "catalogue")
            this.buildCompare();
          else if (this.selectedView.definition.viewType === "grouped")
            this.buildGroupedViewDocs();
          this.updateViewDefinition();
          break;
        case "groupedViewShowDescriptor":
          this.buildGroupedViewDocs();
          this.updateViewDefinition();
          break;
        case "nodeSortProperty":
          this.buildGroupedViewDocs();
          this.updateViewDefinition();
          break;
        case "listViewColumnsEnabled":
          this.buildGroupedDocs();
          this.updateViewDefinition();
          break;
        case "detailCompareOption":
          this.setViewCompareOption("detailCompareOption");
          this.buildCompare();
          this.updateViewDefinition();
          break;
        case "tmpl_id":
          this.changeDocType(true);
          break;
        case "transactionTypeFilters":
          this.selectJDs();
          break;
        case "nameChangeFilters":
          this.selectJDs();
          break;
      }
    },
    // editDocSkills(doc) {
    //   this.docSkillDialogue.doc = doc;
    //   //   let docskills = JSON.parse(JSON.stringify(doc.skills));
    //   this.docSkillDialogue.sections = this.skillCats.map((x) => {
    //     return {
    //       category: x.category,
    //       skills: doc.skills.filter((s) => s.category === x.category),
    //     };
    //   });
    //   this.docSkillDialogue.show = true;
    // },
    // addDocSkill(doc, skill, catIndex, skillIndex) {
    //   let raw = _documents.find((x) => x.system_number === doc.system_number);
    //   doc.skills.findIndex((x) => x.name === skill);
    //   if (!skill) {
    //     let sk = this.tagsDialogue.sections[catIndex].skills[skillIndex];
    //     raw.skills.push(JSON.parse(JSON.stringify(sk)));
    //     this.tagsDialogue.sections = this.buildDocCompareSkillsMatrix();
    //   }
    // },
    // removeDocSkill(doc, skill) {
    //   let raw = _documents.find((x) => x.system_number === doc.system_number);
    //   let ind = raw?.skills.findIndex((x) => x.name === skill);
    //   if (ind >= 0) raw.skills.splice(ind, 1);
    //   ind = doc.skills.findIndex((x) => x.name === skill);
    //   if (ind >= 0) doc.skills.splice(ind, 1);
    //   if (
    //     this.docSkillDialogue.doc &&
    //     this.docSkillDialogue.doc.system_number === doc.system_number
    //   ) {
    //     ind = this.docSkillDialogue.doc.skills.findIndex(
    //       (x) => x.name === skill
    //     );
    //     if (ind >= 0) this.docSkillDialogue.doc.skills.splice(ind, 1);
    //     this.docSkillDialogue.sections.forEach((c) => {
    //       ind = c.skills.findIndex((x) => x.name === skill);
    //       if (ind >= 0) c.skills.splice(ind, 1);
    //     });
    //   }
    //   let sdDoc = this.tagsDialogue.items.find(
    //     (x) => x.system_number === doc.system_number
    //   );
    //   if (sdDoc) {
    //     ind = sdDoc.skills.findIndex((x) => x.name === skill);
    //     if (ind >= 0) sdDoc.skills.splice(ind, 1);
    //     this.tagsDialogue.sections = this.buildDocCompareSkillsMatrix();
    //     sdDoc.sections.forEach((c) => {
    //       ind = c.skills.findIndex((x) => x && x.name === skill);
    //       if (ind >= 0) delete c.skills[ind];
    //     });
    //   }
    // },
    // updateSkillLevel(docSkill, level) {
    //   docSkill.level = level.level;
    //   docSkill.colour = level.colour;
    //   docSkill.textColour = level.textColour;
    // },
    // setSkillLevel(skills, skill, level) {
    //   let item = skills.find((s) => s.name === skill);
    //   this.updateSkillLevel(item, level);
    // },
    // getDocSkill(skills, skill) {
    //   return skills.find((s) => s.name === skill);
    // },
    getShortcuts(callback) {
      this.$store
        .dispatch("settings/getViewDefinitionForUser", this.page + "-shortcuts")
        .then((resp) => {
          this.shortcuts.items = resp.data.Data.length
            ? resp.data.Data[0].definition.items
            : [];
          this.shortcuts.view_definition_id = resp.data.Data.length
            ? resp.data.Data[0].view_definition_id
            : null;
          if (callback) callback();
        }),
        (error) => {
          console.error(error);
        };
    },
    openShortcuts(e) {
      this.shortcuts.show = false;
      this.shortcuts.isDirty = false;
      this.shortcuts.posX = e.clientX;
      this.shortcuts.posY = e.clientY;
      let editItems = JSON.parse(JSON.stringify(this.shortcuts.items));
      editItems.forEach((i) => {
        i.delete = false;
        i.isNew = false;
      });
      this.shortcuts.editItems = editItems;
      if (this.measures.length) {
        this.$nextTick(() => {
          this.shortcuts.show = true;
        });
      }
    },
    toggleRemoveShortcut(s) {
      if (s.isNew) {
        this.shortcuts.editItems.splice(0, 1);
      } else {
        s.delete = !s.delete;
        this.shortcuts.isDirty = true;
      }
    },
    cancelShortcuts() {
      this.shortcuts.show = false;
    },
    addShortcut() {
      this.shortcuts.isDirty = true;
      this.shortcuts.editItems.unshift({
        name: "",
        isNew: true,
        icon: null,
        position: this.shortcuts.editItems.length + 1,
        isDefault: false,
      });
    },
    saveShortcuts() {
      let possibleError = false;
      let data = {
        definition: {
          items: JSON.parse(
            JSON.stringify(this.shortcuts.editItems.filter((x) => !x.delete))
          ),
          default: this.shortcuts.default,
        },
        view_type: this.page + "-shortcuts",
        active: true,
        restricted: true,
        view_definition_id: this.shortcuts.view_definition_id,
        name: "Job catalogue Shortcuts",
        description: "Job catalogue Shortcuts",
      };
      data.definition.items.forEach((x) => {
        delete x.delete;
        delete x.isNew;
        x.isDefault = x.name === this.shortcuts.default;
      });
      axios
        .post("user/saveViewDefinition/", data)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            if (resp.data.Data) {
              if (!data.view_definition_id) {
                this.shortcuts.view_definition_id =
                  resp.data.Data.view.view_definition_id;
              }
              this.shortcuts.items = data.definition.items;
              this.setPrimaryViews();
            }
            this.shortcuts.show = false;
          }
          this.response = resp.data;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    setPrimaryViews() {
      let scItems = this.shortcuts.items;
      if (scItems.length) {
        this.primaryViews = this.views.filter((v) => {
          let sc = scItems.find((s) => s.name === v.name);
          if (sc) {
            v.definition.primary = { icon: sc.icon, position: sc.position };
            v.definition.isDefault = sc.isDefault;
            return true;
          } else {
            return false;
          }
        });
      } else {
        //initial setup still in use
        this.primaryViews = this.views.filter((v) => v.definition.primary);
        scItems = this.primaryViews.map((v) => {
          return {
            name: v.name,
            icon: v.definition.primary.icon,
            position: v.definition.primary.position,
            isDefault: v.definition.isDefault,
          };
        });
      }
      this.shortcuts.default = scItems.find((x) => x.isDefault)?.name;
      this.primaryViews.sort((a, b) =>
        a.definition.primary.position > b.definition.primary.position
          ? 1
          : a.definition.primary.position < b.definition.primary.position
          ? -1
          : 0
      );
      scItems.sort((a, b) =>
        a.position > b.position ? 1 : a.position < b.position ? -1 : 0
      );
      scItems.forEach((i) => (i.delete = false));
      if (!this.shortcuts.items.length) {
        this.shortcuts.items = scItems;
      }
    },
    openMeasurePicker(e) {
      this.measurePicker.show = false;
      this.measurePicker.posX = e.clientX;
      this.measurePicker.posY = e.clientY;
      if (this.measures.length) {
        this.$nextTick(() => {
          this.measurePicker.show = true;
        });
      }
    },
    openDocListMenu(e, item, measure) {
      if (e.preventDefault) e.preventDefault();
      this.docListMenu.show = false;
      this.docListMenu.posX = e.clientX;
      this.docListMenu.posY = e.clientY;
      let JF = this.hierarchyTypes
        .filter((x) => x.ht_name.toLowerCase().indexOf("job") >= 0)
        .map((x) => {
          return {
            ht_id: x.ht_id,
            ht_name: x.ht_name,
            h_levels: x.h_levels,
            linklevel: x.linklevel,
          };
        });
      if (JF.length) JF = JF[0];

      let docs = item ? this.getDocs(item) : this.compareDocs;
      this.docListMenu.hierarchySuggestions.hierarchyType = JF;
      //const emptyList = ["UNCLASSIFIED", ""];
      //this.docListMenu.hierarchySuggestions.show = docs.some(
      //  (x) =>
      //    emptyList.some(
      //      (e) => e === (x[`ht_id_${JF?.ht_id}_1_`] || "").trim()
      //    ) ||
      //    emptyList.some(
      //      (e) => e === (x[`ht_id_${JF?.ht_id}_2_`] || "").trim()
      //    ) ||
      //    emptyList.some((e) => e === (x[`ht_id_${JF?.ht_id}_3_`] || "").trim())
      //);
      this.docListMenu.hierarchySuggestions.show = true;
      this.docListMenu.matchColumn =
        measure && measure.isMatchCount ? measure.column : null;
      this.docListMenu.documentType = this.catalogueDocType.docType;
      if (this.docListMenu.matchColumn) {
        docs = docs.filter((d) => d[this.docListMenu.matchColumn].length > 0);
      }
      this.docListMenu.documents = docs.sort((a,b) => a.doc_name.toLowerCase() > b.doc_name.toLowerCase() ? 1 : a.doc_name.toLowerCase() < b.doc_name.toLowerCase() ? -1 : 0);
      this.docListMenu.title =
        measure?.description || this.catalogueDocType.docType;
      if (this.docListMenu.documents.length) {
        this.$nextTick(() => {
          this.docListMenu.show = true;
        });
      }
    },
    buildMatchItem(item, matchColumns) {
      let match = "";
      if (item)
        matchColumns.forEach((c) => {
          match += this.buildMatchKey(c, item[c]);
        });
      return match;
    },
    getDocs(item) {
      if (item.job_count === 0) return [];
      const match_key = item.match_key;
      return this.selectedDocs.filter(
        (x) =>
          x.doc_id > 0 &&
          this.buildMatchItem(x, item.matchColumns) === match_key
      );
    },
    openContextMenu(e, item, column) {
      this.contextMenu.item = item;
      this.contextMenu.column = column;
      e.preventDefault();
      this.contextMenu.show = false;
      this.contextMenu.posX = e.clientX;
      this.contextMenu.posY = e.clientY;
      let itemText = item[column.value];
      this.contextMenu.title = itemText;
      let actions = [];
      if (column) {
        if (
          column.filterSettings.values.some(
            (x) => x.selected && x.text === itemText
          )
        ) {
          actions.push({
            title: `Remove from Filter`,
            icon: "mdi-filter-minus-outline",
            type: "removeFilter",
            column: null,
          });
        } else {
          actions.push({
            title: `Add to filter`,
            icon: "mdi-filter-plus-outline",
            type: "addFilter",
            column: null,
          });
        }
      }
      this.contextMenu.actions = actions;
      this.$nextTick(() => {
        this.contextMenu.show = true;
      });
    },
    doContextMenuAction(action) {
      switch (action.type) {
        case "addFilter":
          this.setFilterValue(this.contextMenu.item, this.contextMenu.column);
          break;
        case "removeFilter":
          this.removeFilterValue(
            this.contextMenu.item,
            this.contextMenu.column
          );
          break;
      }
      this.contextMenu.show = false;
    },
    openDocActionsMenuContext(event, document) {
      event.preventDefault();
      let doc = { doc_id: document.doc_id };
      if (document.isDocument) {
        doc.doc_id = _documents.find(
          (x) => x.system_number === document.value
        )?.doc_id;
      }
      this.docActionsMenuContext.document = doc;
      this.docActionsMenuContext.posX = event.clientX;
      this.docActionsMenuContext.posY = event.clientY;
      this.docActionsMenuContext.show = false;
      this.$nextTick(() => {
        this.docActionsMenuContext.show = true;
      });
    },
    setDescriptorHeight: function (rowHeading) {
      let lines =
        rowHeading.detailCountHeader.filter(
          (x) =>
            x.dIndex >= this.vScroll.dStart && this.vScroll.dEnd >= x.dIndex
        ).length + 1;
      if (this.detCompareOptions.length === 0) {
        return "descriptor descriptor1line";
      } else if (lines < 8) {
        return "descriptor descriptor" + lines + "line";
      } else {
        return "descriptor descriptor8line";
      }
    },
    selectView: function (v) {
      if (v.definition.link) {
        let params;
        try {
          params = {
            sourcePath: "hierarchyview",
            sourceView: this.selectedView.name,
            sourceTitle: `Job Architecture - ${this.selectedView.name}`,
          };
          this.$passedParams.set(params);
        } catch (e) {
          console.log(e);
        }
        this.$router.push({ path: v.definition.link });
      } else {
        this.selectedView = v;
      }
    },
    initSetView: function () {
      this.isBuilding = true;
      this.resetIsBuilding = false;
      this.doSetView = true;
    },
    setView: function () {
      this.doSetView = false;
      this.resetCompare();
      if (!this.selectedView.orginalDefinition)
        this.selectedView.orginalDefinition = JSON.parse(
          JSON.stringify(this.selectedView.definition)
        );
      if (
        this.selectedView.definition.tmpl_id !== this.catalogueDocType.tmpl_id
      ) {
        this.changeDocType();
        return;
      }
      if (this.selectedView.definition.viewType === "audit") {
        this.showAuditReport(this.selectedView.definition.ht_id);
        return;
      } else if (this.selectedView.definition.viewType === "audit_data") {
        this.showAuditData(this.selectedView.definition.ht_id);
        return;
      }
      let setOption_ = (property, collection) => {
        if (this.selectedView.definition[property]) {
          this[property] = [];
          this.selectedView.definition[property].forEach((o) => {
            let usedDrilldown = o.currentDrillDownLevel;
            let optDef = this[collection].find((x) => x.column === o.column);
            if (o.style) optDef.style = o.style;
            if (usedDrilldown !== undefined) {
              o.currentDrillDownLevel = 1;
              optDef.currentDrillDownLevel = 1;
            } else delete o.currentDrillDownLevel;
            this[property].push(optDef);
            // if (usedDrilldown !== undefined) o.currentDrillDownLevel = 1;
          });
        }
      };
      setOption_("horCompareOptions", "compareOptionsH");
      setOption_("verCompareOptions", "compareOptionsV");
      setOption_("detCompareOptions", "compareOptionsD");
      this.compareOptionsV.forEach((c) => {
        let preset = this.selectedView.definition.attributeFilters.find(
          (x) => x.column === c.column
        );
        c.filterValues = preset ? preset.filterValues : [];
      });
      this.setPreSelectHierarchyFilters();
      this.lvColumnsFiltered
        .filter((c) => c.filterSettings)
        .forEach((c) => {
          let preset = this.selectedView.definition.columnFilters.find(
            (x) => x.column === c.value
          );
          c.filterSettings.values.forEach(
            (v) =>
              (v.selected = preset
                ? preset.filterValues.some((f) => f === v.text)
                : false)
          );
          c.filterSettings.isActive = c.filterSettings.values.some(
            (x) => x.selected
          );
        });
      this.drilldownFilters = [];
      this.selectJDs(true);
      this.selectedView.definition.drilldownFilters.forEach((d) => {
        if (d.compareOptionName === "verCompareOptions") {
          let rowDetail = this.layout.detailRow.rowDetails.find(
            (x) => x.row.keyVal === d.value
          );
          if (rowDetail)
            this.doDrilldown(
              rowDetail.row.rowHeadings[0].node,
              d.compareOptionName,
              true
            );
        } else if (d.compareOptionName === "horCompareOptions") {
          let colDetail = this.layout.headerRow.columns.find(
            (x) => x.title === d.value
          );
          if (colDetail)
            this.doDrilldown(
              colDetail.headerColumns[0].node,
              d.compareOptionName,
              true
            );
        }
      });
    },
    setPreSelectHierarchyFilters: function () {
      let getNode = (items, id) => {
        let node = items.find((x) => x.id === id);
        let index = 0;
        while (!node && index < items.length && items[index].children?.length) {
          node = getNode(items[index].children, id);
          index++;
        }
        return node;
      };
      let preSelectedHierarchies = [];
      this.hierarchyFilters.forEach((c) => {
        let preset = this.selectedView.definition.hierarchyFilters.find(
          (x) => x.ht_id === c.ht_id
        );
        if (preset) {
          preset = JSON.parse(JSON.stringify(preset));
          preset.selected = preset.selected.map((x) => getNode(c.items, x.id));
          preSelectedHierarchies.push(preset);
        }
      });
      this.preSelectedHierarchies = preSelectedHierarchies;
    },
    assignHierarchy: function (data) {
      let possibleError = false;
      this.isSaving = true;
      let self = this;
      axios
        .post("document/saveSuggestedDocHierarchy/", data)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            if (resp.data.Data) {
              if (resp.data.Data.docs?.length) {
                self.afterDocumentUpdate(self, resp.data.Data.docs);
              }
              if (data.callback) data.callback(data.doc_id);
            }
          }
          this.response = resp.data;
          this.isSaving = false;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isSaving = false;
        });
    },
    removeFromDocList: function (doc) {
      let index = this.compareDocs.findIndex((x) => x.doc_id === doc.doc_id);
      if (index >= 0) {
        this.compareDocs.splice(index, 1);
      }
    },
    editAttributes: function (item) {
      let attr = this.attributes.find((x) => x.column === item.column);
      if (!attr) return;
      this.attributeAdmin.attribute = attr;
      this.attributeAdmin.rawValues = attr.lookup_key ? [] : item.listValues;
      this.attributeAdmin.show++;
    },
    lookupUpdated: function (values) {
      let column = this.attributeAdmin.attribute.column;
      let co = this.compareOptionsV.find((x) => x.column === column);
      if (!co) return;
      co.listValues = values;
      this.attributeAdmin.attribute.values = values;
      if (
        this.verCompareOptions.some((o) => o.column === column) ||
        this.horCompareOptions.some((o) => o.column === column)
      ) {
        this.verCompareOptions
          .filter((o) => o.column === column)
          .forEach((o) => o.listValues);
        this.horCompareOptions
          .filter((o) => o.column === column)
          .forEach((o) => o.listValues);
        this.selectJDs();
      }
    },
    lookupCreated: function (values) {
      this.attributeAdmin.attribute.lookup_type = "List Value";
      this.attributeAdmin.attribute.lookup_key =
        this.attributeAdmin.attribute.title;
      this.lookupUpdated(values);
    },
    dragStart: function (event, item) {
      this.dragItem = item;
    },
    allowDrop: function (event, bucket) {
      if (
        !bucket ||
        !bucket.subject ||
        bucket.subject === this.dragItem.node.label
      )
        event.preventDefault();
    },
    addAddition: function (event, bucket) {
      if (event) event.preventDefault();
      if (this.dragItem) {
        if (!bucket.subject) bucket.subject = this.dragItem.node.label;
        bucket.additions.push(this.dragItem);
        // this.dragItem.node.actionPending = true;
        this.setPendingNodes([this.dragItem.node.hierarchy_node_id], true);
      }
    },
    removeAddition: function (bucket, node) {
      //   node.actionPending = false;
      bucket.additions = bucket.additions.filter(
        (x) => x.node.hierarchy_node_id !== node.hierarchy_node_id
      );
      this.setPendingNodes([node.hierarchy_node_id], false);
    },
    removeAdditionAll: function (bucket) {
      this.setPendingNodes(
        bucket.additions.map((a) => a.node.hierarchy_node_id),
        false
      );
      //   bucket.additions.forEach((a) => (a.node.actionPending = false));
      bucket.additions.splice(0, bucket.additions.length);
    },
    removeAdditionNode: function (node) {
      let bucket = this.dragTarget.buckets.find((b) =>
        b.additions.some(
          (a) => a.node.hierarchy_node_id === node.hierarchy_node_id
        )
      );
      if (bucket) this.removeAddition(bucket, node);
    },
    addBucketPlus: function (event) {
      let bucket =
        this.dragTarget.buckets.length === 1 &&
        this.dragItem.node.label === this.dragTarget.buckets[0].subject
          ? this.dragTarget.buckets[0]
          : this.addBucket();
      this.addAddition(event, bucket);
    },
    addBucket: function () {
      let bucket = {
        title: "Workspace " + (this.dragTarget.buckets.length + 1),
        edit: false,
        show: true,
        subject: this.dragItem ? this.dragItem.node.label : null,
        additions: [],
      };
      this.dragTarget.buckets.push(bucket);
      return bucket;
    },
    removeBucket: function (bucket) {
      this.removeAdditionAll(bucket);
      this.dragTarget.buckets = this.dragTarget.buckets.filter(
        (x) => x.title !== bucket.title
      );
    },
    setPendingNodes: function (hierarchy_node_ids, actionPending) {
      if (this.detCompareOptions.some((o) => o.noderule)) {
        this.layout.detailRow.rowDetails.forEach((r) =>
          r.columns.forEach((c) =>
            c.subColumns.forEach((s) =>
              s.categories.forEach((t) =>
                t.rowHeadings
                  .filter(
                    (h) =>
                      h.node &&
                      hierarchy_node_ids.some(
                        (id) => id === h.node.hierarchy_node_id
                      )
                  )
                  .forEach((h) => {
                    h.node.actionPending = actionPending;
                  })
              )
            )
          )
        );
      }
    },
    moveBucket: function (bucket) {
      let node = bucket.additions[0].node;
      let action = {
        action: "move",
        actionDef: {
          title: "Move " + bucket.subject + "s",
          type: "move",
          newLevel: node.level,
          newLevelName: node.level_name,
          currentLevel: node.level,
          currentLevelName: node.level_name,
          column: null,
        },
        node: null,
        listviewColumn: null,
        listviewItem: null,
      };
      this.setUpEditHierarchy(
        null,
        "move",
        action,
        bucket.additions.map((a) => a.node),
        bucket
      );
    },
    getOptionsAvailable: function (axis) {
      let opts = [
        {
          name: "vertical",
          available: this.compareOptionsV,
          used: this.verCompareOptions,
          valueLimit: 0,
        },
        {
          name: "horizontal",
          available: this.compareOptionsH,
          used: this.horCompareOptions,
          valueLimit: 0, //100,
        },
        {
          name: "detail",
          available: this.compareOptionsD,
          used: this.detCompareOptions,
          valueLimit: 0,
        },
      ];
      let dim = opts.find((x) => x.name === axis);
      return dim.available.filter(
        (x) =>
          (!dim.valueLimit ||
            !x.listValueLength ||
            x.listValueLength <= dim.valueLimit) &&
          opts.every((nd) => !nd.used.some((u) => u.column === x.column))
      );
    },
    exportToExcel() {
      let layout = this.layout;
      this.setVisibleHeaderColumns(true);
      let data = {
        rows: [],
        name: this.selectedView.name,
        freeze: { xSplit: 0, ySplit: 0 },
        colWidths: [],
      };
      let leftPanelColsNewRow = [];
      let headerCols = [];

      let addRow = (cols) => {
        data.rows.push({ cols: cols ? cols : [] });
        return data.rows[data.rows.length - 1];
      };

      let copyRow = () => {
        let cols = JSON.parse(
          JSON.stringify(data.rows[data.rows.length - 1].cols)
        );
        cols.forEach((c) => (c.mergeRows = 1));
        return addRow(cols);
      };

      let currentRow = (add) => {
        if (add)
          return addRow(
            leftPanelColsNewRow.map((x) => JSON.parse(JSON.stringify(x)))
          );
        else return data.rows[data.rows.length - 1];
      };

      const addCol = (
        target,
        type,
        text,
        mergeCols,
        style,
        indent,
        mergeRows,
        border
      ) => {
        target.push({
          type: type || "",
          text: text || "",
          mergeCols: mergeCols || 0,
          style: style || null,
          indent: indent || 0,
          mergeRows: mergeRows || 0,
          border: border || null,
        });
      };
      if (layout && layout.headerRow) {
        if (layout.leftPanel.cols) {
          const vAxisHeadingWidth = layout.rightPanel.cols ? (300 * layout.leftPanel.cols) / 12 : 200;
          const titleRatio =
            (vAxisHeadingWidth * layout.leftPanel.colsTitle) / 12;
          const measureRatio =
            (vAxisHeadingWidth * layout.leftPanel.colsTotal) / 12;
          this.verCompareOptions.forEach((v, vi) => {
            addCol(headerCols, "axis-heading-rows", v.name, null, v.style);
            if (layout.rightPanel.cols) data.freeze.xSplit++;
            data.colWidths.push(
              (titleRatio * layout.detailRow.rowHeading.titleWidths[vi].cols) /
                12
            );
          });
          if (layout.leftPanel.colsTotal && this.display.showLeafCount) {
            addCol(
              headerCols,
              "axis-heading-rows",
              this.display.total_col.leafCountLabel
            );
            if (layout.rightPanel.cols) data.freeze.xSplit++;
            data.colWidths.push(
              (measureRatio *
                layout.detailRow.rowHeading.measureWidths[0].cols) /
                12
            );
          }
          if (layout.leftPanel.colsTotal && this.visibleMeasures.length) {
            this.visibleMeasures.forEach((m, mi) => {
              addCol(headerCols, "axis-heading-rows", m.title);
              if (layout.rightPanel.cols) data.freeze.xSplit++;
              data.colWidths.push(
                (measureRatio *
                  layout.detailRow.rowHeading.measureWidths[
                    mi + (this.display.showLeafCount ? 1 : 0)
                  ].cols) /
                  12
              );
            });
          }
          data.rows.push({
            cols: headerCols,
          });
          headerCols.forEach((hc) => {
            addCol(leftPanelColsNewRow, "", "", null, hc.style, 0, 1);
          });
        }
        let groupHeaderRowVIndent = 0;
        let groupHeaderRowHIndent = 0;
        let detailColumnCount = 0;
        if (layout.rightPanel.cols) {
					detailColumnCount = layout.detailRow.detail.widthSplitMeasures.length + (layout.detailRow.detail.widthSplitTitle > 0 ? 1 : 0);
          if (!data.rows.length) addRow();
          addCol(
            data.rows[0].cols,
            "axis-heading-columns",
            this.horCompareOptions
              .map((hc) => this.getCompareOptionDef(hc, "compareOptionsH").name)
              .join(" > "),
            -1,
            null,
            1
          );
          // if (layout.hierarchyHeaderRows.length) {   ### NOT POSSIBLE AT PRESENT
          // layout.hierarchyHeaderRows.forEach(hhr => {
          // 	let row = currentRow(true);
          // 	});
          // }
          if (layout.groupHeaderRow.hRows.length) {
            groupHeaderRowHIndent++;
            layout.groupHeaderRow.hRows.forEach((hr) => {
              let row = currentRow(true);
              addCol(
                row.cols,
                "column-heading",
                hr.value,
                -1,
                null,
                groupHeaderRowHIndent
              );
              groupHeaderRowHIndent++;
            });
          }
        }
        let getNestedWidth = (item) => {
          let tot = 0;
          if (item.children) {
            item.children.forEach((c) =>
              c.rowHeadings.forEach((rh) => (tot += getNestedWidth(rh)))
            );
          } else {
            tot = 1;
          }
          return tot;
        };

        let maxMergeCols = 0;
        let detailsStartRow = 0;
        let detailsEndRow = 0;
        let processNestedColumns = (
          columns,
          targetRow,
          horHeaderRowIndex,
          subjectProperty,
          textProperty,
          colType,
          showMeasures,
          showDetails,
          detailsCount
        ) => {
          columns.forEach((hc, hci) => {
            let subject =
              subjectProperty && hc[subjectProperty]
                ? hc[subjectProperty][0]
                : hc;
            if (subject.children?.length) {
              let row = targetRow;
              let nextHRIndex = horHeaderRowIndex
                ? horHeaderRowIndex + 1
                : horHeaderRowIndex;
              if (nextHRIndex) {
                row =
                  data.rows.length > nextHRIndex
                    ? data.rows[nextHRIndex]
                    : currentRow(true);
              }
              subject.children.forEach((ch, chi) => {
                processNestedColumns(
                  ch.rowHeadings,
                  row,
                  nextHRIndex,
                  subjectProperty,
                  textProperty,
                  colType,
                  showMeasures,
                  showDetails,
                  hci + chi === 0 ? detailsCount : 0
                );
              });
            } else {
              if (showDetails) {
                if (detailsCount && hci === 0) {
                  detailsStartRow = data.rows.length - 1;
                  detailsEndRow = detailsStartRow;
                  for (let i = 1; i < detailsCount; i++) {
                    copyRow();
                    detailsEndRow++;
                  }
                }
                let index = 0;
                for (let i = detailsStartRow; i <= detailsEndRow; i++) {
                  let row = data.rows[i];
                  let category =
                    subject.categories.length &&
                    subject.categories.length > index
                      ? subject.categories[index]
                      : null;
                  let cell = "";
                  if (category) {
                    cell = category.rowHeadings
                      .map(
                        (rh) =>
                          (rh.title_prefix ? rh.title_prefix + ": " : "") +
                          rh.title
                      )
                      .join(" | ");
                  }
                  let border = { right: true };
                  if (i === detailsEndRow) border.bottom = true;
                  addCol(
                    row.cols,
                    colType,
                    cell,
                    null,
                    null,
                    null,
                    null,
                    border
                  );
                  index++;
                data.colWidths.push(this.display.h_col.colWidth * 17 * layout.detailRow.detail.widthSplitTitle);
                this.visibleMeasures.forEach((m, mi) => {
									let prop = m.type + (m.aggregation === "mean" ? "_mean" : "");
                  addCol(
                    row.cols,
                    colType + "-measure",
                    category ? category[prop] : "",
                    null,
                    null,
                    null,
                    null,
                    border
                  );
                data.colWidths.push(this.display.h_col.colWidth * 17 * layout.detailRow.detail.widthSplitMeasures[mi]);
                });
                }
              } else if (showMeasures) {
                this.visibleMeasures.forEach((m, mi) => {
                  let border = mi === this.visibleMeasures.length - 1 ?  { right: true } : null;
									let prop = m.type + (m.aggregation === "mean" ? "_mean" : "");
                  addCol(
                    targetRow.cols,
                    colType + "-measure",
                    subject[prop],
                    null,
                    null,
                    null,
                    null,
                    border
                  );
                data.colWidths.push(this.display.h_col.colWidth * 17 * layout.detailRow.detail.widthSplitMeasures[mi]);
                });
              }
            }
            if (!showDetails && !showMeasures) {

							let mergeCols = (subject.children?.length
								? getNestedWidth(subject)
								: 0) * detailColumnCount; 
								if (mergeCols === 0 && detailColumnCount > 1 && horHeaderRowIndex) mergeCols = detailColumnCount;
              addCol(
                targetRow.cols,
                colType,
                subject.visible === false ? "" : subject[textProperty],
                mergeCols,
                subject.style,
                hci === 0 && groupHeaderRowVIndent
                  ? groupHeaderRowVIndent
                  : null,
                subject.visible === false ? 1 : 0
              );
              maxMergeCols += mergeCols;
            }
          });
        };
        if (layout.rightPanel.cols) {
          let hHeadRow = currentRow(true);
          processNestedColumns(
            layout.headerRow.columns,
            hHeadRow,
            data.rows.length - 1,
            "headerColumns",
            "title",
            "column-heading"
          );
        }
        if (layout.groupHeaderRow.vRows.length) {
          groupHeaderRowVIndent++;
          layout.groupHeaderRow.vRows.forEach((r) => {
            let row = addRow();
            addCol(
              row.cols,
              "row-heading",
              r.value,
              null,
              null,
              groupHeaderRowVIndent
            );
            if (this.display.showLeafCount) {
              addCol(row.cols, "row-heading-measure", r.leaf_node_count);
            }
            this.visibleMeasures.forEach((m) => {
									let prop = m.type + (m.aggregation === "mean" ? "_mean" : "");
              addCol(row.cols, "row-heading-measure", r[prop]);
            });
            processNestedColumns(
              r.columns,
              currentRow(),
              false,
              "subColumns",
              "title",
              "detail",
              true
            );
            groupHeaderRowVIndent += 2;
          });
        }
        //   if (layout.detailRow && layout.detailRow.hierarchyHeadings) {
        //   // tree diagram
        //   }
        if (layout.detailRow) {
          layout.detailRow.rowHeadings.forEach((r, ri) => {
            let row = addRow();
            if (layout.leftPanel.cols > 0) {
              processNestedColumns(
                r.headerColumns,
                currentRow(),
                false,
                null,
                "title",
                "row-heading"
              );
              if (this.display.showLeafCount) {
                addCol(row.cols, "row-heading-measure", r.leaf_node_count);
              }
              this.visibleMeasures.forEach((m) => {
									let prop = m.type + (m.aggregation === "mean" ? "_mean" : "");
                addCol(row.cols, "row-heading-measure", r[prop]);
              });
            }
            if (layout.rightPanel.cols > 0) {
              let rowDetail = layout.detailRow.rowDetails[ri];
              processNestedColumns(
                rowDetail.columns,
                currentRow(),
                false,
                "subColumns",
                "title",
                "detail",
                true,
                this.detCompareOptions.length > 0,
                r.detailCount
              );
            }
          });
        }
        // if (layout.rightPanel.cols) {
        // }
        let colsToMerge = data.rows[0].cols.map((c) => {
          if (c) return { col: null };
        });

        data.rows.forEach((r) => {
          r.cols
            .filter((c, ci) => ci < colsToMerge.length)
            .forEach((c, ci) => {
              if (!c.mergeRows) {
                colsToMerge[ci].col = c;
              } else {
                colsToMerge[ci].col.mergeRows += c.mergeRows;
                c.mergeRows = 0;
              }
            });
        });

        data.rows.forEach((r) =>
          r.cols
            .filter((c) => c.mergeCols === -1)
            .forEach((c) => (c.mergeCols = maxMergeCols))
        );

        data.freeze.ySplit += data.rows.filter((r) =>
          r.cols.some((c) =>
            [
              "column-heading",
              "axis-heading-columns",
              "axis-heading-rows",
            ].some((x) => x === c.type)
          )
        ).length;
      }
      data.colWidths.splice(
        data.rows[data.rows.length - 1].cols.length,
        data.colWidths.length
      );
      this.setVisibleHeaderColumns();
      axios
        .post(`document/generateExcel`, data, {
          responseType: "arraybuffer",
        })
        .then((resp) => {
          const fileName = `${this.$loginState.user.client.replace(
            /[^a-z0-9]/gi,
            ""
          )}_${this.selectedView.name.replace(/[^a-z0-9]/gi, "")}.xlsx`;
          utils.downloadFile(resp.data, fileName, resp.headers["content-type"]);
        })
        .catch((error) => {
          console.log(error);
          this.response = {
            Status: "Error",
            Message: `Error generating permission summary`,
          };
        });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/vars";

.selectViewMenu {
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

.flex-container {
  display: flex;

  .left-col {
    z-index: 3;
    flex: 0 0 400px;
    width: 400px !important;
    height: calc(100vh - 103px) !important;
    position: fixed;
    top: 48px !important;
    overflow: visible !important;
  }
  .right-col {
    transition: padding 200ms;
    // padding-bottom: 65px;
    padding-left: 10px;
    &.hierarchiesPinned {
      padding-left: 410px;
    }
  }
}

.hierarchies-collapsed {
  width: 20px;

  min-height: calc(100vh - 103px) !important;
  .showHierarchies {
    position: fixed;
    top: 72px;
    left: 5px;
  }
}

.theme--light .hierarchies-collapsed {
  background-color: white;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.theme--dark .hierarchies-collapsed,
.theme--dark.v-navigation-drawer {
  background-color: $primary-background-dark;
  border-right: 1px solid hsla(0, 0%, 100%, 0.12);
}

.showHierarchies {
  top: 22px;
  right: -15px;
}
</style>
<style>
div.v-treeview-node__label {
  font-size: 12px;
}
.v-treeview-node__toggle {
  max-width: 10px !important;
}
.v-treeview-node__root {
  padding: 0 !important;
  min-height: 25px !important;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .no-print-border,
  .no-print-border * {
    border: none !important;
    box-shadow: none !important;
  }
  .printFullWidth,
  .printFullWidth * {
    max-width: 100% !important;
    flex: none;
    padding-right: 6px !important;
  }
  .v-main {
    padding-top: 0;
  }
  .mainContent {
    padding: 0 !important;
  }
}
.v-input__icon--append-outer .v-icon {
  color: grey;
  font-size: 18px;
}

.customFlag {
  transform: scale(0.35) !important;
  -ms-transform: scale(0.35) !important;
  -webkit-transform: scale(0.35) !important;
  -moz-transform: scale(0.35) !important;
}

.narrowIcons {
  max-width: 27px;
  padding-top: 7px !important;
  padding-left: 0 !important;
}
</style>
<style>
/* set the CSS */

.node circle,
.node rect {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 3px;
}

.node text {
  font: 12px sans-serif;
}

.node--internal text {
  text-shadow: 0 1px 0 #fff, 0 -1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff;
}

.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 2px;
}

.compcell {
  text-align: center;
  border-right: solid lightgrey 1px;
}

.overflowHidden {
  overflow: hidden;
}
.overflowAuto {
  overflow: auto;
  height: 400px;
}

.title_cell {
  text-align: center;
  overflow: hidden;
  font-weight: bold;
  font-size: 15px;
}

.label_cell label.v-label,
.label_cell {
  text-align: left;
  margin-right: 5px;
  margin-left: 5px;
  color: #4343d3;
  overflow: hidden;
  /* font-weight: bold; */
  font-size: 13px;
  padding-top: 0px;
}
.compare_title {
  text-align: left;
  font-weight: bold;
  font-size: 14px;
}
.compare_sub_title {
  margin-left: 25px;
  text-align: left;
  /* font-weight: bold; */
  font-size: 13px;
}
.compare_expert {
  background-color: darkgreen;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  border-radius: 8px;
  margin: 3px 5px;
}
.compare_practioner {
  background-color: lightgreen;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  border-radius: 8px;
  margin: 3px 5px;
}
.compare_novice {
  background-color: orange;
  text-align: center;
  font-weight: bold;
  font-size: 13px;
  border-radius: 8px;
  margin: 3px 5px;
}
div.v-input__slot {
  margin-bottom: 0;
}
.v-input--selection-controls .v-input__slot > .v-label,
/* .label_cell, */
.col_header {
  white-space: nowrap;
  /* width: 30px;  */
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  font-size: 14px;
  width: 100%;
}
.scrollCol {
  padding: 5px;
  margin-top: -1px;
}
.dialogueRow {
  min-height: 50px;
  max-height: 50px;
}
.dialogueRowTags {
  min-height: 30px;
  max-height: 30px;
}
.dialogueRowHierarchy {
  min-height: 100px;
  max-height: 100px;
}
.dialogueColumn,
.dialogueColumnTop,
.dialogueColumnBottom {
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  margin-right: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dialogueColumnSame {
  background-color: rgb(238 246 227);
}
.dialogueColumnCheckBoxRow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dialogueColumnTop {
  border-top: 1px solid gray;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.dialogueColumnBottom {
  border-bottom: 1px solid gray;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.rowHeader,
.menuSubject {
  border: solid 1px #b7d1cd;
  border-radius: 5px;
  display: block;
  height: 24px;
  font-family: "Martel Sans", sans-serif;
  font-size: 12px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  background-color: white; /* alternate style */
}
.axis-background {
  background-color: #f9fafe; /* alternate style */
}
.menuSubject {
  width: fit-content;
  padding-top: 3px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #f4f1f1;
}
.menuLabel {
  width: fit-content;
  padding-top: 5px;
}
.subjectPicker {
  cursor: pointer;
  display: block;
  border: solid 1px #004d40;
  border-radius: 5px;
  /* height: 32px; */
  height: 95px;
  font-size: 16px;
  padding: 6px;
  padding-left: 12px;
  width: 100%;
}
.subjectText {
  font-size: 14px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  float: left;
  width: 90%;
}
.subjectIcon {
  margin-top: -3px;
  padding-right: 6px;
  float: right;
}
.changed,
.changedSelect > div > div > fieldset {
  border: solid 2px red !important;
}
.tableHeader {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 12px;
  border-top: 1px lightgray solid;
  border-bottom: 1px lightgray solid;
}
.descriptor {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  cursor: pointer;
}
.descriptor1line {
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
}
.descriptor2Line {
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
}
.descriptor3Line {
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
}
.descriptor4Line {
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
}
.descriptor5Line {
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
}
.descriptor6Line {
  -webkit-line-clamp: 6; /* number of lines to show */
  line-clamp: 6;
}
.descriptor7Line {
  -webkit-line-clamp: 7; /* number of lines to show */
  line-clamp: 7;
}
.descriptor8Line {
  -webkit-line-clamp: 8; /* number of lines to show */
  line-clamp: 8;
}

.review-user {
  padding-left: 10px;
  background-color: white;
  border-radius: 5px;
  margin-left: 10px;
  margin-bottom: 3px;
}
.feedback-row {
  background-color: #eceff1;
  border-radius: 8px;
  margin-left: 0;
  margin-bottom: 0;
}
.node-description {
  display: inline;
  border: solid 1px;
  padding: 0 5px;
  font-size: 12px;
  border-radius: 5px;
}
.measure-column {
  min-height: 34px;
  text-align: center;
}
.standard-column {
  min-height: 34px;
}

.leaf_node_count {
  text-align: center;
  border-radius: 16px;
  font-size: 13px;
  color: black;
  max-width: 65px;
  min-width: 20px;
  padding: 0 8px;
  display: inline-block;
  line-height: 19px;
  border: solid gray 2px;
}
.draggable {
  cursor: move;
  border: solid white 2px;
  border-radius: 5px;
  margin: -2px;
}
.draggable:hover {
  border: solid lightblue 2px;
  border-radius: 5px;
}
.drag-target {
  /*   width: 100px;
   height: 40px;
  border: 1px solid gray;
  border-radius: 6px;
  padding: 3px;
  margin: 2px; */
  min-height: 32px;
}
.column-heading {
  border-top: 2px solid lightgray;
  border-bottom: 2px solid lightgray;
  font-size: 12px;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* display: inline-block; */
  width: 100%;
}
.leftpanel-column-container {
  padding-left: 4px;
  padding-right: 4px;
}
.document_cell,
.label_cell {
  text-align: left;
  margin-right: 5px;
  margin-left: 5px;
  color: blue;
  font-size: 13px;
  padding-top: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}
.document_cell {
  /*   border: solid 1px gray;*/
  background-color: #eaf0f6;
  border-radius: 5px;
  padding-left: 3px;
  cursor: pointer;
}
</style>