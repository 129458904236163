var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tbody',_vm._l((_vm.visible_rows),function(d,di){return _c('tr',{key:'d' + d.level + '_' + d.detail.doc_id + '_' + di,class:d.level === 0 ? 'primary-document' : 'match-document-row',style:(d.expanded
        ? 'cursor: zoom-out;'
        : d.hasChildren
        ? 'cursor: zoom-in;'
        : '')},[_c('td',{style:(d.expanded || d.parentKey ? 'border-bottom: none;' : ''),on:{"click":function($event){return _vm.toggleShow(d)}}},[(d.parentKey)?_c('div',{staticStyle:{"width":"1px","position":"relative","height":"1px","top":"0px"},style:({
          left: 10 + (d.level - 1) * 20 + 'px',
        })},[_c('svg',{attrs:{"width":"100","height":d.hasChildren ? 3 : 30}},[_c('line',{staticStyle:{"stroke":"gray","stroke-width":"2"},attrs:{"x1":"0","y1":"1","x2":d.hasChildren ? 12 : 40,"y2":"1"}})])]):_vm._e(),(d.hasChildren && d.expanded)?_c('div',{staticStyle:{"width":"1px","position":"relative","height":"1px","top":"20px"},style:({
          left: 10 + d.level * 20 + 'px',
        })},[_c('svg',{attrs:{"width":"3","height":(d.visibleChildren - 1) * 48 + 40}},[_c('line',{staticStyle:{"stroke":"gray","stroke-width":"2"},attrs:{"x1":"1","y1":"0","x2":"1","y2":(d.visibleChildren - 1) * 48 + 40}})])]):_vm._e(),(d.hasChildren)?_c('v-icon',{style:({ marginLeft: d.level * 20 + 'px' })},[_vm._v(_vm._s(d.expanded ? "mdi-minus-box-outline" : "mdi-plus-box-outline"))]):_vm._e()],1),_vm._l((_vm.columns),function(c,ci){return _c('td',{key:'d' + d.detail.doc_id + 'c' + ci,class:d.level > 0
          ? 'match-document-col' +
            (ci === 0
              ? ' match-document-col-1'
              : ci === _vm.columns.length - 1
              ? ' match-document-col-last'
              : '')
          : '',style:((d.expanded
          ? 'border-bottom: none; '
          : d.level > 0
          ? 'border-top: white solid 5px; '
          : '') + _vm.getBackColour(d.level)),on:{"click":function($event){return _vm.toggleShow(d)}}},[(ci === 0 && d.level > 0)?_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"teal","size":"25"}},[_c('span',{staticClass:"white--text",staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(Number.parseFloat(d.detail.match_pct_content).toFixed()))])]):_vm._e(),(c.value === 'doc_status_text')?_c('DocStatus',{attrs:{"doc_id":d.detail.doc_id,"sideAddendum":""}}):_c('span',[_vm._v(_vm._s(d.detail.detail ? d.detail.detail[c.value] : d.detail[c.value]))])],1)}),_c('td',{style:(d.expanded || di < d.childCount - 1 ? 'border-bottom: none;' : '')},[_c('v-chip',{staticStyle:{"margin-right":"5px","padding-left":"5px","padding-right":"5px"},attrs:{"small":"","label":""}},[_c('DocSummary',{attrs:{"doc_id":d.detail.doc_id,"icon":""},on:{"openDocument":_vm.openDocument,"copyDocument":function($event){return _vm.$emit('copyDocument', $event)}}})],1),(d.childCount)?_c('v-badge',{attrs:{"color":"green","right":"","overlap":"","content":d.childCount}},[_c('v-chip',{staticStyle:{"margin-left":"2px","padding-left":"5px","padding-right":"5px"},attrs:{"small":"","label":"","dark":"","color":"blue darken-2"},on:{"click":function($event){return _vm.$emit('compareDocDetailsFull', d)}}},[_c('v-icon',{attrs:{"small":"","title":"Full compare view"}},[_vm._v("mdi-content-duplicate")])],1),_c('v-chip',{staticStyle:{"margin-left":"2px","padding-left":"5px","padding-right":"5px"},attrs:{"small":"","label":"","dark":"","color":"blue darken-2"},on:{"click":function($event){return _vm.$emit('compareDocDetails', d)}}},[_c('v-icon',{attrs:{"small":"","title":"Side-by-side view"}},[_vm._v("mdi-compare")])],1)],1):_vm._e()],1)],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }