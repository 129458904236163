<template>
  <tbody>
    <tr
      v-for="(d, di) in visible_rows"
      :key="'d' + d.level + '_' + d.detail.doc_id + '_' + di"
      :class="d.level === 0 ? 'primary-document' : 'match-document-row'"
      :style="
        d.expanded
          ? 'cursor: zoom-out;'
          : d.hasChildren
          ? 'cursor: zoom-in;'
          : ''
      "
    >
      <td
        :style="d.expanded || d.parentKey ? 'border-bottom: none;' : ''"
        @click="toggleShow(d)"
      >
        <div
          v-if="d.parentKey"
          :style="{
            left: 10 + (d.level - 1) * 20 + 'px',
          }"
          style="width: 1px; position: relative; height: 1px; top: 0px"
        >
          <svg width="100" :height="d.hasChildren ? 3 : 30">
            <line
              x1="0"
              y1="1"
              :x2="d.hasChildren ? 12 : 40"
              y2="1"
              style="stroke: gray; stroke-width: 2"
            ></line>
          </svg>
        </div>
        <div
          v-if="d.hasChildren && d.expanded"
          style="width: 1px; position: relative; height: 1px; top: 20px"
          :style="{
            left: 10 + d.level * 20 + 'px',
          }"
        >
          <svg width="3" :height="(d.visibleChildren - 1) * 48 + 40">
            <line
              x1="1"
              y1="0"
              x2="1"
              :y2="(d.visibleChildren - 1) * 48 + 40"
              style="stroke: gray; stroke-width: 2"
            ></line>
          </svg>
        </div>
        <v-icon
          v-if="d.hasChildren"
          :style="{ marginLeft: d.level * 20 + 'px' }"
          >{{
            d.expanded ? "mdi-minus-box-outline" : "mdi-plus-box-outline"
          }}</v-icon
        >
      </td>
      <td
        :style="
          (d.expanded
            ? 'border-bottom: none; '
            : d.level > 0
            ? 'border-top: white solid 5px; '
            : '') + getBackColour(d.level)
        "
        v-for="(c, ci) in columns"
        :key="'d' + d.detail.doc_id + 'c' + ci"
        @click="toggleShow(d)"
        :class="
          d.level > 0
            ? 'match-document-col' +
              (ci === 0
                ? ' match-document-col-1'
                : ci === columns.length - 1
                ? ' match-document-col-last'
                : '')
            : ''
        "
      >
        <v-avatar
          v-if="ci === 0 && d.level > 0"
          color="teal"
          size="25"
          class="mr-2"
        >
          <span class="white--text" style="font-size: 12px">{{
            Number.parseFloat(d.detail.match_pct_content).toFixed()
          }}</span>
        </v-avatar>
        <DocStatus
          v-if="c.value === 'doc_status_text'"
          :doc_id="d.detail.doc_id"
          sideAddendum
        ></DocStatus>
        <span v-else>{{
          d.detail.detail ? d.detail.detail[c.value] : d.detail[c.value]
        }}</span>
      </td>
      <td
        :style="
          d.expanded || di < d.childCount - 1 ? 'border-bottom: none;' : ''
        "
      >
        <v-chip
          small
          label
          style="margin-right: 5px; padding-left: 5px; padding-right: 5px"
        >
          <DocSummary
            :doc_id="d.detail.doc_id"
            icon
            @openDocument="openDocument"
            @copyDocument="$emit('copyDocument', $event)"
          ></DocSummary>
        </v-chip>
        <v-badge
          v-if="d.childCount"
          color="green"
          right
          overlap
          :content="d.childCount"
          ><v-chip
            small
            label
            dark
            color="blue darken-2"
            style="margin-left: 2px; padding-left: 5px; padding-right: 5px"
            @click="$emit('compareDocDetailsFull', d)"
          >
            <v-icon small title="Full compare view"
              >mdi-content-duplicate</v-icon
            ></v-chip
          ><v-chip
            small
            label
            dark
            color="blue darken-2"
            style="margin-left: 2px; padding-left: 5px; padding-right: 5px"
            @click="$emit('compareDocDetails', d)"
          >
            <v-icon small title="Side-by-side view">mdi-compare</v-icon></v-chip
          >
        </v-badge>
      </td>
    </tr>
  </tbody>
</template>

<script>
// import ResponseHandler from "@/components/ResponseHandler";
import DocStatus from "@/components/common/DocStatus";
import DocSummary from "@/components/common/DocViewPopUp";
export default {
  name: "SimilarityTableRow",
  components: {
    DocStatus,
    DocSummary,
  },
  props: {
    rows: { type: Array, required: true },
    columns: { type: Array, required: true },
    hierarchical: { type: Boolean, required: false, default: false },
  },
  data: function () {
    return {
      internal_rows: [],
      visible_rows: [],
      response: null,

    };
  },
  watch: {
    rows(val) {
      if (val) this.setup();
    },
  },
  computed: {
    rowClickAction() {
      return "opendoc";
    },
  },
  created() {
    this.setup();
  },
  methods: {
    setup() {
      let internal_rows = [];
      let addRows = (rows, level, parentKey) => {
        rows.forEach((r, ri) => {
          const key = `${parentKey ? parentKey + "_" : ""}${r.doc_id}`;
          internal_rows.push({
            level: level,
            detail: r,
            key: key,
            parentKey: parentKey,
            hasChildren: !!r.filteredItems?.length,
            childCount: r.filteredItems?.length || 0,
            expanded: false,
            visible: level === 0,
            index: ri,
            visibleChildren: 0,
          });
          if (r.filteredItems) {
            addRows(r.filteredItems, level + 1, key);
          }
        });
      };
      addRows(this.rows, 0, null);
      this.internal_rows = internal_rows;
      this.visible_rows = internal_rows.filter((x) => x.visible);
    },
    getBackColour(level) {
      const factor = 255 - level * 10;
      return `background-color: rgb(${factor}, ${factor}, ${factor});`;
    },
    toggleShow(row) {
      row.expanded = !row.expanded;
      const show = row.expanded;
      const showChildren = (key) => {
        this.internal_rows
          .filter((x) => x.parentKey === key && x.visible !== show)
          .forEach((x) => {
            x.visible = x.expanded && show ? true : show;
            if (this.hierarchical && !show) {
              x.expanded = false;
              showChildren(x.key);
            }
          });
      };
      showChildren(row.key);
      this.visible_rows = this.internal_rows.filter((x) => x.visible);
      const countOpenChildren = (row, level) => {
        let directChildren = this.visible_rows.filter(
          (x) => x.parentKey === row.key && x.level === level
        );
        let count = directChildren.length;
        directChildren.forEach((c, ci) => {
          countOpenChildren(c, level + 1);
          if (ci < directChildren.length - 1) count += c.visibleChildren;
        });
        row.visibleChildren = count;
      };
      this.visible_rows
        .filter((r) => r.level === 0)
        .forEach((r) => {
          countOpenChildren(r, 1);
        });
    },
    openDocument(item) {
      this.contextMenu = false;
      this.$emit("openDocument", item);
    },
    openContextMenu(e, item) {
      this.currentTask = item;
      e.preventDefault();
      this.contextMenu = false;
      this.posX = e.clientX;
      this.posY = e.clientY;
      this.$nextTick(() => {
        this.contextMenu = true;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.primary-document {
  font-weight: bold;
  border-top: 1px solid lightgray;
}
.match-document-row {
  margin-left: 10px;
  margin-bottom: 1px;
}
.match-document-col-action {
  padding-top: 5px;
  padding-bottom: 5px;
}
.match-document-col {
  background-color: #f4f3f3;
  padding-top: 5px;
  padding-bottom: 5px;
}
.match-document-col-1 {
  margin-right: -20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.match-document-col-last {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.vertical-line,
.vertical-line-linked,
.vertical-line-1,
.vertical-line-2 {
  display: inline-block;
  float: left;
  width: 2px;
  background-color: lightgray;
  margin-left: 11px;
  margin-top: -40px;
  height: calc(50% + 25px);
  //   margin-top: -40px;
  //   height: calc(100% + 14px);
  z-index: -1;
}
.horizontal-line {
  display: inline-block;
  float: left;
  width: calc(100% + 6px);
  height: 2px;
  background-color: lightgray;
  //   margin-top: 10px;
  margin-left: 11px;
  //   margin-right: -12px;
}
.vertical-line-1,
.vertical-line-2 {
  margin-top: -34px;
  height: calc(50% + 15px);
}
.vertical-line-2 {
  margin-left: calc(7px + 100% / -12);
}
</style>
